/** @format */

import PublicHeader from "../components/core/public/PublicHeader";
import { Outlet } from "react-router-dom";

const PublicLayout = () => {
  return (
    <>
      <div className="h-screen grid grid-rows-layout grid-cols-9">
        <div className="row-span-1 col-span-9">
          <PublicHeader />
        </div>
        <div className="row-span-11 col-span-9">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default PublicLayout;
