/** @format */

import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from 'recharts';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../../components/ui/breadcrumb";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { Skeleton } from "../../components/ui/skeleton";
import { toast } from "../../components/ui/use-toast";
import { RefreshCw, FileJson, AlertCircle } from "lucide-react";
import { Button } from "../../components/ui/button";
import { Alert, AlertDescription, AlertTitle } from "../../components/ui/alert";

// Explicitly NOT importing GlossaryContext to avoid any dependency on it
// import { useGlossary } from "../../contexts/GlossaryContext";

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d'];

// Create axios instance with base URL
const api = axios.create({
  baseURL: 'https://mytranslate.app/',
  //baseURL: 'http://localhost:3000',
  timeout: 10000,
});

const GlossaryDashboard = () => {
  const [domains, setDomains] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [stats, setStats] = useState({
    totalTerms: 0,
    averageTermsPerDomain: 0,
    domainStats: [],
    totalFiles: 0
  });
  const [gttFiles, setGttFiles] = useState([]);
  const [lastUpdated, setLastUpdated] = useState(new Date());

  // Function to load GTT files directly
  const loadGTTFiles = useCallback(async () => {
    try {
      console.log('Fetching GTT files...');
      const response = await api.get('/utm-ittbm/api/gtt/files');
      console.log('GTT files response:', response.data);
      
      if (Array.isArray(response.data)) {
        // Filter out .DS_Store and other non-JSON files
        const jsonFiles = response.data.filter(file => file.name.endsWith('.json'));
        console.log('Filtered JSON files:', jsonFiles);
        setGttFiles(jsonFiles);
        return jsonFiles;
      } else {
        throw new Error('Invalid response format for GTT files');
      }
    } catch (error) {
      console.error('Error loading GTT files:', error);
      setError(`Failed to load GTT files: ${error.message}`);
      toast({
        title: "Error",
        description: "Failed to load GTT files. Please try again.",
        variant: "destructive",
      });
      return [];
    }
  }, []);

  // Function to load file content
  const loadFileContent = useCallback(async (filename) => {
    try {
      console.log(`Loading content for file: ${filename}`);
      const response = await api.get(`/utm-ittbm/api/gtt/file/${encodeURIComponent(filename)}`);
      console.log(`File content for ${filename}:`, response.data);
      return response.data;
    } catch (error) {
      console.error(`Error loading file content for ${filename}:`, error);
      toast({
        title: "Error",
        description: `Failed to load content for ${filename}.`,
        variant: "destructive",
      });
      return null;
    }
  }, []);

  // Force reload GTT files from server
  const forceReloadGTTFiles = useCallback(async () => {
    try {
      console.log('Forcing reload of GTT files on server...');
      await api.post('/utm-ittbm/api/gtt/reload');
      console.log('Server reload successful');
      return true;
    } catch (error) {
      console.error('Error forcing reload on server:', error);
      toast({
        title: "Error",
        description: "Failed to reload GTT files on server. Please try again.",
        variant: "destructive",
      });
      return false;
    }
  }, []);

  const loadDomainData = useCallback(async (forceReload = false) => {
    try {
      setLoading(true);
      setError(null);
      
      // Force reload on server if requested
      if (forceReload) {
        const reloadSuccess = await forceReloadGTTFiles();
        if (!reloadSuccess) {
          setLoading(false);
          return;
        }
      }
      
      // First, load GTT files
      const files = await loadGTTFiles();
      
      if (!files || files.length === 0) {
        console.log('No files found or error loading files');
        setStats({
          totalTerms: 0,
          averageTermsPerDomain: 0,
          domainStats: [],
          totalFiles: 0
        });
        setDomains([]);
        setLoading(false);
        return;
      }
      
      // Extract domain names from filenames
      const domainNames = files.map(file => {
        // Remove .json extension
        const baseName = file.name.replace('.json', '');
        return { name: baseName };
      });
      
      console.log('Extracted domain names:', domainNames);
      setDomains(domainNames);
      
      // Calculate statistics
      const domainStats = [];
      let totalTerms = 0;
      
      // For each file, load its content
      for (const file of files) {
        try {
          const content = await loadFileContent(file.name);
          
          if (Array.isArray(content)) {
            const domainName = file.name.replace('.json', '');
            const validTerms = content.filter(term => 
              term && 
              typeof term === 'object' && 
              term.english && 
              term.malay
            );
            
            console.log(`Domain ${domainName} has ${validTerms.length} valid terms`);
            
            domainStats.push({
              name: domainName,
              terms: validTerms.length
            });
            
            totalTerms += validTerms.length;
          }
        } catch (error) {
          console.error(`Error processing file ${file.name}:`, error);
          domainStats.push({
            name: file.name.replace('.json', ''),
            terms: 0
          });
        }
      }
      
      const averageTerms = domainStats.length > 0 
        ? Math.round(totalTerms / domainStats.length) 
        : 0;

      console.log('Final statistics:', {
        totalTerms,
        averageTermsPerDomain: averageTerms,
        domainStats,
        totalFiles: files.length
      });

      setStats({
        totalTerms,
        averageTermsPerDomain: averageTerms,
        domainStats,
        totalFiles: files.length
      });
      
      // Update last updated timestamp
      setLastUpdated(new Date());

    } catch (error) {
      console.error('Error loading domain data:', error);
      setError(`Failed to load domain data: ${error.message}`);
      toast({
        title: "Error",
        description: "Failed to load glossary data. Please try again.",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  }, [loadGTTFiles, loadFileContent, forceReloadGTTFiles]);

  // Initial load
  useEffect(() => {
    console.log('GlossaryDashboard mounted - loading data directly from GTTFiles');
    loadDomainData();
    
    // Set up an interval to refresh data every 5 minutes
    const intervalId = setInterval(() => {
      console.log('Auto-refreshing dashboard data');
      loadDomainData();
    }, 5 * 60 * 1000); // 5 minutes
    
    // Clean up interval on unmount
    return () => clearInterval(intervalId);
  }, [loadDomainData]);
  
  // Add an effect that runs when the component becomes visible again
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        console.log('Page became visible - reloading data');
        loadDomainData();
      }
    };
    
    document.addEventListener('visibilitychange', handleVisibilityChange);
    
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [loadDomainData]);

  const handleRefresh = () => {
    loadDomainData(true); // Force reload
    toast({
      title: "Refreshed",
      description: "Dashboard data has been refreshed from server.",
    });
  };

  const renderBarChart = () => (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={stats.domainStats} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="terms" fill="#8884d8" name="Number of Terms" />
      </BarChart>
    </ResponsiveContainer>
  );

  const renderPieChart = () => (
    <ResponsiveContainer width="100%" height={300}>
      <PieChart>
        <Pie
          data={stats.domainStats}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={({ name, percent }) => `${name} (${(percent * 100).toFixed(0)}%)`}
          outerRadius={80}
          fill="#8884d8"
          dataKey="terms"
        >
          {stats.domainStats.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  );

  if (loading) {
    return (
      <div className="p-6">
        <Skeleton className="h-[400px]" />
      </div>
    );
  }

  return (
    <>
      <Breadcrumb className="pl-6 pt-6">
        <div className="flex justify-between items-center w-full">
          <span className="text-xl font-semibold">Glossary Dashboard</span>
          <Button 
            variant="outline" 
            size="sm" 
            className="mr-6"
            onClick={handleRefresh}
          >
            <RefreshCw className="h-4 w-4 mr-2" />
            Refresh
          </Button>
        </div>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/dashboard">Dashboard</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator>/</BreadcrumbSeparator>
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/glossary-domain">Glossary Management</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator>/</BreadcrumbSeparator>
          <BreadcrumbItem>
            <BreadcrumbPage>Glossary Dashboard</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <div className="p-6 space-y-6">
        {error && (
          <Alert variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>
              {error}
              <div className="mt-2">
                <Button 
                  variant="outline" 
                  size="sm" 
                  onClick={handleRefresh}
                >
                  Try Again
                </Button>
              </div>
            </AlertDescription>
          </Alert>
        )}

        {/* Last Updated Info */}
        <div className="text-sm text-gray-500 flex justify-end">
          Last updated: {lastUpdated.toLocaleString()}
        </div>

        {/* Summary Cards */}
        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
          <Card>
            <CardHeader>
              <CardTitle>Total Domains</CardTitle>
              <CardDescription>Number of glossary domains</CardDescription>
            </CardHeader>
            <CardContent>
              <p className="text-3xl font-bold">{domains.length}</p>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>Total Terms</CardTitle>
              <CardDescription>Total number of terms across all domains</CardDescription>
            </CardHeader>
            <CardContent>
              <p className="text-3xl font-bold">{stats.totalTerms}</p>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>Average Terms per Domain</CardTitle>
              <CardDescription>Average number of terms in each domain</CardDescription>
            </CardHeader>
            <CardContent>
              <p className="text-3xl font-bold">{stats.averageTermsPerDomain}</p>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>Total Files</CardTitle>
              <CardDescription>Number of GTT files in the system</CardDescription>
            </CardHeader>
            <CardContent>
              <p className="text-3xl font-bold">{stats.totalFiles}</p>
            </CardContent>
          </Card>
        </div>

        {/* Files List */}
        <Card>
          <CardHeader>
            <CardTitle>GTT Files</CardTitle>
            <CardDescription>List of glossary files in the system</CardDescription>
          </CardHeader>
          <CardContent>
            {gttFiles.length > 0 ? (
              <div className="grid gap-2 md:grid-cols-2 lg:grid-cols-3">
                {gttFiles.map((file, index) => (
                  <div key={index} className="flex items-center p-2 border rounded">
                    <FileJson className="h-5 w-5 mr-2 text-blue-500" />
                    <span className="text-sm truncate">{file.name}</span>
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-center text-gray-500 py-10">No files available</p>
            )}
          </CardContent>
        </Card>

        {/* Charts */}
        <div className="grid gap-6 md:grid-cols-2">
          <Card>
            <CardHeader>
              <CardTitle>Terms by Domain</CardTitle>
              <CardDescription>Distribution of terms across domains</CardDescription>
            </CardHeader>
            <CardContent>
              {stats.domainStats.length > 0 ? renderBarChart() : (
                <p className="text-center text-gray-500 py-10">No data available</p>
              )}
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>Domain Distribution</CardTitle>
              <CardDescription>Percentage of terms in each domain</CardDescription>
            </CardHeader>
            <CardContent>
              {stats.domainStats.length > 0 ? renderPieChart() : (
                <p className="text-center text-gray-500 py-10">No data available</p>
              )}
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
};

export default GlossaryDashboard;
