/** @format */

import React, { useState, useEffect, useCallback } from "react";
import { toast } from "../../components/ui/use-toast";
import { FileJson, RefreshCw } from "lucide-react";
import axios from 'axios';
import PlaygroundSimpleHeader from "../../components/core/public/PlaygroundSimpleHeader";
import PlaygroundNavigation from "../../components/core/public/PlaygroundNavigation";

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000; // 1 second

const GTTPlayground = () => {
  const [terms, setTerms] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredTerms, setFilteredTerms] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [gttFiles, setGttFiles] = useState([]);
  const [selectedGttFile, setSelectedGttFile] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  const [selectedLetter, setSelectedLetter] = useState(null);

  // Create alphabet array for navigation
  const alphabet = Array.from('ABCDEFGHIJKLMNOPQRSTUVWXYZ');

  const loadFileContent = useCallback(async (filename, retry = 0) => {
    try {
      setIsLoading(true);
      setTerms([]);
      setFilteredTerms([]);
      setError(null);

      const response = await axios.get(`/utm-ittbm/api/gtt/file/${encodeURIComponent(filename)}`);
      console.log('File content response:', response.data);
      
      if (Array.isArray(response.data)) {
        // Filter out any invalid entries
        const validTerms = response.data.filter(term => 
          term && 
          typeof term === 'object' && 
          typeof term.english === 'string' && 
          typeof term.malay === 'string'
        );
        
        if (validTerms.length > 0) {
          setTerms(validTerms);
          setFilteredTerms(validTerms);
          setSelectedGttFile(filename);
          setRetryCount(0); // Reset retry count on success
          
          toast({
            title: "File loaded",
            description: `Loaded ${validTerms.length} terms from ${filename}`,
          });
        } else {
          throw new Error('No valid terms found in file');
        }
      } else {
        throw new Error('Invalid file content format');
      }
    } catch (error) {
      console.error('Error loading file content:', error);
      
      // Retry logic
      if (retry < MAX_RETRIES) {
        console.log(`Retrying... Attempt ${retry + 1} of ${MAX_RETRIES}`);
        setRetryCount(retry + 1);
        setTimeout(() => loadFileContent(filename, retry + 1), RETRY_DELAY);
        return;
      }
      
      setError('Failed to load file content. Please try again.');
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to load file content",
      });
    } finally {
      setIsLoading(false);
    }
  }, []);

  const loadGTTFiles = useCallback(async (retry = 0) => {
    if (isLoading) return; // Prevent multiple simultaneous calls
    
    try {
      setIsLoading(true);
      setError(null);
      
      const response = await axios.get('/utm-ittbm/api/gtt/files');
      console.log('GTT files response:', response.data);
      
      if (Array.isArray(response.data)) {
        // Filter out .DS_Store and other non-JSON files
        const jsonFiles = response.data.filter(file => file.name.endsWith('.json'));
        if (jsonFiles.length > 0) {
          // Sort files alphabetically by name
          const sortedFiles = [...jsonFiles].sort((a, b) => a.name.localeCompare(b.name));
          setGttFiles(sortedFiles);
          setRetryCount(0); // Reset retry count on success
          
          // Load first file only if no file is currently selected
          if (!selectedGttFile) {
            loadFileContent(sortedFiles[0].name);
          }
        } else {
          throw new Error('No JSON files found');
        }
      } else {
        throw new Error('Invalid response format for GTT files');
      }
    } catch (error) {
      console.error('Error loading GTT files:', error);
      
      // Retry logic
      if (retry < MAX_RETRIES) {
        console.log(`Retrying... Attempt ${retry + 1} of ${MAX_RETRIES}`);
        setRetryCount(retry + 1);
        setTimeout(() => loadGTTFiles(retry + 1), RETRY_DELAY);
        return;
      }
      
      setError('Failed to load GTT files. Please try again.');
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to load GTT files",
      });
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Load GTT files on component mount
  useEffect(() => {
    loadGTTFiles();
  }, []);

  // Filter and sort terms when search query or selected letter changes
  useEffect(() => {
    let filtered = terms;
    
    // Apply search query filter
    if (searchQuery) {
      filtered = filtered.filter(term => 
        term.english.toLowerCase().includes(searchQuery.toLowerCase()) ||
        term.malay.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
    
    // Apply letter filter
    if (selectedLetter) {
      filtered = filtered.filter(term => 
        term.english.toUpperCase().startsWith(selectedLetter)
      );
    }
    
    // Sort filtered terms alphabetically by English term
    const sortedFiltered = [...filtered].sort((a, b) => a.english.localeCompare(b.english));
    setFilteredTerms(sortedFiltered);
  }, [searchQuery, terms, selectedLetter]);

  const handleManualRefresh = () => {
    loadGTTFiles();
  };

  const highlightSearchText = (text, searchQuery) => {
    if (!searchQuery.trim()) return text;
    
    const regex = new RegExp(`(${searchQuery})`, 'gi');
    const parts = text.split(regex);
    
    return parts.map((part, index) => {
      if (part.toLowerCase() === searchQuery.toLowerCase()) {
        return <span key={index} className="text-red-500 font-medium">{part}</span>;
      }
      return part;
    });
  };

  const getFileNameWithoutExtension = (filename) => {
    return filename.replace('.json', '');
  };

  const handleLetterClick = (letter) => {
    setSelectedLetter(letter === selectedLetter ? null : letter);
    setSearchQuery(''); // Clear search query when letter is selected
  };

  // Get available letters from current terms
  const getAvailableLetters = () => {
    const availableLetters = new Set();
    terms.forEach(term => {
      const firstLetter = term.english.charAt(0).toUpperCase();
      if (alphabet.includes(firstLetter)) {
        availableLetters.add(firstLetter);
      }
    });
    return availableLetters;
  };

  return (
    <div className="min-h-screen bg-white">
      <PlaygroundSimpleHeader keyPrefix="playgroundPage" />
      <PlaygroundNavigation />
      
      <div className="container mx-auto px-4 py-8">
        <div className="max-w-10xl mx-auto">
          {/* Header */}
          <div className="text-center mb-12">
            <h1 className="text-4xl font-bold text-gray-900 mb-4">
              Technical Terms
            </h1>
            <p className="text-lg text-gray-600">
              Search and explore technicalterms across different domains
            </p>
          </div>

          {/* Main Content */}
          <div className="bg-white rounded-2xl shadow-xl p-6 md:p-8">
            {/* GTT Files List */}
            <div className="mb-8">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold text-gray-900">
                  Term Categories
                  {retryCount > 0 && (
                    <span className="ml-2 text-sm text-gray-500">
                      (Retrying... {retryCount}/{MAX_RETRIES})
                    </span>
                  )}
                </h2>
                <button
                  onClick={handleManualRefresh}
                  className="flex items-center gap-2 px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors"
                  disabled={isLoading}
                >
                  <RefreshCw className={`h-5 w-5 ${isLoading ? 'animate-spin' : ''}`} />
                  Refresh Files
                </button>
              </div>
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
                {gttFiles.length > 0 ? (
                  gttFiles.map((file, index) => (
                    <div
                      key={index}
                      className={`p-4 border rounded-lg hover:shadow-md transition-all cursor-pointer ${
                        selectedGttFile === file.name ? 'border-indigo-500 bg-indigo-50' : ''
                      }`}
                      onClick={() => loadFileContent(file.name)}
                    >
                      <div className="flex items-center gap-2">
                        <FileJson className="h-5 w-5 text-indigo-600" />
                        <span className="text-sm font-medium truncate">{getFileNameWithoutExtension(file.name)}</span>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="col-span-full p-4 border rounded-lg text-center text-gray-500">
                    No Term Categories found. Please upload some files.
                  </div>
                )}
              </div>
            </div>

            {/* Search Bar */}
            <div className="mb-8">
              <div className="w-full">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Search Terms
                </label>
                <div className="relative">
                  <input
                    type="text"
                    value={searchQuery}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                      setSelectedLetter(null); // Clear letter filter when searching
                    }}
                    placeholder="Search English or Malay terms..."
                    className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    disabled={isLoading}
                  />
                  <span className="absolute left-3 top-2.5">🔍</span>
                </div>
              </div>
            </div>

            {/* Alphabetical Navigation */}
            <div className="mb-6">
              <div className="flex flex-wrap gap-2 justify-center">
                {alphabet.map((letter) => {
                  const isAvailable = getAvailableLetters().has(letter);
                  return (
                    <button
                      key={letter}
                      onClick={() => handleLetterClick(letter)}
                      disabled={!isAvailable || isLoading}
                      className={`
                        w-8 h-8 rounded-full font-medium text-sm
                        flex items-center justify-center
                        transition-colors
                        ${isAvailable 
                          ? selectedLetter === letter
                            ? 'bg-indigo-600 text-white'
                            : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                          : 'bg-gray-50 text-gray-300 cursor-not-allowed'
                        }
                      `}
                    >
                      {letter}
                    </button>
                  );
                })}
              </div>
              {selectedLetter && (
                <div className="text-center mt-2">
                  <button
                    onClick={() => setSelectedLetter(null)}
                    className="text-sm text-indigo-600 hover:text-indigo-800"
                  >
                    Clear Filter
                  </button>
                </div>
              )}
            </div>

            {/* Table */}
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="border-b">
                    <th className="py-3 px-4 text-left font-medium text-gray-700">English Term</th>
                    <th className="py-3 px-4 text-left font-medium text-gray-700">Malay Term</th>
                    <th className="py-3 px-4 text-left font-medium text-gray-700">Domain</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colSpan="3" className="text-center py-8">
                        <div className="flex justify-center items-center">
                          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
                          <span className="ml-3">Loading...</span>
                        </div>
                      </td>
                    </tr>
                  ) : error ? (
                    <tr>
                      <td colSpan="3" className="text-center py-8 text-red-500">
                        {error}
                      </td>
                    </tr>
                  ) : filteredTerms.length > 0 ? (
                    filteredTerms.map((term, index) => (
                      <tr key={index} className="border-b last:border-b-0 hover:bg-gray-50">
                        <td className="py-3 px-4">{highlightSearchText(term.english, searchQuery)}</td>
                        <td className="py-3 px-4">{highlightSearchText(term.malay, searchQuery)}</td>
                        <td className="py-3 px-4">{getFileNameWithoutExtension(selectedGttFile)}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3" className="text-center py-8 text-gray-500">
                        {selectedGttFile ? (
                          searchQuery ? 
                            "No matching terms found" : 
                            "No terms available in this file"
                        ) : (
                          "Please select a file to view terms"
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GTTPlayground;
