/** @format */

import Dashboard from "../pages/Dashboard/Dashboard";
import UserLayout from "../layouts/UserLayout";
import TextTranslate from "../pages/TextTranslate/TextTranslate";
import ComingSoon from "../pages/ComingSoon/ComingSoon";
import FileTranslate from "../pages/FileTranslate/FileTranslate";
import GlossaryTechnicalTerm from "../pages/GTT/GlossaryTechnicalTerm";
import TermBaseDictionary from "../pages/GTT/TermBaseDictionary";
import TermBaseDomain from "../pages/GTT/TermBaseDomain";
import ResourcesTermBase from "../pages/GTT/ResourcesTermBase";
import ConvertExcelToJson from "../pages/GlossaryManagement/ConvertExcelToJson";
import GlossaryDomain from "../pages/GlossaryManagement/GlossaryDomain";
import GlossaryDashboard from "../pages/GlossaryManagement/GlossaryDashboard";
import MergeJsonFiles from "../pages/GlossaryManagement/MergeJsonFiles";
import AIModelDashboard from "../pages/AIModelManagement/AIModelDashboard";
import ModelSettings from "../pages/AIModelManagement/ModelSettings";
import TranslateDashboard from "../pages/TranslateManagement/TranslateDashboard";
import TranslateResult from "../pages/TranslateManagement/TranslateResult";
import TextTranslateResult from "../pages/TranslateManagement/TextTranslateResult";
import TextTranslateAnalysis from "../pages/TranslateManagement/TextTranslateAnalysis";
import TextBatchTranslate from "../pages/TextBatchTranslate/TextBatchTranslate";
import FileExtraction from "../pages/FileExtraction/FileExtraction";

const userRoutes = [
  {
    path: "/app",
    element: <UserLayout />,
    children: [
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "translate-dashboard",
        element: <TranslateDashboard />,
      },
      {
        path: "file-translate-results",
        element: <TranslateResult />,
      },
      {
        path: "text-translate-results",
        element: <TextTranslateResult />,
      },
      {
        path: "text-translate-analysis/:id",
        element: <TextTranslateAnalysis />,
      },
      {
        path: "text-batch-translate",
        element: <TextBatchTranslate />,
      },
      {
        path: "text-translate",
        element: <TextTranslate />,
      },
      {
        path: "file-translate",
        element: <FileTranslate />,
      },
      {
        path: "file-extraction",
        element: <FileExtraction />,
      },
      {
        path: "glossary",
        element: <GlossaryTechnicalTerm />,
      },
      {
        path: "term-base-dictionary",
        element: <TermBaseDictionary />,
      },
      {
        path: "term-base-domain",
        element: <TermBaseDomain />,
      },
      {
        path: "resources-term-base",
        element: <ResourcesTermBase />,
      },
      {
        path: "convert-glossary",
        element: <ConvertExcelToJson />,
      },
      {
        path: "domain-glossary",
        element: <GlossaryDomain />,
      },
      {
        path: "dashboard-glossary",
        element: <GlossaryDashboard />,
      },
      {
        path: "merge-json",
        element: <MergeJsonFiles />,
      },
      {
        path: "ai-models",
        element: <AIModelDashboard />,
      },
      {
        path: "ai-models/history",
        element: <ComingSoon />,
      },
      {
        path: "ai-models/settings",
        element: <ModelSettings />,
      },
      {
        path: "*",
        element: <ComingSoon />,
      },
    ],
  },
];

export default userRoutes;
