/** @format */

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Card, CardContent } from '../../components/ui/card';
import { Button } from '../../components/ui/button';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../components/ui/table';
import { Input } from '../../components/ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select";
import mammoth from 'mammoth';
import { Document, Packer, Table as DocxTable, TableRow as DocxTableRow, TableCell as DocxTableCell, Paragraph, HeadingLevel } from 'docx';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/ui/popover";
import { Search } from 'lucide-react';
import PlaygroundSimpleHeader from "../../components/core/public/PlaygroundSimpleHeader";
import PlaygroundNavigation from "../../components/core/public/PlaygroundNavigation";
import { debounce } from 'lodash';

const ManualTranslationConcordancePlayground = () => {
  const [tableData, setTableData] = useState([]);
  const [selectedText, setSelectedText] = useState("");
  const [concordanceResults, setConcordanceResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [popoverPosition, setPopoverPosition] = useState({ x: 0, y: 0 });
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const fileInputRef = useRef(null);
  const popoverRef = useRef(null);
  const [editingIndex, setEditingIndex] = useState(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [resourceTerms, setResourceTerms] = useState([]);
  const [isLoadingTerms, setIsLoadingTerms] = useState(false);
  const abortControllerRef = useRef(null);
  const resourceCacheRef = useRef(new Map());

  // Cleanup function
  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      // Clear cache and references
      resourceCacheRef.current.clear();
      setTableData([]);
      setResourceTerms([]);
      setIsLoadingTerms(false);
      setConcordanceResults([]);
      setIsPopoverOpen(false);
    };
  }, []);

  const fetchAllResourceTerms = async () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();
    
    setIsLoadingTerms(true);
    let allTerms = [];
    
    try {
      // First check if we have cached resource list
      let resourceFiles;
      const cacheKey = 'resource-list';
      
      if (resourceCacheRef.current.has(cacheKey)) {
        resourceFiles = resourceCacheRef.current.get(cacheKey);
      } else {
        const response = await fetch('/api/resources/list', {
          signal: abortControllerRef.current.signal
        });
        if (!response.ok) throw new Error('Failed to fetch resources list');
        resourceFiles = await response.json();
        resourceCacheRef.current.set(cacheKey, resourceFiles);
      }

      // Process files in chunks to prevent freezing
      const chunkSize = 3; // Process 3 files at a time
      for (let i = 0; i < resourceFiles.length; i += chunkSize) {
        if (abortControllerRef.current.signal.aborted) return;

        const chunk = resourceFiles.slice(i, i + chunkSize);
        const chunkPromises = chunk.map(async (file) => {
          try {
            const fileCacheKey = `file-${file}`;
            let terms;

            if (resourceCacheRef.current.has(fileCacheKey)) {
              terms = resourceCacheRef.current.get(fileCacheKey);
            } else {
              const fileResponse = await fetch(`/api/resources/${encodeURIComponent(file)}`, {
                signal: abortControllerRef.current.signal
              });
              if (!fileResponse.ok) throw new Error(`Failed to fetch resource file: ${file}`);
              terms = await fileResponse.json();
              resourceCacheRef.current.set(fileCacheKey, terms);
            }

            return terms.map(term => ({
              ...term,
              domain: file.replace(/\.\w+$/, '').replace(/^\d+-/, '')
            }));
          } catch (error) {
            if (error.name === 'AbortError') throw error;
            console.error(`Error processing resource file ${file}:`, error);
            return [];
          }
        });

        // Wait for current chunk to complete
        const chunkResults = await Promise.all(chunkPromises);
        allTerms = [...allTerms, ...chunkResults.flat()];

        // Update terms progressively
        setResourceTerms(prevTerms => [...prevTerms, ...chunkResults.flat()]);

        // Give UI a chance to update
        await new Promise(resolve => setTimeout(resolve, 0));
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Fetch aborted');
        return;
      }
      console.error('Error fetching all resources:', error);
    } finally {
      if (!abortControllerRef.current.signal.aborted) {
        setIsLoadingTerms(false);
      }
    }
  };

  // Debounced highlight function
  const debouncedHighlight = useCallback(
    debounce(() => {
      if (tableData.length > 0 && resourceTerms.length > 0 && !isLoadingTerms) {
        highlightMatchingTerms();
      }
    }, 300),
    [tableData, resourceTerms, isLoadingTerms]
  );

  // Update effect to use debounced highlight
  useEffect(() => {
    debouncedHighlight();
    return () => debouncedHighlight.cancel();
  }, [tableData, resourceTerms, isLoadingTerms]);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    fetchAllResourceTerms();

    const reader = new FileReader();
    reader.onload = (event) => {
      const arrayBuffer = event.target.result;
      
      mammoth.extractRawText({ arrayBuffer })
        .then(result => {
          const text = result.value;
          
          const sentences = text.split(/(?<=[.!?])\s+/);
          
          const initializedData = sentences
            .filter(sentence => sentence.trim().length > 0)
            .map(sentence => ({
              sourceText: sentence.trim(),
              translatedText: '',
              status: 'pending',
              highlightedText: null
            }));

          setTableData(initializedData);
        })
        .catch(error => {
          console.error('Error extracting text from docx:', error);
          alert('Error extracting text from the document. Please make sure it is a valid .docx file.');
        });
    };

    reader.readAsArrayBuffer(file);
  };

  const highlightMatchingTerms = () => {
    const newTableData = tableData.map(row => {
      const sourceText = row.sourceText;
      
      return {
        ...row,
        highlightedText: createHighlightedText(sourceText)
      };
    });
    
    setTableData(newTableData);
  };

  const createHighlightedText = (text) => {
    if (!text || resourceTerms.length === 0) return text;
    
    // Create arrays for single-word and multi-word terms
    const singleWordTerms = {};
    const multiWordTerms = [];
    
    // Helper function to escape special regex characters
    const escapeRegExp = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    };
    
    resourceTerms.forEach(term => {
      if (term.english) {
        const mainTerm = term.english.toLowerCase().trim();
        const words = mainTerm.split(/\s+/);
        
        if (words.length > 1) {
          // For multi-word terms, store the exact phrase
          multiWordTerms.push({
            term: mainTerm,
            pattern: new RegExp('\\b' + escapeRegExp(mainTerm) + '\\b', 'gi')
          });
        } else {
          // Handle single word variations
          singleWordTerms[mainTerm] = true;
          
          // Store singular/plural variations
          if (mainTerm.endsWith('s')) {
            singleWordTerms[mainTerm.slice(0, -1)] = true;
          } else {
            singleWordTerms[mainTerm + 's'] = true;
          }
          
          // Store with/without hyphens
          if (mainTerm.includes('-')) {
            singleWordTerms[mainTerm.replace(/-/g, ' ')] = true;
          }
        }
      }
    });

    // First, handle multi-word terms
    let processedText = text;
    let segments = [];
    let lastIndex = 0;

    // Sort multi-word terms by length (longest first) to handle overlapping terms
    multiWordTerms.sort((a, b) => b.term.length - a.term.length);

    // Find and mark all multi-word term positions
    const matches = [];
    multiWordTerms.forEach(({ term, pattern }) => {
      let match;
      while ((match = pattern.exec(processedText)) !== null) {
        matches.push({
          start: match.index,
          end: match.index + match[0].length,
          term: match[0],
          originalTerm: term
        });
      }
    });

    // Sort matches by start position and handle overlaps
    matches.sort((a, b) => a.start - b.start);
    const validMatches = [];
    let lastEnd = -1;

    matches.forEach(match => {
      if (match.start >= lastEnd) {
        validMatches.push(match);
        lastEnd = match.end;
      }
    });

    // Build segments with highlighted terms
    validMatches.forEach((match, index) => {
      if (match.start > lastIndex) {
        // Add text between matches
        const textBetween = processedText.slice(lastIndex, match.start);
        if (textBetween) {
          segments.push(handleSingleWords(textBetween, singleWordTerms));
        }
      }

      // Add highlighted multi-word term
      segments.push(
        <span key={`multi-${index}`} className="bg-yellow-200 px-0.5 rounded" title={`Term: ${match.originalTerm}`}>
          {match.term}
        </span>
      );

      lastIndex = match.end;
    });

    // Add remaining text
    if (lastIndex < processedText.length) {
      segments.push(handleSingleWords(processedText.slice(lastIndex), singleWordTerms));
    }

    return segments.length > 0 ? segments : handleSingleWords(processedText, singleWordTerms);
  };

  // Helper function to handle single word highlighting
  const handleSingleWords = (text, singleWordTerms) => {
    const tokens = text.split(/(\s+|[.,;:!?"'\-\(\)]+)/);
    
    return tokens.map((token, index) => {
      if (!token.trim() || /^[\s.,;:!?"'\-\(\)]+$/.test(token)) {
        return token;
      }
      
      const cleanToken = token.toLowerCase().trim();
      
      if (singleWordTerms[cleanToken]) {
        return (
          <span key={`single-${index}`} className="bg-yellow-200 px-0.5 rounded" title={`Term: ${cleanToken}`}>
            {token}
          </span>
        );
      }
      
      return token;
    });
  };

  const handleTextSelection = (index) => {
    setSelectedRowIndex(index);
    
    const selection = window.getSelection();
    const selectedText = selection.toString().trim();
    
    if (selectedText && selectedText.length > 0) {
      setSelectedText(selectedText);
      
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const rect = range.getBoundingClientRect();
        
        setPopoverPosition({
          x: rect.left + window.scrollX + (rect.width / 2),
          y: rect.bottom + window.scrollY
        });
        
        searchConcordance(selectedText);
      }
    } else {
      setIsPopoverOpen(false);
    }
  };

  const searchConcordance = async (text) => {
    setIsSearching(true);
    setConcordanceResults([]);
    
    try {
      const normalizedText = text.toLowerCase().trim();
      
      const resourceMatches = await searchResourcesFolder(normalizedText);
      
      setConcordanceResults(resourceMatches);
      setIsPopoverOpen(resourceMatches.length > 0);
    } catch (error) {
      console.error('Error searching concordance:', error);
    } finally {
      setIsSearching(false);
    }
  };

  const searchResourcesFolder = async (searchText) => {
    // Cancel any ongoing requests
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    
    // Create new abort controller for this request
    abortControllerRef.current = new AbortController();
    
    let matches = [];
    
    try {
      const response = await fetch('/api/resources/list', {
        signal: abortControllerRef.current.signal
      });
      if (!response.ok) {
        throw new Error('Failed to fetch resources list');
      }
      
      const resourceFiles = await response.json();
      
      for (const file of resourceFiles) {
        try {
          const fileResponse = await fetch(`/api/resources/${encodeURIComponent(file)}`, {
            signal: abortControllerRef.current.signal
          });
          if (!fileResponse.ok) {
            console.error(`Failed to fetch resource file: ${file}`);
            continue;
          }
          
          const terms = await fileResponse.json();
          
          const fileMatches = terms.filter(term => {
            return term.english && (
              term.english.toLowerCase().includes(searchText) ||
              term.english.toLowerCase() === searchText
            );
          });
          
          const matchesWithSource = fileMatches.map(term => ({
            ...term,
            domain: file.replace(/\.\w+$/, '').replace(/^\d+-/, ''),
            source: 'Resources'
          }));
          
          matches = [...matches, ...matchesWithSource];
        } catch (error) {
          if (error.name === 'AbortError') {
            console.log('Fetch aborted');
            return [];
          }
          console.error(`Error processing resource file ${file}:`, error);
        }
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Fetch aborted');
        return [];
      }
      console.error('Error fetching resources:', error);
    }
    
    return matches;
  };

  const handleCopyTranslation = (translation) => {
    if (selectedRowIndex !== null) {
      const newData = [...tableData];
      
      if (newData[selectedRowIndex].translatedText) {
        newData[selectedRowIndex].translatedText += ' ' + translation;
      } else {
        newData[selectedRowIndex].translatedText = translation;
      }
      
      setTableData(newData);
      
      setEditingIndex(selectedRowIndex);
    }
    
    setIsPopoverOpen(false);
  };

  const startNewSession = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    setTableData([]);
    setEditingIndex(null);
    setSelectedRowIndex(null);
    setResourceTerms([]);
    setConcordanceResults([]);
    setIsPopoverOpen(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const downloadDocx = async () => {
    const doc = new Document({
      sections: [{
        children: [
          new Paragraph({
            text: "Manual Translation Results",
            heading: HeadingLevel.HEADING_1
          }),
          new DocxTable({
            rows: [
              new DocxTableRow({
                children: [
                  new DocxTableCell({
                    children: [new Paragraph({ text: "Source Text (English)" })],
                    width: {
                      size: 50,
                      type: "percentage"
                    }
                  }),
                  new DocxTableCell({
                    children: [new Paragraph({ text: "Translated Text (Malay)" })],
                    width: {
                      size: 50,
                      type: "percentage"
                    }
                  })
                ]
              }),
              ...tableData.map(row => 
                new DocxTableRow({
                  children: [
                    new DocxTableCell({
                      children: [new Paragraph({ text: row.sourceText })],
                      width: {
                        size: 50,
                        type: "percentage"
                      }
                    }),
                    new DocxTableCell({
                      children: [new Paragraph({ text: row.translatedText })],
                      width: {
                        size: 50,
                        type: "percentage"
                      }
                    })
                  ]
                })
              )
            ]
          })
        ]
      }]
    });

    const blob = await Packer.toBlob(doc);
    
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'manual_translation_results.docx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className="min-h-screen bg-white">
      <PlaygroundSimpleHeader keyPrefix="playgroundPage" />
      <PlaygroundNavigation />
      
      <div className="container mx-auto px-4 py-8">
        <Card className="mb-6">
          <CardContent className="p-6">
            <div className="flex flex-col gap-4">
              <div className="space-y-4 py-4">
                <h2 className="text-2xl font-bold">Manual Translation with Concordance</h2>
                <p className="text-gray-500">
                  Upload a Word document (.docx) to extract text by sentences and translate them manually with Term Base concordance search assistance.
                </p>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6 items-end">
                  <div className="flex flex-col space-y-2">
                    <label htmlFor="file-upload" className="text-sm font-medium">Upload Word Document</label>
                    <Input
                      id="file-upload"
                      type="file"
                      accept=".docx"
                      onChange={handleFileUpload}
                      ref={fileInputRef}
                      className="w-full"
                    />
                  </div>
                  <div className="flex items-end">
                    <Button
                      onClick={startNewSession}
                      disabled={tableData.length === 0}
                      variant="destructive"
                      className="w-full"
                    >
                      New Session
                    </Button>
                  </div>
                  <div className="flex items-end">
                    <Button
                      onClick={downloadDocx}
                      disabled={tableData.length === 0}
                      variant="outline"
                      className="w-full"
                    >
                      Download Docx
                    </Button>
                  </div>
                </div>
                {isLoadingTerms && (
                  <div className="text-sm text-amber-600">
                    Loading resource terms for highlighting...
                  </div>
                )}
                {resourceTerms.length > 0 && !isLoadingTerms && (
                  <div className="text-sm text-green-600">
                    <span className="font-semibold">Resource terms loaded:</span> Words with existing translations in the resource folder are highlighted in <span className="bg-yellow-200 px-1 rounded">yellow</span>.
                  </div>
                )}
              </div>
            </div>
          </CardContent>
        </Card>

        <div className="overflow-x-auto mt-6">
          <Table className="w-full border-collapse table-fixed bg-white">
            <TableHeader>
              <TableRow>
                <TableHead className="w-[45%]">Source Text (English)</TableHead>
                <TableHead className="w-[10%] text-center">Instructions</TableHead>
                <TableHead className="w-[45%]">Translated Text (Malay)</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {tableData.map((row, index) => (
                <TableRow key={index} className="border-b hover:bg-white-50">
                  <TableCell className="align-top py-4 px-4">
                    <div 
                      className="max-h-[200px] overflow-y-auto whitespace-pre-wrap break-words p-2 bg-white-50 rounded"
                      onMouseUp={() => handleTextSelection(index)}
                    >
                      {row.highlightedText || row.sourceText}
                    </div>
                  </TableCell>
                  <TableCell className="align-middle text-center py-4 px-2">
                    <div className="text-sm text-gray-500">
                      Highlight text in source to search Term Base
                    </div>
                  </TableCell>
                  <TableCell className="align-top py-4 px-4">
                    {editingIndex === index ? (
                      <textarea
                        value={row.translatedText}
                        onChange={(e) => {
                          const newData = [...tableData];
                          newData[index].translatedText = e.target.value;
                          setTableData(newData);
                        }}
                        onBlur={() => setEditingIndex(null)}
                        autoFocus
                        className="w-full min-h-[200px] p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                      />
                    ) : (
                      <div
                        className="cursor-pointer hover:bg-gray-100 p-3 rounded max-h-[200px] overflow-y-auto whitespace-pre-wrap break-words bg-white-50"
                        onClick={() => {
                          setEditingIndex(index);
                          setSelectedRowIndex(index);
                        }}
                      >
                        {row.translatedText || 'Click to add translation'}
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              ))}
              {tableData.length === 0 && (
                <TableRow>
                  <TableCell colSpan={3} className="text-center py-8 text-gray-500">
                    Upload a Word document to start manual translation
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>

        <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
          <PopoverTrigger asChild>
            <Button
              className="hidden"
              ref={popoverRef}
            />
          </PopoverTrigger>
          <PopoverContent className="w-96 p-0" align="start">
            <div className="p-3 border-b">
              <div className="flex items-center gap-2">
                <Search className="h-4 w-4 text-gray-500" />
                <span className="font-medium">Term Base Results</span>
              </div>
              <div className="text-sm text-gray-500 mt-1">
                Search term: <span className="font-medium">{selectedText}</span>
              </div>
            </div>
            <div className="max-h-80 overflow-y-auto">
              {isSearching ? (
                <div className="p-4 text-center">Searching...</div>
              ) : concordanceResults.length > 0 ? (
                <div className="divide-y">
                  {concordanceResults.map((result, idx) => (
                    <div key={idx} className="p-3 hover:bg-gray-50">
                      <div className="flex justify-between items-start mb-1">
                        <div className="text-sm font-medium">{result.english}</div>
                        <div className="text-xs bg-gray-100 px-2 py-1 rounded">
                          {result.domain}
                        </div>
                      </div>
                      <div className="text-sm text-gray-700">{result.malay}</div>
                      <Button 
                        variant="ghost" 
                        size="sm" 
                        className="mt-2 text-blue-600 hover:text-blue-800 hover:bg-blue-50 p-0 h-auto"
                        onClick={() => handleCopyTranslation(result.malay)}
                      >
                        Use this translation
                      </Button>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="p-4 text-center text-gray-500">
                  No Term Base matches found
                </div>
              )}
            </div>
          </PopoverContent>
        </Popover>
      </div>
    </div>
  );
};

export default ManualTranslationConcordancePlayground;
