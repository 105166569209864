/** @format */

import React, { createContext, useContext, useState, useEffect } from "react";

// Create a context with default value `null`
const AuthContext = createContext({
  user: { role: "", id: "" },
  login: () => {},
  logout: () => {},
});

// Define the AuthProvider component
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({
    role: "",
    id: "",
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  const login = (data) => {
    localStorage.setItem("user", JSON.stringify(data)); // Store user data in localStorage
    localStorage.setItem("token", data.token); // Store token in localStorage
    setUser(data);
  };

  const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the AuthContext
export const useAuth = () => useContext(AuthContext);
