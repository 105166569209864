/** @format */

import React from "react";
import { Link } from "react-router-dom";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "../../ui/collapsible";
import {
  Sidebar,
  SidebarHeader,
  SidebarContent,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubItem,
  SidebarMenuBadge,
} from "../../ui/sidebar";

const SidebarLayout = ({ logo, content }) => {
  // Debug: Log the content prop to see if it includes the Resources Term Base link
  console.log('SidebarLayout content:', JSON.stringify(content, null, 2));
  
  return (
    <Sidebar>
      <SidebarHeader>
        <div className="flex flex-col items-center p-4 space-y-2">
          <img src={logo} alt="UTM Logo" className="h-12 w-auto" />
          <span className="font-bold text-lg">UTM-ITBM</span>
        </div>
      </SidebarHeader>
      <SidebarContent>
        {content.map(({ groupLabel, items }) => {
          // Debug: Log each group and its items
          console.log(`Rendering group: ${groupLabel}`, items);
          
          return (
            <SidebarGroup key={groupLabel}>
              <SidebarGroupLabel>{groupLabel}</SidebarGroupLabel>
              <SidebarGroupContent>
                <SidebarMenu>
                  {items.map((item) => {
                    // Debug: Log each item
                    console.log(`Rendering item: ${item.title}`, item);
                    
                    return (
                      <Collapsible key={item.title} className="group/collapsible">
                        <SidebarMenuItem>
                          <CollapsibleTrigger asChild>
                            {item.url ? (
                              <>
                                <SidebarMenuButton asChild>
                                  <Link to={item.url}>
                                    <item.icon />
                                    <span>{item.title}</span>
                                  </Link>
                                </SidebarMenuButton>
                                {item.badge && (
                                  <SidebarMenuBadge className="bg-secondary">
                                    {item.badge}
                                  </SidebarMenuBadge>
                                )}
                              </>
                            ) : (
                              <SidebarMenuButton>
                                <item.icon />
                                <span>{item.title}</span>
                              </SidebarMenuButton>
                            )}
                          </CollapsibleTrigger>
                          {item.subItems && (
                            <CollapsibleContent>
                              <SidebarMenuSub>
                                {item.subItems.map((subItem) => {
                                  // Debug: Log each subItem
                                  console.log(`Rendering subItem: ${subItem.title}`, subItem);
                                  
                                  return (
                                    <SidebarMenuSubItem key={subItem.title}>
                                      <Link
                                        to={subItem.url}
                                        className="flex items-center space-x-2"
                                      >
                                        <subItem.icon className="w-5 h-5" />
                                        <span>{subItem.title}</span>
                                      </Link>
                                    </SidebarMenuSubItem>
                                  );
                                })}
                              </SidebarMenuSub>
                            </CollapsibleContent>
                          )}
                        </SidebarMenuItem>
                      </Collapsible>
                    );
                  })}
                </SidebarMenu>
              </SidebarGroupContent>
            </SidebarGroup>
          );
        })}
      </SidebarContent>
    </Sidebar>
  );
};

export default SidebarLayout;
