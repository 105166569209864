/** @format */

import React from "react";
import image from "../../assets/img/landing banner.png";
import { useTranslation } from "react-i18next";
import UserFooter from "../../components/core/user/UserFooter";

const Landing = () => {
  const [t] = useTranslation("translation", {
    keyPrefix: "landingPage",
  });

  const services = [
    {
      title: t("textTranslation"),
      description: t("textTranslationDesc"),
      icon: "📝"
    },
    {
      title: t("fileTranslation"),
      description: t("fileTranslationDesc"),
      icon: "📄"
    },
    {
      title: t("glossaryTerms"),
      description: t("glossaryTermsDesc"),
      icon: "📚"
    }
  ];

  return (
    <div className="min-h-screen bg-gray-600 flex flex-col">
      <div className="flex-grow">
        {/* Hero Section */}
        <div className="container mx-auto px-4 py-16">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
            <div className="space-y-6">
              <h1 className="text-5xl font-bold text-blue-100 leading-tight">
                {t("advanced")}
                <span className="text-red-500">{t("nmt")}</span> {t("service")}
              </h1>
              <p className="text-xl text-blue-100">{t("desc")}</p>
            </div>
            <div className="flex justify-center">
              <img
                src={image}
                alt="AI Illustration"
                className="max-w-full h-auto rounded-lg shadow-lg"
              />
            </div>
          </div>
        </div>

        {/* Our Services Section */}
        <div className="bg-white py-16">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-center text-gray-900 mb-12">
              {t("ourServices")}
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {services.map((service, index) => (
                <div
                  key={index}
                  className="p-8 bg-gray-50 rounded-xl shadow-sm hover:shadow-md transition-all duration-300 group"
                >
                  <div className="text-4xl mb-4 group-hover:scale-110 transition-transform duration-300">
                    {service.icon}
                  </div>
                  <h3 className="text-xl font-semibold text-gray-900 mb-3">
                    {service.title}
                  </h3>
                  <p className="text-gray-600 leading-relaxed">
                    {service.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Our Ecosystem Section */}
        <div className="container mx-auto px-4 py-16">
          <h2 className="text-3xl font-bold text-center text-blue-100 mb-12">
            {t("ourEcosystem")}
          </h2>
          <div className="max-w-3xl mx-auto">
            <div className="bg-gradient-to-r from-purple-500 to-indigo-600 p-8 rounded-2xl text-white shadow-xl">
              <h3 className="text-2xl font-semibold mb-4">{t("aiModel")}</h3>
              <p className="text-lg opacity-90">{t("discover")}</p>
            </div>
          </div>
        </div>
      </div>
      
      {/* Footer */}
      <UserFooter />
    </div>
  );
};

export default Landing;
