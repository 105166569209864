/** @format */

import React, { useState, useRef } from "react";
import { Button } from "../../components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../../components/ui/breadcrumb";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs";
import { useTranslation } from "react-i18next";
import { Upload, X, Download, FileJson, RefreshCw, FileSpreadsheet } from "lucide-react";
import { saveAs } from 'file-saver';

const MergeJsonFiles = () => {
  const { t } = useTranslation();
  const jsonFileInputRef = useRef(null);
  const csvFileInputRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [mergedContent, setMergedContent] = useState(null);
  const [error, setError] = useState("");
  const [fileType, setFileType] = useState("json");

  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(prev => [...prev, ...files]);
    
    // Reset file input
    if (fileType === "json") {
      jsonFileInputRef.current.value = '';
    } else {
      csvFileInputRef.current.value = '';
    }
  };

  const removeFile = (index) => {
    setSelectedFiles(prev => prev.filter((_, i) => i !== index));
  };

  const readFileAsJson = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const content = JSON.parse(e.target.result);
          resolve(content);
        } catch (error) {
          reject(new Error(`Invalid JSON in file ${file.name}`));
        }
      };
      reader.onerror = () => reject(new Error(`Error reading file ${file.name}`));
      reader.readAsText(file);
    });
  };

  const parseCSV = (csvText) => {
    // Simple CSV parser - handles comma-separated values
    // Assumes first row is header and subsequent rows are data
    const rows = csvText.split('\n');
    const headers = rows[0].split(',').map(header => header.trim().replace(/^"(.*)"$/, '$1'));
    
    // Find the indices of english and malay columns
    const englishIndex = headers.findIndex(h => h.toLowerCase() === 'english' || h.toLowerCase() === 'source');
    const malayIndex = headers.findIndex(h => h.toLowerCase() === 'malay' || h.toLowerCase() === 'target');
    
    if (englishIndex === -1 || malayIndex === -1) {
      throw new Error("CSV must have 'English/Source' and 'Malay/Target' columns");
    }
    
    // Parse the data rows
    const result = [];
    for (let i = 1; i < rows.length; i++) {
      if (!rows[i].trim()) continue; // Skip empty rows
      
      // Split by comma and handle quoted values
      let values = [];
      let inQuote = false;
      let currentValue = '';
      let row = rows[i];
      
      for (let j = 0; j < row.length; j++) {
        const char = row[j];
        
        if (char === '"' && (j === 0 || row[j-1] !== '\\')) {
          inQuote = !inQuote;
        } else if (char === ',' && !inQuote) {
          values.push(currentValue.trim());
          currentValue = '';
        } else {
          currentValue += char;
        }
      }
      
      // Add the last value
      values.push(currentValue.trim());
      
      // Remove surrounding quotes from all values
      values = values.map(val => val.replace(/^"(.*)"$/, '$1'));
      
      if (values.length >= Math.max(englishIndex, malayIndex) + 1) {
        result.push({
          english: values[englishIndex],
          malay: values[malayIndex]
        });
      }
    }
    
    return result;
  };

  const readFileAsCSV = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const content = parseCSV(e.target.result);
          resolve(content);
        } catch (error) {
          reject(new Error(`Error parsing CSV file ${file.name}: ${error.message}`));
        }
      };
      reader.onerror = () => reject(new Error(`Error reading file ${file.name}`));
      reader.readAsText(file);
    });
  };

  const handleMerge = async () => {
    if (selectedFiles.length === 0) {
      setError(`Please select at least one ${fileType.toUpperCase()} file`);
      return;
    }

    try {
      setError("");
      let contents;
      
      if (fileType === "json") {
        contents = await Promise.all(selectedFiles.map(readFileAsJson));
      } else {
        contents = await Promise.all(selectedFiles.map(readFileAsCSV));
      }
      
      // Merge all contents into a single array
      const mergedArray = contents.reduce((acc, curr) => {
        // Ensure current content is an array
        const contentArray = Array.isArray(curr) ? curr : [curr];
        return [...acc, ...contentArray];
      }, []);

      // Remove duplicates based on both english and malay fields
      const uniqueEntries = mergedArray.reduce((acc, curr) => {
        const isDuplicate = acc.some(item => 
          item.english === curr.english && 
          item.malay === curr.malay
        );
        if (!isDuplicate) {
          acc.push(curr);
        }
        return acc;
      }, []);

      setMergedContent(uniqueEntries);
      
      // Success message
      setError(""); // Clear any previous errors
      
    } catch (error) {
      setError(error.message);
    }
  };

  const handleNewMerge = () => {
    setSelectedFiles([]);
    setMergedContent(null);
    setError("");
    if (fileType === "json") {
      jsonFileInputRef.current.value = '';
    } else {
      csvFileInputRef.current.value = '';
    }
  };

  const handleDownload = (downloadType) => {
    if (!mergedContent) {
      setError("No merged content to download");
      return;
    }

    if (downloadType === "json") {
      // Download as JSON
      const blob = new Blob([JSON.stringify(mergedContent, null, 2)], {
        type: "application/json;charset=utf-8"
      });
      saveAs(blob, `merged_glossary_${new Date().toISOString().split('T')[0]}.json`);
    } else {
      // Download as CSV
      // Create CSV header
      let csvContent = "English,Malay\n";
      
      // Add each row
      mergedContent.forEach(item => {
        // Properly escape fields that contain commas or quotes
        const englishField = escapeCSVField(item.english);
        const malayField = escapeCSVField(item.malay);
        csvContent += `${englishField},${malayField}\n`;
      });
      
      const blob = new Blob([csvContent], {
        type: "text/csv;charset=utf-8"
      });
      saveAs(blob, `merged_glossary_${new Date().toISOString().split('T')[0]}.csv`);
    }
  };

  // Helper function to escape CSV fields
  const escapeCSVField = (field) => {
    if (!field) return '';
    
    // If the field contains commas, quotes, or newlines, wrap it in quotes
    if (field.includes(',') || field.includes('"') || field.includes('\n')) {
      // Double up any quotes to escape them
      return `"${field.replace(/"/g, '""')}"`;
    }
    return field;
  };

  const handleTabChange = (value) => {
    setFileType(value);
    setSelectedFiles([]);
    setMergedContent(null);
    setError("");
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      <Breadcrumb className="pl-6 pt-6">
        <span className="text-xl font-semibold">Merge Files</span>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink href="/dashboard">Dashboard</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator>/</BreadcrumbSeparator>
          <BreadcrumbItem>
            <BreadcrumbLink href="/glossary-domain">Glossary Management</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator>/</BreadcrumbSeparator>
          <BreadcrumbItem>
            <BreadcrumbPage>Merge Files</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <div className="container mx-auto p-6">
        <Card>
          <CardHeader>
            <div className="flex justify-between items-center">
              <div>
                <CardTitle>Merge Files</CardTitle>
                <CardDescription>
                  Select multiple files to merge. Files should contain glossary terms with English and Malay translations.
                </CardDescription>
              </div>
              <Button
                variant="outline"
                onClick={handleNewMerge}
                className="flex items-center gap-2"
              >
                <RefreshCw className="h-4 w-4" />
                New Merge
              </Button>
            </div>
          </CardHeader>
          <CardContent>
            <Tabs defaultValue="json" onValueChange={handleTabChange}>
              <TabsList className="grid w-full grid-cols-2 mb-4">
                <TabsTrigger value="json">JSON Files</TabsTrigger>
                <TabsTrigger value="csv">CSV Files</TabsTrigger>
              </TabsList>
              
              <TabsContent value="json" className="space-y-4">
                <div>
                  <input
                    type="file"
                    ref={jsonFileInputRef}
                    accept=".json"
                    multiple
                    onChange={handleFileSelect}
                    className="hidden"
                  />
                  <Button
                    onClick={() => jsonFileInputRef.current.click()}
                    className="flex items-center gap-2"
                  >
                    <Upload className="h-4 w-4" />
                    Select JSON Files
                  </Button>
                  <p className="text-sm text-gray-500 mt-2">
                    JSON files should contain an array of objects with 'english' and 'malay' fields.
                  </p>
                </div>
              </TabsContent>
              
              <TabsContent value="csv" className="space-y-4">
                <div>
                  <input
                    type="file"
                    ref={csvFileInputRef}
                    accept=".csv"
                    multiple
                    onChange={handleFileSelect}
                    className="hidden"
                  />
                  <Button
                    onClick={() => csvFileInputRef.current.click()}
                    className="flex items-center gap-2"
                  >
                    <Upload className="h-4 w-4" />
                    Select CSV Files
                  </Button>
                  <p className="text-sm text-gray-500 mt-2">
                    CSV files should have headers with 'English/Source' and 'Malay/Target' columns.
                  </p>
                </div>
              </TabsContent>

              {/* Selected Files List */}
              {selectedFiles.length > 0 && (
                <div className="border rounded-md p-4 space-y-2 mt-4">
                  <h3 className="font-medium">Selected Files:</h3>
                  <div className="space-y-2">
                    {selectedFiles.map((file, index) => (
                      <div
                        key={index}
                        className="flex items-center justify-between bg-gray-50 p-2 rounded"
                      >
                        <div className="flex items-center gap-2">
                          {fileType === "json" ? (
                            <FileJson className="h-4 w-4 text-blue-500" />
                          ) : (
                            <FileSpreadsheet className="h-4 w-4 text-green-500" />
                          )}
                          <span>{file.name}</span>
                        </div>
                        <button
                          onClick={() => removeFile(index)}
                          className="text-red-500 hover:text-red-700"
                        >
                          <X className="h-4 w-4" />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* Error Message */}
              {error && (
                <div className="text-red-500 text-sm mt-4">{error}</div>
              )}

              {/* Action Buttons */}
              <div className="flex gap-4 mt-4">
                <Button
                  onClick={handleMerge}
                  disabled={selectedFiles.length === 0}
                  className="flex items-center gap-2"
                >
                  Merge Files
                </Button>
                {mergedContent && (
                  <div className="flex gap-2">
                    <Button
                      variant="outline"
                      onClick={() => handleDownload(fileType)}
                      className="flex items-center gap-2"
                    >
                      <Download className="h-4 w-4" />
                      Download as {fileType === "json" ? "JSON" : "CSV"}
                    </Button>
                    <Button
                      variant="outline"
                      onClick={() => handleDownload(fileType === "json" ? "csv" : "json")}
                      className="flex items-center gap-2"
                    >
                      <Download className="h-4 w-4" />
                      Download as {fileType === "json" ? "CSV" : "JSON"}
                    </Button>
                  </div>
                )}
              </div>

              {/* Preview */}
              {mergedContent && (
                <div className="mt-4">
                  <h3 className="font-medium mb-2">Preview:</h3>
                  <pre className="bg-gray-50 p-4 rounded-md overflow-auto max-h-96">
                    {JSON.stringify(mergedContent, null, 2)}
                  </pre>
                </div>
              )}
            </Tabs>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default MergeJsonFiles;
