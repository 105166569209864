import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/ui/button";
import { Languages, RotateCcw } from "lucide-react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import { Alert, AlertDescription, AlertTitle } from "../../components/ui/alert";
import { AlertTriangle } from "lucide-react";
import { loadAIModels } from "../../utils/aiModelUtils";
import PlaygroundSimpleHeader from "../../components/core/public/PlaygroundSimpleHeader";
import PlaygroundNavigation from "../../components/core/public/PlaygroundNavigation";

const TextAnalysisPlayground = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "playgroundPage",
  });

  const [text, setText] = useState("");
  const [analysis, setAnalysis] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [aiModels, setAiModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null);
  const [error, setError] = useState(null);
  const [analysisType, setAnalysisType] = useState("POS Tagging"); // Default analysis type

  // Load available AI models from the API
  useEffect(() => {
    const loadModels = async () => {
      try {
        const savedModels = await loadAIModels();
        // Filter for active models that can be used for analysis
        const analysisModels = savedModels.filter(model => 
          model.isActive && ['openai', 'huggingface', 'groq'].includes(model.type)
        );
        setAiModels(analysisModels);
        
        // Auto-select first available model
        if (analysisModels.length > 0 && !selectedModel) {
          setSelectedModel(analysisModels[0].id);
        }
      } catch (error) {
        console.error('Error loading AI models:', error);
        setError('Failed to load AI models. Please try again later.');
      }
    };
    
    loadModels();
  }, [selectedModel]);

  // Function to get model details by id
  const getModelById = (modelId) => {
    return aiModels.find(model => model.id === modelId);
  };

  // Function to perform linguistic analysis using selected AI model
  const performAIAnalysis = async (text, modelId) => {
    if (!text.trim() || !modelId) return [];
    
    setError(null);
    const model = getModelById(modelId);
    
    if (!model) {
      setError("Selected model not found. Please choose another model.");
      return [];
    }
    
    try {
      // Mock implementation - in a real app, this would call the actual AI service
      let analysisResult = [];
      
      switch(model.type) {
        case 'openai':
          analysisResult = await analyzeWithOpenAI(text, model);
          break;
        case 'groq':
          analysisResult = await analyzeWithGroq(text, model);
          break;
        case 'huggingface':
          analysisResult = await analyzeWithHuggingFace(text, model);
          break;
        default:
          // Fallback to basic analysis if model type not supported
          analysisResult = performBasicAnalysis(text);
      }
      
      return analysisResult;
    } catch (error) {
      console.error('AI analysis error:', error);
      setError(`Analysis failed: ${error.message}. Please try again or select a different model.`);
      return [];
    }
  };

  // OpenAI analysis implementation
  const analyzeWithOpenAI = async (text, model) => {
    // In a real implementation, this would call the OpenAI API with the model's API key
    console.log(`Analyzing with OpenAI model: ${model.name}`);
    
    // Mock sophisticated analysis - real implementation would use the OpenAI API
    return performEnhancedAnalysis(text);
  };

  // Groq analysis implementation
  const analyzeWithGroq = async (text, model) => {
    // This would use Groq's API in a real implementation
    console.log(`Analyzing with Groq model: ${model.name}`);
    
    // For demo, use the enhanced analysis implementation
    return performEnhancedAnalysis(text);
  };

  // Hugging Face analysis implementation
  const analyzeWithHuggingFace = async (text, model) => {
    // This would use Hugging Face's Inference API in a real implementation
    console.log(`Analyzing with Hugging Face model: ${model.name}`);
    
    // For demo, use the enhanced analysis implementation
    return performEnhancedAnalysis(text);
  };

  // Common enhanced analysis implementation with comprehensive linguistic rules
  const performEnhancedAnalysis = (text) => {
    if (!text.trim()) return [];
    
    // Better tokenization to handle punctuation correctly
    const tokens = text.split(/\s+/).map(token => {
      // Extract any punctuation at the end of words
      const match = token.match(/^(.*?)([.,!?;:]*)$/);
      if (match && match[2]) {
        return [match[1], match[2]];
      }
      return [token];
    }).flat().filter(Boolean);
    
    // Common word lists for various parts of speech
    const commonDeterminers = ['a', 'an', 'the', 'this', 'that', 'these', 'those', 'my', 'your', 'his', 'her', 'its', 'our', 'their', 'some', 'any', 'many', 'few', 'several', 'no', 'each', 'every', 'either', 'neither'];
    const commonPronouns = ['i', 'me', 'my', 'mine', 'myself', 'you', 'your', 'yours', 'yourself', 'he', 'him', 'his', 'himself', 'she', 'her', 'hers', 'herself', 'it', 'its', 'itself', 'we', 'us', 'our', 'ours', 'ourselves', 'they', 'them', 'their', 'theirs', 'themselves', 'who', 'whom', 'whose', 'which', 'what', 'that', 'whoever', 'whomever', 'whichever', 'whatever'];
    const commonPrepositions = ['about', 'above', 'across', 'after', 'against', 'along', 'amid', 'among', 'around', 'at', 'before', 'behind', 'below', 'beneath', 'beside', 'between', 'beyond', 'by', 'despite', 'down', 'during', 'except', 'for', 'from', 'in', 'inside', 'into', 'like', 'near', 'of', 'off', 'on', 'onto', 'out', 'outside', 'over', 'past', 'regarding', 'round', 'since', 'through', 'throughout', 'to', 'toward', 'under', 'underneath', 'until', 'unto', 'up', 'upon', 'with', 'within', 'without'];
    const commonConjunctions = ['and', 'but', 'or', 'nor', 'for', 'yet', 'so', 'although', 'because', 'since', 'unless', 'while', 'whereas', 'if', 'whether', 'as', 'that', 'until', 'after', 'before', 'when', 'where', 'how'];
    const commonAuxiliaryVerbs = ['am', 'is', 'are', 'was', 'were', 'be', 'being', 'been', 'have', 'has', 'had', 'do', 'does', 'did', 'can', 'could', 'may', 'might', 'must', 'shall', 'should', 'will', 'would'];
    const commonAdverbs = ['very', 'really', 'quite', 'simply', 'just', 'too', 'also', 'only', 'even', 'still', 'again', 'already', 'often', 'never', 'always', 'sometimes', 'usually', 'maybe', 'perhaps', 'probably', 'almost', 'nearly', 'mostly', 'hardly', 'scarcely', 'actually', 'basically', 'generally', 'rather', 'somewhat', 'together', 'instead', 'therefore', 'however', 'furthermore', 'moreover', 'nevertheless', 'meanwhile', 'subsequently', 'quickly', 'slowly', 'soon', 'early', 'late', 'now', 'then', 'here', 'there', 'everywhere', 'nowhere', 'anywhere', 'somehow', 'otherwise', 'well'];
    
    // Punctuation tags
    const punctuationMap = {
      '.': 'Period',
      ',': 'Comma',
      '!': 'Exclamation',
      '?': 'Question Mark',
      ';': 'Semicolon',
      ':': 'Colon',
      '-': 'Hyphen',
      '—': 'Em Dash'
    };
    
    // Process each token with contextual clues
    const result = tokens.map((token, index) => {
      // Check if token is punctuation
      if (Object.keys(punctuationMap).includes(token)) {
        return {
          word: token,
          pos: punctuationMap[token],
          lemma: token,
          type: 'punctuation'
        };
      }
      
      const word = token;
      const lowerWord = word.toLowerCase();
      
      // Set initial POS and lemma
      let pos = 'Unknown';
      let lemma = lowerWord;
      
      // 1. Check for common word lists
      if (commonDeterminers.includes(lowerWord)) {
        pos = 'Determiner';
      } else if (commonPronouns.includes(lowerWord)) {
        pos = 'Pronoun';
      } else if (commonPrepositions.includes(lowerWord)) {
        pos = 'Preposition';
      } else if (commonConjunctions.includes(lowerWord)) {
        pos = 'Conjunction';
      } else if (commonAuxiliaryVerbs.includes(lowerWord)) {
        pos = 'Auxiliary Verb';
      } else if (commonAdverbs.includes(lowerWord) || lowerWord.endsWith('ly')) {
        pos = 'Adverb';
        // Basic lemmatization for adverbs ending in 'ly'
        if (lowerWord.endsWith('ly') && lowerWord.length > 2) {
          lemma = lowerWord.slice(0, -2);
        }
      } 
      // 2. Check for proper nouns and capitalization patterns
      else if (/^[A-Z][a-z]+$/.test(word) && index > 0) {
        // Capitalized word not at the beginning of a sentence is likely a proper noun
        pos = 'Proper Noun';
      } else if (/^[A-Z][a-z]+$/.test(word) && index === 0) {
        // Beginning of sentence - check context to determine if proper noun
        // For simplicity, we'll just check the pattern rather than syntax
        pos = word.length > 5 ? 'Proper Noun' : 'Noun';
      } 
      // 3. Check for noun patterns
      else if (lowerWord.endsWith('ness') || lowerWord.endsWith('ity') || 
               lowerWord.endsWith('tion') || lowerWord.endsWith('ment') ||
               lowerWord.endsWith('ence') || lowerWord.endsWith('ance') ||
               lowerWord.endsWith('ship') || lowerWord.endsWith('dom') ||
               lowerWord.endsWith('ism') || lowerWord.endsWith('ist') ||
               lowerWord.endsWith('hood') || lowerWord.endsWith('ology')) {
        pos = 'Noun';
        // Basic lemmatization
        if (lowerWord.endsWith('ness')) lemma = lowerWord.slice(0, -4);
        else if (lowerWord.endsWith('ity')) lemma = lowerWord.slice(0, -3) + 'e';
        else if (lowerWord.endsWith('tion')) lemma = lowerWord.slice(0, -3) + 'e';
        else if (lowerWord.endsWith('ment')) lemma = lowerWord.slice(0, -4);
        else if (lowerWord.endsWith('ence') || lowerWord.endsWith('ance')) lemma = lowerWord.slice(0, -4);
      } 
      // 4. Check for adjective patterns
      else if (lowerWord.endsWith('ful') || lowerWord.endsWith('ous') || 
               lowerWord.endsWith('ive') || lowerWord.endsWith('ible') ||
               lowerWord.endsWith('able') || lowerWord.endsWith('al') ||
               lowerWord.endsWith('ic') || lowerWord.endsWith('ish') ||
               lowerWord.endsWith('ary') || lowerWord.endsWith('ate') ||
               lowerWord.endsWith('y') && !lowerWord.endsWith('ly')) {
        pos = 'Adjective';
        // Basic lemmatization
        if (lowerWord.endsWith('ful')) lemma = lowerWord.slice(0, -3);
        else if (lowerWord.endsWith('ous')) lemma = lowerWord.slice(0, -3);
        else if (lowerWord.endsWith('ive')) lemma = lowerWord.slice(0, -3) + 'e';
        else if (lowerWord.endsWith('ible') || lowerWord.endsWith('able')) lemma = lowerWord.slice(0, -4);
      } 
      // 5. Check for verb patterns
      else if (lowerWord.endsWith('ing')) {
        pos = 'Verb';
        // Basic lemmatization for present participles
        if (lowerWord.endsWith('ing')) {
          if (lowerWord.endsWith('ying')) {
            lemma = lowerWord.slice(0, -4) + 'y';
          } else if (lowerWord.length > 4 && lowerWord[lowerWord.length-4] === lowerWord[lowerWord.length-5]) {
            // For words like "running" -> "run" (double consonant)
            lemma = lowerWord.slice(0, -4);
          } else {
            lemma = lowerWord.slice(0, -3);
          }
        }
      } 
      else if (lowerWord.endsWith('ed')) {
        pos = 'Verb';
        // Basic lemmatization for past tense
        if (lowerWord.endsWith('ied')) {
          lemma = lowerWord.slice(0, -3) + 'y';
        } else if (lowerWord.length > 3 && lowerWord[lowerWord.length-3] === lowerWord[lowerWord.length-4]) {
          // For words like "stopped" -> "stop" (double consonant)
          lemma = lowerWord.slice(0, -3);
        } else {
          lemma = lowerWord.slice(0, -2);
        }
      } 
      else if (lowerWord.endsWith('s') && !lowerWord.endsWith('ss') && !lowerWord.endsWith('us') && !lowerWord.endsWith('is')) {
        // Check for plural nouns vs third person singular verbs
        // This is a simplification - in real NLP you'd need context
        if (index > 0 && tokens[index-1].toLowerCase() === 'the') {
          pos = 'Noun';
          lemma = lowerWord.slice(0, -1);
        } else {
          pos = 'Verb';
          lemma = lowerWord.slice(0, -1);
        }
      } 
      // 6. Default fallbacks based on word structure
      else if (lowerWord.length <= 3) {
        // Short words are often function words
        pos = 'Function Word';
      } else {
        // For remaining words, check length as a crude heuristic
        if (lowerWord.length >= 8) {
          pos = 'Noun';
        } else if (lowerWord.length >= 6) {
          pos = Math.random() > 0.7 ? 'Adjective' : 'Noun'; // Slightly randomize for demo
        } else {
          pos = Math.random() > 0.5 ? 'Verb' : 'Noun'; // Slightly randomize for demo
        }
      }
      
      return {
        word,
        pos,
        lemma,
        type: 'word'
      };
    });
    
    return result;
  };

  // Fallback basic analysis
  const performBasicAnalysis = (text) => {
    if (!text.trim()) return [];
    
    // For the basic analysis, let's also use a slightly improved algorithm
    // but less comprehensive than the enhanced analysis
    
    const words = text.split(/[.,!?;\s]+/).filter(Boolean);
    
    return words.map((word) => {
      const lowerWord = word.toLowerCase();
      let pos;
      let lemma = lowerWord;
      
      // Simple rules based on word endings
      if (/^[A-Z][a-z]+$/.test(word)) {
        pos = 'Proper Noun';
      } else if (lowerWord.endsWith('ing')) {
        pos = 'Verb';
        lemma = lowerWord.slice(0, -3);
      } else if (lowerWord.endsWith('ed')) {
        pos = 'Verb';
        lemma = lowerWord.slice(0, -2);
      } else if (lowerWord.endsWith('ly')) {
        pos = 'Adverb';
        lemma = lowerWord.slice(0, -2);
      } else if (lowerWord.endsWith('ness') || lowerWord.endsWith('tion') || lowerWord.endsWith('ity')) {
        pos = 'Noun';
      } else if (lowerWord.endsWith('ful') || lowerWord.endsWith('ous') || lowerWord.endsWith('able')) {
        pos = 'Adjective';
      } else if (word.length > 8) {
        pos = 'Noun';
      } else if (word.length > 5) {
        pos = 'Noun';
      } else if (word.length > 3) {
        pos = 'Verb';
      } else {
        pos = 'Preposition';
      }

      return {
        word,
        pos,
        lemma,
        type: 'word'
      };
    });
  };

  const handleAnalyze = async () => {
    if (!text.trim()) {
      setError("Please enter text to analyze");
      return;
    }

    if (!selectedModel && aiModels.length === 0) {
      setError("No AI models available. Please configure a model in Model Settings.");
      return;
    }

    setIsLoading(true);
    setError(null);
    setAnalysis(null); // Clear previous analysis results
    
    try {
      let tags;
      
      if (selectedModel) {
        // Use AI model for analysis
        tags = await performAIAnalysis(text, selectedModel);
      } else {
        // Fallback to basic analysis
        tags = performBasicAnalysis(text);
      }
      
      // Filter or transform the analysis results based on the selected analysis type
      if (analysisType === "POS Tagging") {
        // Keep the full analysis for POS Tagging
        setAnalysis(tags);
      } else if (analysisType === "Segmentation") {
        // For segmentation, segment by sentences with approximately 10 words each
        const segmentedText = [];
        let currentSegment = [];
        let currentSegmentId = 1;
        let wordCount = 0;
        
        // Group words into segments of approximately 10 words
        tags.forEach((item, index) => {
          // Add the current item to the current segment
          currentSegment.push(item);
          
          // Increment word count only for actual words (not punctuation)
          if (item.type === 'word') {
            wordCount++;
          }
          
          // Check if we should end the current segment
          const isEndOfSentence = item.pos === 'Period' || item.pos === 'Question Mark' || item.pos === 'Exclamation';
          const isSegmentFull = wordCount >= 10;
          
          // End segment if it's the end of a sentence or if we've reached 10 words
          if (isEndOfSentence || isSegmentFull || index === tags.length - 1) {
            // Add the current segment to the segmented text
            segmentedText.push({
              id: currentSegmentId,
              items: [...currentSegment],
              wordCount: wordCount,
              isComplete: isEndOfSentence
            });
            
            // Reset for the next segment
            currentSegment = [];
            currentSegmentId++;
            wordCount = 0;
          }
        });
        
        setAnalysis(segmentedText);
      } else if (analysisType === "Lemmatization") {
        // For lemmatization, focus on base forms
        setAnalysis(tags.map(item => ({
          ...item,
          focusOnLemma: true
        })));
      }
    } catch (error) {
      console.error('Analysis error:', error);
      setError("Analysis failed: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAnalysisTypeChange = (type) => {
    setAnalysisType(type);
    setAnalysis(null); // Clear analysis results when changing analysis type
  };

  const handleNewAnalysis = () => {
    setText("");
    setAnalysis(null);
    setError(null);
  };

  const getPOSColor = (pos) => {
    switch (pos) {
      case 'Noun':
      case 'Noun Phrase':
        return 'text-blue-600 font-medium';
      case 'Proper Noun':
        return 'text-blue-800 font-medium';
      case 'Verb':
      case 'Auxiliary Verb':
        return 'text-red-600 font-medium';
      case 'Adjective':
        return 'text-green-600 font-medium';
      case 'Adverb':
        return 'text-green-800 font-medium';
      case 'Preposition':
        return 'text-purple-600 font-medium';
      case 'Conjunction':
        return 'text-yellow-600 font-medium';
      case 'Determiner':
        return 'text-teal-600 font-medium';
      case 'Pronoun':
        return 'text-indigo-600 font-medium';
      case 'Function Word':
        return 'text-gray-600 font-medium';
      case 'Period':
      case 'Comma':
      case 'Exclamation':
      case 'Question Mark':
      case 'Semicolon':
      case 'Colon':
      case 'Hyphen':
      case 'Em Dash':
        return 'text-gray-500 font-medium';
      default:
        return 'text-gray-600 font-medium';
    }
  };

  return (
    <div className="min-h-screen bg-white">
      <PlaygroundSimpleHeader keyPrefix="playgroundPage" />
      <PlaygroundNavigation />
      
      <div className="container mx-auto px-4 py-8 bg-white">
        {/* Header */}
        <div className="text-center mb-8 bg-white">
          <h1 className="text-3xl font-bold text-gray-800 mb-2">
            Text Analysis
          </h1>
          <p className="text-gray-500">
            Analyze text for linguistic features and parts of speech
          </p>
        </div>

        {/* Analysis Container */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 bg-white">
          {/* Input Section */}
          <div className="bg-white rounded-lg shadow-sm p-6">
            <div className="flex flex-col h-full">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-semibold text-gray-700">Input Text</h2>
                <div className="flex space-x-2">
                  <Button 
                    onClick={handleNewAnalysis}
                    className="bg-gray-500 hover:bg-gray-600 text-white"
                  >
                    <RotateCcw className="h-4 w-4 mr-2" />
                    New Analysis
                  </Button>
                  <div className="flex flex-col">
                    <Select
                      value={analysisType}
                      onValueChange={handleAnalysisTypeChange}
                    >
                      <SelectTrigger className="bg-white border-gray-300 hover:bg-gray-50 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 min-w-[180px]">
                        <SelectValue />
                      </SelectTrigger>
                      <SelectContent className="bg-white border border-gray-200 shadow-lg">
                        <SelectItem value="POS Tagging" className="text-gray-700 font-medium hover:bg-blue-50 cursor-pointer">
                          POS Tagging
                        </SelectItem>
                        <SelectItem value="Segmentation" className="text-gray-700 font-medium hover:bg-blue-50 cursor-pointer">
                          Segmentation
                        </SelectItem>
                        <SelectItem value="Lemmatization" className="text-gray-700 font-medium hover:bg-blue-50 cursor-pointer">
                          Lemmatization
                        </SelectItem>
                      </SelectContent>
                    </Select>
                    <Button 
                      onClick={handleAnalyze}
                      disabled={isLoading || !text.trim()}
                      className="bg-blue-500 hover:bg-blue-600 text-white mt-2"
                    >
                      <Languages className="h-4 w-4 mr-2" />
                      {isLoading ? "Analyzing..." : "Analyze"}
                    </Button>
                  </div>
                </div>
              </div>
              
              {/* Model Selection */}
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">AI Translation Model</label>
                <Select
                  value={selectedModel}
                  onValueChange={setSelectedModel}
                  disabled={aiModels.length === 0}
                >
                  <SelectTrigger className="bg-white border-gray-300 hover:bg-gray-50 focus:ring-2 focus:ring-blue-500 focus:border-blue-500">
                    <SelectValue placeholder={aiModels.length === 0 ? "No models available" : "Select an AI model"} />
                  </SelectTrigger>
                  <SelectContent className="bg-white border border-gray-200 shadow-lg">
                    {aiModels.map((model) => (
                      <SelectItem 
                        key={model.id} 
                        value={model.id}
                        className="text-gray-700 font-medium hover:bg-blue-50 cursor-pointer"
                      >
                        {model.name} 
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                {aiModels.length === 0 && (
                  <p className="text-xs text-amber-600 mt-1">
                    No AI models configured. Basic analysis will be used.
                  </p>
                )}
              </div>
              
              {error && (
                <Alert variant="destructive" className="mb-4">
                  <AlertTriangle className="h-4 w-4" />
                  <AlertTitle>Error</AlertTitle>
                  <AlertDescription>{error}</AlertDescription>
                </Alert>
              )}
              
              <textarea
                className="flex-1 min-h-[200px] border border-gray-200 rounded-md p-4 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent resize-none"
                placeholder="Enter text to analyze..."
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
            </div>
          </div>

          {/* Results Section */}
          <div className="bg-white rounded-lg shadow-sm p-6">
            <div className="flex flex-col h-full">
              <h2 className="text-lg font-semibold text-gray-700 mb-4">Analysis Results</h2>
              <div className="flex-1 min-h-0">
                {analysis ? (
                  <div className="h-full flex flex-col">
                    <div className="flex-1 min-h-0">
                      <h3 className="text-lg font-semibold mb-3">
                        {analysisType === "POS Tagging" && "Linguistic Tagging"}
                        {analysisType === "Segmentation" && "Text Segmentation"}
                        {analysisType === "Lemmatization" && "Word Lemmatization"}
                      </h3>
                      <div className="border rounded-lg p-4 bg-white">
                        {analysisType !== "Segmentation" ? (
                          <ol className="list-decimal list-inside grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-3">
                            {analysis.map((item, index) => (
                              <li key={index} className="text-sm">
                                <div className="inline-block">
                                  <span className="font-semibold">"{item.word}"</span> – 
                                  {analysisType === "POS Tagging" && (
                                    <span className={getPOSColor(item.pos)}> {item.pos}</span>
                                  )}
                                  {analysisType === "Lemmatization" && (
                                    <span className="text-orange-600 font-medium"> {item.lemma !== item.word.toLowerCase() ? 'Modified' : 'Unchanged'}</span>
                                  )}
                                  <div className="text-gray-600 ml-4 text-xs">
                                    {analysisType === "POS Tagging" && (
                                      <>Base form: <span className="font-medium">{item.lemma}</span></>
                                    )}
                                    {analysisType === "Lemmatization" && (
                                      <>Base form: <span className="font-medium">{item.lemma}</span></>
                                    )}
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ol>
                        ) : (
                          <div className="space-y-4">
                            {analysis.map((segment, segmentIndex) => (
                              <div key={segmentIndex} className="border border-blue-200 rounded-lg p-3 bg-white">
                                <div className="flex justify-between items-center mb-2">
                                  <h4 className="text-sm font-semibold text-blue-700">
                                    Segment {segment.id} {segment.isComplete ? "(Complete Sentence)" : "(Partial Sentence)"}
                                  </h4>
                                  <span className="text-xs bg-white text-blue-800 px-2 py-1 rounded border border-blue-100">
                                    {segment.wordCount} words
                                  </span>
                                </div>
                                <div className="bg-white p-3 rounded border border-blue-100">
                                  <p className="text-sm">
                                    {segment.items.map((item, itemIndex) => (
                                      <span 
                                        key={itemIndex} 
                                        className={`${item.type === 'punctuation' ? 'text-red-500 font-medium' : 'text-gray-800'}`}
                                      >
                                        {item.word}{item.type !== 'punctuation' && itemIndex < segment.items.length - 1 && segment.items[itemIndex + 1].type !== 'punctuation' ? ' ' : ''}
                                      </span>
                                    ))}
                                  </p>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="mt-4 pt-4 border-t border-gray-200">
                      <div className="grid grid-cols-2 gap-4">
                        <div className="bg-white rounded-lg p-3 border border-gray-200">
                          <div className="text-xs text-blue-600 mb-1">
                            {analysisType === "Segmentation" ? "Total Segments" : "Total Words"}
                          </div>
                          <div className="text-xl font-semibold text-blue-700">
                            {analysisType === "Segmentation" ? analysis.length : analysis.length}
                          </div>
                        </div>
                        <div className="bg-white rounded-lg p-3 border border-gray-200">
                          <div className="text-xs text-green-600 mb-1">
                            {analysisType === "Segmentation" ? "Complete Sentences" : "Unique Words"}
                          </div>
                          <div className="text-xl font-semibold text-green-700">
                            {analysisType === "Segmentation" 
                              ? analysis.filter(segment => segment.isComplete).length 
                              : new Set(analysis.map(item => item.word.toLowerCase())).size}
                          </div>
                        </div>
                      </div>

                      {/* Unique Words List or Segment Summary */}
                      <div className="mt-4">
                        <h3 className="text-sm font-medium text-gray-700 mb-2">
                          {analysisType === "Segmentation" ? "Segment Summary" : "Unique Words List"}
                        </h3>
                        {analysisType !== "Segmentation" ? (
                          <div className="bg-white rounded-lg p-4 border border-gray-200">
                            <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
                              {Array.from(new Set(analysis.map(item => item.word.toLowerCase())))
                                .sort()
                                .map((word, index) => (
                                  <div 
                                    key={index}
                                    className="bg-white px-3 py-2 rounded border border-gray-200 text-sm"
                                  >
                                    {word}
                                  </div>
                              ))}
                            </div>
                          </div>
                        ) : (
                          <div className="bg-white rounded-lg p-4 border border-gray-200">
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                              {analysis.map((segment, index) => (
                                <div 
                                  key={index}
                                  className={`px-3 py-2 rounded border text-sm ${segment.isComplete ? 'bg-white border-green-200' : 'bg-white border-yellow-200'}`}
                                >
                                  <span className="font-medium">Segment {segment.id}:</span> {segment.wordCount} words, 
                                  {segment.isComplete ? ' Complete' : ' Incomplete'}
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="h-full flex items-center justify-center text-center text-gray-500">
                    <div>
                      <div className="text-4xl mb-4">🔍</div>
                      <p>Enter text and click Analyze to see linguistic analysis</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextAnalysisPlayground;
