/** @format */

import React, { useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import i18n from "i18next";
import { I18nextProvider } from "react-i18next";
import { initLanguage } from "../src/lib/language";
import { AuthProvider } from "./providers/AuthProvider";
import { Toaster } from "./components/ui/toaster";
import { GlossaryProvider } from "./contexts/GlossaryContext";

// ROUTES
import publicRoutes from "./routes/publicRoutes";
import userRoutes from "./routes/userRoutes";
import playgroundRoutes from "./routes/playgroundRoutes";
import GlossaryTechnicalTerm from "./pages/GTT/GlossaryTechnicalTerm";
import NotFound from "./pages/Public/NotFound";

const router = createBrowserRouter([
  ...playgroundRoutes, // Playground routes first for specific matching
  ...publicRoutes,     // Then public routes
  ...userRoutes,       // Then user routes
  {
    path: "/domain-gtt",
    element: <GlossaryTechnicalTerm />
  },
  {
    path: "*",         // Catch-all route last
    element: <NotFound />
  }
], {
  basename: process.env.PUBLIC_URL || "/utm-ittbm",
  future: {
    v7_fetcherPersist: true,
    v7_normalizeFormMethod: true,
    v7_partialHydration: true,
    v7_relativeSplatPath: true,
    v7_skipActionErrorRevalidation: true,
    v7_startTransition: true,
  },
});

const App = () => {
  // useEffect(() => {
  //   initLanguage()
  //     .then(() => {
  //       console.log("Language initialized");
  //     })
  //     .catch((err) => {
  //       console.error("Language initialization failed", err);
  //     });
  //   console.log("App component mounted");
  // }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <AuthProvider>
        <GlossaryProvider>
          <RouterProvider
            router={router}
            future={{
              v7_startTransition: true,
            }}
          >
            {console.log("RouterProvider rendered")}
          </RouterProvider>
          <Toaster />
        </GlossaryProvider>
      </AuthProvider>
    </I18nextProvider>
  );
};

export default App;
