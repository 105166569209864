/** @format */

import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../../components/ui/dialog";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../../components/ui/breadcrumb";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import {
  Cloud,
  Terminal,
  Bot,
  Key,
  Plus,
  Globe,
  Server,
  RefreshCw,
  Trash2,
  Power,
  PowerOff,
} from "lucide-react";
import { loadAIModels, saveAIModel, updateAIModel } from "../../utils/aiModelUtils";

const ModelSettings = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    apiKey: "",
    modelId: "",
    endpoint: "",
  });
  const [savedModels, setSavedModels] = useState([]);
  const [ollamaModels, setOllamaModels] = useState([]);
  const [isLoadingModels, setIsLoadingModels] = useState(false);

  useEffect(() => {
    loadSavedModels();
  }, []);

  const loadSavedModels = async () => {
    const models = await loadAIModels();
    setSavedModels(models);
  };

  const fetchOllamaModels = async (endpoint = 'http://localhost:11434') => {
    console.log('Fetching Ollama models...');
    setIsLoadingModels(true);
    try {
      const response = await fetch(`${endpoint}/api/tags`);
      if (!response.ok) {
        throw new Error(`Failed to fetch Ollama models: ${response.status}`);
      }
      const data = await response.json();
      console.log('Ollama models:', data);
      
      // Extract model names from the models array
      const modelNames = data.models
        ?.map(model => model.name)
        .filter(name => name && name.trim() !== '') || [];
        
      console.log('Model names:', modelNames);
      setOllamaModels(modelNames);
    } catch (error) {
      console.error('Error fetching Ollama models:', error);
      setOllamaModels([]);
    } finally {
      setIsLoadingModels(false);
    }
  };

  const options = [
    {
      id: "openai",
      title: "OpenAI Models",
      description: "Add models from OpenAI using your API token",
      icon: Cloud,
      fields: [
        { name: "name", label: "Model Name", type: "text" },
        { name: "apiKey", label: "OpenAI API Key", type: "password" },
      ],
    },
    {
      id: "groq",
      title: "Groq Models",
      description: "Add models from Groq with API token",
      icon: Globe,
      fields: [
        { name: "name", label: "Model Name", type: "text" },
        { name: "apiKey", label: "Groq API Key", type: "password" },
      ],
    },
    {
      id: "ollama",
      title: "Local Models (Ollama)",
      description: "Add and manage local Ollama models",
      icon: Server,
      fields: [
        { name: "name", label: "Model Name", type: "text", hidden: true },
        { name: "modelId", label: "Select Model", type: "select", options: ollamaModels },
        { name: "endpoint", label: "Local Endpoint (optional)", type: "text", placeholder: "http://localhost:11434" },
      ],
      onSelect: () => fetchOllamaModels(),
    },
    {
      id: "huggingface",
      title: "Hugging Face Inference",
      description: "Add models from Hugging Face Inference API",
      icon: Bot,
      fields: [
        { name: "name", label: "Model Name", type: "text" },
        { name: "modelId", label: "Model ID (e.g., organization/model-name)", type: "text" },
        { name: "apiKey", label: "Hugging Face API Token", type: "password" },
        { name: "endpoint", label: "Inference Endpoint (optional)", type: "text" },
      ],
    },
  ];

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setFormData({
      name: "",
      apiKey: "",
      modelId: "",
      endpoint: "",
    });
    if (option.onSelect) {
      option.onSelect();
    }
    setIsDialogOpen(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      // Validate required fields based on model type
      if (selectedOption.id === 'huggingface') {
        if (!formData.modelId || !formData.apiKey || !formData.name) {
          alert("Please fill in all required fields");
          return;
        }
      } else if (selectedOption.id === 'ollama') {
        if (!formData.modelId) {
          alert("Please select a model");
          return;
        }
        // For Ollama, use the selected model as the name
        formData.name = formData.modelId;
      } else if (!formData.name || !formData.apiKey) {
        alert("Please fill in all required fields");
        return;
      }

      // Create model configuration
      const modelConfig = {
        id: Date.now(),
        type: selectedOption.id,
        name: formData.name,
        modelId: formData.modelId,
        apiKey: formData.apiKey,
        endpoint: formData.endpoint,
        isActive: true,
      };

      // Save to backend
      const success = await saveAIModel(modelConfig);
      if (success) {
        await loadSavedModels(); // Reload the models list
        setIsDialogOpen(false);
        setFormData({
          name: "",
          apiKey: "",
          modelId: "",
          endpoint: "",
        });
      } else {
        alert("Failed to save model configuration");
      }
    } catch (error) {
      console.error('Error saving model:', error);
      alert("Failed to save model: " + error.message);
    }
  };

  const handleDeleteModel = (modelId) => {
    if (window.confirm('Are you sure you want to delete this model?')) {
      const updatedModels = savedModels.filter(model => model.id !== modelId);
      const success = updateAIModel(modelId, null);
      if (success) {
        loadSavedModels(); // Reload the models list
      } else {
        alert("Failed to delete model");
      }
    }
  };

  const handleToggleActive = async (model) => {
    try {
      const success = await updateAIModel(model.id, !model.isActive);
      if (success) {
        await loadSavedModels(); // Reload the models list
      } else {
        alert("Failed to update model status");
      }
    } catch (error) {
      console.error('Error updating model:', error);
      alert("Failed to update model: " + error.message);
    }
  };

  const getModelIcon = (type) => {
    switch (type) {
      case 'openai':
        return Cloud;
      case 'groq':
        return Globe;
      case 'ollama':
        return Server;
      case 'huggingface':
        return Bot;
      default:
        return Terminal;
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      <Breadcrumb className="pl-6 pt-6">
        <span className="text-xl font-semibold">Model Settings</span>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink href="/ai-models">AI Models</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>Model Settings</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <div className="container mx-auto p-6">
        {/* Model List */}
        {savedModels.length > 0 && (
          <Card className="mb-6">
            <CardHeader>
              <CardTitle>Configured Models</CardTitle>
              <CardDescription>
                Manage your configured translation models
              </CardDescription>
            </CardHeader>
            <CardContent>
              <div className="divide-y">
                {savedModels.map((model) => {
                  const IconComponent = getModelIcon(model.type);
                  return (
                    <div
                      key={model.id}
                      className="py-4 flex items-center justify-between"
                    >
                      <div className="flex items-center gap-4">
                        <div className={`p-2 rounded-lg ${
                          model.isActive ? 'bg-green-100' : 'bg-gray-100'
                        }`}>
                          <IconComponent className={`h-6 w-6 ${
                            model.isActive ? 'text-green-600' : 'text-gray-600'
                          }`} />
                        </div>
                        <div>
                          <h3 className="font-medium">{model.name}</h3>
                          <p className="text-sm text-gray-500">
                            Type: {model.type.charAt(0).toUpperCase() + model.type.slice(1)}
                            {model.endpoint && ` • Endpoint: ${model.endpoint}`}
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center gap-2">
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() => handleToggleActive(model)}
                          className={model.isActive ? 'text-green-600' : 'text-gray-600'}
                        >
                          {model.isActive ? (
                            <Power className="h-4 w-4" />
                          ) : (
                            <PowerOff className="h-4 w-4" />
                          )}
                        </Button>
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() => handleDeleteModel(model.id)}
                          className="text-red-600"
                        >
                          <Trash2 className="h-4 w-4" />
                        </Button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </CardContent>
          </Card>
        )}

        {/* Model Options */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {options.map((option) => (
            <Card key={option.id} className="hover:shadow-lg transition-shadow">
              <CardHeader>
                <div className="flex items-center gap-4">
                  <div className="p-2 rounded-lg bg-primary/10">
                    <option.icon className="h-6 w-6 text-primary" />
                  </div>
                  <div>
                    <CardTitle>{option.title}</CardTitle>
                    <CardDescription>{option.description}</CardDescription>
                  </div>
                </div>
              </CardHeader>
              <CardContent>
                <Button
                  className="w-full flex items-center justify-center gap-2"
                  onClick={() => handleOptionSelect(option)}
                >
                  <Plus className="h-4 w-4" />
                  Add Model
                </Button>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>

      {/* Add Model Dialog */}
      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle className="flex items-center gap-2">
              {selectedOption?.icon && <selectedOption.icon className="h-5 w-5" />}
              Add {selectedOption?.title}
            </DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            {selectedOption?.fields.map((field) => (
              <div key={field.name} className="grid gap-2">
                <Label htmlFor={field.name}>{field.label}</Label>
                {field.type === 'select' ? (
                  <div className="relative">
                    {isLoadingModels && (
                      <div className="absolute right-3 top-3">
                        <RefreshCw className="h-4 w-4 animate-spin" />
                      </div>
                    )}
                    <Select
                      onValueChange={(value) => handleInputChange({ target: { name: field.name, value } })}
                      value={formData[field.name]}
                    >
                      <SelectTrigger className="w-full bg-white">
                        <SelectValue placeholder="Select a model" />
                      </SelectTrigger>
                      <SelectContent className="bg-white">
                        {ollamaModels.length > 0 ? (
                          ollamaModels.map((model) => (
                            <SelectItem key={model} value={model} className="hover:bg-gray-100">
                              {model}
                            </SelectItem>
                          ))
                        ) : (
                          <SelectItem value="_no_models" disabled className="text-gray-500">
                            {isLoadingModels ? "Loading models..." : "No models found"}
                          </SelectItem>
                        )}
                      </SelectContent>
                    </Select>
                  </div>
                ) : !field.hidden && (
                  <Input
                    id={field.name}
                    name={field.name}
                    type={field.type}
                    placeholder={field.placeholder}
                    value={formData[field.name]}
                    onChange={handleInputChange}
                  />
                )}
              </div>
            ))}
          </div>
          <DialogFooter>
            <Button variant="outline" onClick={() => setIsDialogOpen(false)}>
              Cancel
            </Button>
            <Button onClick={handleSubmit} className="flex items-center gap-2">
              <Key className="h-4 w-4" />
              Add Model
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ModelSettings;
