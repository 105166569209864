/** @format */

import React from "react";
import image from "../../assets/img/signup banner.png";

const SignUp = () => {
  return (
    <div
      className="flex flex-col bg-gray-50"
      style={{ height: "calc(100vh - 4.05rem)" }}
    >
      <div className="grid grid-cols-2 h-screen">
        {/* Left Section */}
        <div className="bg-gray-100 flex items-center justify-center">
          <div className="max-w-md mx-auto">
            <div className="flex justify-center">
              <img
                src={image}
                alt="AI Illustration"
                className="max-w-full h-auto"
              />
            </div>
          </div>
        </div>

        {/* Right Section */}
        <div className="flex items-center justify-center">
          <div className="w-full max-w-md">
            <h2 className="text-2xl font-semibold text-center mb-6">
              Sign Up to <span className="text-secondary">UTM-ITTBM</span>
            </h2>

            <form>
              <div className="mb-4">
                <label className="block text-sm mb-1">Full Name</label>
                <input
                  type="text"
                  className="w-full border p-2 rounded focus:outline-none focus:ring focus:ring-indigo-300"
                  placeholder="First and Last Names"
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm mb-1">Email Address</label>
                <input
                  type="email"
                  className="w-full border p-2 rounded focus:outline-none focus:ring focus:ring-indigo-300"
                  placeholder="Email Address"
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm mb-1">Country</label>
                <select className="w-full border p-2 rounded focus:outline-none focus:ring focus:ring-indigo-300">
                  <option>Malaysia</option>
                  <option>Indonesia</option>
                  <option>Singapore</option>
                </select>
              </div>

              <div className="mb-4">
                <label className="block text-sm mb-1">Password</label>
                <input
                  type="password"
                  className="w-full border p-2 rounded focus:outline-none focus:ring focus:ring-indigo-300"
                  placeholder="Password"
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm mb-1">Confirm Password</label>
                <input
                  type="password"
                  className="w-full border p-2 rounded focus:outline-none focus:ring focus:ring-indigo-300"
                  placeholder="Confirm Password"
                />
              </div>

              <div className="flex items-center space-x-2 mb-4">
                <input type="checkbox" className="form-checkbox" />
                <span className="text-sm">
                  By continuing, I agree with your{" "}
                  <a href="#" className="text-indigo-600 hover:underline">
                    Terms and Conditions
                  </a>{" "}
                  and{" "}
                  <a href="#" className="text-indigo-600 hover:underline">
                    Privacy Policies
                  </a>
                </span>
              </div>

              <button
                type="submit"
                className="w-full bg-secondary text-white p-2 rounded hover:bg-accent"
              >
                SIGN UP
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
