/** @format */

import React, { useEffect, useState } from "react";
import { useAuth } from "../../../providers/AuthProvider";
import { Avatar, AvatarFallback, AvatarImage } from "../../ui/avatar";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../ui/dropdown-menu";
import {
  Archive,
  BellDot,
  BookCopy,
  LogOut,
  RectangleEllipsis,
  UserPen,
  Wallet,
  Globe,
} from "lucide-react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Flag from "react-flagpack";

const UserHeader = ({ BurgerButton }) => {
  const { logout } = useAuth();

  const { t, i18n } = useTranslation("translation", {
    keyPrefix: "userHeaderPage",
  });

  const [lang, setLang] = useState(i18n.language);
  const [loading, setLoading] = useState(true);
  const windowW = window.innerWidth;

  const changeLanguage = (lng) => {
    sessionStorage.setItem("defaultLang", lng);
    setLang(sessionStorage.getItem("defaultLang"));
    setLoading(true);
    i18n.changeLanguage(lng);
  };

  useEffect(() => {}, []);

  return (
    <header className="bg-white border-b p-4 flex justify-between items-center shadow-sm">
      <div className="container mx-10 flex h-16 items-center justify-between px-4">
        <div className="flex items-center">
          <BurgerButton />
          <h1 className="text-2xl lg:text-3xl font-bold text-gray-800 ml-4 hidden md:block">
            MyTranslate: AI Powered Language Translation
          </h1>
        </div>
        <div className="flex items-center space-x-4">
          <div className="mx-5">
            <DropdownMenu>
              <DropdownMenuTrigger>
                <Globe />
              </DropdownMenuTrigger>
              <DropdownMenuContent className="bg-white shadow-lg rounded-md">
                <DropdownMenuItem onClick={() => changeLanguage("bm")}>
                  <Flag code="MY" /> BM
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => changeLanguage("en")}>
                  <Flag code="US" /> EN
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
          <div className="mx-5">
            <BellDot />
          </div>
          <DropdownMenu>
            <DropdownMenuTrigger>
              <Avatar>
                <AvatarImage src="https://github.com/shadcn.png" />
                <AvatarFallback>CN</AvatarFallback>
              </Avatar>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="bg-white shadow-lg rounded-md">
              <div className="text-center">
                <DropdownMenuLabel>
                  <strong>Admin</strong>
                </DropdownMenuLabel>
                <span style={{ fontSize: "12px" }}>Administrator</span>
              </div>
              <DropdownMenuSeparator className="bg-gray-300" />
              {/* <DropdownMenuItem onClick={() => logout()}> */}
              <DropdownMenuItem>
                <LogOut />
                <Link to="/">Logout</Link>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
    </header>
  );
};

export default UserHeader;
