/** @format */

import React from 'react';
import GlossaryTooltip from '../components/ui/glossary-tooltip';

/**
 * Process text to highlight and add tooltips to words that match glossary terms
 * 
 * @param {string} text - The text to process
 * @param {Function} findTermFunc - Function to find matching terms
 * @returns {Array} - Array of React elements with tooltips applied to matching terms
 */
export const processTextWithGlossary = (text, findTermFunc) => {
  if (!text || typeof text !== 'string' || !findTermFunc) {
    return text;
  }

  // Split the text into words while preserving punctuation and spaces
  const words = text.split(/(\s+|[.,!?;:()[\]{}'"«»""''\-–—])/);
  
  return words.map((word, index) => {
    // Skip processing for whitespace and punctuation
    if (!word.trim() || /^[\s.,!?;:()[\]{}'"«»""''\-–—]+$/.test(word)) {
      return word;
    }
    
    // Try to find a matching term
    const term = findTermFunc(word);
    
    if (term) {
      return (
        <GlossaryTooltip key={index} term={term}>
          {word}
        </GlossaryTooltip>
      );
    }
    
    return word;
  });
};
