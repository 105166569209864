/** @format */

import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../../components/ui/breadcrumb";
import { Button } from "../../components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import { toast } from "../../components/ui/use-toast";
import { Alert, AlertDescription, AlertTitle } from "../../components/ui/alert";
import { Info } from "lucide-react";

const ConvertExcelToJson = () => {
  const [excelFile, setExcelFile] = useState(null);
  const [jsonData, setJsonData] = useState(null);

  // Known column names for English and Malay terms
  const englishColumns = ['English', 'english', 'ENGLISH', 'English Term', 'term_en', 'termEn', 'Term (English)', 'EnglishTerm'];
  const malayColumns = ['Malay', 'malay', 'MALAY', 'Malay Term', 'term_my', 'termMy', 'Term (Malay)', 'MalayTerm'];

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || 
          file.type === 'application/vnd.ms-excel') {
        setExcelFile(file);
        setJsonData(null); // Reset previous conversion
      } else {
        toast({
          variant: "destructive",
          title: "Invalid file type",
          description: "Please upload an Excel file (.xlsx or .xls)",
        });
      }
    }
  };

  const convertToJson = () => {
    if (!excelFile) {
      toast({
        variant: "destructive",
        title: "No file selected",
        description: "Please select an Excel file first",
      });
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        
        // Get all data as rows first
        const rawRows = XLSX.utils.sheet_to_json(worksheet, { 
          header: 1, // Get header row
          defval: '', // Default value for empty cells
          raw: false, // Convert all cells to string
          blankrows: false // Skip blank rows
        });

        if (rawRows.length < 2) {
          toast({
            variant: "destructive",
            title: "Empty file",
            description: "The Excel file must contain a header row and at least one data row",
          });
          return;
        }

        // Find the first non-empty row (header row)
        let headerRowIndex = 0;
        while (headerRowIndex < rawRows.length) {
          const row = rawRows[headerRowIndex];
          if (row.some(cell => String(cell || '').trim() !== '')) {
            break;
          }
          headerRowIndex++;
        }

        if (headerRowIndex >= rawRows.length) {
          toast({
            variant: "destructive",
            title: "No data found",
            description: "Could not find any non-empty rows in the Excel file",
          });
          return;
        }

        // Get headers from the first non-empty row and clean them
        const headers = rawRows[headerRowIndex].map(h => String(h || '').trim());
        console.log('Found headers at row', headerRowIndex + 1, ':', headers);

        // Find the correct column indices
        let englishColIndex = -1;
        let malayColIndex = -1;

        headers.forEach((header, index) => {
          const headerLower = header.toLowerCase();
          
          // Check English column variations
          if (englishColumns.map(h => h.toLowerCase()).includes(headerLower)) {
            englishColIndex = index;
          }
          // Check Malay column variations
          if (malayColumns.map(h => h.toLowerCase()).includes(headerLower)) {
            malayColIndex = index;
          }
        });

        console.log('Column indices:', { englishColIndex, malayColIndex });

        if (englishColIndex === -1 || malayColIndex === -1) {
          let missingColumns = [];
          if (englishColIndex === -1) missingColumns.push('English');
          if (malayColIndex === -1) missingColumns.push('Malay');
          
          toast({
            variant: "destructive",
            title: "Column headers not found",
            description: `Missing required columns: ${missingColumns.join(', ')}. Expected one of:\nEnglish: ${englishColumns.join(', ')}\nMalay: ${malayColumns.join(', ')}`,
          });
          return;
        }

        // Process data rows (skip header row)
        const transformedData = rawRows.slice(headerRowIndex + 1)
          .map((row, rowIndex) => {
            // Skip empty rows
            if (!row.some(cell => String(cell || '').trim() !== '')) {
              return null;
            }

            const englishTerm = String(row[englishColIndex] || '').trim();
            const malayTerm = String(row[malayColIndex] || '').trim();
            
            // Debug log for empty terms
            if (!englishTerm || !malayTerm) {
              console.log(`Empty term at row ${rowIndex + headerRowIndex + 2}:`, { englishTerm, malayTerm });
            }

            return {
              english: englishTerm,
              malay: malayTerm
            };
          })
          .filter(term => term && term.english && term.malay); // Remove null entries and empty terms

        console.log('Transformed data:', transformedData);

        if (transformedData.length === 0) {
          toast({
            variant: "destructive",
            title: "No valid terms found",
            description: "Please ensure all rows have both English and Malay terms filled in",
          });
          return;
        }

        setJsonData(transformedData);
        
        toast({
          title: "Conversion successful",
          description: `Found ${transformedData.length} valid terms (skipped ${headerRowIndex} empty rows at the top)`,
        });
      } catch (error) {
        console.error('Excel conversion error:', error);
        toast({
          variant: "destructive",
          title: "Conversion failed",
          description: `Error: ${error.message}. Please ensure your Excel file is properly formatted and not corrupted.`,
        });
      }
    };

    reader.onerror = (error) => {
      console.error('File reading error:', error);
      toast({
        variant: "destructive",
        title: "File reading error",
        description: "Failed to read the Excel file. The file might be corrupted.",
      });
    };

    reader.readAsBinaryString(excelFile);
  };

  const downloadJson = () => {
    if (!jsonData) {
      toast({
        variant: "destructive",
        title: "No data to download",
        description: "Please convert an Excel file first",
      });
      return;
    }

    const jsonString = JSON.stringify(jsonData, null, 2);
    const blob = new Blob([jsonString], { type: 'application/json' });
    const fileName = excelFile.name.replace(/\.[^/.]+$/, "") + '.json';
    saveAs(blob, fileName);

    toast({
      title: "Download started",
      description: "Your JSON file is being downloaded",
    });
  };

  return (
    <>
      <Breadcrumb className="pl-6 pt-6">
        <span className="text-xl font-semibold">Convert Excel to JSON</span>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink href="/dashboard">Dashboard</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator>/</BreadcrumbSeparator>
          <BreadcrumbItem>
            <BreadcrumbLink href="/glossary">Glossary Management</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator>/</BreadcrumbSeparator>
          <BreadcrumbItem>
            <BreadcrumbPage>Convert Excel to JSON</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <div className="p-6">
        <Card>
          <CardHeader>
            <CardTitle>Excel to JSON Converter</CardTitle>
            <CardDescription>
              Upload an Excel file (.xlsx or .xls) to convert it to JSON format
            </CardDescription>
          </CardHeader>
          <CardContent>
            <div className="space-y-6">
              <Alert>
                <Info className="h-4 w-4" />
                <AlertTitle>Excel File Format Guide</AlertTitle>
                <AlertDescription>
                  <p>Your Excel file must have two columns with these headers:</p>
                  <ul className="list-disc list-inside mt-2">
                    <li><strong>English terms column</strong> - use one of these headers:
                      <br />{englishColumns.join(", ")}
                    </li>
                    <li className="mt-2"><strong>Malay terms column</strong> - use one of these headers:
                      <br />{malayColumns.join(", ")}
                    </li>
                  </ul>
                </AlertDescription>
              </Alert>

              <div className="space-y-2">
                <Label htmlFor="excel-file">Upload Excel File</Label>
                <Input
                  id="excel-file"
                  type="file"
                  accept=".xlsx,.xls"
                  onChange={handleFileChange}
                />
              </div>

              <div className="flex gap-4">
                <Button onClick={convertToJson} disabled={!excelFile}>
                  Convert to JSON
                </Button>
                <Button 
                  onClick={downloadJson} 
                  disabled={!jsonData}
                  variant="outline"
                >
                  Download JSON
                </Button>
              </div>

              {jsonData && (
                <div className="mt-6">
                  <Label>Preview ({jsonData.length} terms)</Label>
                  <pre className="mt-2 p-4 bg-slate-100 rounded-lg overflow-auto max-h-[400px] text-sm">
                    {JSON.stringify(jsonData, null, 2)}
                  </pre>
                </div>
              )}
            </div>
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default ConvertExcelToJson;
