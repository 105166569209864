/** @format */

import { useAuth } from '../../providers/AuthProvider';
import { AuthProvider } from '../../providers/AuthProvider'
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import image from "../../assets/img/login banner3.png";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/ui/button";
import { Link } from "react-router-dom";

const Login = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "loginPage",
  });
  const { login } = useAuth();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const handleLogin = () => {
    // Check for admin credentials
    if (email === "admin" && password === "@utm123") {
      // Admin login
      login({
        id: "admin",
        role: "admin",
      });

      Swal.fire({
        title: "Success!",
        text: "Admin Login Successful!",
        icon: "success",
      }).then((result) => {
        if (result.isConfirmed || result.isDismissed) {
          navigate("/app/dashboard"); // Navigate to admin dashboard
        }
      });
    } else {
      // Regular user login or invalid credentials
      const role = "user"; // Default role
      
      // Check if credentials are valid (you can add more validation here)
      if (email && password) {
        login({
          id: "null",
          role,
        });

        Swal.fire({
          title: "Success!",
          text: "Logging In Successful!",
          icon: "success",
        }).then((result) => {
          if (result.isConfirmed || result.isDismissed) {
            navigate("/app/dashboard");
          }
        });
      } else {
        // Show error for invalid credentials
        Swal.fire({
          title: "Error!",
          text: "Invalid credentials. Please try again.",
          icon: "error",
        });
      }
    }
  };

  return (
    <div
      className="flex flex-col bg-gray-50"
      style={{ height: "calc(100vh - 4.05rem)" }}
    >
      <div className="grid grid-cols-2 h-screen">
        {/* Left Section */}
        <div className="bg-gray-100 flex items-center justify-center">
          <div className="flex justify-center">
            <img src={image} alt="AI Illustration" className="w-5/12 h-auto" />
          </div>
        </div>

        {/* Right Section */}
        <div className="flex items-center justify-center">
          <div className="w-full max-w-md">
            <h2 className="text-2xl font-semibold text-center mb-6">
              {t("first")}
              <span className="text-secondary">UTM-ITTBM</span>
            </h2>
            <div className="mb-4">
              <label className="block text-sm mb-1">{t("email")}</label>
              <input
                type="email"
                className="w-full border p-2 rounded focus:outline-none focus:ring focus:ring-indigo-300"
                placeholder="username"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm mb-1">{t("pass")}</label>
              <input
                type="password"
                className="w-full border p-2 rounded focus:outline-none focus:ring focus:ring-indigo-300"
                placeholder="********"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="flex justify-between items-center mb-4">
              <label className="flex items-center space-x-2 text-sm">
                <input
                  type="checkbox"
                  className="form-checkbox"
                  checked={rememberMe}
                  onChange={() => setRememberMe(!rememberMe)}
                />
                <span>{t("loglama")}</span>
              </label>
              <a href="#" className="text-sm text-secondary hover:underline">
                {t("forgotpassword")}
              </a>
            </div>
            <Button
              className="w-full bg-secondary text-white p-2 rounded hover:bg-accent"
              onClick={handleLogin}
            >
              {t("button")}
            </Button>

            <div className="text-left mt-6">
              <span className="text-sm">{t("by")}</span>
              <a href="#" className="text-sm text-secondary hover:underline">
                {t("tnc")}
              </a>
              <span className="text-sm"> and </span>
              <a href="#" className="text-sm text-secondary hover:underline">
                {t("pp")}
              </a>
              .
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
