/** @format */

import React from "react";
import {
  BellDot,
  Book,
  BookOpen,
  Brain,
  Cable,
  ChevronsRight,
  CopyCheck,
  Database,
  FileSymlink,
  FileText,
  FolderKanban,
  History,
  Languages,
  LayoutDashboard,
  MessageCircleQuestion,
  MessageCircleWarning,
  MessagesSquare,
  Package,
  Settings,
  SlidersHorizontal,
  UserPen,
  Users,
  Wallet,
  WalletCards,
} from "lucide-react";

import SidebarLayout from "./SidebarLayout";
import { useTranslation } from "react-i18next";
import utmLogo from "../../../assets/img/utm.png";

const UserSidebar = () => {
  const [t] = useTranslation("translation", {
    keyPrefix: "userSidebar",
  });

  // Debug: Log the translation function
  console.log('Translation function:', t);

  const content = [
    {
      groupLabel: "Main Menu",
      items: [
        {
          title: "Dashboard",
          icon: LayoutDashboard,
          url: "/app/dashboard",
        },
        {
          title: t("item2"),
          icon: Languages,
          subItems: [
            {
              title: t("item3"),
              url: "/app/translate-dashboard",
              icon: ChevronsRight,
            },
            {
              title: t("item4"),
              url: "/app/file-translate-results",
              icon: ChevronsRight,
            },
            {
              title: t("item5"),
              url: "/app/text-translate-results",
              icon: ChevronsRight,
            },
            {
              title: t("item6"),
              url: "/app/translate-configuration",
              icon: ChevronsRight,
            },
          ],
        },
        {
          title: t("item7"),
          icon: Users,
          subItems: [
            {
              title: t("item8"),
              url: "/app/user-dashboard",
              icon: ChevronsRight,
            },
            { title: t("item9"), url: "/app/user-list", icon: ChevronsRight },
            {
              title: t("item10"),
              url: "/app/activity-monitoring",
              icon: ChevronsRight,
            },
          ],
        },
        {
          title: "Terms Base Settings",
          icon: Wallet,
          subItems: [
            {
              title: "Convert Excell to JSON",
              url: "/app/convert-glossary",
              icon: ChevronsRight,
            },
            {
              title: "Add Terms Category",
              url: "/app/domain-glossary",
              icon: ChevronsRight,
            },
            {
              title: "Merge JSON Files",
              url: "/app/merge-json",
              icon: ChevronsRight,
            },
            {
              title: "Terms Dashboard",
              url: "/app/dashboard-glossary",
              icon: ChevronsRight,
            },
            {
              title: "Add Dictionary",
              url: "/app/term-base-dictionary",
              icon: BookOpen,
            },
          ],
        },
        //{
        //  title: t("item11"),
        //  url: "/app/support-requests",
        //  icon: Cable,
        //},
        //{
        //  title: t("item12"),
        //  url: "/app/notifications",
        //  icon: MessageCircleWarning,
        //  badge: 108,
        //},
        {
          title: t("item13"),
          url: "/app/general-setting",
          icon: SlidersHorizontal,
        },
      ],
    },
    {
      groupLabel: t("group3"),
      items: [
        {
          title: t("item14"),
          url: "/app/text-translate",
          icon: MessagesSquare,
        },
        {
          title: t("item15"),
          url: "/app/file-translate",
          icon: FileSymlink,
        },
        {
          title: "Batch Translate (Excell File)",
          url: "/app/text-batch-translate",
          icon: FileText,
        },
        {
          title: "File Extraction (Docx File)",
          url: "/app/file-extraction",
          icon: FolderKanban,
        },
        {
          title: t("item18"),
          icon: UserPen,
          subItems: [
            { title: t("item19"), url: "/app/my-profile", icon: ChevronsRight },
            {
              title: t("item20"),
              url: "/app/change-password",
              icon: ChevronsRight,
            },
          ],
        },
      ],
    },
    {
      // Debug: Use a hardcoded string for the group label
      groupLabel: "Terms Base",
      items: [
        {
          title: "Technical Terms",
          url: "/app/glossary",
          icon: Book,
        },
        {
          title: "Dictionary",
          url: "/app/resources-term-base",
          icon: Database,
        },
      ],
    },
    {
      groupLabel: "AI Management",
      items: [
        {
          title: "AI Models",
          icon: Brain,
          subItems: [
            {
              title: "Models Dashboard",
              url: "/app/ai-models",
              icon: Database,
            },
            //{
            //  title: "Training History",
            //  url: "/app/ai-models/history",
            //  icon: History,
            //},
            {
              title: "Model Settings",
              url: "/app/ai-models/settings",
              icon: Settings,
            },
          ],
        },
      ],
    },
  ];

  // Debug: Log the content array
  console.log('UserSidebar content:', JSON.stringify(content, null, 2));

  return <SidebarLayout content={content} logo={utmLogo} />;
};

export default UserSidebar;
