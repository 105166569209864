import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../../components/ui/breadcrumb";
import { FileText } from "lucide-react";

const TranslateResult = () => {
  const [translations, setTranslations] = useState([]);

  useEffect(() => {
    const fileTranslations = JSON.parse(localStorage.getItem('fileTranslations') || '[]');
    // Sort by most recent first
    const sortedTranslations = fileTranslations.sort((a, b) => 
      new Date(b.timestamp) - new Date(a.timestamp)
    );
    setTranslations(sortedTranslations);
  }, []);

  // Fallback function to count words if stored count is not available
  const getWordCount = (text) => {
    if (!text) return 0;
    return text.trim().split(/\s+/).length;
  };

  return (
    <div className="space-y-4 p-8">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink href="/app/dashboard">Home</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink href="/app/file-translate">File Translation</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>Translation Results</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <Card className="w-full">
        <CardHeader>
          <div className="flex items-center space-x-2">
            <FileText className="h-6 w-6" />
            <CardTitle>File Translation Results</CardTitle>
          </div>
          <CardDescription>History of translated files and their details</CardDescription>
        </CardHeader>
        <CardContent>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>File Name</TableHead>
                <TableHead>Source → Target</TableHead>
                <TableHead className="text-right">Source Words</TableHead>
                <TableHead className="text-right">Translated Words</TableHead>
                <TableHead>AI Model</TableHead>
                <TableHead>Status</TableHead>
                <TableHead>Date</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {translations.map((translation) => (
                <TableRow key={translation.timestamp}>
                  <TableCell>{translation.fileName}</TableCell>
                  <TableCell>{`${translation.sourceLanguage} → ${translation.targetLanguage}`}</TableCell>
                  <TableCell className="text-right">
                    {translation.sourceWordCount || getWordCount(translation.sourceText)}
                  </TableCell>
                  <TableCell className="text-right">
                    {translation.translatedWordCount || getWordCount(translation.translatedContent)}
                  </TableCell>
                  <TableCell className="capitalize">{translation.modelType}</TableCell>
                  <TableCell className="capitalize">{translation.status}</TableCell>
                  <TableCell>
                    {new Date(translation.timestamp).toLocaleDateString()}
                  </TableCell>
                </TableRow>
              ))}
              {translations.length === 0 && (
                <TableRow>
                  <TableCell colSpan={7} className="text-center text-muted-foreground">
                    No translation results found. Try translating some files first.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  );
};

export default TranslateResult;
