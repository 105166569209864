/** @format */
import React from "react";
import image from "../../assets/img/under-construction.png";

const ComingSoon = () => {
  return (
    <div
      className="flex items-center justify-center bg-gray-50"
      style={{ height: "calc(100vh - 6.2rem)" }}
    >
      <div className="text-center">
        <img
          src={image}
          alt="Coming Soon"
          className="h-72 w-auto mx-auto mb-6"
        />
        <h1 className="text-4xl font-bold text-gray-800 mb-3">Coming Soon!</h1>
        <p className="text-lg text-gray-600 mb-5">
          This page is currently under construction. We're working hard to bring
          it to life. Stay tuned for updates!
        </p>
      </div>
    </div>
  );
};

export default ComingSoon;
