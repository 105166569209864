/** @format */

import { Outlet } from "react-router-dom";
import UserHeader from "../components/core/user/UserHeader";
import UserSidebar from "../components/core/user/UserSidebar";
import { SidebarProvider, SidebarTrigger } from "../components/ui/sidebar";

const UserLayout = () => {
  return (
    <>
      <div className="h-screen grid-cols-[auto,1fr] w-full">
        <SidebarProvider>
          <UserSidebar />
          <main className="w-full">
            <UserHeader BurgerButton={SidebarTrigger} />
            <Outlet />
          </main>
        </SidebarProvider>
      </div>
    </>
  );
};

export default UserLayout;
