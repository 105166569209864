/** @format */

import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../../components/ui/breadcrumb";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { FileText, File, Languages, FileDown } from "lucide-react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
} from "recharts";

const TranslateDashboard = () => {
  const [textStats, setTextStats] = useState({
    totalSourceWords: 0,
    totalTranslatedWords: 0,
    translationCount: 0
  });

  const [fileStats, setFileStats] = useState({
    totalFiles: 0,
    totalWords: 0,
    completedFiles: 0
  });

  const [textTimelineData, setTextTimelineData] = useState([]);
  const [fileTimelineData, setFileTimelineData] = useState([]);

  useEffect(() => {
    loadTextTranslationStats();
    loadFileTranslationStats();
  }, []);

  // Function to count words (same as in TextTranslateResult.jsx)
  const getWordCount = (text) => {
    if (!text) return 0;
    return text.trim().split(/\s+/).length;
  };

  const loadTextTranslationStats = () => {
    try {
      // Load text translations from localStorage (same source as TextTranslateResult.jsx)
      const textTranslations = JSON.parse(localStorage.getItem('textTranslations') || '[]');
      
      // Calculate overall stats
      const stats = textTranslations.reduce((acc, translation) => {
        // Use sourceWordCount if available, otherwise calculate it
        const sourceWords = translation.sourceWordCount || getWordCount(translation.sourceText);
        // Use translatedWordCount if available, otherwise calculate it
        const translatedWords = translation.translatedWordCount || getWordCount(translation.translatedText);
        
        return {
          totalSourceWords: acc.totalSourceWords + sourceWords,
          totalTranslatedWords: acc.totalTranslatedWords + translatedWords,
          translationCount: acc.translationCount + 1
        };
      }, { totalSourceWords: 0, totalTranslatedWords: 0, translationCount: 0 });

      setTextStats(stats);

      // Prepare timeline data
      const timelineData = textTranslations.reduce((acc, translation) => {
        const date = new Date(translation.timestamp).toLocaleDateString();
        const sourceWords = translation.sourceWordCount || getWordCount(translation.sourceText);
        const translatedWords = translation.translatedWordCount || getWordCount(translation.translatedText);

        const existingDay = acc.find(d => d.date === date);
        if (existingDay) {
          existingDay.sourceWords += sourceWords;
          existingDay.translatedWords += translatedWords;
          existingDay.count += 1;
        } else {
          acc.push({
            date,
            sourceWords,
            translatedWords,
            count: 1
          });
        }
        return acc;
      }, []);

      // Sort by date
      timelineData.sort((a, b) => new Date(a.date) - new Date(b.date));
      setTextTimelineData(timelineData);
    } catch (error) {
      console.error('Error loading text translation stats:', error);
    }
  };

  const loadFileTranslationStats = () => {
    try {
      // Load file translations from localStorage
      const fileTranslations = JSON.parse(localStorage.getItem('fileTranslations') || '[]');
      
      // Calculate overall stats
      const stats = fileTranslations.reduce((acc, file) => {
        const isCompleted = file.status === 'completed';
        const words = file.translatedContent?.split(/\s+/).length || 0;
        
        return {
          totalFiles: acc.totalFiles + 1,
          totalWords: acc.totalWords + words,
          completedFiles: acc.completedFiles + (isCompleted ? 1 : 0)
        };
      }, { totalFiles: 0, totalWords: 0, completedFiles: 0 });

      setFileStats(stats);

      // Prepare timeline data
      const timelineData = fileTranslations.reduce((acc, file) => {
        const date = new Date(file.timestamp).toLocaleDateString();
        const words = file.translatedContent?.split(/\s+/).length || 0;

        const existingDay = acc.find(d => d.date === date);
        if (existingDay) {
          existingDay.words += words;
          existingDay.files += 1;
        } else {
          acc.push({
            date,
            words,
            files: 1
          });
        }
        return acc;
      }, []);

      // Sort by date
      timelineData.sort((a, b) => new Date(a.date) - new Date(b.date));
      setFileTimelineData(timelineData);
    } catch (error) {
      console.error('Error loading file translation stats:', error);
    }
  };

  const StatCard = ({ icon: Icon, title, value, description, className }) => (
    <Card className={className}>
      <CardHeader className="flex flex-row items-center justify-between pb-2">
        <CardTitle className="text-sm font-medium text-muted-foreground">
          {title}
        </CardTitle>
        <Icon className="h-4 w-4 text-muted-foreground" />
      </CardHeader>
      <CardContent>
        <div className="text-2xl font-bold">{value}</div>
        <p className="text-xs text-muted-foreground">{description}</p>
      </CardContent>
    </Card>
  );

  return (
    <>
      <Breadcrumb className="pl-6 pt-6">
        <span className="text-xl font-semibold">Translation Dashboard</span>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink href="/dashboard">Dashboard</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator>/</BreadcrumbSeparator>
          <BreadcrumbItem>
            <BreadcrumbLink href="/translate-management">
              Translation Management
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator>/</BreadcrumbSeparator>
          <BreadcrumbItem>
            <BreadcrumbPage>Translation Dashboard</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <div className="p-6">
        {/* Text Translation Stats */}
        <div className="mb-6">
          <h2 className="text-lg font-semibold mb-4">Text Translation Statistics</h2>
          <div className="grid gap-4 md:grid-cols-3 mb-6">
            <StatCard
              icon={Languages}
              title="Total Source Words"
              value={textStats.totalSourceWords.toLocaleString()}
              description="Total number of words in source text"
            />
            <StatCard
              icon={Languages}
              title="Total Translated Words"
              value={textStats.totalTranslatedWords.toLocaleString()}
              description="Total number of words in translated text"
            />
            <StatCard
              icon={FileText}
              title="Total Translations"
              value={textStats.translationCount.toLocaleString()}
              description="Number of text translations performed"
            />
          </div>

          {/* Text Translation Timeline Chart */}
          <Card className="p-4">
            <CardHeader>
              <CardTitle>Text Translation Activity</CardTitle>
              <CardDescription>Daily word count and translation activity</CardDescription>
            </CardHeader>
            <CardContent>
              <div className="h-[300px]">
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={textTimelineData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line 
                      type="monotone" 
                      dataKey="sourceWords" 
                      stroke="#8884d8" 
                      name="Source Words"
                    />
                    <Line 
                      type="monotone" 
                      dataKey="translatedWords" 
                      stroke="#82ca9d" 
                      name="Translated Words"
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </CardContent>
          </Card>
        </div>

        {/* File Translation Stats */}
        <div>
          <h2 className="text-lg font-semibold mb-4">File Translation Statistics</h2>
          <div className="grid gap-4 md:grid-cols-3 mb-6">
            <StatCard
              icon={File}
              title="Total Files"
              value={fileStats.totalFiles.toLocaleString()}
              description="Total number of files uploaded"
            />
            <StatCard
              icon={FileDown}
              title="Completed Files"
              value={fileStats.completedFiles.toLocaleString()}
              description="Number of files successfully translated"
            />
            <StatCard
              icon={Languages}
              title="Total Words Translated"
              value={fileStats.totalWords.toLocaleString()}
              description="Total number of words translated from files"
            />
          </div>

          {/* File Translation Timeline Chart */}
          <Card className="p-4">
            <CardHeader>
              <CardTitle>File Translation Activity</CardTitle>
              <CardDescription>Daily file translation activity</CardDescription>
            </CardHeader>
            <CardContent>
              <div className="h-[300px]">
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={fileTimelineData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
                    <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
                    <Tooltip />
                    <Legend />
                    <Bar 
                      yAxisId="left" 
                      dataKey="files" 
                      fill="#8884d8" 
                      name="Files Translated"
                    />
                    <Bar 
                      yAxisId="right" 
                      dataKey="words" 
                      fill="#82ca9d" 
                      name="Words Translated"
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
};

export default TranslateDashboard;
