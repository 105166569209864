/** @format */

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import utmLogo from "../../../assets/img/utm.png";

const PlaygroundSimpleHeader = ({ keyPrefix = "playgroundPage" }) => {
  const [t, i18n] = useTranslation("translation", {
    keyPrefix: keyPrefix,
  });

  const [lang, setLang] = useState(i18n.language);
  const changeLanguage = (lng) => {
    sessionStorage.setItem("defaultLang", lng);
    setLang(sessionStorage.getItem("defaultLang"));
    i18n.changeLanguage(lng);
  };

  return (
    <header className="w-full border-b bg-background/95 py-4">
      <div className="container mx-auto flex items-center justify-between px-6">
        <div className="flex items-center space-x-2">
          <Link to="/" className="flex items-center space-x-2">
            <img src={utmLogo} alt="UTM Logo" className="w-28 h-auto" />
            <span className="font-bold">UTM-ITBM</span>
          </Link>
        </div>
        
        
      </div>
    </header>
  );
};

export default PlaygroundSimpleHeader; 