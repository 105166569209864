import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../../components/ui/breadcrumb";
import { Languages } from "lucide-react";

const TextTranslateAnalysis = () => {
  const { id } = useParams();
  const [translation, setTranslation] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const textTranslations = JSON.parse(localStorage.getItem('textTranslations') || '[]');
    const found = textTranslations.find(t => t.id === id);
    if (found) {
      setTranslation(found);
    }
    setLoading(false);
  }, [id]);

  if (loading) {
    return <div className="p-8">Loading...</div>;
  }

  if (!translation) {
    return <div className="p-8">Translation not found</div>;
  }

  // Function to get POS tag in both languages
  const getPOSTag = (word, isSource) => {
    // This is a simplified example. In a real application, you would use
    // a proper NLP library for accurate POS tagging
    const length = word.length;
    let englishTag, malayTag;

    if (length > 8) {
      englishTag = 'Noun Phrase';
      malayTag = 'Frasa Nama';
    } else if (length > 5) {
      englishTag = 'Noun';
      malayTag = 'Kata Nama';
    } else if (length > 3) {
      englishTag = 'Verb';
      malayTag = 'Kata Kerja';
    } else {
      englishTag = 'Preposition';
      malayTag = 'Kata Sendi Nama';
    }

    return isSource ? englishTag : malayTag;
  };

  // Function to analyze text and create paired analysis
  const analyzePairedText = () => {
    if (!translation.sourceText || !translation.translatedText) {
      return <div className="text-muted-foreground">No text available for analysis</div>;
    }

    try {
      const sourceWords = translation.sourceText.split(/[.,!?;\s]+/).filter(Boolean);
      const targetWords = translation.translatedText.split(/[.,!?;\s]+/).filter(Boolean);
      
      // Create paired analysis entries
      const analysisEntries = sourceWords.map((sourceWord, index) => {
        const targetWord = targetWords[index] || '';
        const sourcePOS = getPOSTag(sourceWord, true);
        const targetPOS = getPOSTag(targetWord, false);

        return {
          sourceWord,
          targetWord,
          sourcePOS,
          targetPOS
        };
      });

      return (
        <div className="space-y-4">
          <h3 className="text-lg font-semibold">Linguistic Tagging</h3>
          <div className="border rounded-lg p-4 max-h-[500px] overflow-y-auto">
            <ol className="list-decimal list-inside space-y-3">
              {analysisEntries.map((entry, index) => (
                <li key={index} className="text-sm">
                  <div className="ml-6">
                    <span className="font-semibold">"{entry.sourceWord}"</span> – 
                    <span className="text-blue-600"> {entry.sourcePOS}</span> ({entry.targetPOS}) – 
                    <span className="font-semibold"> "{entry.targetWord}"</span>
                    <div className="text-gray-600 ml-4 mt-1">
                      Word type: {entry.sourcePOS} in {translation.sourceLanguage}, 
                      corresponding to {entry.targetPOS} in {translation.targetLanguage}
                    </div>
                  </div>
                </li>
              ))}
            </ol>
          </div>
        </div>
      );
    } catch (error) {
      console.error('Error analyzing text:', error);
      return <span className="text-red-500">Error analyzing text</span>;
    }
  };

  return (
    <div className="space-y-4 p-8">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink href="/app/dashboard">Home</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink href="/app/text-translate-results">Translation Results</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>Linguistic Analysis</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <Card className="w-full">
        <CardHeader>
          <div className="flex items-center space-x-2">
            <Languages className="h-6 w-6" />
            <CardTitle>Translation Analysis</CardTitle>
          </div>
          <CardDescription>
            Translation and linguistic analysis from {translation.sourceLanguage} to {translation.targetLanguage}
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="space-y-6">
            {/* Translation Info Section */}
            <div className="pb-4 border-b">
              <div className="flex justify-between items-center">
                <div className="text-sm text-muted-foreground">
                  Translation ID: {translation.id}
                </div>
                <div className="text-sm text-muted-foreground">
                  Last Updated: {new Date(translation.timestamp).toLocaleString('en-MY', {
                    dateStyle: 'medium',
                    timeStyle: 'medium'
                  })}
                </div>
              </div>
            </div>

            {/* Source Text Section */}
            <div className="space-y-2">
              <h3 className="text-lg font-semibold">{translation.sourceLanguage} Text</h3>
              <div className="border rounded-lg p-4 bg-gray-50">
                <p className="text-sm whitespace-pre-wrap">{translation.sourceText}</p>
              </div>
            </div>

            {/* Translated Text Section */}
            <div className="space-y-2">
              <h3 className="text-lg font-semibold">{translation.targetLanguage} Translation</h3>
              <div className="border rounded-lg p-4 bg-gray-50">
                <p className="text-sm whitespace-pre-wrap">{translation.translatedText}</p>
              </div>
            </div>

            {/* Linguistic Analysis Section */}
            <div className="pt-4 border-t">
              {analyzePairedText()}
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default TextTranslateAnalysis;
