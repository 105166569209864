/** @format */

import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../../components/ui/breadcrumb";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import { Search, Download, RefreshCw, Settings } from "lucide-react";
import { toast } from "../../components/ui/use-toast";
import { Link } from "react-router-dom";
import axios from "axios";
import ReactApexChart from "react-apexcharts";

const TermBaseDictionary = () => {
  const [allTerms, setAllTerms] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredTerms, setFilteredTerms] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDomain, setSelectedDomain] = useState("all");
  const [domains, setDomains] = useState([]);
  const [chartData, setChartData] = useState({
    englishWordCount: 0,
    malayWordCount: 0,
    domainCounts: {},
    wordLengthDistribution: {
      english: {},
      malay: {}
    }
  });

  // Load terms from resources folder
  useEffect(() => {
    loadTermsFromResources();
  }, []);

  // Filter terms based on search query and selected domain
  useEffect(() => {
    let filtered = [...allTerms];
    
    if (searchQuery) {
      filtered = filtered.filter(term => 
        term.english.toLowerCase().includes(searchQuery.toLowerCase()) ||
        term.malay.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (term.domain && term.domain.toLowerCase().includes(searchQuery.toLowerCase()))
      );
    }
    
    if (selectedDomain && selectedDomain !== "all") {
      filtered = filtered.filter(term => term.domain === selectedDomain);
    }
    
    setFilteredTerms(filtered);
    updateChartData(filtered);
  }, [searchQuery, selectedDomain, allTerms]);

  const loadTermsFromResources = async () => {
    setIsLoading(true);
    setAllTerms([]);
    setFilteredTerms([]);
    
    try {
      // Get list of JSON files from resources directory
      const response = await axios.get('/api/resources/list');
      const jsonFiles = response.data.filter(file => file.endsWith('.json'));
      
      let allLoadedTerms = [];
      let uniqueDomains = new Set();
      let errorFiles = [];

      // Process each JSON file
      for (const file of jsonFiles) {
        try {
          const fileResponse = await axios.get(`/api/resources/${file}`);
          const data = fileResponse.data;
          
          if (!Array.isArray(data)) {
            console.error(`Invalid data format for file ${file}: expected an array`);
            errorFiles.push(file);
            continue;
          }

          // Extract domain name from filename
          // The format is now either:
          // 1. DomainName.json (exact domain name)
          // 2. DomainName_timestamp.json (if there was a conflict)
          let domain;
          
          // Check if filename contains a timestamp (has underscore followed by numbers)
          if (file.match(/_\d+\.json$/)) {
            // Extract domain name before the timestamp
            domain = file.replace(/_\d+\.json$/, '');
          } else {
            // Just remove the extension
            domain = file.replace(/\.json$/, '');
          }
          
          // Use the domain name as is without any formatting
          uniqueDomains.add(domain);

          const formattedTerms = data
            .filter(item => item && typeof item === 'object')
            .map(item => ({
              english: (item.english || '').trim(),
              malay: (item.malay || '').trim(),
              domain: domain
            }))
            .filter(term => term.english && term.malay);

          allLoadedTerms = [...allLoadedTerms, ...formattedTerms];
        } catch (error) {
          console.error(`Error loading terms from file ${file}:`, error);
          errorFiles.push(file);
        }
      }

      // Sort all terms alphabetically by English term
      allLoadedTerms.sort((a, b) => a.english.localeCompare(b.english));
      
      setAllTerms(allLoadedTerms);
      setFilteredTerms(allLoadedTerms);
      setDomains(Array.from(uniqueDomains));
      updateChartData(allLoadedTerms);
      
      if (allLoadedTerms.length > 0) {
        toast({
          title: "Terms loaded",
          description: `Loaded ${allLoadedTerms.length} terms from resources`,
        });
      } else {
        toast({
          variant: "destructive",
          title: "No terms found",
          description: "No valid terms found in resources",
        });
      }

      if (errorFiles.length > 0) {
        toast({
          variant: "destructive",
          title: "Some files had errors",
          description: `Could not load terms from: ${errorFiles.join(', ')}`,
        });
      }
    } catch (error) {
      console.error('Error loading terms from resources:', error);
      toast({
        variant: "destructive",
        title: "Error loading terms",
        description: error.message || "Failed to load terms from resources",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const updateChartData = (terms) => {
    if (!terms.length) return;

    // Count English and Malay words
    const englishWords = new Set();
    const malayWords = new Set();
    const domainCounts = {};
    const englishLengths = {};
    const malayLengths = {};

    terms.forEach(term => {
      // Count unique words
      englishWords.add(term.english.toLowerCase());
      malayWords.add(term.malay.toLowerCase());
      
      // Count by domain
      if (term.domain) {
        domainCounts[term.domain] = (domainCounts[term.domain] || 0) + 1;
      }
      
      // Word length distribution
      const englishLength = term.english.length;
      const malayLength = term.malay.length;
      
      englishLengths[englishLength] = (englishLengths[englishLength] || 0) + 1;
      malayLengths[malayLength] = (malayLengths[malayLength] || 0) + 1;
    });

    setChartData({
      englishWordCount: englishWords.size,
      malayWordCount: malayWords.size,
      domainCounts,
      wordLengthDistribution: {
        english: englishLengths,
        malay: malayLengths
      }
    });
  };

  const handleExportTerms = () => {
    if (filteredTerms.length === 0) {
      toast({
        variant: "destructive",
        title: "No terms to export",
        description: "There are no terms available to export.",
      });
      return;
    }

    // Create a CSV string
    const csvContent = [
      "English,Malay,Domain", // Header row
      ...filteredTerms.map(term => 
        `"${term.english.replace(/"/g, '""')}","${term.malay.replace(/"/g, '""')}","${(term.domain || '').replace(/"/g, '""')}"`
      )
    ].join("\n");

    // Create a Blob and download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `terms_export.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleRefresh = () => {
    loadTermsFromResources();
  };

  // Pie chart options for language distribution
  const languagePieOptions = {
    chart: {
      type: 'pie',
      fontFamily: 'inherit',
    },
    labels: ['English Words', 'Malay Words'],
    colors: ['#4F46E5', '#10B981'],
    legend: {
      position: 'bottom',
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 300
        },
        legend: {
          position: 'bottom'
        }
      }
    }],
    tooltip: {
      y: {
        formatter: function(val) {
          return val + " words";
        }
      }
    }
  };

  // Domain distribution chart options
  const domainChartOptions = {
    chart: {
      type: 'pie',
      fontFamily: 'inherit',
    },
    labels: Object.keys(chartData.domainCounts),
    colors: ['#4F46E5', '#10B981', '#F59E0B', '#EF4444', '#8B5CF6', '#EC4899'],
    legend: {
      position: 'bottom',
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 300
        },
        legend: {
          position: 'bottom'
        }
      }
    }]
  };

  return (
    <>
      <Breadcrumb className="pl-6 pt-6">
        <span className="text-xl font-semibold">Dictionary Base</span>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink href="/dashboard">Dashboard</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator>/</BreadcrumbSeparator>
          <BreadcrumbItem>
            <BreadcrumbLink href="/glossary">Terms Base Settings</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator>/</BreadcrumbSeparator>
          <BreadcrumbItem>
            <BreadcrumbPage>Add Dictionary</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <div className="p-6">
        <Card>
          <CardHeader>
            <div className="flex justify-between items-start">
              <div>
                <CardTitle>Term Base Dictionary Dashboard</CardTitle>
                <CardDescription>
                  Visualize and analyze terms from all domains
                </CardDescription>
              </div>
              <div className="flex gap-4">
                <div className="relative w-[300px]">
                  <Search className="absolute left-2 top-2.5 h-4 w-4 text-gray-500" />
                  <Input
                    type="text"
                    placeholder="Search terms or domains..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="pl-8 bg-white border-gray-300"
                  />
                </div>
                {domains.length > 0 && (
                  <Select value={selectedDomain} onValueChange={setSelectedDomain}>
                    <SelectTrigger className="w-[180px] bg-white border-gray-300">
                      <SelectValue placeholder="Select Domain" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="all">All Domains</SelectItem>
                      {domains.map((domain, index) => (
                        <SelectItem key={index} value={domain}>
                          {domain}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                )}
                <Button
                  variant="outline"
                  onClick={handleRefresh}
                  title="Refresh Terms"
                  className="bg-white border-gray-300 hover:bg-gray-50"
                >
                  <RefreshCw className="h-4 w-4 mr-2" />
                  Refresh
                </Button>
                <Button
                  variant="outline"
                  onClick={handleExportTerms}
                  disabled={filteredTerms.length === 0}
                  title="Export to CSV"
                  className="bg-white border-gray-300 hover:bg-gray-50"
                >
                  <Download className="h-4 w-4 mr-2" />
                  Export
                </Button>
                <Link to="/app/term-base-domain">
                  <Button
                    variant="outline"
                    title="Manage Term Base Domains"
                    className="bg-white border-gray-300 hover:bg-gray-50"
                  >
                    <Settings className="h-4 w-4 mr-2" />
                    Settings
                  </Button>
                </Link>
              </div>
            </div>
          </CardHeader>
          <CardContent>
            {isLoading ? (
              <div className="text-center py-4">Loading terms from resources...</div>
            ) : filteredTerms.length > 0 ? (
              <div className="space-y-8">
                {/* Stats Cards */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                  <Card>
                    <CardHeader className="pb-2">
                      <CardTitle className="text-lg">Total Terms</CardTitle>
                    </CardHeader>
                    <CardContent>
                      <div className="text-3xl font-bold">{filteredTerms.length}</div>
                      <p className="text-sm text-gray-500 mt-1">
                        {selectedDomain === "all" 
                          ? "Across all domains" 
                          : `In ${selectedDomain} domain`}
                      </p>
                    </CardContent>
                  </Card>
                  <Card>
                    <CardHeader className="pb-2">
                      <CardTitle className="text-lg">Unique English Words</CardTitle>
                    </CardHeader>
                    <CardContent>
                      <div className="text-3xl font-bold">{chartData.englishWordCount}</div>
                      <p className="text-sm text-gray-500 mt-1">
                        {Math.round((chartData.englishWordCount / (chartData.englishWordCount + chartData.malayWordCount)) * 100)}% of total vocabulary
                      </p>
                    </CardContent>
                  </Card>
                  <Card>
                    <CardHeader className="pb-2">
                      <CardTitle className="text-lg">Unique Malay Words</CardTitle>
                    </CardHeader>
                    <CardContent>
                      <div className="text-3xl font-bold">{chartData.malayWordCount}</div>
                      <p className="text-sm text-gray-500 mt-1">
                        {Math.round((chartData.malayWordCount / (chartData.englishWordCount + chartData.malayWordCount)) * 100)}% of total vocabulary
                      </p>
                    </CardContent>
                  </Card>
                </div>

                {/* Charts */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <Card>
                    <CardHeader>
                      <CardTitle>Language Distribution</CardTitle>
                      <CardDescription>
                        Distribution of English and Malay terms
                      </CardDescription>
                    </CardHeader>
                    <CardContent>
                      <div className="h-80">
                        <ReactApexChart 
                          options={languagePieOptions} 
                          series={[chartData.englishWordCount, chartData.malayWordCount]} 
                          type="pie" 
                          height="100%" 
                        />
                      </div>
                    </CardContent>
                  </Card>

                  {Object.keys(chartData.domainCounts).length > 0 && (
                    <Card>
                      <CardHeader>
                        <CardTitle>Domain Distribution</CardTitle>
                        <CardDescription>
                          Terms distribution across domains
                        </CardDescription>
                      </CardHeader>
                      <CardContent>
                        <div className="h-80">
                          <ReactApexChart 
                            options={domainChartOptions} 
                            series={Object.values(chartData.domainCounts)} 
                            type="pie" 
                            height="100%" 
                          />
                        </div>
                      </CardContent>
                    </Card>
                  )}
                </div>

                {/* Term Sample */}
                <Card>
                  <CardHeader>
                    <CardTitle>Sample Terms</CardTitle>
                    <CardDescription>
                      Showing {Math.min(5, filteredTerms.length)} of {filteredTerms.length} terms
                    </CardDescription>
                  </CardHeader>
                  <CardContent>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                      {filteredTerms.slice(0, 5).map((term, index) => (
                        <Card key={index} className="border border-gray-200">
                          <CardContent className="p-4">
                            <div className="font-medium">{term.english}</div>
                            <div className="text-gray-600">{term.malay}</div>
                            {term.domain && (
                              <div className="text-xs text-gray-500 mt-1">{term.domain}</div>
                            )}
                          </CardContent>
                        </Card>
                      ))}
                    </div>
                  </CardContent>
                </Card>
              </div>
            ) : (
              <div className="text-center py-8">
                <p className="text-lg mb-2">No terms found</p>
                <p className="text-gray-500">
                  {searchQuery 
                    ? "Try adjusting your search query or domain filter" 
                    : "No terms available in the resources folder"}
                </p>
                <Button 
                  variant="outline" 
                  onClick={handleRefresh} 
                  className="mt-4"
                >
                  <RefreshCw className="h-4 w-4 mr-2" />
                  Refresh Data
                </Button>
              </div>
            )}
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default TermBaseDictionary;
