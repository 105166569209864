import React, { useState, useEffect, useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  CardFooter,
} from "../../components/ui/card";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../../components/ui/breadcrumb";
import { Button } from "../../components/ui/button";
import { MessageSquare, ExternalLink, ChevronLeft, ChevronRight } from "lucide-react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const TextTranslateResult = () => {
  const [translations, setTranslations] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  // Load translations data only once
  useEffect(() => {
    const textTranslations = JSON.parse(localStorage.getItem('textTranslations') || '[]');
    // Sort by most recent first
    const sortedTranslations = textTranslations.sort((a, b) => 
      new Date(b.timestamp) - new Date(a.timestamp)
    );
    setTranslations(sortedTranslations);
  }, []); // Empty dependency array - load only once

  // Calculate total pages and validate current page
  const totalPages = useMemo(() => {
    return Math.max(1, Math.ceil(translations.length / rowsPerPage));
  }, [translations.length, rowsPerPage]);

  // Validate current page is within bounds
  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(totalPages);
    }
  }, [currentPage, totalPages]);

  // Function to count words
  const getWordCount = (text) => {
    if (!text) return 0;
    return text.trim().split(/\s+/).length;
  };

  // Get current page data using memo to prevent recalculation
  const currentData = useMemo(() => {
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = Math.min(startIndex + rowsPerPage, translations.length);
    return translations.slice(startIndex, endIndex);
  }, [translations, currentPage, rowsPerPage]);

  // Calculate display range
  const displayRange = useMemo(() => {
    if (!translations.length) return { start: 0, end: 0 };
    
    const start = (currentPage - 1) * rowsPerPage + 1;
    const end = Math.min(currentPage * rowsPerPage, translations.length);
    
    return { start, end };
  }, [translations.length, currentPage, rowsPerPage]);

  // Handle page changes
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  // Jump to specific page
  const jumpToPage = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  // Generate page numbers for pagination
  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxPageButtons = 5; // Max number of page buttons to show
    
    let startPage = Math.max(1, currentPage - Math.floor(maxPageButtons / 2));
    let endPage = Math.min(totalPages, startPage + maxPageButtons - 1);
    
    // Adjust if we're near the end
    if (endPage - startPage + 1 < maxPageButtons) {
      startPage = Math.max(1, endPage - maxPageButtons + 1);
    }
    
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
    
    return pageNumbers;
  };

  return (
    <div className="space-y-4 p-8">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink href="/app/dashboard">Home</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink href="/app/text-translate">Text Translation</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>Translation Results</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <Card className="w-full">
        <CardHeader>
          <div className="flex items-center space-x-2">
            <MessageSquare className="h-6 w-6" />
            <CardTitle>Text Translation Results</CardTitle>
          </div>
          <CardDescription>History of translated texts and their analysis</CardDescription>
        </CardHeader>
        <CardContent>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Source → Target</TableHead>
                <TableHead className="text-right">Source Words</TableHead>
                <TableHead className="text-right">Translated Words</TableHead>
                <TableHead>AI Model</TableHead>
                <TableHead>Last Updated</TableHead>
                <TableHead className="text-center">Analysis</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {currentData.map((translation) => (
                <TableRow key={translation.timestamp}>
                  <TableCell>{`${translation.sourceLanguage} → ${translation.targetLanguage}`}</TableCell>
                  <TableCell className="text-right">
                    {translation.sourceWordCount || getWordCount(translation.sourceText)}
                  </TableCell>
                  <TableCell className="text-right">
                    {translation.translatedWordCount || getWordCount(translation.translatedText)}
                  </TableCell>
                  <TableCell className="capitalize">{translation.modelType}</TableCell>
                  <TableCell>
                    {new Date(translation.timestamp).toLocaleString('en-MY', {
                      dateStyle: 'medium',
                      timeStyle: 'short'
                    })}
                  </TableCell>
                  <TableCell className="text-center">
                    <Button
                      variant="ghost"
                      size="sm"
                      asChild
                    >
                      <Link 
                        to={`/app/text-translate-analysis/${translation.id}`}
                        className="flex items-center gap-1"
                      >
                        Analysis
                        <ExternalLink className="h-4 w-4" />
                      </Link>
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              {translations.length === 0 && (
                <TableRow>
                  <TableCell colSpan={6} className="text-center text-muted-foreground">
                    No translation results found. Try translating some text first.
                  </TableCell>
                </TableRow>
              )}
              {translations.length > 0 && currentData.length === 0 && (
                <TableRow>
                  <TableCell colSpan={6} className="text-center text-muted-foreground">
                    No translations found for this page. Try going back to the previous page.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </CardContent>
        {translations.length > 0 && (
          <CardFooter className="flex items-center justify-between border-t px-6 py-4">
            <div className="text-sm text-muted-foreground">
              Showing {displayRange.start} - {displayRange.end} of {translations.length} entries
            </div>
            <div className="flex items-center space-x-2">
              <Button 
                variant="outline" 
                onClick={handlePreviousPage} 
                disabled={currentPage === 1}
                className="h-8 w-8 p-0"
                aria-label="Previous page"
              >
                <ChevronLeft className="h-4 w-4" />
              </Button>
              
              {/* First page button */}
              {currentPage > 3 && (
                <>
                  <Button 
                    variant={currentPage === 1 ? "default" : "outline"} 
                    onClick={() => jumpToPage(1)}
                    className="h-8 w-8 p-0"
                  >
                    1
                  </Button>
                  {currentPage > 4 && <span className="mx-1">...</span>}
                </>
              )}
              
              {/* Page number buttons */}
              {getPageNumbers().map(pageNum => (
                <Button 
                  key={pageNum}
                  variant={currentPage === pageNum ? "default" : "outline"} 
                  onClick={() => jumpToPage(pageNum)}
                  className="h-8 w-8 p-0"
                >
                  {pageNum}
                </Button>
              ))}
              
              {/* Last page button */}
              {currentPage < totalPages - 2 && (
                <>
                  {currentPage < totalPages - 3 && <span className="mx-1">...</span>}
                  <Button 
                    variant={currentPage === totalPages ? "default" : "outline"} 
                    onClick={() => jumpToPage(totalPages)}
                    className="h-8 w-8 p-0"
                  >
                    {totalPages}
                  </Button>
                </>
              )}
              
              <Button 
                variant="outline" 
                onClick={handleNextPage} 
                disabled={currentPage === totalPages}
                className="h-8 w-8 p-0"
                aria-label="Next page"
              >
                <ChevronRight className="h-4 w-4" />
              </Button>
            </div>
          </CardFooter>
        )}
      </Card>
    </div>
  );
};

export default TextTranslateResult;
