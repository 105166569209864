/** @format */

import PublicLayout from "../layouts/PublicLayout";
import Landing from "../pages/Public/Landing";
import NotFound from "../pages/Public/NotFound";
import Login from "../pages/Public/Login";
import SignUp from "../pages/Public/SignUp";
import About from "../pages/Public/About";

const publicRoutes = [
  {
    element: <PublicLayout />,
    children: [
      {
        path: "/",
        element: <Landing />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/signup",
        element: <SignUp />,
      },
    ],
  },
];

export default publicRoutes;
