/** @format */

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../../components/ui/breadcrumb";
import React, { useState, useEffect } from "react";
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from "../../components/ui/select";

import Chart from "react-apexcharts";

const Dashboard = () => {
  const [timeFrame, setTimeFrame] = useState("month");

  // Mock data for translation metrics
  const translationMetrics = {
    day: {
      textTranslations: 16,
      fileTranslations: 3,
      sourceTextChars: 2500,
      translatedTextChars: 2800,
      previousPeriod: {
        textTranslations: 14,
        fileTranslations: 2,
        sourceTextChars: 2100,
        translatedTextChars: 2300,
      }
    },
    week: {
      textTranslations: 107,
      fileTranslations: 21,
      sourceTextChars: 18700,
      translatedTextChars: 21200,
      previousPeriod: {
        textTranslations: 91,
        fileTranslations: 17,
        sourceTextChars: 15500,
        translatedTextChars: 17600,
      }
    },
    month: {
      textTranslations: 432,
      fileTranslations: 78,
      sourceTextChars: 74500,
      translatedTextChars: 83600,
      previousPeriod: {
        textTranslations: 387,
        fileTranslations: 65,
        sourceTextChars: 65800,
        translatedTextChars: 72400,
      }
    },
    year: {
      textTranslations: 5187,
      fileTranslations: 934,
      sourceTextChars: 895000,
      translatedTextChars: 1035000,
      previousPeriod: {
        textTranslations: 4510,
        fileTranslations: 782,
        sourceTextChars: 786000,
        translatedTextChars: 892000,
      }
    }
  };

  // Calculate percentage changes
  const calculatePercentChange = (current, previous) => {
    if (previous === 0) return current > 0 ? 100 : 0;
    return ((current - previous) / previous * 100).toFixed(1);
  };

  const getCurrentMetrics = () => translationMetrics[timeFrame];
  
  const metrics = getCurrentMetrics();
  
  const percentChanges = {
    textTranslations: calculatePercentChange(metrics.textTranslations, metrics.previousPeriod.textTranslations),
    fileTranslations: calculatePercentChange(metrics.fileTranslations, metrics.previousPeriod.fileTranslations),
    sourceTextChars: calculatePercentChange(metrics.sourceTextChars, metrics.previousPeriod.sourceTextChars),
    translatedTextChars: calculatePercentChange(metrics.translatedTextChars, metrics.previousPeriod.translatedTextChars),
  };

  // Chart data for translation activity
  const getTimeLabels = () => {
    switch(timeFrame) {
      case 'day':
        return ['12am', '3am', '6am', '9am', '12pm', '3pm', '6pm', '9pm'];
      case 'week':
        return ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
      case 'month':
        return [...Array(30).keys()].map(day => `Day ${day + 1}`);
      case 'year':
        return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      default:
        return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    }
  };

  // Generate sample data for translation charts
  const generateChartData = () => {
    const labels = getTimeLabels();
    const textData = [];
    const fileData = [];
    
    labels.forEach(() => {
      textData.push(Math.floor(Math.random() * metrics.textTranslations / labels.length * 1.5));
      fileData.push(Math.floor(Math.random() * metrics.fileTranslations / labels.length * 1.5));
    });
    
    return {
      labels,
      textData,
      fileData
    };
  };

  const chartData = generateChartData();

  const translationActivityOptions = {
    chart: {
      type: "line",
      height: 350,
      toolbar: {
        show: false
      }
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 3,
    },
    xaxis: {
      categories: chartData.labels,
    },
    yaxis: {
      title: {
        text: "Count",
      },
    },
    colors: ['#6366f1', '#f43f5e'],
    tooltip: {
      y: {
        formatter: (val) => `${val}`,
      },
    },
    legend: {
      position: 'top'
    }
  };

  const translationActivitySeries = [
    {
      name: "Text Translations",
      data: chartData.textData,
    },
    {
      name: "File Translations",
      data: chartData.fileData,
    },
  ];

  // Characters usage chart data
  const characterUsageOptions = {
    chart: {
      type: "area",
      height: 350,
      toolbar: {
        show: false
      }
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    xaxis: {
      categories: chartData.labels,
    },
    yaxis: {
      title: {
        text: "Characters",
      },
    },
    colors: ['#6366f1', '#8b5cf6'],
    tooltip: {
      y: {
        formatter: (val) => `${val.toLocaleString()} chars`,
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.3,
      }
    },
    legend: {
      position: 'top'
    }
  };

  // Generate random character usage data based on the total metrics
  const generateCharUsageData = () => {
    const labels = getTimeLabels();
    const sourceData = [];
    const translatedData = [];
    
    labels.forEach(() => {
      sourceData.push(Math.floor(Math.random() * metrics.sourceTextChars / labels.length * 1.5));
      translatedData.push(Math.floor(Math.random() * metrics.translatedTextChars / labels.length * 1.5));
    });
    
    return {
      sourceData,
      translatedData
    };
  };

  const charUsage = generateCharUsageData();

  const characterUsageSeries = [
    {
      name: "Source Text Characters",
      data: charUsage.sourceData,
    },
    {
      name: "Translated Text Characters",
      data: charUsage.translatedData,
    },
  ];

  return (
    <div className="flex flex-col bg-gray-50">
      <br />
      <Breadcrumb className="pl-6 pt-6">
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink>Admin</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>Dashboard</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <div className="container px-4 h-full min-w-full flex">
        <div className="p-6 space-y-6 w-full">
          {/* Time Period Selector */}
          <div className="flex justify-end mb-4">
            <Select value={timeFrame} onValueChange={setTimeFrame}>
              <SelectTrigger className="w-[180px] bg-white border-gray-300 hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500">
                <SelectValue placeholder="Select Time Period" />
              </SelectTrigger>
              <SelectContent className="bg-white border border-gray-200 shadow-lg">
                <SelectGroup>
                  <SelectLabel className="text-gray-700 font-medium">Time Period</SelectLabel>
                  <SelectItem value="day" className="hover:bg-indigo-50 cursor-pointer">Today</SelectItem>
                  <SelectItem value="week" className="hover:bg-indigo-50 cursor-pointer">This Week</SelectItem>
                  <SelectItem value="month" className="hover:bg-indigo-50 cursor-pointer">This Month</SelectItem>
                  <SelectItem value="year" className="hover:bg-indigo-50 cursor-pointer">This Year</SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>

          {/* Translation Metrics Section */}
          <div className="grid grid-cols-4 gap-4">
            <Card className="bg-indigo-500 text-white">
              <CardHeader>
                <CardTitle className="text-md">
                  Text Translations ({timeFrame === 'day' ? 'Today' : timeFrame === 'week' ? 'This Week' : timeFrame === 'month' ? 'This Month' : 'This Year'})
                </CardTitle>
                <CardDescription className="text-indigo-100">{percentChanges.textTranslations > 0 ? '↑' : '↓'} {Math.abs(percentChanges.textTranslations)}% compared to previous {timeFrame}</CardDescription>
              </CardHeader>
              <CardContent>
                <p className="text-2xl font-bold">{metrics.textTranslations.toLocaleString()}</p>
                <p className="text-sm mt-2">Previous {timeFrame}: {metrics.previousPeriod.textTranslations.toLocaleString()}</p>
              </CardContent>
            </Card>

            <Card className="bg-rose-500 text-white">
              <CardHeader>
                <CardTitle className="text-md">
                  File Translations ({timeFrame === 'day' ? 'Today' : timeFrame === 'week' ? 'This Week' : timeFrame === 'month' ? 'This Month' : 'This Year'})
                </CardTitle>
                <CardDescription className="text-rose-100">{percentChanges.fileTranslations > 0 ? '↑' : '↓'} {Math.abs(percentChanges.fileTranslations)}% compared to previous {timeFrame}</CardDescription>
              </CardHeader>
              <CardContent>
                <p className="text-2xl font-bold">{metrics.fileTranslations.toLocaleString()}</p>
                <p className="text-sm mt-2">Previous {timeFrame}: {metrics.previousPeriod.fileTranslations.toLocaleString()}</p>
              </CardContent>
            </Card>

            <Card className="bg-violet-500 text-white">
              <CardHeader>
                <CardTitle className="text-md">
                  Source Text Characters ({timeFrame === 'day' ? 'Today' : timeFrame === 'week' ? 'This Week' : timeFrame === 'month' ? 'This Month' : 'This Year'})
                </CardTitle>
                <CardDescription className="text-violet-100">{percentChanges.sourceTextChars > 0 ? '↑' : '↓'} {Math.abs(percentChanges.sourceTextChars)}% compared to previous {timeFrame}</CardDescription>
              </CardHeader>
              <CardContent>
                <p className="text-2xl font-bold">{metrics.sourceTextChars.toLocaleString()}</p>
                <p className="text-sm mt-2">Previous {timeFrame}: {metrics.previousPeriod.sourceTextChars.toLocaleString()}</p>
              </CardContent>
            </Card>

            <Card className="bg-purple-700 text-white">
              <CardHeader>
                <CardTitle className="text-md">
                  Translated Text Characters ({timeFrame === 'day' ? 'Today' : timeFrame === 'week' ? 'This Week' : timeFrame === 'month' ? 'This Month' : 'This Year'})
                </CardTitle>
                <CardDescription className="text-purple-100">{percentChanges.translatedTextChars > 0 ? '↑' : '↓'} {Math.abs(percentChanges.translatedTextChars)}% compared to previous {timeFrame}</CardDescription>
              </CardHeader>
              <CardContent>
                <p className="text-2xl font-bold">{metrics.translatedTextChars.toLocaleString()}</p>
                <p className="text-sm mt-2">Previous {timeFrame}: {metrics.previousPeriod.translatedTextChars.toLocaleString()}</p>
              </CardContent>
            </Card>
          </div>

          {/* Translation Activity Charts */}
          <div className="grid grid-cols-2 gap-4">
            <Card>
              <CardHeader>
                <CardTitle className="text-md">
                  Translation Activity ({timeFrame === 'day' ? 'Today' : timeFrame === 'week' ? 'This Week' : timeFrame === 'month' ? 'This Month' : 'This Year'})
                </CardTitle>
              </CardHeader>
              <CardContent>
                <Chart
                  options={translationActivityOptions}
                  series={translationActivitySeries}
                  type="line"
                  height={350}
                />
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle className="text-md">
                  Character Usage ({timeFrame === 'day' ? 'Today' : timeFrame === 'week' ? 'This Week' : timeFrame === 'month' ? 'This Month' : 'This Year'})
                </CardTitle>
              </CardHeader>
              <CardContent>
                <Chart
                  options={characterUsageOptions}
                  series={characterUsageSeries}
                  type="area"
                  height={350}
                />
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
