/** @format */

import React from "react";
import ReactDOM from "react-dom/client";

import "./assets/styles/globals.css";
import "sweetalert2/dist/sweetalert2.css";
import "./i18n";
import App from "./app";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
