/** @format */

import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import { Button } from "../../components/ui/button";
import { Cloud, Globe, Server, Bot, Power, PowerOff, Settings } from "lucide-react";
import { Link } from "react-router-dom";
import { loadAIModels, updateAIModel } from "../../utils/aiModelUtils";

const AIModelDashboard = () => {
  const [models, setModels] = useState([]);
  const [stats, setStats] = useState({
    total: 0,
    active: 0,
    openai: 0,
    groq: 0,
    ollama: 0,
    huggingface: 0,
  });

  useEffect(() => {
    loadModelData();
  }, []);

  const loadModelData = async () => {
    try {
      const savedModels = await loadAIModels();
      setModels(savedModels);

      // Calculate stats
      const newStats = {
        total: savedModels.length,
        active: savedModels.filter(m => m.isActive).length,
        openai: savedModels.filter(m => m.type === 'openai').length,
        groq: savedModels.filter(m => m.type === 'groq').length,
        ollama: savedModels.filter(m => m.type === 'ollama').length,
        huggingface: savedModels.filter(m => m.type === 'huggingface').length,
      };
      setStats(newStats);
    } catch (error) {
      console.error('Error loading models:', error);
    }
  };

  const getModelIcon = (type) => {
    switch (type) {
      case 'openai':
        return <Cloud className="h-6 w-6" />;
      case 'groq':
        return <Globe className="h-6 w-6" />;
      case 'ollama':
        return <Server className="h-6 w-6" />;
      case 'huggingface':
        return <Bot className="h-6 w-6" />;
      default:
        return null;
    }
  };

  const handleToggleActive = async (model) => {
    try {
      const success = await updateAIModel(model.id, !model.isActive);
      if (success) {
        await loadModelData(); // Reload all models and stats
      } else {
        alert("Failed to update model status");
      }
    } catch (error) {
      console.error('Error updating model:', error);
      alert("Failed to update model: " + error.message);
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      <div className="container mx-auto p-6">
        <div className="flex justify-between items-center mb-6">
          <div>
            <h1 className="text-2xl font-bold">AI Model Dashboard</h1>
            <p className="text-gray-600">Monitor and manage your AI translation models</p>
          </div>
          <Link to="/app/ai-models/settings">
            <Button variant="outline" className="flex items-center gap-2">
              <Settings className="h-4 w-4" />
              Model Settings
            </Button>
          </Link>
        </div>

        {/* Stats Overview */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
          <Card>
            <CardHeader className="pb-2">
              <CardTitle className="text-sm font-medium text-gray-500">
                Total Models
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">{stats.total}</div>
              <p className="text-xs text-gray-500">
                Active: {stats.active} | Inactive: {stats.total - stats.active}
              </p>
            </CardContent>
          </Card>
          <Card>
            <CardHeader className="pb-2">
              <CardTitle className="text-sm font-medium text-gray-500">
                OpenAI Models
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">{stats.openai}</div>
              <p className="text-xs text-gray-500">GPT-based models</p>
            </CardContent>
          </Card>
          <Card>
            <CardHeader className="pb-2">
              <CardTitle className="text-sm font-medium text-gray-500">
                Groq Models
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">{stats.groq}</div>
              <p className="text-xs text-gray-500">Mixtral-based models</p>
            </CardContent>
          </Card>
          <Card>
            <CardHeader className="pb-2">
              <CardTitle className="text-sm font-medium text-gray-500">
                Local Models
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">{stats.ollama + stats.huggingface}</div>
              <p className="text-xs text-gray-500">
                Ollama: {stats.ollama} | HF: {stats.huggingface}
              </p>
            </CardContent>
          </Card>
        </div>

        {/* Models List */}
        <Card>
          <CardHeader>
            <CardTitle>Configured Models</CardTitle>
            <CardDescription>
              List of all configured translation models
            </CardDescription>
          </CardHeader>
          <CardContent>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Model</TableHead>
                  <TableHead>Type</TableHead>
                  <TableHead>Endpoint</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead className="text-right">Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {models.map((model) => (
                  <TableRow key={model.id}>
                    <TableCell className="font-medium">
                      <div className="flex items-center gap-2">
                        <div className={`p-2 rounded-lg ${
                          model.isActive ? 'bg-green-100' : 'bg-gray-100'
                        }`}>
                          {getModelIcon(model.type)}
                        </div>
                        {model.name}
                      </div>
                    </TableCell>
                    <TableCell>
                      {model.type.charAt(0).toUpperCase() + model.type.slice(1)}
                    </TableCell>
                    <TableCell>
                      {model.endpoint || 'Default'}
                    </TableCell>
                    <TableCell>
                      <span className={`px-2 py-1 rounded-full text-xs ${
                        model.isActive 
                          ? 'bg-green-100 text-green-800' 
                          : 'bg-gray-100 text-gray-800'
                      }`}>
                        {model.isActive ? 'Active' : 'Inactive'}
                      </span>
                    </TableCell>
                    <TableCell className="text-right">
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => handleToggleActive(model)}
                        className={model.isActive ? 'text-green-600' : 'text-gray-600'}
                      >
                        {model.isActive ? (
                          <Power className="h-4 w-4" />
                        ) : (
                          <PowerOff className="h-4 w-4" />
                        )}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default AIModelDashboard;
