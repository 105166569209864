/** @format */

import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/ui/button";
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from "../../components/ui/card";
import { Languages, Save, Download, FileDown, Pencil, RefreshCw, Trash2 } from "lucide-react";
import { saveAs } from 'file-saver';
import { Table, TableHeader, TableBody, TableRow, TableHead, TableCell } from "../../components/ui/table";
import { useGlossary } from "../../contexts/GlossaryContext";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select";
import { loadAIModels } from "../../utils/aiModelUtils";
import PlaygroundSimpleHeader from "../../components/core/public/PlaygroundSimpleHeader";
import PlaygroundNavigation from "../../components/core/public/PlaygroundNavigation";

const TermBase = () => {
  const [t] = useTranslation("translation", {
    keyPrefix: "termBasePage",
  });

  const [sourceText, setSourceText] = useState("");
  const [translatedText, setTranslatedText] = useState("");
  const [termPairs, setTermPairs] = useState([]);
  const [sourceSelection, setSourceSelection] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [availableModels, setAvailableModels] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sourceLanguage, setSourceLanguage] = useState("English");
  const [targetLanguage, setTargetLanguage] = useState("Bahasa Melayu");
  const [wordCount, setWordCount] = useState(0);
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [cursorPosition, setCursorPosition] = useState({ top: 0, left: 0 });
  const [currentWord, setCurrentWord] = useState("");
  const [allTerms, setAllTerms] = useState([]);
  const [error, setError] = useState(null);
  const sourceTextAreaRef = useRef(null);

  // Function to count words in a text
  const countWords = (text) => {
    return text.trim() ? text.trim().split(/\s+/).length : 0;
  };

  useEffect(() => {
    const fetchModels = async () => {
      try {
        const models = await loadAIModels();
        const activeModels = models.filter(model => model.isActive);
        console.log('Available models:', activeModels);
        setAvailableModels(activeModels);
        if (activeModels.length > 0 && !selectedModel) {
          setSelectedModel(activeModels[0]);
        }
      } catch (error) {
        console.error('Error fetching models:', error);
        setError('Failed to load AI models. Please try again later.');
      }
    };

    fetchModels();
  }, []);

  useEffect(() => {
    // Update word count when source text changes
    setWordCount(countWords(sourceText));
  }, [sourceText]);

  useEffect(() => {
    // Load all terms from termBaseDomains
    const loadAllTerms = () => {
      try {
        const allTermsList = [];
        
        // Load from termBaseDomains only
        const savedTermBaseDomains = localStorage.getItem('termBaseDomains');
        if (!savedTermBaseDomains) {
          setAllTerms([]);
          return;
        }

        const parsedDomains = JSON.parse(savedTermBaseDomains);
        
        // Process each domain
        parsedDomains.forEach(domain => {
          try {
            // Decode the base64 content with Unicode support
            const base64Content = domain.content;
            const jsonString = decodeURIComponent(escape(atob(base64Content)));
            const data = JSON.parse(jsonString);

            // Validate and transform the data
            if (Array.isArray(data)) {
              const domainTerms = data
                .filter(item => item && typeof item === 'object')
                .map(item => ({
                  english: (item.english || '').trim(),
                  malay: (item.malay || '').trim(),
                  domain: domain.name
                }))
                .filter(term => term.english && term.malay);

              allTermsList.push(...domainTerms);
            }
          } catch (error) {
            console.error(`Error parsing content for termBase domain ${domain.name}:`, error);
          }
        });

        setAllTerms(allTermsList);
        console.log("Loaded terms:", allTermsList.length);
      } catch (error) {
        console.error('Error loading terms:', error);
        setAllTerms([]);
      }
    };

    loadAllTerms();
  }, []);

  const handleTranslate = async () => {
    if (!sourceText.trim()) {
      alert("Please enter text to translate");
      return;
    }

    if (!selectedModel) {
      alert("Please select a translation model");
      return;
    }

    if (countWords(sourceText) > 100) {
      alert("Please limit your text to 100 words to control token usage");
      return;
    }

    setIsLoading(true);
    try {
      let translatedResult;
      
      switch (selectedModel.type) {
        case 'openai':
          translatedResult = await translateWithOpenAI(sourceText, selectedModel.apiKey);
          break;
        case 'groq':
          translatedResult = await translateWithGroq(sourceText, selectedModel.apiKey);
          break;
        case 'ollama':
          translatedResult = await translateWithOllama(sourceText, selectedModel.endpoint);
          break;
        case 'huggingface':
          translatedResult = await translateWithHuggingFace(
            sourceText, 
            selectedModel.apiKey, 
            selectedModel.modelId, 
            selectedModel.endpoint
          );
          break;
        default:
          throw new Error('Unsupported model type');
      }

      setTranslatedText(translatedResult);
    } catch (error) {
      console.error('Translation error:', error);
      alert("Translation failed: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  // Translation service functions
  const translateWithOpenAI = async (text, apiKey) => {
    try {
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`
        },
        body: JSON.stringify({
          model: "gpt-3.5-turbo",
          messages: [
            {
              role: "system",
              content: `You are a professional translator. Translate the following text from ${sourceLanguage} to ${targetLanguage}. Provide only the translated text without any additional comments or explanations.`
            },
            {
              role: "user",
              content: text
            }
          ],
          temperature: 0.3,
          max_tokens: 1000
        })
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error?.message || 'OpenAI API error');
      }

      const data = await response.json();
      return data.choices[0].message.content.trim();
    } catch (error) {
      console.error('OpenAI translation error:', error);
      throw error;
    }
  };

  const translateWithGroq = async (text, apiKey) => {
    try {
      const response = await fetch('https://api.groq.com/openai/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`
        },
        body: JSON.stringify({
          model: selectedModel.modelId || 'mistral-saba-24b',
          messages: [
            {
              role: "system",
              content: `You are a professional translator. Translate the following text from ${sourceLanguage} to ${targetLanguage}. Provide only the translated text without any additional comments or explanations.`
            },
            {
              role: "user",
              content: text
            }
          ],
          temperature: 0.3,
          max_tokens: 1000
        })
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error?.message || 'Groq API error');
      }

      const data = await response.json();
      return data.choices[0].message.content.trim();
    } catch (error) {
      console.error('Groq translation error:', error);
      throw error;
    }
  };

  const translateWithOllama = async (text, endpoint) => {
    try {
      console.log('Ollama Request:', {
        endpoint,
        model: selectedModel.modelId || "llama3",
        text: text.substring(0, 100)
      });
      
      const response = await fetch(`${endpoint}/api/generate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          model: selectedModel.modelId || "llama3",
          prompt: `Translate the following text from ${sourceLanguage} to ${targetLanguage}. Provide only the translated text without any additional comments or explanations.\n\n${text}`,
          stream: false,
          options: {
            temperature: 0.1
          }
        })
      });

      if (!response.ok) {
        throw new Error(`Ollama API error: ${response.status}`);
      }

      const data = await response.json();
      return data.response.trim();
    } catch (error) {
      console.error('Ollama translation error:', error);
      throw error;
    }
  };

  const translateWithHuggingFace = async (text, apiKey, modelId, endpoint) => {
    try {
      const apiUrl = endpoint || `https://api-inference.huggingface.co/models/${modelId}`;
      
      // Log request details for debugging
      console.log('HF Request:', {
        url: apiUrl,
        modelId,
        hasApiKey: !!apiKey,
        text: text.substring(0, 100) // Log first 100 chars only
      });

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`
        },
        body: JSON.stringify({
          inputs: text,
          options: {
            wait_for_model: true
          }
        })
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        console.error('HF Error:', errorData);
        throw new Error(`Hugging Face API error: ${response.status} - ${errorData.error || 'Unknown error'}`);
      }

      const data = await response.json();
      console.log('HF Response:', data); // Debug log
      
      // Handle different response formats
      if (Array.isArray(data)) {
        // Translation model response
        if (data[0]?.translation_text) {
          return data[0].translation_text;
        }
        // Text generation response
        if (data[0]?.generated_text) {
          return data[0].generated_text;
        }
        // Raw text array response
        if (typeof data[0] === 'string') {
          return data[0];
        }
        // Try to extract text from complex array response
        if (data[0]?.text) {
          return data[0].text;
        }
        return JSON.stringify(data[0]);
      }
      
      // Single object response
      if (data.translation_text) {
        return data.translation_text;
      }
      if (data.generated_text) {
        return data.generated_text;
      }
      if (data.text) {
        return data.text;
      }
      
      // If we get here, try to convert the response to a string
      if (typeof data === 'string') {
        return data;
      }
      
      return JSON.stringify(data);
    } catch (error) {
      console.error('HF Translation error:', error);
      throw new Error(`Hugging Face translation failed: ${error.message}`);
    }
  };

  const handleSourceTextSelect = () => {
    const selection = window.getSelection();
    if (selection && selection.toString().trim()) {
      console.log('Source selection:', selection.toString().trim());
      setSourceSelection({
        text: selection.toString().trim(),
        start: selection.anchorOffset,
        end: selection.focusOffset,
      });
    }
  };

  const handleTargetTextSelect = () => {
    const selection = window.getSelection();
    if (!selection) return;
    
    const selectedText = selection.toString().trim();
    console.log('Target selection:', selectedText);
    
    if (selectedText && sourceSelection) {
      const newPair = {
        source: sourceSelection.text,
        target: selectedText,
      };
      
      console.log('Creating new term pair:', newPair);
      setTermPairs(prevPairs => [...prevPairs, newPair]);
      setSourceSelection(null);
    }
  };

  // Get the current word being typed
  const getCurrentWord = (text, cursorPos) => {
    if (!text || cursorPos === undefined) return "";
    
    // Find the start of the current word
    let start = cursorPos;
    while (start > 0 && text[start - 1] !== ' ' && text[start - 1] !== '\n') {
      start--;
    }
    
    // Find the end of the current word
    let end = cursorPos;
    while (end < text.length && text[end] !== ' ' && text[end] !== '\n') {
      end++;
    }
    
    return text.substring(start, end);
  };

  // Handle input in source text area
  const handleSourceTextInput = (e) => {
    const newText = e.target.value;
    setSourceText(newText);
    
    const cursorPos = e.target.selectionStart;
    const word = getCurrentWord(newText, cursorPos);
    setCurrentWord(word);
    
    // Only show suggestions if the word is at least 2 characters long
    if (word && word.length >= 2) {
      // Find matching terms
      const matches = allTerms.filter(term => {
        const englishMatch = term.english.toLowerCase().includes(word.toLowerCase());
        const malayMatch = term.malay.toLowerCase().includes(word.toLowerCase());
        return englishMatch || malayMatch;
      }).slice(0, 5); // Limit to 5 suggestions
      
      setSuggestions(matches);
      setShowSuggestions(matches.length > 0);
      
      // Calculate position for the suggestions dropdown
      if (sourceTextAreaRef.current) {
        const textArea = sourceTextAreaRef.current;
        const cursorCoords = getCursorCoordinates(textArea, cursorPos);
        setCursorPosition({
          top: cursorCoords.top + 20, // Position below the cursor
          left: cursorCoords.left
        });
      }
    } else {
      setShowSuggestions(false);
    }
  };

  // Get cursor coordinates in the textarea
  const getCursorCoordinates = (textArea, cursorPos) => {
    // Create a hidden div with the same styling as the textarea
    const div = document.createElement('div');
    const style = window.getComputedStyle(textArea);
    
    // Copy styles from textarea to div
    const stylesToCopy = [
      'font-family', 'font-size', 'font-weight', 'line-height',
      'letter-spacing', 'padding-left', 'padding-top', 'width',
      'text-indent', 'white-space', 'word-wrap', 'box-sizing'
    ];
    
    stylesToCopy.forEach(prop => {
      div.style[prop] = style.getPropertyValue(prop);
    });
    
    div.style.position = 'absolute';
    div.style.visibility = 'hidden';
    div.style.whiteSpace = 'pre-wrap';
    
    // Get text before cursor
    const textBeforeCursor = textArea.value.substring(0, cursorPos);
    
    // Create a span for the text before cursor
    const span = document.createElement('span');
    span.textContent = textBeforeCursor;
    div.appendChild(span);
    
    // Add div to document
    document.body.appendChild(div);
    
    // Get position of the span end
    const rect = span.getBoundingClientRect();
    const textAreaRect = textArea.getBoundingClientRect();
    
    // Remove the div
    document.body.removeChild(div);
    
    // Return coordinates relative to the textarea
    return {
      top: rect.height,
      left: rect.width % textAreaRect.width
    };
  };

  // Insert the selected suggestion into the text
  const handleSuggestionClick = (suggestion) => {
    if (!sourceTextAreaRef.current) return;
    
    const textArea = sourceTextAreaRef.current;
    const cursorPos = textArea.selectionStart;
    const text = sourceText;
    
    // Find the start and end of the current word
    let start = cursorPos;
    while (start > 0 && text[start - 1] !== ' ' && text[start - 1] !== '\n') {
      start--;
    }
    
    let end = cursorPos;
    while (end < text.length && text[end] !== ' ' && text[end] !== '\n') {
      end++;
    }
    
    // Replace the current word with the suggestion
    const sourceLanguageLower = sourceLanguage.toLowerCase();
    const termToInsert = sourceLanguageLower.includes("english") ? suggestion.english : suggestion.malay;
    
    const newText = text.substring(0, start) + termToInsert + text.substring(end);
    setSourceText(newText);
    
    // Set cursor position after the inserted term
    setTimeout(() => {
      textArea.focus();
      const newCursorPos = start + termToInsert.length;
      textArea.setSelectionRange(newCursorPos, newCursorPos);
    }, 0);
    
    setShowSuggestions(false);
  };

  const handleSave = () => {
    if (termPairs.length === 0) {
      alert("No terms to save!");
      return;
    }

    // Format the term pairs according to the required format
    const formattedTerms = termPairs.map(pair => ({
      english: sourceLanguage.toLowerCase() === "english" ? pair.source : pair.target,
      malay: sourceLanguage.toLowerCase() === "english" ? pair.target : pair.source
    }));

    const blob = new Blob([JSON.stringify(formattedTerms, null, 2)], {
      type: "application/json",
    });
    
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "termbase.json";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handleClear = () => {
    setSourceText("");
    setTranslatedText("");
    setTermPairs([]);
    setSourceSelection(null);
    setIsLoading(false);
    setWordCount(0);
  };

  return (
    <div className="min-h-screen bg-white">
      <PlaygroundSimpleHeader keyPrefix="playgroundPage" />
      <PlaygroundNavigation />
      
      <div className="container mx-auto px-4 py-8 max-w-[75%]">
        <div className="bg-white rounded-2xl shadow-xl p-6 md:p-8">
          <h1 className="text-3xl font-bold mb-8">Term Base Creator</h1>
          
          <div className="mb-6 grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="w-full">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Source Language
              </label>
              <Select
                value={sourceLanguage}
                onValueChange={setSourceLanguage}
              >
                <SelectTrigger className="w-full bg-white border-gray-300 hover:bg-blue-50 transition-colors duration-200">
                  <SelectValue placeholder="Select source language" />
                </SelectTrigger>
                <SelectContent className="bg-white">
                  <SelectItem value="English" className="hover:bg-blue-50">English</SelectItem>
                  <SelectItem value="Bahasa Melayu" className="hover:bg-blue-50">Bahasa Melayu</SelectItem>
                </SelectContent>
              </Select>
            </div>
            
            <div className="w-full">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Target Language
              </label>
              <Select
                value={targetLanguage}
                onValueChange={setTargetLanguage}
              >
                <SelectTrigger className="w-full bg-gray-100 border-gray-300 hover:bg-blue-50 transition-colors duration-200">
                  <SelectValue placeholder="Select target language" />
                </SelectTrigger>
                <SelectContent className="bg-white">
                  <SelectItem value="English" className="hover:bg-blue-50">English</SelectItem>
                  <SelectItem value="Bahasa Melayu" className="hover:bg-blue-50">Bahasa Melayu</SelectItem>
                </SelectContent>
              </Select>
            </div>
            
            <div className="w-full">
              <label className="block text-sm font-medium text-gray-700 mb-2">AI Translation Model</label>
              <Select
                value={selectedModel}
                onValueChange={setSelectedModel}
              >
                <SelectTrigger className="w-full hover:bg-blue-50 transition-colors duration-200">
                  <SelectValue placeholder="Select a model">
                    {selectedModel ? (
                      <div className="flex items-center gap-2">
                        <Languages className="h-4 w-4" />
                        {selectedModel.name}
                      </div>
                    ) : (
                      "Select a model"
                    )}
                  </SelectValue>
                </SelectTrigger>
                <SelectContent>
                  {availableModels.map((model) => (
                    <SelectItem key={model.id} value={model} className="hover:bg-blue-50">
                      <div className="flex items-center gap-2">
                        <Languages className="h-4 w-4" />
                        {model.name}
                      </div>
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div>
              <div className="flex justify-between items-center mb-2">
                <label className="block text-sm font-medium text-gray-700">
                  Source Text
                </label>
                <span className="text-sm text-gray-500">
                  {wordCount} words
                </span>
              </div>
              <div className="relative">
                <textarea
                  className="w-full h-48 p-4 border rounded-lg"
                  value={sourceText}
                  onChange={handleSourceTextInput}
                  onMouseUp={handleSourceTextSelect}
                  placeholder="Enter source text here..."
                  id="source-text-area"
                  ref={sourceTextAreaRef}
                />
                {showSuggestions && (
                  <div 
                    className="absolute z-10 bg-white border border-gray-200 rounded-md shadow-lg max-h-60 overflow-y-auto"
                    style={{ 
                      width: '300px',
                      maxWidth: '90%',
                      top: cursorPosition.top,
                      left: cursorPosition.left
                    }}
                  >
                    <ul className="py-1">
                      {suggestions.map((suggestion, index) => (
                        <li 
                          key={index} 
                          className="px-4 py-2 hover:bg-blue-50 cursor-pointer text-sm"
                          onClick={() => handleSuggestionClick(suggestion)}
                        >
                          <div className="font-medium">
                            {sourceLanguage.toLowerCase().includes("english") ? suggestion.english : suggestion.malay}
                          </div>
                          <div className="text-xs text-gray-500">
                            {sourceLanguage.toLowerCase().includes("english") ? suggestion.malay : suggestion.english}
                            {suggestion.domain && <span className="ml-2 text-blue-500">({suggestion.domain})</span>}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              {sourceSelection && (
                <div className="mt-2 p-2 bg-blue-50 border border-blue-200 rounded-md">
                  <p className="text-sm text-blue-800">
                    Selected source text: <strong>{sourceSelection.text}</strong>
                  </p>
                  <p className="text-xs text-blue-600">
                    Now select the corresponding text in the target area
                  </p>
                </div>
              )}
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Translated Text
              </label>
              <textarea
                className="w-full h-48 p-4 border rounded-lg"
                value={translatedText}
                onChange={(e) => setTranslatedText(e.target.value)}
                onMouseUp={handleTargetTextSelect}
                placeholder="Translated text will appear here..."
                readOnly={isLoading}
                id="target-text-area"
              />
            </div>
          </div>
          
          <div className="flex justify-center gap-4 mb-6">
            <Button
              onClick={handleTranslate}
              className="bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700 flex items-center gap-2"
              disabled={isLoading || !sourceText.trim() || !selectedModel}
            >
              {isLoading ? (
                <>
                  <RefreshCw className="h-4 w-4 animate-spin" />
                  Translating...
                </>
              ) : (
                <>
                  <Languages className="h-4 w-4" />
                  Translate
                </>
              )}
            </Button>
            
            <Button
              onClick={handleClear}
              className="bg-gray-500 text-white px-6 py-2 rounded-lg hover:bg-gray-600 flex items-center gap-2"
            >
              <Trash2 className="h-4 w-4" />
              Clear
            </Button>
          </div>

          <div className="mb-6">
            <h2 className="text-xl font-semibold mb-4">Term Pairs</h2>
            <div className="bg-blue-50 p-4 rounded-lg mb-4">
              <h3 className="text-md font-medium text-blue-800 mb-2">How to create term pairs:</h3>
              <ol className="list-decimal pl-5 text-sm text-blue-700 space-y-1">
                <li>Select text in the source area</li>
                <li>Select the corresponding text in the target area</li>
                <li>The term pair will be automatically added to the table below</li>
              </ol>
            </div>
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Source ({sourceLanguage})
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Target ({targetLanguage})
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-24">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {termPairs.length > 0 ? (
                    termPairs.map((pair, index) => (
                      <tr key={index}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {pair.source}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {pair.target}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          <button 
                            onClick={() => {
                              setTermPairs(termPairs.filter((_, i) => i !== index));
                            }}
                            className="text-red-500 hover:text-red-700"
                          >
                            <Trash2 className="h-4 w-4" />
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3" className="px-6 py-4 text-center text-sm text-gray-500">
                        No term pairs created yet
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <div className="flex justify-end">
            <Button
              onClick={handleSave}
              className="bg-green-600 text-white px-6 py-2 rounded-lg hover:bg-green-700 flex items-center gap-2"
              disabled={termPairs.length === 0}
            >
              <Save className="h-4 w-4" />
              Save Terms
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermBase;
