/** @format */

import React, { createContext, useState, useContext, useEffect, useCallback } from "react";
import axios from "axios";

// Create the context
const GlossaryContext = createContext();

// Constants for retry logic
const MAX_RETRIES = 3;
const RETRY_DELAY = 1000; // 1 second

// Create a provider component
export const GlossaryProvider = ({ children }) => {
  const [allTerms, setAllTerms] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [retryCount, setRetryCount] = useState(0);

  // Load all glossary terms from all domains
  useEffect(() => {
    loadAllTerms();
  }, []);

  // Load JSON files from GTTFiles folder
  const loadAllTerms = useCallback(async (retry = 0) => {
    try {
      setIsLoaded(false);
      setError(null);
      
      // Fetch the list of JSON files from the GTTFiles folder
      const response = await axios.get('/utm-ittbm/api/gtt/files');
      console.log('GTT files response:', response.data);
      
      if (!response.data || !Array.isArray(response.data) || response.data.length === 0) {
        console.log('No GTT files found or invalid response format');
        setAllTerms([]);
        setIsLoaded(true);
        setRetryCount(0);
        return;
      }

      const files = response.data;
      const allTermsList = [];
      
      // Process each JSON file
      for (const file of files) {
        try {
          // Fetch the content of each JSON file
          const fileResponse = await axios.get(`/utm-ittbm/api/gtt/file/${encodeURIComponent(file.name)}`);
          console.log(`File content for ${file.name}:`, fileResponse.data);
          
          const fileData = fileResponse.data;
          
          // Extract domain name from the file name (remove .json extension)
          let domainName = file.name.replace('.json', '');
          
          // Ensure domain name is not empty
          if (!domainName || domainName.trim() === '') {
            domainName = 'Unknown Domain';
          }
          
          // Parse the JSON data and extract terms
          if (fileData && typeof fileData === 'object') {
            // Check if the data is an array or has a specific structure
            let terms = [];
            
            if (Array.isArray(fileData)) {
              // If the data is already an array of terms
              terms = fileData;
            } else if (fileData.terms && Array.isArray(fileData.terms)) {
              // If the data has a 'terms' property that is an array
              terms = fileData.terms;
            } else if (fileData.data && Array.isArray(fileData.data)) {
              // If the data has a 'data' property that is an array
              terms = fileData.data;
            } else if (fileData.glossary && Array.isArray(fileData.glossary)) {
              // If the data has a 'glossary' property that is an array
              terms = fileData.glossary;
            } else if (fileData.entries && Array.isArray(fileData.entries)) {
              // If the data has an 'entries' property that is an array
              terms = fileData.entries;
            } else {
              // Try to extract terms from the structure
              const possibleTerms = Object.values(fileData).find(val => Array.isArray(val));
              if (possibleTerms) {
                terms = possibleTerms;
              }
            }
            
            // Map the terms to a consistent format
            const formattedTerms = terms
              .filter(item => item && typeof item === 'object')
              .map(item => {
                // Try to extract English and Malay terms from various property names
                const english = (
                  item.english || 
                  item.term_english || 
                  item.englishTerm || 
                  item.en || 
                  item.source || 
                  item.sourceText || 
                  ''
                ).trim();
                
                const malay = (
                  item.malay || 
                  item.term_malay || 
                  item.malayTerm || 
                  item.ms || 
                  item.target || 
                  item.targetText || 
                  ''
                ).trim();
                
                return {
                  english,
                  malay,
                  domain: domainName
                };
              })
              .filter(term => term.english && term.malay);
            
            console.log(`Processed ${formattedTerms.length} terms from ${file.name}`);
            allTermsList.push(...formattedTerms);
          }
        } catch (error) {
          console.error(`Error processing file ${file.name}:`, error);
        }
      }

      console.log(`Total terms loaded: ${allTermsList.length}`);
      setAllTerms(allTermsList);
      setRetryCount(0); // Reset retry count on success
    } catch (error) {
      console.error('Error loading glossary terms:', error);
      
      // Retry logic
      if (retry < MAX_RETRIES) {
        console.log(`Retrying... Attempt ${retry + 1} of ${MAX_RETRIES}`);
        setRetryCount(retry + 1);
        setTimeout(() => loadAllTerms(retry + 1), RETRY_DELAY);
        return;
      }
      
      setError('Failed to load glossary terms. Please try again later.');
    } finally {
      setIsLoaded(true);
    }
  }, [retryCount]);

  // Force reload of all terms
  const reloadAllTerms = async () => {
    try {
      // Call the server endpoint to force reload GTT files
      await axios.post('/utm-ittbm/api/gtt/reload');
      // Then load the terms
      await loadAllTerms();
      return true;
    } catch (error) {
      console.error('Error reloading terms:', error);
      setError('Failed to reload terms. Please try again later.');
      return false;
    }
  };

  // Find a term by exact match in either English or Malay
  const findExactTerm = (text) => {
    if (!text) return null;
    
    const normalizedText = text.toLowerCase().trim();
    return allTerms.find(
      term => 
        term.english.toLowerCase().trim() === normalizedText || 
        term.malay.toLowerCase().trim() === normalizedText
    );
  };

  // Find a term that contains the given text in either English or Malay
  const findTermContaining = (text) => {
    if (!text) return null;
    
    const normalizedText = text.toLowerCase().trim();
    return allTerms.find(
      term => 
        term.english.toLowerCase().includes(normalizedText) || 
        term.malay.toLowerCase().includes(normalizedText)
    );
  };

  return (
    <GlossaryContext.Provider
      value={{
        allTerms,
        isLoaded,
        error,
        findExactTerm,
        findTermContaining,
        loadAllTerms,
        reloadAllTerms
      }}
    >
      {children}
    </GlossaryContext.Provider>
  );
};

// Custom hook to use the glossary context
export const useGlossary = () => useContext(GlossaryContext);

export default GlossaryContext;
