/** @format */

import React, { useState } from "react";
import { Button } from "../../ui/button";
import { useTranslation } from "react-i18next";
import utmLogo from "../../../assets/img/utm.png";
import { Link } from "react-router-dom";

const PublicHeader = () => {
  const windowW = window.innerWidth;

  const [t, i18n] = useTranslation("translation", {
    keyPrefix: "publicHeader",
  });

  const [lang, setLang] = useState(i18n.language);
  const changeLanguage = (lng) => {
    sessionStorage.setItem("defaultLang", lng);
    setLang(sessionStorage.getItem("defaultLang"));
    i18n.changeLanguage(lng);
  };

  return (
    <header className="sticky top-0 z-50 w-full border-b bg-background/95 backdrop-blur">
      <div className="flex h-20 items-center justify-between w-full px-4 md:px-8 lg:px-12">
        <div className="flex items-center space-x-4">
          <img src={utmLogo} alt="UTM Logo" className="w-40 h-auto" />
          <span className="font-bold text-lg">UTM-ITBM</span>
        </div>
        <nav className="flex items-center space-x-8">
          <Link to="/" className="text-sm font-medium">
            {t("home")}
          </Link>
          <Link to="/about" className="text-sm font-medium">
            {t("about")}
          </Link>
          <Link to="/playground" className="text-sm font-medium" target="_blank" rel="noopener noreferrer">
            {t("playground")}
          </Link>
          <Button className="bg-accent text-white hover:bg-transparent hover:outline hover:outline-2 hover:outline-accent hover:text-accent">
            <Link to="/login">{t("login")}</Link>
          </Button>
          <Button
            variant="secondary"
            className="hover:bg-transparent hover:outline hover:outline-2 hover:outline-secondary hover:text-accent"
          >
            <Link to="/signup">{t("signup")}</Link>
          </Button>
          {windowW >= 1280 && (
            <>
              &nbsp; &nbsp;
              <div
                style={{
                  margin: "auto 8px",
                  color: lang === "bm" ? "#1678c2" : "#CBCBCB",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setLang("bm");
                  changeLanguage("bm");
                }}
              >
                <p>BM</p>
              </div>
              <div
                style={{
                  margin: "auto 8px",
                }}
              >
                |
              </div>
              <div
                style={{
                  margin: "auto 8px",
                  color: lang === "en" ? "#1678c2" : "#CBCBCB",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setLang("en");
                  changeLanguage("en");
                }}
              >
                <p>EN</p>
              </div>
            </>
          )}
        </nav>
      </div>
    </header>
  );
};

export default PublicHeader;
