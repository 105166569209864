/** @format */

import React, { useState, useRef, useEffect } from "react";

const GlossaryTooltip = ({ term, children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const tooltipRef = useRef(null);
  const triggerRef = useRef(null);
  const timeoutRef = useRef(null);

  // Position the tooltip based on the trigger element
  useEffect(() => {
    if (isVisible && tooltipRef.current && triggerRef.current) {
      const updatePosition = () => {
        const triggerRect = triggerRef.current.getBoundingClientRect();
        const tooltipRect = tooltipRef.current.getBoundingClientRect();
        
        // Calculate position
        let top = triggerRect.top - tooltipRect.height - 10;
        let left = triggerRect.left + (triggerRect.width / 2) - (tooltipRect.width / 2);
        
        // Adjust position if tooltip would go off screen
        if (top < 10) {
          // Show below instead of above if not enough space
          top = triggerRect.bottom + 10;
        }
        
        // Ensure tooltip stays within horizontal bounds
        left = Math.max(10, Math.min(left, window.innerWidth - tooltipRect.width - 10));
        
        // Apply position with transform for better performance
        tooltipRef.current.style.transform = `translate(${left}px, ${window.scrollY + top}px)`;
      };

      // Initial position
      updatePosition();

      // Update position on scroll and resize
      window.addEventListener('scroll', updatePosition);
      window.addEventListener('resize', updatePosition);

      return () => {
        window.removeEventListener('scroll', updatePosition);
        window.removeEventListener('resize', updatePosition);
      };
    }
  }, [isVisible]);

  const handleMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setIsVisible(true);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setIsVisible(false);
    }, 100); // Small delay to prevent flickering when moving between tooltip and trigger
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  if (!term) {
    return children;
  }

  return (
    <>
      <span
        ref={triggerRef}
        className="relative cursor-pointer border-b border-dashed border-blue-400 text-blue-600 hover:text-blue-800"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </span>
      
      {isVisible && (
        <div
          ref={tooltipRef}
          className="fixed left-0 top-0 z-50 bg-white rounded-lg shadow-lg p-4 border border-gray-200 w-80 pointer-events-auto"
          style={{ maxWidth: "90vw" }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="flex flex-col space-y-2">
            <div className="flex justify-between items-start">
              <h3 className="font-medium text-gray-900">Glossary Term</h3>
              <span className="text-xs bg-blue-100 text-blue-800 px-2 py-0.5 rounded">
                {term.domain}
              </span>
            </div>
            
            <div className="space-y-1">
              <div className="flex flex-col">
                <span className="text-xs text-gray-500">English</span>
                <span className="text-sm font-medium">{term.english}</span>
              </div>
              
              <div className="flex flex-col">
                <span className="text-xs text-gray-500">Bahasa Melayu</span>
                <span className="text-sm font-medium">{term.malay}</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default GlossaryTooltip;
