/** @format */

import React, { useState, useEffect, useRef } from 'react';
import { Card, CardContent } from '../../components/ui/card';
import { Button } from '../../components/ui/button';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../components/ui/table';
import { Input } from '../../components/ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select";
import { Checkbox } from "../../components/ui/checkbox";
import PlaygroundSimpleHeader from "../../components/core/public/PlaygroundSimpleHeader";
import PlaygroundNavigation from "../../components/core/public/PlaygroundNavigation";
import { Document, Packer, Table as DocxTable, TableRow as DocxTableRow, TableCell as DocxTableCell, Paragraph, HeadingLevel } from 'docx';
import mammoth from 'mammoth';
import { loadAIModels } from "../../utils/aiModelUtils";

const TranslationMemoryPlayground = () => {
  // Translation Memory state
  const [translationMemory, setTranslationMemory] = useState([]);
  const [sourceLanguage, setSourceLanguage] = useState("English");
  const [targetLanguage, setTargetLanguage] = useState("Bahasa Melayu");
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredMemory, setFilteredMemory] = useState([]);
  
  // File extraction and translation state
  const [extractedSegments, setExtractedSegments] = useState([]);
  const [processingRows, setProcessingRows] = useState(new Set());
  const [selectedModel, setSelectedModel] = useState(null);
  const [availableModels, setAvailableModels] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [error, setError] = useState(null);
  
  // Refs
  const fileInputRef = useRef(null);
  const tmFileInputRef = useRef(null);

  // Effect to filter translation memory based on search query
  useEffect(() => {
    if (!searchQuery) {
      setFilteredMemory(translationMemory);
      return;
    }

    const filtered = translationMemory.filter(entry =>
      entry.sourceText.toLowerCase().includes(searchQuery.toLowerCase()) ||
      entry.targetText.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredMemory(filtered);
  }, [searchQuery, translationMemory]);

  // Load AI models on component mount
  useEffect(() => {
    const fetchModels = async () => {
      try {
        const models = await loadAIModels();
        const activeModels = models.filter(model => model.isActive);
        setAvailableModels(activeModels);
        if (activeModels.length > 0 && !selectedModel) {
          setSelectedModel(activeModels[0]);
        }
      } catch (error) {
        console.error('Error fetching models:', error);
        setError('Failed to load AI models. Please try again later.');
      }
    };

    fetchModels();
  }, []);

  // Function to add a translation pair to memory
  const addTranslationPair = (sourceText, targetText) => {
    if (!sourceText.trim() || !targetText.trim()) {
      alert('Please enter both source and target text');
      return;
    }

    const newEntry = {
      id: Date.now(),
      sourceText: sourceText.trim(),
      targetText: targetText.trim(),
      sourceLanguage,
      targetLanguage,
      dateAdded: new Date().toISOString()
    };

    setTranslationMemory(prev => [...prev, newEntry]);
  };

  // Function to delete a translation memory entry
  const deleteEntry = (id) => {
    setTranslationMemory(prev => prev.filter(entry => entry.id !== id));
  };

  // Function to handle translation memory file upload
  const handleTMFileUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        const content = JSON.parse(event.target.result);
        if (Array.isArray(content)) {
          setTranslationMemory(content);
        }
      } catch (error) {
        alert('Error reading file. Please make sure it is a valid JSON file.');
      }
    };
    reader.readAsText(file);
  };

  // Function to handle Word document upload
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (event) => {
      const arrayBuffer = event.target.result;
      
      // Use mammoth to extract text from docx
      mammoth.extractRawText({ arrayBuffer })
        .then(result => {
          const text = result.value;
          
          // Split text by sentences
          const sentences = text.split(/(?<=[.!?])\s+/);
          
          // Initialize the data with empty translations and selection state
          const initializedData = sentences
            .filter(sentence => sentence.trim().length > 0) // Filter out empty sentences
            .map(sentence => ({
              sourceText: sentence.trim(),
              translatedText: '',
              status: 'pending',
              selected: false // For selecting which pairs to add to TM
            }));

          // Set the data
          setExtractedSegments(initializedData);
          setProcessingRows(new Set());
          setEditingIndex(null);
        })
        .catch(error => {
          console.error('Error extracting text from docx:', error);
          alert('Error extracting text from the document. Please make sure it is a valid .docx file.');
        });
    };

    reader.readAsArrayBuffer(file);
  };

  // Translation functions for different models
  const translateWithOpenAI = async (text, apiKey) => {
    try {
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`,
        },
        body: JSON.stringify({
          model: 'gpt-3.5-turbo',
          messages: [
            {
              role: 'system',
              content: `You are a translation assistant. Translate the following text from ${sourceLanguage} to ${targetLanguage}. Provide only the translation, no additional explanations.`
            },
            {
              role: 'user',
              content: text
            }
          ],
          temperature: 0.3
        })
      });

      if (!response.ok) {
        throw new Error(`OpenAI API error: ${response.status}`);
      }

      const data = await response.json();
      return data.choices[0].message.content.trim();
    } catch (error) {
      throw new Error(`OpenAI translation failed: ${error.message}`);
    }
  };

  const translateWithGroq = async (text, apiKey) => {
    try {
      const response = await fetch('https://api.groq.com/openai/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`,
        },
        body: JSON.stringify({
          model: 'mixtral-8x7b-32768',
          messages: [
            {
              role: 'system',
              content: `You are a translation assistant. Translate the following text from ${sourceLanguage} to ${targetLanguage}. Provide only the translation, no additional explanations.`
            },
            {
              role: 'user',
              content: text
            }
          ],
          temperature: 0.3
        })
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(`Groq API error: ${response.status} - ${errorData.error?.message || 'Unknown error'}`);
      }

      const data = await response.json();
      return data.choices[0].message.content.trim();
    } catch (error) {
      throw new Error(`Groq translation failed: ${error.message}`);
    }
  };

  const translateWithOllama = async (text, endpoint = 'http://localhost:11434') => {
    try {
      const response = await fetch(`${endpoint}/api/generate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          model: selectedModel.modelId,
          prompt: `You are a translation assistant. Your task is to translate the following text from ${sourceLanguage} to ${targetLanguage}. Only output the translation, nothing else.\n\nText to translate:\n${text}\n\nTranslation:`,
          stream: false,
          options: {
            temperature: 0.1
          }
        })
      });

      if (!response.ok) {
        throw new Error(`Ollama API error: ${response.status}`);
      }

      const data = await response.json();

      let cleanedResponse = data.response
        .replace(/<think>[\s\S]*?<\/think>/g, '')
        .replace(/Translation:/i, '')
        .trim();

      if (!cleanedResponse) {
        cleanedResponse = data.response.trim();
      }

      return cleanedResponse;
    } catch (error) {
      console.error('Ollama error:', error);
      throw new Error(`Ollama translation failed: ${error.message}`);
    }
  };

  const translateWithHuggingFace = async (text, apiKey, modelId, endpoint) => {
    try {
      const apiUrl = endpoint || `https://api-inference.huggingface.co/models/${modelId}`;
      
      // Log request details for debugging
      console.log('HF Request:', {
        url: apiUrl,
        modelId,
        hasApiKey: !!apiKey,
        text: text.substring(0, 100) // Log first 100 chars only
      });

      // Create system prompt for better translation quality
      const systemPrompt = `You are a professional translator specializing in English and Bahasa Melayu translations. 
Current task: Translate FROM ${sourceLanguage} TO ${targetLanguage}.
Rules:
1. If source is Bahasa Melayu and target is English: Translate to proper English
2. If source is English and target is Bahasa Melayu: Translate to proper Bahasa Melayu
3. Maintain the original meaning and context
4. Only provide the direct translation, no explanations
5. Ensure the output is ONLY in the target language
6. Make sure always use formal and high standard translation by following legal and official translation guidelines

Text to translate:
`;

      // Combine system prompt with text for models that support it
      const promptedText = `${systemPrompt}${text}\n\nTranslation:`;

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`
        },
        body: JSON.stringify({
          inputs: promptedText,
          options: {
            wait_for_model: true
          }
        })
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        console.error('HF Error:', errorData);
        throw new Error(`Hugging Face API error: ${response.status} - ${errorData.error || 'Unknown error'}`);
      }

      const data = await response.json();
      console.log('HF Response:', data); // Debug log
      
      // Handle different response formats
      if (Array.isArray(data)) {
        // Translation model response
        if (data[0]?.translation_text) {
          return data[0].translation_text;
        }
        // Text generation response
        if (data[0]?.generated_text) {
          // Try to extract just the translation part after the prompt
          const generatedText = data[0].generated_text;
          const translationPart = generatedText.split('Translation:')[1];
          if (translationPart) {
            return translationPart.trim();
          }
          return generatedText;
        }
        // Raw text array response
        if (typeof data[0] === 'string') {
          return data[0];
        }
        // Try to extract text from complex array response
        if (data[0]?.text) {
          return data[0].text;
        }
        return JSON.stringify(data[0]);
      }
      
      // Single object response
      if (data.translation_text) {
        return data.translation_text;
      }
      if (data.generated_text) {
        // Try to extract just the translation part after the prompt
        const generatedText = data.generated_text;
        const translationPart = generatedText.split('Translation:')[1];
        if (translationPart) {
          return translationPart.trim();
        }
        return generatedText;
      }
      if (data.text) {
        return data.text;
      }
      
      // If we get here, try to convert the response to a string
      if (typeof data === 'string') {
        return data;
      }
      
      return JSON.stringify(data);
    } catch (error) {
      console.error('HF Translation error:', error);
      throw new Error(`Hugging Face translation failed: ${error.message}`);
    }
  };

  // Function to translate a single segment
  const translateSegment = async (text, index) => {
    if (!selectedModel) {
      console.error('No model selected');
      return;
    }

    setProcessingRows(prev => new Set(prev).add(index));

    try {
      let translatedText = '';

      switch (selectedModel.type) {
        case 'ollama':
          translatedText = await translateWithOllama(text);
          break;
        case 'openai':
          translatedText = await translateWithOpenAI(text, selectedModel.apiKey);
          break;
        case 'groq':
          translatedText = await translateWithGroq(text, selectedModel.apiKey);
          break;
        case 'huggingface':
          translatedText = await translateWithHuggingFace(
            text,
            selectedModel.apiKey,
            selectedModel.modelId,
            selectedModel.endpoint
          );
          break;
        default:
          throw new Error('Unsupported model type');
      }

      setExtractedSegments(prevData => {
        const newData = [...prevData];
        newData[index] = {
          ...newData[index],
          translatedText,
          status: 'completed'
        };
        return newData;
      });
    } catch (error) {
      console.error('Translation error:', error);
      setExtractedSegments(prevData => {
        const newData = [...prevData];
        newData[index] = {
          ...newData[index],
          status: 'error'
        };
        return newData;
      });
    } finally {
      setProcessingRows(prev => {
        const newSet = new Set(prev);
        newSet.delete(index);
        return newSet;
      });
    }
  };

  // Function to translate all segments
  const translateAllSegments = async () => {
    if (!selectedModel) {
      console.error('No model selected');
      return;
    }

    // Translate each row sequentially
    for (let i = 0; i < extractedSegments.length; i++) {
      if (extractedSegments[i].status !== 'completed') {
        await translateSegment(extractedSegments[i].sourceText, i);
      }
    }
  };

  // Function to toggle segment selection
  const toggleSegmentSelection = (index) => {
    setExtractedSegments(prevData => {
      const newData = [...prevData];
      newData[index] = {
        ...newData[index],
        selected: !newData[index].selected
      };
      return newData;
    });
  };

  // Function to add selected segments to translation memory
  const addSelectedToTM = () => {
    const selectedSegments = extractedSegments.filter(segment => 
      segment.selected && segment.status === 'completed' && segment.translatedText.trim()
    );
    
    if (selectedSegments.length === 0) {
      alert('Please select completed translations to add to memory');
      return;
    }

    const newEntries = selectedSegments.map(segment => ({
      id: Date.now() + Math.random(),
      sourceText: segment.sourceText,
      targetText: segment.translatedText,
      sourceLanguage,
      targetLanguage,
      dateAdded: new Date().toISOString()
    }));

    setTranslationMemory(prev => [...prev, ...newEntries]);
    alert(`Added ${newEntries.length} entries to translation memory`);
  };

  // Function to download translation memory as JSON
  const downloadMemory = () => {
    const dataStr = JSON.stringify(translationMemory, null, 2);
    const blob = new Blob([dataStr], { type: 'application/json' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'translation_memory.json';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  // Function to download translation memory as DOCX
  const downloadDocx = async () => {
    const doc = new Document({
      sections: [{
        children: [
          new Paragraph({
            text: "Translation Memory",
            heading: HeadingLevel.HEADING_1
          }),
          new DocxTable({
            rows: [
              new DocxTableRow({
                children: [
                  new DocxTableCell({
                    children: [new Paragraph({ text: "Source Text" })],
                    width: { size: 50, type: "percentage" }
                  }),
                  new DocxTableCell({
                    children: [new Paragraph({ text: "Target Text" })],
                    width: { size: 50, type: "percentage" }
                  })
                ]
              }),
              ...translationMemory.map(entry => 
                new DocxTableRow({
                  children: [
                    new DocxTableCell({
                      children: [new Paragraph({ text: entry.sourceText })],
                      width: { size: 50, type: "percentage" }
                    }),
                    new DocxTableCell({
                      children: [new Paragraph({ text: entry.targetText })],
                      width: { size: 50, type: "percentage" }
                    })
                  ]
                })
              )
            ]
          })
        ]
      }]
    });

    const blob = await Packer.toBlob(doc);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'translation_memory.docx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  // Function to clear translation memory
  const clearMemory = () => {
    if (window.confirm('Are you sure you want to clear all translation memory entries?')) {
      setTranslationMemory([]);
      setSearchQuery('');
    }
  };

  // Function to start a new extraction session
  const startNewSession = () => {
    setExtractedSegments([]);
    setProcessingRows(new Set());
    setEditingIndex(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <div className="min-h-screen bg-white">
      <PlaygroundSimpleHeader keyPrefix="playgroundPage" />
      <PlaygroundNavigation />
      
      <div className="container mx-auto px-4 py-8">
        {/* File Extraction & Translation Card */}
        <Card className="mb-6">
          <CardContent className="p-6">
            <div className="flex flex-col gap-4">
              <div className="space-y-4 py-4">
                <h2 className="text-2xl font-bold">File Extraction & Translation</h2>
                <p className="text-gray-500">
                  Upload a Word document (.docx) to extract text by sentences, translate them, and add selected pairs to your translation memory.
                </p>
                <div className="grid grid-cols-1 md:grid-cols-4 gap-6 items-end">
                  <div className="flex flex-col space-y-2">
                    <label htmlFor="model-select" className="text-sm font-medium">Translation Engine Model</label>
                    <Select
                      value={selectedModel?.id || ''}
                      onValueChange={(value) => {
                        const model = availableModels.find(m => m.id === value);
                        setSelectedModel(model);
                      }}
                    >
                      <SelectTrigger id="model-select" className="w-full bg-white border-gray-300">
                        <SelectValue placeholder="Select Model" />
                      </SelectTrigger>
                      <SelectContent className="bg-white">
                        {availableModels.map((model) => (
                          <SelectItem 
                            key={model.id} 
                            value={model.id}
                            className="hover:bg-gray-200 focus:bg-gray-200 cursor-pointer py-2"
                          >
                            {model.name} ({model.type})
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                  <div className="flex flex-col space-y-2">
                    <label htmlFor="file-upload" className="text-sm font-medium">Upload Word Document</label>
                    <Input
                      id="file-upload"
                      type="file"
                      accept=".docx"
                      onChange={handleFileUpload}
                      ref={fileInputRef}
                      className="w-full"
                    />
                  </div>
                  <div className="flex items-end">
                    <Button
                      onClick={translateAllSegments}
                      disabled={extractedSegments.length === 0 || !selectedModel}
                      variant="outline"
                      className="w-full"
                    >
                      Translate All
                    </Button>
                  </div>
                  <div className="flex items-end gap-2">
                    <Button
                      onClick={startNewSession}
                      disabled={extractedSegments.length === 0}
                      variant="destructive"
                      className="w-full"
                    >
                      New Session
                    </Button>
                    <Button
                      onClick={addSelectedToTM}
                      disabled={extractedSegments.filter(s => s.selected).length === 0}
                      variant="default"
                      className="w-full"
                    >
                      Add Selected to TM
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>

        {/* Extracted Segments Table */}
        {extractedSegments.length > 0 && (
          <div className="overflow-x-auto mt-6 mb-10">
            <h3 className="text-xl font-bold mb-4">Extracted Segments</h3>
            <Table className="w-full border-collapse table-fixed bg-white">
              <TableHeader>
                <TableRow>
                  <TableHead className="w-[5%] text-center">Select</TableHead>
                  <TableHead className="w-[42.5%]">Source Text</TableHead>
                  <TableHead className="w-[10%] text-center">Actions</TableHead>
                  <TableHead className="w-[42.5%]">Translated Text</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {extractedSegments.map((segment, index) => (
                  <TableRow key={index} className="border-b hover:bg-white-50">
                    <TableCell className="align-middle text-center">
                      <Checkbox 
                        checked={segment.selected}
                        onCheckedChange={() => toggleSegmentSelection(index)}
                        disabled={segment.status !== 'completed'}
                      />
                    </TableCell>
                    <TableCell className="align-top py-4 px-4">
                      <div className="max-h-[200px] overflow-y-auto whitespace-pre-wrap break-words p-2 bg-white-50 rounded">
                        {segment.sourceText}
                      </div>
                    </TableCell>
                    <TableCell className="align-middle text-center py-4 px-2">
                      <Button
                        onClick={() => translateSegment(segment.sourceText, index)}
                        disabled={processingRows.has(index) || !selectedModel}
                        size="sm"
                        className="w-full"
                      >
                        {processingRows.has(index) ? 'Processing...' : 'Translate'}
                      </Button>
                    </TableCell>
                    <TableCell className="align-top py-4 px-4">
                      {editingIndex === index ? (
                        <textarea
                          value={segment.translatedText}
                          onChange={(e) => {
                            const newData = [...extractedSegments];
                            newData[index].translatedText = e.target.value;
                            setExtractedSegments(newData);
                          }}
                          onBlur={() => setEditingIndex(null)}
                          autoFocus
                          className="w-full min-h-[200px] p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                      ) : (
                        <div
                          className="cursor-pointer hover:bg-gray-100 p-3 rounded max-h-[200px] overflow-y-auto whitespace-pre-wrap break-words bg-white-50"
                          onClick={() => setEditingIndex(index)}
                        >
                          {segment.translatedText || (segment.status === 'error' ? 'Error translating' : '')}
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )}

        {/* Translation Memory Management Card */}
        <Card className="mb-6">
          <CardContent className="p-6">
            <div className="flex flex-col gap-4">
              <div className="space-y-4 py-4">
                <h2 className="text-2xl font-bold">Translation Memory</h2>
                <p className="text-gray-500">
                  Manage your translation memory database. Search through existing entries and export your memory.
                </p>

                {/* Language Selection */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                  <div>
                    <label className="block text-sm font-medium mb-2">Source Language</label>
                    <Select value={sourceLanguage} onValueChange={setSourceLanguage}>
                      <SelectTrigger className="w-full">
                        <SelectValue placeholder="Select source language" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="English">English</SelectItem>
                        <SelectItem value="Bahasa Melayu">Bahasa Melayu</SelectItem>
                        {/* Add more languages as needed */}
                      </SelectContent>
                    </Select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-2">Target Language</label>
                    <Select value={targetLanguage} onValueChange={setTargetLanguage}>
                      <SelectTrigger className="w-full">
                        <SelectValue placeholder="Select target language" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="Bahasa Melayu">Bahasa Melayu</SelectItem>
                        <SelectItem value="English">English</SelectItem>
                        {/* Add more languages as needed */}
                      </SelectContent>
                    </Select>
                  </div>
                </div>

                {/* Action Buttons */}
                <div className="flex flex-wrap gap-4">
                  <Input
                    type="file"
                    accept=".json"
                    onChange={handleTMFileUpload}
                    ref={tmFileInputRef}
                    className="flex-1"
                  />
                  <Button onClick={downloadMemory} variant="outline" className="flex-1">
                    Export JSON
                  </Button>
                  <Button onClick={downloadDocx} variant="outline" className="flex-1">
                    Export DOCX
                  </Button>
                  <Button onClick={clearMemory} variant="destructive" className="flex-1">
                    Clear Memory
                  </Button>
                </div>

                {/* Search Bar */}
                <div className="mt-6">
                  <Input
                    type="text"
                    placeholder="Search translation memory..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="w-full"
                  />
                </div>
              </div>
            </div>
          </CardContent>
        </Card>

        {/* Translation Memory Table */}
        <div className="overflow-x-auto mt-6">
          <h3 className="text-xl font-bold mb-4">Translation Memory Entries ({filteredMemory.length})</h3>
          <Table className="w-full border-collapse bg-white">
            <TableHeader>
              <TableRow>
                <TableHead className="w-[40%]">Source Text</TableHead>
                <TableHead className="w-[40%]">Target Text</TableHead>
                <TableHead className="w-[20%] text-center">Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {filteredMemory.map((entry) => (
                <TableRow key={entry.id} className="border-b hover:bg-gray-50">
                  <TableCell className="align-top py-4 px-4">
                    <div className="max-h-[200px] overflow-y-auto whitespace-pre-wrap break-words">
                      {entry.sourceText}
                    </div>
                  </TableCell>
                  <TableCell className="align-top py-4 px-4">
                    <div className="max-h-[200px] overflow-y-auto whitespace-pre-wrap break-words">
                      {entry.targetText}
                    </div>
                  </TableCell>
                  <TableCell className="align-middle text-center py-4 px-2">
                    <Button
                      onClick={() => deleteEntry(entry.id)}
                      variant="destructive"
                      size="sm"
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default TranslationMemoryPlayground; 