/** @format */

import React from 'react';
import Playground from "../pages/Playground/Playground";
import TextTranslationPlayground from "../pages/Playground/TextTranslationPlayground";
import GTTPlayground from "../pages/Playground/GTTPlayground";
import TextAnalysisPlayground from "../pages/Playground/TextAnalysisPlayground";
import BatchTranslationPlayground from "../pages/Playground/BatchTranslationPlayground";
import FileExtractionTranslationPlayground from "../pages/Playground/FileExtractionTranslationPlayground";
import ManualTranslationConcordancePlayground from "../pages/Playground/ManualTranslationConcordancePlayground";
import TermBase from "../pages/Playground/TermBase";
import TranslationMemoryPlayground from "../pages/Playground/TranslationMemoryPlayground";

// All routes are now standalone without using PlaygroundLayout
const playgroundRoutes = [
  {
    path: "/playground",
    element: <Playground />,
  },
  {
    path: "/playground/text-translation",
    element: <TextTranslationPlayground />,
  },
  {
    path: "/playground/gtt",
    element: <GTTPlayground />,
  },
  {
    path: "/playground/text-analysis",
    element: <TextAnalysisPlayground />,
  },
  {
    path: "/playground/batch-translation",
    element: <BatchTranslationPlayground />,
  },
  {
    path: "/playground/file-extraction-translation",
    element: <FileExtractionTranslationPlayground />,
  },
  {
    path: "/playground/manual-translation-concordance",
    element: <ManualTranslationConcordancePlayground />,
  },
  {
    path: "/playground/term-base",
    element: <TermBase />,
  },
  {
    path: "/playground/translation-memory",
    element: <TranslationMemoryPlayground />,
  },
];

export default playgroundRoutes;
