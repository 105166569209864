/** @format */

import React from "react";

function NotFound() {
  return (
    <div
      className="flex flex-col bg-gray-50"
      style={{ height: "calc(100vh - 4.05rem)" }}
    >
      <div className="container mx-auto my-20 px-4 h-full flex">
        <div>Not Found</div>
      </div>
    </div>
  );
}

export default NotFound;
