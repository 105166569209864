/** @format */

import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../../components/ui/breadcrumb";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import { Input } from "../../components/ui/input";
import { Search, RefreshCw, AlertCircle, ArrowUpDown, ArrowUp, ArrowDown } from "lucide-react";
import { Button } from "../../components/ui/button";
import { toast } from "../../components/ui/use-toast";
import { useGlossary } from "../../contexts/GlossaryContext";
import { Alert, AlertDescription, AlertTitle } from "../../components/ui/alert";

const GlossaryTechnicalTerm = () => {
  const { allTerms, isLoaded, error, loadAllTerms, reloadAllTerms } = useGlossary();
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [terms, setTerms] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredTerms, setFilteredTerms] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'asc'
  });
  const [selectedLetter, setSelectedLetter] = useState(null);

  useEffect(() => {
    // When allTerms are loaded from the GlossaryContext
    if (isLoaded) {
      // Extract unique domain names from allTerms and filter out empty ones
      const uniqueDomains = [...new Set(allTerms.map(term => term.domain))]
        .filter(domain => domain && domain.trim() !== '');
      
      if (uniqueDomains.length > 0) {
        // If no domain is selected or the selected domain is no longer available, select the first one
        if (!selectedDomain || !uniqueDomains.includes(selectedDomain)) {
          setSelectedDomain(uniqueDomains[0]);
        }
      } else {
        // If no domains are available, reset the selected domain
        setSelectedDomain(null);
        setTerms([]);
        setFilteredTerms([]);
        
        // Only show the toast if there was an error loading terms
        if (error) {
          toast({
            variant: "destructive",
            title: "Error loading terms",
            description: error,
          });
        }
      }
    }
  }, [isLoaded, allTerms, selectedDomain, error]);

  useEffect(() => {
    if (selectedDomain && isLoaded) {
      loadTermsForDomain(selectedDomain);
    }
  }, [selectedDomain, isLoaded, allTerms]);

  useEffect(() => {
    // Filter terms based on search query and selected letter
    let filtered = terms;
    
    // Apply search filter if query exists
    if (searchQuery.trim()) {
      filtered = terms.filter(term => 
        term.english.toLowerCase().includes(searchQuery.toLowerCase()) ||
        term.malay.toLowerCase().includes(searchQuery.toLowerCase())
      );
      // Clear letter selection when searching
      if (selectedLetter) setSelectedLetter(null);
    }
    // Apply letter filter if a letter is selected and no search query
    else if (selectedLetter && !searchQuery.trim()) {
      filtered = terms.filter(term => 
        term.english.toLowerCase().startsWith(selectedLetter.toLowerCase())
      );
    }
    
    setFilteredTerms(filtered);
  }, [searchQuery, terms, selectedLetter]);

  // Initial load of terms
  useEffect(() => {
    if (!isLoaded && !error) {
      loadAllTerms();
    }
  }, [loadAllTerms]);

  // Apply sorting when sortConfig or filteredTerms change
  useEffect(() => {
    let sortedTerms = [...filteredTerms];
    if (sortConfig.key) {
      sortedTerms.sort((a, b) => {
        const aValue = a[sortConfig.key].toLowerCase();
        const bValue = b[sortConfig.key].toLowerCase();
        
        if (aValue < bValue) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
      setFilteredTerms(sortedTerms);
    }
  }, [sortConfig]);

  const loadTermsForDomain = (domainName) => {
    setIsLoading(true);
    
    try {
      if (!domainName) {
        setTerms([]);
        setFilteredTerms([]);
        setIsLoading(false);
        return;
      }
      
      // Filter terms from the global allTerms that match the selected domain
      const domainTerms = allTerms.filter(term => term.domain === domainName);
      
      if (domainTerms.length === 0) {
        toast({
          variant: "warning",
          title: "No terms found",
          description: `No terms found for domain: ${domainName}`,
        });
      } else {
        toast({
          title: "Terms loaded",
          description: `Loaded ${domainTerms.length} terms from ${domainName}`,
        });
      }
      
      setTerms(domainTerms);
      setFilteredTerms(domainTerms);
      // Reset sort when loading new terms
      setSortConfig({ key: null, direction: 'asc' });
      // Reset letter filter
      setSelectedLetter(null);
    } catch (error) {
      console.error('Error loading terms:', error);
      toast({
        variant: "destructive",
        title: "Error loading terms",
        description: error.message || "Failed to load terms for the selected domain",
      });
      setTerms([]);
      setFilteredTerms([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRefresh = async () => {
    setRefreshing(true);
    try {
      // Use the reloadAllTerms function which forces a server-side reload
      const success = await reloadAllTerms();
      
      if (success) {
        // Get the updated allTerms after loading
        const uniqueDomains = [...new Set(allTerms.map(term => term.domain))]
          .filter(domain => domain && domain.trim() !== '');
        
        if (uniqueDomains.length > 0) {
          toast({
            title: "Glossary refreshed",
            description: `Successfully loaded ${uniqueDomains.length} domains with ${allTerms.length} terms`,
          });
        } else {
          toast({
            variant: "warning",
            title: "No domains found",
            description: "No domains were found in the JSON files. Please check the GTTFiles folder.",
          });
        }
      } else {
        toast({
          variant: "destructive",
          title: "Refresh failed",
          description: error || "Failed to refresh glossary terms",
        });
      }
    } catch (err) {
      console.error('Error refreshing glossary:', err);
      toast({
        variant: "destructive",
        title: "Refresh failed",
        description: err.message || "Failed to refresh glossary terms. Check the console for details.",
      });
    } finally {
      setRefreshing(false);
    }
  };

  const highlightSearchText = (text, searchQuery) => {
    if (!searchQuery.trim()) return text;
    
    const regex = new RegExp(`(${searchQuery})`, 'gi');
    const parts = text.split(regex);
    
    return parts.map((part, index) => {
      if (part.toLowerCase() === searchQuery.toLowerCase()) {
        return <span key={index} className="text-red-500 font-medium">{part}</span>;
      }
      return part;
    });
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
    
    // Create a new sorted array
    const sortedTerms = [...filteredTerms].sort((a, b) => {
      const aValue = a[key].toLowerCase();
      const bValue = b[key].toLowerCase();
      
      if (aValue < bValue) {
        return direction === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
    
    setFilteredTerms(sortedTerms);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key !== key) {
      return <ArrowUpDown className="ml-1 h-4 w-4 inline" />;
    }
    return sortConfig.direction === 'asc' 
      ? <ArrowUp className="ml-1 h-4 w-4 inline text-blue-500" />
      : <ArrowDown className="ml-1 h-4 w-4 inline text-blue-500" />;
  };

  const getAlphabetLetters = () => {
    // Generate A-Z array
    return Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i));
  };

  const getAvailableLetters = () => {
    // Get unique first letters from all terms
    const uniqueLetters = new Set();
    terms.forEach(term => {
      if (term.english && term.english.length > 0) {
        uniqueLetters.add(term.english.charAt(0).toUpperCase());
      }
    });
    return Array.from(uniqueLetters).sort();
  };

  const handleLetterClick = (letter) => {
    setSearchQuery(""); // Clear search when selecting a letter
    setSelectedLetter(letter === selectedLetter ? null : letter);
  };

  return (
    <>
      <Breadcrumb className="pl-6 pt-6">
        <span className="text-xl font-semibold">Technical Terms</span>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink href="/dashboard">Term Base</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator>/</BreadcrumbSeparator>
          <BreadcrumbItem>
            <BreadcrumbPage>Technical Terms</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <div className="p-6">
        <Card>
          <CardHeader>
            <div className="flex justify-between items-start">
              <div>
                <CardTitle>Technical Terms Database</CardTitle>
                <CardDescription>
                  View and search technical terms by domain
                </CardDescription>
              </div>
              <div className="flex gap-4">
                <Button 
                  variant="outline" 
                  size="sm" 
                  onClick={handleRefresh}
                  disabled={refreshing}
                  className="flex items-center gap-1"
                >
                  <RefreshCw className={`h-4 w-4 ${refreshing ? 'animate-spin' : ''}`} />
                  {refreshing ? 'Refreshing...' : 'Refresh from JSON Files'}
                </Button>
                <div className="w-[200px]">
                  <Select
                    value={selectedDomain}
                    onValueChange={setSelectedDomain}
                  >
                    <SelectTrigger className="bg-white border-gray-300 hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500">
                      <SelectValue placeholder="Select Domain" />
                    </SelectTrigger>
                    <SelectContent className="bg-white border border-gray-200 shadow-lg">
                      {isLoaded ? (
                        [...new Set(allTerms.map(term => term.domain))]
                          .filter(domainName => domainName && domainName.trim() !== '')
                          .map((domainName) => (
                            <SelectItem 
                              key={domainName} 
                              value={domainName}
                              className="text-gray-700 font-medium hover:bg-indigo-50 cursor-pointer"
                            >
                              {domainName}
                            </SelectItem>
                          ))
                      ) : (
                        <SelectItem value="loading" disabled>Loading domains...</SelectItem>
                      )}
                      {isLoaded && [...new Set(allTerms.map(term => term.domain))].filter(domainName => domainName && domainName.trim() !== '').length === 0 && (
                        <SelectItem value="no-domains" disabled>No domains available</SelectItem>
                      )}
                    </SelectContent>
                  </Select>
                </div>
                <div className="relative w-[300px]">
                  <Search className="absolute left-2 top-2.5 h-4 w-4 text-gray-500" />
                  <Input
                    type="text"
                    placeholder="Search terms..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="pl-8 bg-white border-gray-300"
                  />
                </div>
              </div>
            </div>
          </CardHeader>
          <CardContent>
            {error && (
              <Alert variant="destructive" className="mb-4">
                <AlertCircle className="h-4 w-4" />
                <AlertTitle>Error</AlertTitle>
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}
            
            {!isLoaded && !error && (
              <div className="text-center py-8">
                <RefreshCw className="h-8 w-8 animate-spin mx-auto mb-4 text-gray-400" />
                <p className="text-gray-500">Loading glossary terms...</p>
              </div>
            )}
            
            {isLoaded && !error && (isLoading ? (
              <div className="text-center py-4">Loading terms...</div>
            ) : (
              <>
                {/* Alphabetical Navigation */}
                {terms.length > 0 && !searchQuery && (
                  <div className="mb-6">
                    <div className="flex flex-wrap gap-2 justify-center">
                      {getAlphabetLetters().map(letter => {
                        const isAvailable = getAvailableLetters().includes(letter);
                        return (
                          <button
                            key={letter}
                            onClick={() => isAvailable && handleLetterClick(letter)}
                            className={`w-8 h-8 flex items-center justify-center rounded-full text-sm font-medium transition-colors
                              ${selectedLetter === letter 
                                ? 'bg-indigo-600 text-white' 
                                : isAvailable 
                                  ? 'bg-gray-100 text-gray-800 hover:bg-gray-200' 
                                  : 'bg-gray-50 text-gray-300 cursor-default'
                              }`}
                            disabled={!isAvailable}
                          >
                            {letter}
                          </button>
                        );
                      })}
                      {selectedLetter && (
                        <button
                          onClick={() => setSelectedLetter(null)}
                          className="ml-2 px-3 py-1 text-xs bg-gray-200 hover:bg-gray-300 rounded-full"
                        >
                          Clear
                        </button>
                      )}
                    </div>
                  </div>
                )}

                {/* Letter filter info */}
                {selectedLetter && !searchQuery && (
                  <div className="mb-4 px-2">
                    <h3 className="text-lg font-medium">
                      Terms starting with "{selectedLetter}" ({filteredTerms.length})
                    </h3>
                  </div>
                )}

                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead className="w-[50px]">No.</TableHead>
                      <TableHead 
                        className="cursor-pointer hover:bg-gray-50"
                        onClick={() => handleSort('english')}
                      >
                        English Term {getSortIcon('english')}
                      </TableHead>
                      <TableHead 
                        className="cursor-pointer hover:bg-gray-50"
                        onClick={() => handleSort('malay')}
                      >
                        Malay Term {getSortIcon('malay')}
                      </TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {filteredTerms.length > 0 ? (
                      filteredTerms.map((term, index) => (
                        <TableRow key={index}>
                          <TableCell className="font-medium">{index + 1}</TableCell>
                          <TableCell>
                            {highlightSearchText(term.english, searchQuery)}
                          </TableCell>
                          <TableCell>
                            {highlightSearchText(term.malay, searchQuery)}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3} className="text-center py-4">
                          {selectedDomain
                            ? selectedLetter 
                              ? `No terms starting with "${selectedLetter}" found in this domain`
                              : "No terms found for the selected domain"
                            : "Please select a domain"}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </>
            ))}
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default GlossaryTechnicalTerm;