/** @format */

import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../../components/ui/breadcrumb";
import { Button } from "../../components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import { Link } from 'react-router-dom';
import { toast } from "../../components/ui/use-toast";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../components/ui/alert-dialog";
import { Plus, Upload, X, FileJson, Loader2, RefreshCw } from 'lucide-react';
import { useGlossary } from "../../contexts/GlossaryContext";

const GlossaryDomain = () => {
  const { reloadAllTerms } = useGlossary();
  const [domains, setDomains] = useState([]);
  const [newDomain, setNewDomain] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  // Function to load GTT files from server
  const loadGTTFiles = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(null);
      
      const response = await axios.get('/utm-ittbm/api/gtt/files');
      console.log('GTT files response:', response.data);
      
      if (Array.isArray(response.data)) {
        // Filter out non-JSON files
        const jsonFiles = response.data.filter(file => file.name.endsWith('.json'));
        
        // Map files to domain objects
        const domainObjects = jsonFiles.map(file => {
          // Extract domain name from filename (remove .json extension)
          const domainName = file.name.replace('.json', '');
          
          return {
            id: file.name, // Use filename as ID
            name: domainName,
            fileName: file.name,
            filePath: file.path,
            uploadDate: new Date().toISOString() // We don't have the actual upload date
          };
        });
        
        setDomains(domainObjects);
      } else {
        throw new Error('Invalid response format for GTT files');
      }
    } catch (error) {
      console.error('Error loading GTT files:', error);
      setError('Failed to load GTT files. Please try again.');
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to load GTT files",
      });
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Load GTT files when component mounts
  useEffect(() => {
    loadGTTFiles();
  }, [loadGTTFiles]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    handleFile(file);
  };

  const handleFile = (file) => {
    if (file) {
      if (file.type === 'application/json') {
        setSelectedFile(file);
        toast({
          title: "File selected",
          description: `${file.name} has been selected`,
        });
      } else {
        toast({
          variant: "destructive",
          title: "Invalid file type",
          description: "Please upload a JSON file",
        });
      }
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    handleFile(file);
  };

  // Function to upload file to server
  const uploadFileToServer = async (file, domainName) => {
    try {
      // Use the provided domainName parameter
      if (!domainName || domainName.trim() === '') {
        throw new Error('Domain name is required');
      }
      
      console.log('Using domain name for upload:', domainName); // Debug log
      
      // Create a new FormData object
      const formData = new FormData();
      
      // IMPORTANT: Add the domain name as a separate field
      formData.append('domainName', domainName);
      
      // Add a specific filename field to ensure server uses this name
      formData.append('targetFilename', `${domainName}.json`);
      
      // Add the original file - server should use the targetFilename field
      formData.append('file', file);
      
      // Log all form data entries for debugging
      for (let [key, value] of formData.entries()) {
        console.log(`Form data entry - ${key}:`, value instanceof File ? value.name : value);
      }
      
      // Make the request with both the form data and custom headers
      const response = await axios.post(
        `/utm-ittbm/api/upload/glossary`, 
        formData, 
        {
          params: {
            domainName: domainName,
            useNameAsFilename: true,
            targetFilename: `${domainName}.json`
          },
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-Domain-Name': domainName,
            'X-Target-Filename': `${domainName}.json`
          }
        }
      );
      
      if (!response.data.success) {
        throw new Error(response.data.error || 'Upload failed');
      }
      
      // Explicitly set the filename in the response to ensure consistency
      return {
        ...response.data,
        filename: `${domainName}.json`
      };
    } catch (error) {
      console.error('Error uploading file:', error);
      throw new Error(error.response?.data?.error || error.message || 'Failed to upload file to server');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!newDomain.trim()) {
      toast({
        variant: "destructive",
        title: "Domain name required",
        description: "Please enter a domain name",
      });
      return;
    }

    if (!selectedFile) {
      toast({
        variant: "destructive",
        title: "File required",
        description: "Please upload a JSON file",
      });
      return;
    }

    try {
      setIsUploading(true);
      
      // Use the newDomain state directly to ensure consistency
      const domainNameToUse = newDomain.trim();
      
      // Log the domain name before uploading
      console.log('Submitting with domain name:', domainNameToUse);
      
      // First upload the file to the server with the domain name
      const uploadResult = await uploadFileToServer(selectedFile, domainNameToUse);
      
      console.log('Upload result:', uploadResult);
      
      // Reset form and dialog state
      setNewDomain('');
      setSelectedFile(null);
      setIsDialogOpen(false);
      setIsUploading(false);

      // Trigger a reload of the GTT files
      try {
        await reloadAllTerms();
        console.log('GTT files reloaded after adding new domain');
        
        // Reload the domains list to show the new domain
        await loadGTTFiles();
      } catch (reloadError) {
        console.error('Error reloading GTT files:', reloadError);
      }

      toast({
        title: "Domain added",
        description: `${domainNameToUse} has been added successfully and saved as ${domainNameToUse}.json in the GTT folder`,
      });
    } catch (error) {
      setIsUploading(false);
      toast({
        variant: "destructive",
        title: "Error adding domain",
        description: error.message,
      });
    }
  };

  const deleteDomain = async (fileName) => {
    try {
      setIsDeleting(true);
      setIsDeleteDialogOpen(false);
      
      // Delete the file from the server
      await axios.delete(`/utm-ittbm/api/gtt/file/${encodeURIComponent(fileName)}`);
      console.log(`File ${fileName} deleted from server`);

      // Trigger a reload of the GTT files
      try {
        await reloadAllTerms();
        console.log('GTT files reloaded after deleting domain');
        
        // Reload the domains list to reflect the deletion
        await loadGTTFiles();
      } catch (reloadError) {
        console.error('Error reloading GTT files:', reloadError);
      }

      toast({
        title: "Domain deleted",
        description: "Domain has been deleted successfully",
      });
    } catch (error) {
      console.error('Error deleting domain:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to delete domain",
      });
    } finally {
      setIsDeleting(false);
      setFileToDelete(null);
    }
  };

  const confirmDelete = (fileName) => {
    setFileToDelete(fileName);
    setIsDeleteDialogOpen(true);
  };

  const resetForm = () => {
    setNewDomain('');
    setSelectedFile(null);
    setIsDialogOpen(false);
  };

  const handleRefresh = async () => {
    try {
      await reloadAllTerms();
      await loadGTTFiles();
      toast({
        title: "Refreshed",
        description: "Domain list has been refreshed",
      });
    } catch (error) {
      console.error('Error refreshing domains:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to refresh domains",
      });
    }
  };

  return (
    <>
      <div className="flex justify-between items-center p-6">
        <div>
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbLink as={Link} to="/dashboard">Dashboard</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator>/</BreadcrumbSeparator>
              <BreadcrumbItem>
                <BreadcrumbPage>Terms Base Settings</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
          <h1 className="text-2xl font-bold mt-2">Terms Domain</h1>
        </div>
        <div className="flex gap-4">
          <Link
            to="/app/dashboard-glossary"
            className="inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/80 h-9 px-4 py-2"
          >
            View Dashboard
          </Link>
          <Button onClick={handleRefresh} variant="outline" className="gap-2">
            <RefreshCw className="h-4 w-4" />
            Refresh
          </Button>
          <Button onClick={() => setIsDialogOpen(true)}>Add Domain</Button>
        </div>
      </div>
      <div className="p-6">
        <Card>
          <CardHeader>
            <div className="flex justify-between items-center">
              <div>
                <CardTitle>Terms Domain</CardTitle>
                <CardDescription>
                  Manage terms domain and their associated JSON files
                </CardDescription>
              </div>
              <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                <DialogTrigger asChild>
                </DialogTrigger>
                <DialogContent className="sm:max-w-[500px]">
                  <DialogHeader>
                    <DialogTitle className="text-2xl">Add New Terms Domain</DialogTitle>
                    <DialogDescription className="text-base pt-2">
                      Create a new domain category and upload its glossary JSON file
                    </DialogDescription>
                  </DialogHeader>
                  
                  <form onSubmit={handleSubmit} className="space-y-6 py-4">
                    <div className="space-y-2">
                      <Label htmlFor="domain-name2" className="text-base">Domain Name</Label>
                      <Input
                        id="domain-name2"
                        value={newDomain}
                        onChange={(e) => setNewDomain(e.target.value)}
                        placeholder="Enter domain name"
                        className="h-11"
                      />
                    </div>

                    <div className="space-y-2">
                      <Label className="text-base">Upload JSON File</Label>
                      <div
                        className={`border-2 border-dashed rounded-lg p-6 transition-colors ${
                          isDragging ? 'border-primary bg-primary/5' : 'border-gray-200'
                        } ${selectedFile ? 'bg-green-50' : ''}`}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                      >
                        <div className="flex flex-col items-center justify-center gap-3">
                          {selectedFile ? (
                            <>
                              <FileJson className="h-10 w-10 text-green-500" />
                              <div className="text-center">
                                <p className="text-sm font-medium">{selectedFile.name}</p>
                                <p className="text-xs text-gray-500">
                                  {(selectedFile.size / 1024).toFixed(2)} KB
                                </p>
                              </div>
                              <Button
                                type="button"
                                variant="outline"
                                size="sm"
                                className="mt-2"
                                onClick={() => setSelectedFile(null)}
                              >
                                <X className="h-4 w-4 mr-1" />
                                Remove File
                              </Button>
                            </>
                          ) : (
                            <>
                              <Upload className="h-10 w-10 text-gray-400" />
                              <div className="text-center">
                                <p className="text-sm font-medium">Drag & Drop your JSON file here</p>
                                <p className="text-xs text-gray-500">or click to browse</p>
                              </div>
                              <Input
                                id="json-file"
                                type="file"
                                accept=".json"
                                onChange={handleFileChange}
                                className="hidden"
                              />
                              <Button
                                type="button"
                                variant="outline"
                                onClick={() => document.getElementById('json-file').click()}
                              >
                                Browse Files
                              </Button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>

                    <DialogFooter className="gap-3 sm:gap-0">
                      <Button
                        type="button"
                        variant="outline"
                        onClick={resetForm}
                      >
                        Cancel
                      </Button>
                      <Button type="submit" disabled={isUploading}>
                        {isUploading ? (
                          <>
                            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                            Uploading...
                          </>
                        ) : (
                          <>
                            <Plus className="h-4 w-4" />
                            Add Domain
                          </>
                        )}
                      </Button>
                    </DialogFooter>
                  </form>
                </DialogContent>
              </Dialog>
            </div>
          </CardHeader>
          <CardContent>
            {isLoading ? (
              <div className="flex justify-center items-center py-8">
                <Loader2 className="h-8 w-8 animate-spin text-primary" />
                <span className="ml-2 text-lg">Loading domains...</span>
              </div>
            ) : error ? (
              <div className="text-center py-8 text-red-500">
                <p>{error}</p>
                <Button 
                  onClick={loadGTTFiles} 
                  variant="outline" 
                  className="mt-4"
                >
                  Try Again
                </Button>
              </div>
            ) : (
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Domain Name</TableHead>
                    <TableHead>File Name</TableHead>
                    <TableHead>Actions</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {domains.map((domain) => (
                    <TableRow key={domain.id}>
                      <TableCell className="font-medium">{domain.name}</TableCell>
                      <TableCell>{domain.fileName}</TableCell>
                      <TableCell>
                        <Button
                          variant="destructive"
                          size="sm"
                          onClick={() => confirmDelete(domain.fileName)}
                          className="flex items-center gap-1"
                        >
                          <X className="h-4 w-4" />
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                  {domains.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={3} className="text-center text-muted-foreground">
                        No domains added yet
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </CardContent>
        </Card>
      </div>

      {/* Delete Confirmation Dialog */}
      <AlertDialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <AlertDialogContent className="bg-gray-50">
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This will permanently delete the file "{fileToDelete}" from the glossary.
              This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => setFileToDelete(null)}>Cancel</AlertDialogCancel>
            <AlertDialogAction 
              onClick={() => deleteDomain(fileToDelete)}
              className="bg-red-600 hover:bg-red-700 text-white"
              disabled={isDeleting}
            >
              {isDeleting ? (
                <>
                  <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                  Deleting...
                </>
              ) : (
                'Delete'
              )}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default GlossaryDomain;
