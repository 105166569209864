/** @format */

import React, { useState, useEffect, useRef } from 'react';
import { Card, CardContent } from '../../components/ui/card';
import { Button } from '../../components/ui/button';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../components/ui/table';
import { Input } from '../../components/ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select";
import mammoth from 'mammoth';
import { Document, Packer, Table as DocxTable, TableRow as DocxTableRow, TableCell as DocxTableCell, Paragraph, HeadingLevel } from 'docx';
import { loadAIModels } from "../../utils/aiModelUtils";
import PlaygroundSimpleHeader from "../../components/core/public/PlaygroundSimpleHeader";
import PlaygroundNavigation from "../../components/core/public/PlaygroundNavigation";

const FileExtractionTranslationPlayground = () => {
  const [tableData, setTableData] = useState([]);
  const [processingRows, setProcessingRows] = useState(new Set());
  const [selectedModel, setSelectedModel] = useState(null);
  const [availableModels, setAvailableModels] = useState([]);
  const [sourceLanguage, setSourceLanguage] = useState("English");
  const [targetLanguage, setTargetLanguage] = useState("Bahasa Melayu");
  const [editingIndex, setEditingIndex] = useState(null);
  const [error, setError] = useState(null);
  const [translationMemory, setTranslationMemory] = useState([]);
  const [similarTranslations, setSimilarTranslations] = useState({});
  const [showSimilarityModal, setShowSimilarityModal] = useState(false);
  const [currentComparisonIndex, setCurrentComparisonIndex] = useState(null);
  const [isLoadingComparison, setIsLoadingComparison] = useState(false);
  const [totalWordCount, setTotalWordCount] = useState(0);
  const [showWordLimitWarning, setShowWordLimitWarning] = useState(false);
  const fileInputRef = useRef(null);

  const MAX_WORDS = 200;

  const segmentText = (text) => {
    // Split text by sentence boundaries
    // This regex looks for: .!? followed by spaces and capital letter
    const sentences = text.split(/(?<=[.!?])\s+(?=[A-Z])/);
    
    // Filter out empty sentences and trim each sentence
    const validSentences = sentences
      .map(sentence => sentence.trim())
      .filter(sentence => sentence.length > 0);
    
    // Process sentences and track word count
    const processedSegments = [];
    let totalWords = 0;
    
    for (const sentence of validSentences) {
      const wordCount = sentence.split(/\s+/).length;
      
      // If adding this sentence would exceed MAX_WORDS, stop processing
      if (totalWords + wordCount > MAX_WORDS) {
        break;
      }
      
      processedSegments.push({
        text: sentence,
        wordCount: wordCount
      });
      
      totalWords += wordCount;
    }
    
    return processedSegments;
  };

  useEffect(() => {
    // Load available translation models from API
    const fetchModels = async () => {
      try {
        const models = await loadAIModels();
        const activeModels = models.filter(model => model.isActive);
        setAvailableModels(activeModels);
        if (activeModels.length > 0 && !selectedModel) {
          setSelectedModel(activeModels[0]);
        }
      } catch (error) {
        console.error('Error fetching models:', error);
        setError('Failed to load AI models. Please try again later.');
      }
    };

    fetchModels();
  }, []);

  // Load translation memory from GTTFiles and resources folders
  useEffect(() => {
    const loadTranslationMemory = async () => {
      try {
        console.log('Loading translation memory...');
        
        // Try different endpoint paths to handle various server configurations
        const endpoints = [
          '/api/translations/all',
          '/utm-ittbm/api/translations/all',
          '/api/translations/gttfiles',
          '/utm-ittbm/api/translations/gttfiles'
        ];
        
        let combinedData = [];
        let success = false;
        
        // Try each endpoint until one works
        for (const endpoint of endpoints) {
          try {
            console.log(`Trying endpoint: ${endpoint}`);
            const response = await fetch(endpoint);
            
            if (response.ok) {
              const data = await response.json();
              console.log(`Endpoint ${endpoint} succeeded with ${data.length} entries`);
              combinedData = data;
              success = true;
              break;
            } else {
              console.log(`Endpoint ${endpoint} failed with status: ${response.status}`);
            }
          } catch (endpointError) {
            console.error(`Error with endpoint ${endpoint}:`, endpointError);
          }
        }
        
        if (!success) {
          // If all endpoints failed, try a direct file read approach
          console.log('All API endpoints failed, trying direct file access');
          
          // Try to load sample data from a local file
          try {
            // Create some sample data for testing
            const sampleData = [
              { english: "instrument", malay: "alat", domain: "Kahwin" },
              { english: "office copy", malay: "salinan pejabat", domain: "Kahwin" },
              { english: "national language", malay: "bahasa kebangsaan", domain: "Kahwin" },
              { english: "Court", malay: "Mahkamah", domain: "Kahwin" },
              { english: "qualification", malay: "Kelayakan", domain: "Education" },
              { english: "degree", malay: "ijazah", domain: "Education" },
              { english: "fellowship", malay: "fellowships", domain: "Education" },
              { english: "authority to practise", malay: "kuasa untuk mengamalkan", domain: "Education" },
              { english: "testimonial", malay: "testimoni", domain: "Education" }
            ];
            
            combinedData = sampleData;
            success = true;
            console.log('Using sample data for testing:', combinedData.length, 'entries');
          } catch (localError) {
            console.error('Local file access also failed:', localError);
            throw new Error('All methods to load translation memory failed');
          }
        }
        
        if (success) {
          // Set the translation memory
          setTranslationMemory(combinedData);
          console.log('Translation memory loaded:', combinedData.length, 'entries');
        } else {
          throw new Error('Failed to load translation memory from any source');
        }
      } catch (error) {
        console.error('Error loading translation memory:', error);
        setError('Failed to load translation memory. Some features may not work properly.');
      }
    };

    loadTranslationMemory();
  }, []);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (event) => {
      const arrayBuffer = event.target.result;
      
      // Use mammoth to extract text from docx
      mammoth.extractRawText({ arrayBuffer })
        .then(result => {
          const text = result.value;
          
          // Count total words
          const totalWords = text.trim().split(/\s+/).length;
          setTotalWordCount(totalWords);
          
          // Show warning if exceeds limit
          const exceedsLimit = totalWords > MAX_WORDS;
          setShowWordLimitWarning(exceedsLimit);
          
          // Segment the text by sentences
          const segments = segmentText(text);
          
          // Initialize the data with empty translations
          const initializedData = segments.map(segment => ({
            sourceText: segment.text,
            translatedText: '',
            wordCount: segment.wordCount,
            status: 'pending'
          }));

          // Set the data
          setTableData(initializedData);
          setProcessingRows(new Set());
          setEditingIndex(null);
        })
        .catch(error => {
          console.error('Error extracting text from docx:', error);
          alert('Error extracting text from the document. Please make sure it is a valid .docx file.');
        });
    };

    reader.readAsArrayBuffer(file);
  };

  const translateWithOpenAI = async (text, apiKey) => {
    try {
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`,
        },
        body: JSON.stringify({
          model: 'gpt-3.5-turbo',
          messages: [
            {
              role: 'system',
              content: `You are a translation assistant. Translate the following text from ${sourceLanguage} to ${targetLanguage}. Provide only the translation, no additional explanations.`
            },
            {
              role: 'user',
              content: text
            }
          ],
          temperature: 0.3
        })
      });

      if (!response.ok) {
        throw new Error(`OpenAI API error: ${response.status}`);
      }

      const data = await response.json();
      return data.choices[0].message.content.trim();
    } catch (error) {
      throw new Error(`OpenAI translation failed: ${error.message}`);
    }
  };

  const translateWithGroq = async (text, apiKey) => {
    try {
      const response = await fetch('https://api.groq.com/openai/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`,
        },
        body: JSON.stringify({
          model: selectedModel.modelId || 'mistral-saba-24b',
          messages: [
            {
              role: 'system',
              content: `You are a translation assistant. Translate the following text from ${sourceLanguage} to ${targetLanguage}. Provide only the translation, no additional explanations.`
            },
            {
              role: 'user',
              content: text
            }
          ],
          temperature: 0.3
        })
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(`Groq API error: ${response.status} - ${errorData.error?.message || 'Unknown error'}`);
      }

      const data = await response.json();
      return data.choices[0].message.content.trim();
    } catch (error) {
      throw new Error(`Groq translation failed: ${error.message}`);
    }
  };

  const translateWithOllama = async (text, endpoint = 'http://localhost:11434') => {
    try {
      console.log('Ollama Request:', {
        endpoint,
        model: selectedModel.modelId,
        text: text.substring(0, 100)
      });

      const response = await fetch(`${endpoint}/api/generate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          model: selectedModel.modelId,
          prompt: `You are a translation assistant. Your task is to translate the following text from ${sourceLanguage} to ${targetLanguage}. Only output the translation, nothing else.\n\nText to translate:\n${text}\n\nTranslation:`,
          stream: false,
          options: {
            temperature: 0.1
          }
        })
      });

      if (!response.ok) {
        throw new Error(`Ollama API error: ${response.status}`);
      }

      const data = await response.json();
      console.log('Ollama Response:', data);

      let cleanedResponse = data.response
        .replace(/<think>[\s\S]*?<\/think>/g, '')
        .replace(/Translation:/i, '')
        .trim();

      if (!cleanedResponse) {
        cleanedResponse = data.response.trim();
      }

      return cleanedResponse;
    } catch (error) {
      console.error('Ollama error:', error);
      throw new Error(`Ollama translation failed: ${error.message}`);
    }
  };

  const translateWithHuggingFace = async (text, apiKey, modelId, endpoint) => {
    try {
      const apiUrl = endpoint || `https://api-inference.huggingface.co/models/${modelId}`;
      
      console.log('HF Request:', {
        url: apiUrl,
        modelId,
        hasApiKey: !!apiKey,
        text: text.substring(0, 100) // Log first 100 chars only
      });

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`
        },
        body: JSON.stringify({
          inputs: text,
          options: {
            wait_for_model: true
          }
        })
      });

      if (!response.ok) {
        throw new Error(`HuggingFace API error: ${response.status}`);
      }

      const data = await response.json();
      
      // Handle different response formats
      if (Array.isArray(data) && data.length > 0) {
        if (data[0].translation_text) {
          return data[0].translation_text;
        } else if (data[0].generated_text) {
          return data[0].generated_text;
        }
      }
      
      // Fallback to raw response
      return typeof data === 'string' ? data : JSON.stringify(data);
    } catch (error) {
      throw new Error(`HuggingFace translation failed: ${error.message}`);
    }
  };

  const translateText = async (text, index) => {
    if (!selectedModel) {
      console.error('No model selected');
      return;
    }

    setProcessingRows(prev => new Set(prev).add(index));

    try {
      let translatedText = '';

      switch (selectedModel.type) {
        case 'ollama':
          translatedText = await translateWithOllama(text);
          break;
        case 'openai':
          translatedText = await translateWithOpenAI(text, selectedModel.apiKey);
          break;
        case 'groq':
          translatedText = await translateWithGroq(text, selectedModel.apiKey);
          break;
        case 'huggingface':
          translatedText = await translateWithHuggingFace(
            text,
            selectedModel.apiKey,
            selectedModel.modelId,
            selectedModel.endpoint
          );
          break;
        default:
          throw new Error('Unsupported model type');
      }

      console.log('Translation completed:', translatedText?.substring(0, 30));
      
      // Find similar translations in the translation memory
      const similar = findSimilarTranslations(text, translatedText);
      console.log('Similar translations found:', similar.length);
      
      // Update similar translations state
      setSimilarTranslations(prev => {
        const updated = {
          ...prev,
          [index]: similar
        };
        console.log('Updated similarTranslations state:', updated);
        return updated;
      });

      setTableData(prevData => {
        const newData = [...prevData];
        newData[index] = {
          ...newData[index],
          translatedText,
          status: 'completed'
        };
        return newData;
      });
    } catch (error) {
      console.error('Translation error:', error);
      setTableData(prevData => {
        const newData = [...prevData];
        newData[index] = {
          ...newData[index],
          status: 'error'
        };
        return newData;
      });
    } finally {
      setProcessingRows(prev => {
        const newSet = new Set(prev);
        newSet.delete(index);
        return newSet;
      });
    }
  };

  const translateAll = async () => {
    if (!selectedModel) {
      console.error('No model selected');
      return;
    }

    // Translate each row sequentially
    for (let i = 0; i < tableData.length; i++) {
      if (tableData[i].status !== 'completed') {
        await translateText(tableData[i].sourceText, i);
      }
    }
  };

  const startNewSession = () => {
    setTableData([]);
    setProcessingRows(new Set());
    setEditingIndex(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const downloadDocx = async () => {
    // Create a new document
    const doc = new Document({
      sections: [{
        children: [
          new Paragraph({
            text: "Translation Results",
            heading: HeadingLevel.HEADING_1
          }),
          new Paragraph({
            text: `Total Words: ${totalWordCount} (${tableData.length} segments of up to ${MAX_WORDS} words each)`,
            spacing: {
              after: 400
            }
          }),
          new DocxTable({
            rows: [
              // Header row
              new DocxTableRow({
                children: [
                  new DocxTableCell({
                    children: [new Paragraph({ text: "Source Text" })],
                    width: {
                      size: 42,
                      type: "percentage"
                    }
                  }),
                  new DocxTableCell({
                    children: [new Paragraph({ text: "Words" })],
                    width: {
                      size: 8,
                      type: "percentage"
                    }
                  }),
                  new DocxTableCell({
                    children: [new Paragraph({ text: "Translated Text" })],
                    width: {
                      size: 42,
                      type: "percentage"
                    }
                  })
                ]
              }),
              // Data rows
              ...tableData.map(row => 
                new DocxTableRow({
                  children: [
                    new DocxTableCell({
                      children: [new Paragraph({ text: row.sourceText })],
                      width: {
                        size: 42,
                        type: "percentage"
                      }
                    }),
                    new DocxTableCell({
                      children: [new Paragraph({ text: row.wordCount.toString() })],
                      width: {
                        size: 8,
                        type: "percentage"
                      }
                    }),
                    new DocxTableCell({
                      children: [new Paragraph({ text: row.translatedText })],
                      width: {
                        size: 42,
                        type: "percentage"
                      }
                    })
                  ]
                })
              )
            ]
          })
        ]
      }]
    });

    // Generate the document
    const blob = await Packer.toBlob(doc);
    
    // Create download link
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'translation_results.docx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  // Function to calculate similarity between two strings - enhanced for better matching
  const calculateSimilarity = (str1, str2) => {
    if (!str1 || !str2) return 0;
    
    // Early exit for identical strings
    if (str1 === str2) return 100;
    
    // Enhanced text normalization
    const normalizeString = (text) => {
      return text
        .toLowerCase()
        .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '') // Remove punctuation
        .replace(/\s+/g, ' ')
        .trim()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '') // Remove diacritics
        .replace(/\d+/g, 'NUM'); // Normalize numbers
    };
    
    const s1 = normalizeString(str1);
    const s2 = normalizeString(str2);
    
    // Split into words and remove empty strings
    const words1 = s1.split(/\s+/).filter(Boolean);
    const words2 = s2.split(/\s+/).filter(Boolean);
    
    if (words1.length === 0 || words2.length === 0) return 0;
    
    // Generate n-grams (2-grams and 3-grams) for phrase matching
    const generateNGrams = (words, n) => {
      const ngrams = [];
      for (let i = 0; i <= words.length - n; i++) {
        ngrams.push(words.slice(i, i + n).join(' '));
      }
      return new Set(ngrams);
    };
    
    // Generate n-grams for both texts
    const bigrams1 = generateNGrams(words1, 2);
    const bigrams2 = generateNGrams(words2, 2);
    const trigrams1 = generateNGrams(words1, 3);
    const trigrams2 = generateNGrams(words2, 3);
    
    // For very long texts, use a sampling approach to improve performance
    const MAX_WORDS_TO_PROCESS = 150; // Increased from 100
    const shouldSample = words1.length > MAX_WORDS_TO_PROCESS || words2.length > MAX_WORDS_TO_PROCESS;
    
    let processedWords1, processedWords2;
    
    if (shouldSample) {
      // Enhanced sampling strategy: beginning, middle, and end with overlap
      const sampleWords = (words, count) => {
        if (words.length <= count) return words;
        
        const third = Math.floor(count / 3);
        const overlap = Math.floor(third / 4); // Add overlap between sections
        
        const start = words.slice(0, third + overlap);
        const middleStart = Math.floor(words.length / 2) - Math.floor(third / 2);
        const middle = words.slice(middleStart - overlap, middleStart + third + overlap);
        const end = words.slice(words.length - (third + overlap));
        
        return [...start, ...middle, ...end];
      };
      
      processedWords1 = sampleWords(words1, MAX_WORDS_TO_PROCESS);
      processedWords2 = words2; // Keep all words2 for better matching
    } else {
      processedWords1 = words1;
      processedWords2 = words2;
    }
    
    // Create enhanced word matching sets
    const words2Set = new Set(processedWords2);
    const words2Stems = new Set();
    const words2Partial = new Set();
    const words2Phonetic = new Set(); // For phonetic matching
    
    // Enhanced word processing
    processedWords2.forEach(word => {
      if (word.length < 3) return;
      
      // Add stems (enhanced with more variations)
      if (word.length > 4) {
        const endings = ['s', 'es', 'ed', 'ing', 'ly', 'er', 'est', 'ment', 'tion', 'sion'];
        endings.forEach(ending => {
          if (word.endsWith(ending)) {
            const stem = word.slice(0, word.length - ending.length);
            if (stem.length >= 3) {
              words2Stems.add(stem);
            }
          }
        });
        
        // Add word roots for partial matching
        for (let i = 3; i <= Math.min(6, word.length); i++) {
          words2Partial.add(word.substring(0, i));
        }
        
        // Add simple phonetic code
        const phoneticCode = word
          .replace(/[aeiou]/g, 'a')
          .replace(/[bcdfghjklmnpqrstvwxyz]/g, (c) => {
            return {
              'b': 'b', 'f': 'f', 'p': 'b',
              'c': 'k', 'g': 'k', 'j': 'k',
              'd': 'd', 't': 'd',
              'l': 'l', 'r': 'l',
              'm': 'm', 'n': 'm',
              's': 's', 'z': 's',
              'v': 'v', 'w': 'v'
            }[c] || c;
          });
        words2Phonetic.add(phoneticCode);
      }
    });
    
    // Enhanced matching with weights
    let matchScore = 0;
    const alreadyMatched = new Set();
    
    // Function to get phonetic code
    const getPhoneticCode = (word) => {
      return word
        .replace(/[aeiou]/g, 'a')
        .replace(/[bcdfghjklmnpqrstvwxyz]/g, (c) => {
          return {
            'b': 'b', 'f': 'f', 'p': 'b',
            'c': 'k', 'g': 'k', 'j': 'k',
            'd': 'd', 't': 'd',
            'l': 'l', 'r': 'l',
            'm': 'm', 'n': 'm',
            's': 's', 'z': 's',
            'v': 'v', 'w': 'v'
          }[c] || c;
        });
    };
    
    // N-gram matching
    let ngramScore = 0;
    bigrams1.forEach(bigram => {
      if (bigrams2.has(bigram)) ngramScore += 2.0;
    });
    trigrams1.forEach(trigram => {
      if (trigrams2.has(trigram)) ngramScore += 3.0;
    });
    
    // Word-level matching with enhanced scoring
    for (const word1 of processedWords1) {
      if (word1.length < 3) continue;
      if (alreadyMatched.has(word1)) continue;
      
      // Exact match
      if (words2Set.has(word1)) {
        matchScore += 1.0;
        alreadyMatched.add(word1);
        continue;
      }
      
      // Stem matching with enhanced endings
      let stemMatched = false;
      const endings = ['s', 'es', 'ed', 'ing', 'ly', 'er', 'est', 'ment', 'tion', 'sion'];
      for (const ending of endings) {
        if (word1.endsWith(ending)) {
          const stem = word1.slice(0, word1.length - ending.length);
          if (stem.length >= 3 && (words2Set.has(stem) || words2Stems.has(stem))) {
            matchScore += 0.9;
            stemMatched = true;
            alreadyMatched.add(word1);
            break;
          }
        }
      }
      if (stemMatched) continue;
      
      // Partial matching with progressive weights
      if (word1.length >= 4) {
        for (let i = 3; i <= Math.min(6, word1.length); i++) {
          const partial = word1.substring(0, i);
          if (words2Partial.has(partial)) {
            matchScore += 0.7 * (i / word1.length);
            alreadyMatched.add(word1);
            break;
          }
        }
      }
      
      // Phonetic matching
      const phoneticCode = getPhoneticCode(word1);
      if (words2Phonetic.has(phoneticCode)) {
        matchScore += 0.6;
        alreadyMatched.add(word1);
        continue;
      }
    }
    
    // Calculate final similarity score
    const wordMatchWeight = 0.7;
    const ngramMatchWeight = 0.3;
    
    const wordMatchScore = matchScore / Math.max(words1.length, words2.length);
    const ngramMatchScore = ngramScore / (Math.max(bigrams1.size + trigrams1.size, bigrams2.size + trigrams2.size) || 1);
    
    const finalScore = (wordMatchScore * wordMatchWeight + ngramMatchScore * ngramMatchWeight) * 100;
    
    return Math.min(100, Math.round(finalScore));
  };

  // Function to find similar translations in the translation memory
  const findSimilarTranslations = (sourceText, translatedText) => {
    console.log('Finding similar translations for:', { 
      sourceText: sourceText?.substring(0, 30), 
      translatedText: translatedText?.substring(0, 30),
      memorySize: translationMemory.length
    });
    
    if (!translationMemory.length) {
      console.log('Translation memory is empty');
      return [];
    }
    
    if (!sourceText || !translatedText) {
      console.log('Source text or translated text is empty');
      return [];
    }
    
    try {
      // Limit the number of entries to process for better performance
      const MAX_ENTRIES_TO_PROCESS = 1000;
      const entriesToProcess = translationMemory.length > MAX_ENTRIES_TO_PROCESS ? 
        translationMemory.slice(0, MAX_ENTRIES_TO_PROCESS) : 
        translationMemory;
      
      console.log(`Processing ${entriesToProcess.length} out of ${translationMemory.length} entries`);
      
      // First pass: quick filter to reduce the number of entries for detailed comparison
      const potentialMatches = entriesToProcess.filter(entry => {
        try {
          if (!entry.english || !entry.malay) return false;
          
          // Get the appropriate source and target text based on language settings
          const entrySourceText = sourceLanguage.toLowerCase() === 'english' ? entry.english : entry.malay;
          const entryTargetText = targetLanguage.toLowerCase().includes('melayu') ? entry.malay : entry.english;
          
          // More lenient length-based check
          const sourceLengthRatio = entrySourceText.length / Math.max(1, sourceText.length);
          const targetLengthRatio = entryTargetText.length / Math.max(1, translatedText.length);
          
          return (sourceLengthRatio >= 0.3 && sourceLengthRatio <= 3) || 
                 (targetLengthRatio >= 0.3 && targetLengthRatio <= 3);
        } catch (error) {
          return false;
        }
      });
      
      console.log(`Quick filter reduced entries to ${potentialMatches.length}`);
      
      // Second pass: detailed similarity calculation on the reduced set
      const similarEntries = [];
      
      for (const entry of potentialMatches) {
        try {
          // Get the appropriate source and target text based on language settings
          const entrySourceText = sourceLanguage.toLowerCase() === 'english' ? entry.english : entry.malay;
          const entryTargetText = targetLanguage.toLowerCase().includes('melayu') ? entry.malay : entry.english;
          
          // Check if target language matches (this is more important)
          const targetSimilarity = calculateSimilarity(entryTargetText, translatedText);
          
          // Only calculate source similarity if target similarity is promising
          if (targetSimilarity >= 40) {
            // Check if source language matches (less important but still relevant)
            const sourceSimilarity = calculateSimilarity(entrySourceText, sourceText);
            
            // Calculate combined similarity score (weighted more towards target similarity)
            const combinedSimilarity = (targetSimilarity * 0.7) + (sourceSimilarity * 0.3);
            
            // Return entries with similarity above threshold
            if (combinedSimilarity >= 60) {
              similarEntries.push({
                ...entry,
                similarity: combinedSimilarity,
                targetSimilarity: targetSimilarity,
                sourceSimilarity: sourceSimilarity
              });
            }
          }
        } catch (error) {
          console.error('Error processing entry:', error);
        }
      }
      
      // Sort by similarity (highest first)
      similarEntries.sort((a, b) => b.similarity - a.similarity);
      
      // Limit the number of results
      const MAX_RESULTS = 10;
      const limitedResults = similarEntries.slice(0, MAX_RESULTS);
      
      console.log('Similar entries found:', limitedResults.length);
      return limitedResults;
    } catch (error) {
      console.error('Error finding similar translations:', error);
      return [];
    }
  };

  // Function to highlight similar words in the text
  const getHighlightedText = (text1, text2) => {
    if (!text1 || !text2) return text1;
    
    // For very long texts, truncate to improve performance
    const maxLength = 500;
    const displayText = text1.length > maxLength ? 
      text1.substring(0, maxLength) + '...' : 
      text1;
    
    // Normalize and prepare text2 for comparison
    const normalizeString = (text) => {
      return text
        .toLowerCase()
        .replace(/\s+/g, ' ')
        .trim()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, ''); // Remove diacritics
    };
    
    const text2Normalized = normalizeString(text2);
    const words2 = text2Normalized.split(/\s+/);
    const words2Set = new Set(words2);
    
    // Create stems and partial matches for text2
    const words2Stems = new Set();
    const words2Partial = new Set();
    
    words2.forEach(word => {
      if (word.length < 3) return;
      
      // Add simple stems (remove common endings)
      if (word.length > 4) {
        ['s', 'es', 'ed', 'ing', 'ly'].forEach(ending => {
          if (word.endsWith(ending)) {
            const stem = word.slice(0, word.length - ending.length);
            if (stem.length >= 3) {
              words2Stems.add(stem);
            }
          }
        });
        
        // Add word root for partial matching
        words2Partial.add(word.substring(0, Math.min(5, word.length)));
      }
    });
    
    // Split text1 into words and process each word
    const words1 = displayText.split(/(\s+|[.,;:!?()])/); // Split by spaces and punctuation
    
    return words1.map(word => {
      // Skip spaces and punctuation
      if (!word.trim() || word.length < 3) return word;
      
      const wordNormalized = normalizeString(word);
      
      // Exact match
      if (words2Set.has(wordNormalized)) {
        return `<span style="font-style: italic; color: red;">${word}</span>`;
      }
      
      // Check for stem matches
      let stemMatched = false;
      
      // Check if this word's stem exists in the other text
      ['s', 'es', 'ed', 'ing', 'ly'].forEach(ending => {
        if (wordNormalized.endsWith(ending)) {
          const stem = wordNormalized.slice(0, wordNormalized.length - ending.length);
          if (stem.length >= 3 && words2Set.has(stem)) {
            stemMatched = true;
          }
        }
      });
      
      if (stemMatched) {
        return `<span style="font-style: italic; color: red;">${word}</span>`;
      }
      
      // Check if a stem of this word exists in the other text
      if (wordNormalized.length >= 4 && words2Stems.has(wordNormalized)) {
        return `<span style="font-style: italic; color: red;">${word}</span>`;
      }
      
      // Partial matching for longer words
      if (wordNormalized.length >= 5) {
        const partial1 = wordNormalized.substring(0, Math.min(5, wordNormalized.length));
        if (words2Partial.has(partial1)) {
          return `<span style="font-style: italic; color: red;">${word}</span>`;
        }
      }
      
      return word;
    }).join('');
  };

  // Function to use memory translation for a specific row
  const applyMemoryTranslation = (rowIndex, memoryTranslation) => {
    setTableData(prevData => {
      const newData = [...prevData];
      if (newData[rowIndex]) {
        newData[rowIndex] = {
          ...newData[rowIndex],
          translatedText: memoryTranslation
        };
      }
      return newData;
    });
    
    // Clear the similar translations for this row
    setSimilarTranslations(prev => {
      const updated = { ...prev };
      delete updated[rowIndex];
      return updated;
    });
  };

  // Function to dismiss similar translation
  const dismissSimilarTranslation = (rowIndex) => {
    setSimilarTranslations(prev => {
      const updated = { ...prev };
      delete updated[rowIndex];
      return updated;
    });
  };

  // Function to manually check for similar translations
  const checkSimilarTranslations = (index) => {
    const row = tableData[index];
    if (!row || !row.translatedText) {
      console.log('No translated text to check for row:', index);
      return;
    }
    
    console.log('Manually checking for similar translations for row:', index);
    
    setIsLoadingComparison(true);
    
    setTimeout(() => {
      try {
        const similar = findSimilarTranslations(row.sourceText, row.translatedText);
        
        setIsLoadingComparison(false);
        
        if (similar.length > 0) {
          console.log('Found similar translations:', similar.length);
          setSimilarTranslations(prev => ({
            ...prev,
            [index]: similar
          }));
          setCurrentComparisonIndex(index);
          setShowSimilarityModal(true);
        } else {
          console.log('No similar translations found');
          setTimeout(() => {
            alert('No similar translations found in the translation memory.');
          }, 100);
        }
      } catch (error) {
        setIsLoadingComparison(false);
        console.error('Error checking similar translations:', error);
        setTimeout(() => {
          alert('Error checking similar translations. Please try again.');
        }, 100);
      }
    }, 0);
  };

  return (
    <div className="min-h-screen bg-white">
      <PlaygroundSimpleHeader keyPrefix="playgroundPage" />
      <PlaygroundNavigation />
      
      <div className="container mx-auto px-4 py-8">
        {showWordLimitWarning && (
          <div className="bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 rounded relative mb-4" role="alert">
            <strong className="font-bold">Warning: </strong>
            <span className="block sm:inline">
              Your document contains {totalWordCount} words. Only the first {MAX_WORDS} words will be processed to optimize token usage.
              Each segment will contain up to {MAX_WORDS} words.
            </span>
          </div>
        )}
        <Card className="mb-6">
          <CardContent className="p-6">
            <div className="flex flex-col gap-4">
              <div className="space-y-4 py-4">
                <h2 className="text-2xl font-bold">File Extraction and Translation</h2>
                <p className="text-gray-500">
                  Upload a Word document (.docx) to extract text by sentences and translate them.
                </p>
                <div className="grid grid-cols-1 md:grid-cols-4 gap-6 items-end">
                  <div className="flex flex-col space-y-2">
                    <label htmlFor="model-select" className="text-sm font-medium">AI Translation Model</label>
                    <Select
                      value={selectedModel?.id || ''}
                      onValueChange={(value) => {
                        const model = availableModels.find(m => m.id === value);
                        setSelectedModel(model);
                      }}
                    >
                      <SelectTrigger id="model-select" className="w-full bg-white border-gray-300">
                        <SelectValue placeholder="Select Model" />
                      </SelectTrigger>
                      <SelectContent className="bg-white">
                        {availableModels.map((model) => (
                          <SelectItem 
                            key={model.id} 
                            value={model.id}
                            className="hover:bg-gray-200 focus:bg-gray-200 cursor-pointer py-2"
                          >
                            {model.name} 
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                  <div className="flex flex-col space-y-2">
                    <label htmlFor="file-upload" className="text-sm font-medium">Upload Word Document</label>
                    <Input
                      id="file-upload"
                      type="file"
                      accept=".docx"
                      onChange={handleFileUpload}
                      ref={fileInputRef}
                      className="w-full"
                    />
                  </div>
                  <div className="flex items-end">
                    <Button
                      onClick={translateAll}
                      disabled={tableData.length === 0 || !selectedModel}
                      variant="outline"
                      className="w-full"
                    >
                      Translate All
                    </Button>
                  </div>
                  <div className="flex items-end">
                    <Button
                      onClick={startNewSession}
                      disabled={tableData.length === 0}
                      variant="destructive"
                      className="w-full"
                    >
                      New Session
                    </Button>
                    <Button
                      onClick={downloadDocx}
                      disabled={tableData.length === 0}
                      variant="outline"
                      className="w-full ml-2"
                    >
                      Download Docx
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>

        <div className="overflow-x-auto mt-6">
          <Table className="w-full border-collapse table-fixed bg-white">
            <TableHeader>
              <TableRow>
                <TableHead className="w-[42%]">Source Text</TableHead>
                <TableHead className="w-[8%] text-center">Words</TableHead>
                <TableHead className="w-[8%] text-center">Actions</TableHead>
                <TableHead className="w-[42%]">Translated Text</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {tableData.map((row, index) => (
                <TableRow key={index} className="border-b hover:bg-white-50">
                  <TableCell className="align-top py-4 px-4">
                    <div className="max-h-[200px] overflow-y-auto whitespace-pre-wrap break-words p-2 bg-white-50 rounded">
                      {row.sourceText}
                    </div>
                  </TableCell>
                  <TableCell className="align-middle text-center py-4 px-2">
                    <div className="text-sm text-gray-600">
                      {row.wordCount}
                    </div>
                  </TableCell>
                  <TableCell className="align-middle text-center py-4 px-2">
                    <div className="flex flex-col gap-2">
                      <Button
                        onClick={() => translateText(row.sourceText, index)}
                        disabled={processingRows.has(index) || !selectedModel}
                        size="sm"
                        className="w-full"
                      >
                        {processingRows.has(index) ? 'Processing...' : 'Translate'}
                      </Button>
                      
                      {row.translatedText && (
                        <Button
                          onClick={() => checkSimilarTranslations(index)}
                          disabled={isLoadingComparison}
                          variant="outline"
                          size="sm"
                          className="w-full"
                        >
                          {isLoadingComparison ? 'Checking...' : 'Check Similar'}
                        </Button>
                      )}
                    </div>
                  </TableCell>
                  <TableCell className="align-top py-4 px-4">
                    {editingIndex === index ? (
                      <textarea
                        value={row.translatedText}
                        onChange={(e) => {
                          const newData = [...tableData];
                          newData[index].translatedText = e.target.value;
                          setTableData(newData);
                        }}
                        onBlur={() => setEditingIndex(null)}
                        autoFocus
                        className="w-full min-h-[200px] p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                      />
                    ) : (
                      <div className="flex flex-col gap-3">
                        <div
                          className="cursor-pointer hover:bg-gray-100 p-3 rounded max-h-[200px] overflow-y-auto whitespace-pre-wrap break-words bg-white-50"
                          onClick={() => setEditingIndex(index)}
                        >
                          {row.translatedText || (row.status === 'error' ? 'Error translating' : '')}
                        </div>
                        
                        {/* Similar translations section - displayed directly under the translated text */}
                        {similarTranslations[index]?.length > 0 && (
                          <div className="border rounded-lg p-3 bg-blue-50">
                            <div className="flex justify-between items-center mb-2">
                              <h3 className="font-medium text-blue-800">
                                Similar Translation Found ({similarTranslations[index].length})
                              </h3>
                              <div className="text-sm text-blue-600">
                                Similarity: {similarTranslations[index][0].similarity.toFixed(1)}%
                              </div>
                            </div>
                            
                            {/* Display the first similar translation */}
                            {similarTranslations[index][0] && (
                              <div className="mb-3">
                                <div className="text-sm text-gray-500 mb-1">Memory Translation:</div>
                                <div 
                                  className="p-2 bg-white rounded border border-blue-200 max-h-[150px] overflow-y-auto whitespace-pre-wrap break-words"
                                  dangerouslySetInnerHTML={{ 
                                    __html: getHighlightedText(
                                      targetLanguage.toLowerCase().includes('melayu') 
                                        ? similarTranslations[index][0].malay 
                                        : similarTranslations[index][0].english,
                                      row.translatedText
                                    )
                                  }}
                                />
                              </div>
                            )}
                            
                            <div className="flex justify-end space-x-2 mt-2">
                              <Button
                                onClick={() => dismissSimilarTranslation(index)}
                                variant="outline"
                                size="sm"
                              >
                                Keep AI Translation
                              </Button>
                              <Button
                                onClick={() => applyMemoryTranslation(
                                  index,
                                  targetLanguage.toLowerCase().includes('melayu') 
                                    ? similarTranslations[index][0].malay 
                                    : similarTranslations[index][0].english
                                )}
                                variant="default"
                                size="sm"
                              >
                                Use Memory Translation
                              </Button>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>

      {/* Loading overlay for when comparison is being calculated */}
      {isLoadingComparison && (
        <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-40">
          <div className="bg-white rounded-lg p-6 shadow-lg">
            <div className="flex items-center space-x-4">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
              <p>Finding similar translations...</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FileExtractionTranslationPlayground;
