/** @format */

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import PlaygroundSimpleHeader from "../../components/core/public/PlaygroundSimpleHeader";
import PlaygroundNavigation from "../../components/core/public/PlaygroundNavigation";

const Playground = () => {
  const [t] = useTranslation("translation", {
    keyPrefix: "playgroundPage",
  });

  // Fallback image if the custom image fails to load
  const fallbackImage = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='120' viewBox='0 0 200 120'%3E%3Crect width='200' height='120' fill='%23f3f4f6'/%3E%3Ctext x='50%25' y='50%25' font-family='Arial' font-size='16' fill='%236b7280' text-anchor='middle' dy='.3em'%3EFeature Image%3C/text%3E%3C/svg%3E";

  const options = [
    {
      title: t("textTranslation"),
      description: t("textTranslationDesc"),
      link: "/playground/text-translation",
      image: "./images/features/text-translation.png",
      bgColor: "bg-gray-100"
    },
    {
      title: "Batch Translation",
      description: "Translate multiple texts at once using Excel files",
      link: "/playground/batch-translation",
      image: "./images/features/batch-translation.png",
      bgColor: "bg-gray-100"
    },
    {
      title: "File Extraction & Translation",
      description: "Extract text from Word documents and translate by sentences",
      link: "/playground/file-extraction-translation",
      image: "./images/features/file-extraction.png",
      bgColor: "bg-gray-100"
    },
    {
      title: "Manual Translation (Concordance)",
      description: "Manually translate text with concordance search assistance",
      link: "/playground/manual-translation-concordance",
      image: "./images/features/concordance.png",
      bgColor: "bg-gray-100"
    },
    {
      title: t("gttTitle"),
      description: t("gttDesc"),
      link: "/playground/gtt",
      image: "./images/features/glossary.png",
      bgColor: "bg-gray-100"
    },
    {
      title: "Text Analysis",
      description: "Analyze text for linguistic features and parts of speech such as POS, segmentation, lemmatization",
      link: "/playground/text-analysis",
      image: "./images/features/text-analysis.png",
      bgColor: "bg-gray-100"
    },
    {
      title: "Translation Memory",
      description: "Create terminology pairs from source and translated text",
      link: "/playground/term-base",
      image: "./images/features/translation-memory.png",
      bgColor: "bg-gray-100"
    }
    //{
    //  title: "Translation Memory",
    //  description: "Create and manage translation memory for consistent translations",
    //  link: "/playground/translation-memory",
    //  icon: "🔄"
    //}
  ];

  return (
    <div className="min-h-screen bg-white">
      <PlaygroundSimpleHeader keyPrefix="playgroundPage" />
      <PlaygroundNavigation />

      <div className="py-12 bg-white">
        <div className="container mx-auto px-6">
          <div className="max-w-8xl mx-auto">
            {/* Header */}
            <div className="text-center mb-12">
              <h1 className="text-3xl font-medium text-gray-900 mb-3">
                {t("title")}
              </h1>
              <p className="text-lg text-gray-600 max-w-2xl mx-auto">
                {t("subtitle")}
              </p>
            </div>

            {/* Options Grid - Two Cards Per Row */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {options.map((option, index) => (
                <Link
                  key={index}
                  to={option.link}
                  className={`group overflow-hidden bg-white border border-gray-200 rounded-xl hover:shadow-lg transition-all duration-300 flex flex-col ${option.bgColor}`}
                >
                  {/* Image Container */}
                  <div className="relative h-90 overflow-hidden bg-gray-100">
                    <img
                      src={option.image}
                      alt={option.title}
                      className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
                      onError={(e) => {
                        console.log(`Failed to load image: ${option.image}`);
                        e.target.onerror = null;
                        e.target.src = fallbackImage;
                      }}
                    />
                  </div>

                  {/* Content */}
                  <div className="p-6 flex flex-col flex-grow">
                    <h2 className="text-xl font-semibold text-gray-800 mb-2 group-hover:text-blue-600 transition-colors duration-200">
                      {option.title}
                    </h2>
                    <p className="text-gray-600 text-sm flex-grow">
                      {option.description}
                    </p>
                    <div className="flex items-center mt-4 text-blue-600">
                      <span className="text-sm font-medium">Try it now</span>
                      <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        className="h-4 w-4 ml-1 transform group-hover:translate-x-1 transition-transform duration-200" 
                        viewBox="0 0 20 20" 
                        fill="currentColor"
                      >
                        <path 
                          fillRule="evenodd" 
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" 
                          clipRule="evenodd" 
                        />
                      </svg>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Playground;
