/** @format */

import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LayoutDashboard } from "lucide-react";

const PlaygroundNavigation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;

  // Remove /utm-ittbm prefix if it exists for comparison
  const normalizedPath = currentPath.replace("/utm-ittbm", "");

  const options = [
    {
      title: "Dashboard",
      path: "/playground",
      icon: "🏠"
    },
    {
      title: "Translation",
      path: "/playground/text-translation",
      icon: "🔤"
    },
    {
      title: "Excel Translation",
      path: "/playground/batch-translation",
      icon: "📝"
    },
    {
      title: "Docx Translation",
      path: "/playground/file-extraction-translation",
      icon: "📄"
    },
    {
      title: "Manual Translation",
      path: "/playground/manual-translation-concordance",
      icon: "🔍"
    },
    {
      title: "Terms",
      path: "/playground/gtt",
      icon: "📚"
    },
    {
      title: "Text Analysis",
      path: "/playground/text-analysis",
      icon: "🔍"
    },
    {
      title: "Translation Memory",
      path: "/playground/term-base",
      icon: "📖"
    }
    //{
    //  title: "Translation Memory",
    //  link: "/playground/translation-memory",
    //  icon: "🔄"
    //}
  ];

  const handleNavigation = (path) => {
    // Clean up any ongoing processes before navigation
    window.stop(); // Stop any ongoing requests
    navigate(path);
  };

  return (
    <div className="sticky top-0 z-10 bg-gray-300 shadow-md">
      <div className="container mx-auto px-4">
        <div className="overflow-x-auto py-1">
          <nav className="flex min-w-full">
            {options.map((option, index) => {
              const isActive = normalizedPath === option.path;
              
              return (
                <button
                  key={index}
                  onClick={() => handleNavigation(option.path)}
                  className={`relative px-4 py-3 mx-1 text-sm font-medium rounded-md transition-all duration-200 flex items-center whitespace-nowrap ${
                    isActive 
                      ? "text-blue-600 bg-white" 
                      : "text-gray-700 hover:bg-gray-50 hover:text-blue-500"
                  }`}
                >
                  <span className="flex items-center justify-center w-6 h-6 mr-2 text-lg">
                    {option.icon}
                  </span>
                  <span>{option.title}</span>
                  {isActive && (
                    <span className="absolute bottom-0 left-0 w-full h-0.5 bg-blue-600 rounded-t-md"></span>
                  )}
                </button>
              );
            })}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default PlaygroundNavigation; 