import React, { useState, useEffect, useRef } from 'react';
import { Card, CardContent } from '../../components/ui/card';
import { Button } from '../../components/ui/button';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../components/ui/table';
import { Input } from '../../components/ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { loadAIModels } from "../../utils/aiModelUtils";
import PlaygroundSimpleHeader from "../../components/core/public/PlaygroundSimpleHeader";
import PlaygroundNavigation from "../../components/core/public/PlaygroundNavigation";

const maxRecords = 10; // Maximum records to load

const BatchTranslationPlayground = () => {
  const [tableData, setTableData] = useState([]);
  const [processingRows, setProcessingRows] = useState(new Set());
  const [selectedModel, setSelectedModel] = useState(null);
  const [availableModels, setAvailableModels] = useState([]);
  const [sourceLanguage, setSourceLanguage] = useState("English");
  const [targetLanguage, setTargetLanguage] = useState("Bahasa Melayu");
  const [batchResults, setBatchResults] = useState({});
  const [editingIndex, setEditingIndex] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [showWarning, setShowWarning] = useState(false);
  const [error, setError] = useState(null);
  const [translationMemory, setTranslationMemory] = useState([]);
  const [similarTranslations, setSimilarTranslations] = useState({});
  const [showSimilarityModal, setShowSimilarityModal] = useState(false);
  const [currentComparisonIndex, setCurrentComparisonIndex] = useState(null);
  const [isLoadingComparison, setIsLoadingComparison] = useState(false);
  const fileInputRef = useRef(null);

  // Load translation memory from GTTFiles and resources folders
  useEffect(() => {
    const loadTranslationMemory = async () => {
      try {
        console.log('Loading translation memory...');
        
        // Try different endpoint paths to handle various server configurations
        const endpoints = [
          '/api/translations/all',
          '/utm-ittbm/api/translations/all',
          '/api/translations/gttfiles',
          '/utm-ittbm/api/translations/gttfiles'
        ];
        
        let combinedData = [];
        let success = false;
        
        // Try each endpoint until one works
        for (const endpoint of endpoints) {
          try {
            console.log(`Trying endpoint: ${endpoint}`);
            const response = await fetch(endpoint);
            
            if (response.ok) {
              const data = await response.json();
              console.log(`Endpoint ${endpoint} succeeded with ${data.length} entries`);
              combinedData = data;
              success = true;
              break;
            } else {
              console.log(`Endpoint ${endpoint} failed with status: ${response.status}`);
            }
          } catch (endpointError) {
            console.error(`Error with endpoint ${endpoint}:`, endpointError);
          }
        }
        
        if (!success) {
          // If all endpoints failed, try a direct file read approach
          console.log('All API endpoints failed, trying direct file access');
          
          // Try to load sample data from a local file
          try {
            // Create some sample data for testing
            const sampleData = [
              { english: "instrument", malay: "alat", domain: "Kahwin" },
              { english: "office copy", malay: "salinan pejabat", domain: "Kahwin" },
              { english: "national language", malay: "bahasa kebangsaan", domain: "Kahwin" },
              { english: "Court", malay: "Mahkamah", domain: "Kahwin" },
              { english: "qualification", malay: "Kelayakan", domain: "Education" },
              { english: "degree", malay: "ijazah", domain: "Education" },
              { english: "fellowship", malay: "fellowships", domain: "Education" },
              { english: "authority to practise", malay: "kuasa untuk mengamalkan", domain: "Education" },
              { english: "testimonial", malay: "testimoni", domain: "Education" }
            ];
            
            combinedData = sampleData;
            success = true;
            console.log('Using sample data for testing:', combinedData.length, 'entries');
          } catch (localError) {
            console.error('Local file access also failed:', localError);
            throw new Error('All methods to load translation memory failed');
          }
        }
        
        if (success) {
          // Set the translation memory
          setTranslationMemory(combinedData);
          console.log('Translation memory loaded:', combinedData.length, 'entries');
        } else {
          throw new Error('Failed to load translation memory from any source');
        }
      } catch (error) {
        console.error('Error loading translation memory:', error);
        setError('Failed to load translation memory. Some features may not work properly.');
      }
    };

    loadTranslationMemory();
  }, []);

  // Function to calculate similarity between two strings - enhanced for better matching
  const calculateSimilarity = (str1, str2) => {
    if (!str1 || !str2) return 0;
    
    // Early exit for identical strings
    if (str1 === str2) return 100;
    
    // Normalize strings: lowercase, remove extra spaces, and normalize special characters
    const normalizeString = (text) => {
      return text
        .toLowerCase()
        .replace(/\s+/g, ' ')
        .trim()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, ''); // Remove diacritics
    };
    
    const s1 = normalizeString(str1);
    const s2 = normalizeString(str2);
    
    // Split into words and remove empty strings
    const words1 = s1.split(/\s+/).filter(Boolean);
    const words2 = s2.split(/\s+/).filter(Boolean);
    
    if (words1.length === 0 || words2.length === 0) return 0;
    
    // For very long texts, use a sampling approach to improve performance
    const MAX_WORDS_TO_PROCESS = 100;
    const shouldSample = words1.length > MAX_WORDS_TO_PROCESS || words2.length > MAX_WORDS_TO_PROCESS;
    
    let processedWords1, processedWords2;
    
    if (shouldSample) {
      // Sample words from the beginning, middle, and end
      const sampleWords = (words, count) => {
        if (words.length <= count) return words;
        
        const third = Math.floor(count / 3);
        const start = words.slice(0, third);
        const middle = words.slice(Math.floor(words.length / 2) - Math.floor(third / 2), 
                                  Math.floor(words.length / 2) + Math.ceil(third / 2));
        const end = words.slice(words.length - third);
        
        return [...start, ...middle, ...end];
      };
      
      processedWords1 = sampleWords(words1, MAX_WORDS_TO_PROCESS);
      processedWords2 = words2; // Keep all words2 for better matching
    } else {
      processedWords1 = words1;
      processedWords2 = words2;
    }
    
    // Create a set of words and word stems from the second text for faster lookup
    const words2Set = new Set(processedWords2);
    const words2Stems = new Set();
    const words2Partial = new Set();
    
    // Create simple stems and partial matches for better matching
    processedWords2.forEach(word => {
      // Skip very short words
      if (word.length < 3) return;
      
      // Add simple stems (remove common endings)
      if (word.length > 4) {
        // Remove common endings for English
        ['s', 'es', 'ed', 'ing', 'ly'].forEach(ending => {
          if (word.endsWith(ending)) {
            const stem = word.slice(0, word.length - ending.length);
            if (stem.length >= 3) {
              words2Stems.add(stem);
            }
          }
        });
        
        // Add word root (first 4-5 chars) for partial matching
        words2Partial.add(word.substring(0, Math.min(5, word.length)));
      }
    });
    
    // Count matching words with enhanced matching
    let matchCount = 0;
    const alreadyMatched = new Set(); // To avoid counting duplicates
    
    for (const word1 of processedWords1) {
      // Skip very short words
      if (word1.length < 3) continue;
      
      // Skip if we've already matched this word
      if (alreadyMatched.has(word1)) continue;
      
      // Exact match
      if (words2Set.has(word1)) {
        matchCount += 1.0; // Full weight for exact match
        alreadyMatched.add(word1);
        continue;
      }
      
      // Check for stem matches
      let stemMatched = false;
      
      // Check if this word's stem exists in the other text
      ['s', 'es', 'ed', 'ing', 'ly'].forEach(ending => {
        if (word1.endsWith(ending)) {
          const stem = word1.slice(0, word1.length - ending.length);
          if (stem.length >= 3 && words2Set.has(stem)) {
            matchCount += 0.9; // Slightly less weight for stem match
            stemMatched = true;
            alreadyMatched.add(word1);
          }
        }
      });
      
      if (stemMatched) continue;
      
      // Check if a stem of this word exists in the other text
      if (word1.length >= 4 && words2Stems.has(word1)) {
        matchCount += 0.9; // Slightly less weight for stem match
        alreadyMatched.add(word1);
        continue;
      }
      
      // Partial matching for longer words
      if (word1.length >= 5) {
        const partial1 = word1.substring(0, Math.min(5, word1.length));
        if (words2Partial.has(partial1)) {
          matchCount += 0.7; // Less weight for partial match
          alreadyMatched.add(word1);
          continue;
        }
      }
    }
    
    // Calculate similarity percentage
    const totalUniqueWords = shouldSample ? 
      MAX_WORDS_TO_PROCESS : 
      new Set([...words1, ...words2]).size;
    
    const similarityScore = totalUniqueWords > 0 ? (matchCount / totalUniqueWords) * 100 : 0;
    
    return Math.min(100, similarityScore); // Cap at 100%
  };

  // Function to find similar translations in the translation memory - enhanced for better matching
  const findSimilarTranslations = (sourceText, translatedText) => {
    console.log('Finding similar translations for:', { 
      sourceText: sourceText?.substring(0, 30), 
      translatedText: translatedText?.substring(0, 30),
      memorySize: translationMemory.length
    });
    
    if (!translationMemory.length) {
      console.log('Translation memory is empty, checking if we need to load it');
      // If translation memory is empty but we have a translated text, try to load it again
      if (translatedText) {
        const loadTranslationMemoryAsync = async () => {
          try {
            // Create some sample data for testing if API fails
            const sampleData = [
              { english: "instrument", malay: "alat", domain: "Kahwin" },
              { english: "office copy", malay: "salinan pejabat", domain: "Kahwin" },
              { english: "national language", malay: "bahasa kebangsaan", domain: "Kahwin" },
              { english: "Court", malay: "Mahkamah", domain: "Kahwin" },
              { english: "qualification", malay: "Kelayakan", domain: "Education" },
              { english: "degree", malay: "ijazah", domain: "Education" },
              { english: "fellowship", malay: "fellowships", domain: "Education" },
              { english: "authority to practise", malay: "kuasa untuk mengamalkan", domain: "Education" },
              { english: "testimonial", malay: "testimoni", domain: "Education" }
            ];
            
            setTranslationMemory(sampleData);
            console.log('Loaded sample translation memory data');
          } catch (error) {
            console.error('Error loading sample translation memory:', error);
          }
        };
        
        loadTranslationMemoryAsync();
      }
      
      console.log('No translation memory or empty text, returning empty array');
      return [];
    }
    
    if (!sourceText || !translatedText) {
      console.log('Source text or translated text is empty');
      return [];
    }
    
    try {
      // Limit the number of entries to process for better performance
      const MAX_ENTRIES_TO_PROCESS = 1000; // Increased from 500 to check more entries
      const entriesToProcess = translationMemory.length > MAX_ENTRIES_TO_PROCESS ? 
        translationMemory.slice(0, MAX_ENTRIES_TO_PROCESS) : 
        translationMemory;
      
      console.log(`Processing ${entriesToProcess.length} out of ${translationMemory.length} entries`);
      
      // Use a more efficient approach for similarity calculation
      const similarEntries = [];
      
      // First pass: quick filter to reduce the number of entries for detailed comparison
      const potentialMatches = entriesToProcess.filter(entry => {
        try {
          if (!entry.english || !entry.malay) return false;
          
          // Get the appropriate source and target text based on language settings
          const entrySourceText = sourceLanguage.toLowerCase() === 'english' ? entry.english : entry.malay;
          const entryTargetText = targetLanguage.toLowerCase().includes('melayu') ? entry.malay : entry.english;
          
          // More lenient length-based check
          const sourceLengthRatio = entrySourceText.length / Math.max(1, sourceText.length);
          const targetLengthRatio = entryTargetText.length / Math.max(1, translatedText.length);
          
          // Check for common words (quick check)
          const hasCommonWords = (text1, text2) => {
            const words1 = text1.toLowerCase().split(/\s+/).filter(w => w.length > 3);
            const words2 = text2.toLowerCase().split(/\s+/).filter(w => w.length > 3);
            
            if (words1.length === 0 || words2.length === 0) return false;
            
            // Check if any word from text1 is in text2
            return words1.some(word => words2.includes(word));
          };
          
          return (sourceLengthRatio >= 0.3 && sourceLengthRatio <= 3) || 
                 (targetLengthRatio >= 0.3 && targetLengthRatio <= 3) ||
                 hasCommonWords(entrySourceText, sourceText) ||
                 hasCommonWords(entryTargetText, translatedText);
        } catch (error) {
          return false;
        }
      });
      
      console.log(`Quick filter reduced entries to ${potentialMatches.length}`);
      
      // Second pass: detailed similarity calculation on the reduced set
      for (const entry of potentialMatches) {
        try {
          // Get the appropriate source and target text based on language settings
          const entrySourceText = sourceLanguage.toLowerCase() === 'english' ? entry.english : entry.malay;
          const entryTargetText = targetLanguage.toLowerCase().includes('melayu') ? entry.malay : entry.english;
          
          // Check if target language matches (this is more important)
          const targetSimilarity = calculateSimilarity(entryTargetText, translatedText);
          
          // Only calculate source similarity if target similarity is promising
          if (targetSimilarity >= 40) { // Lowered threshold from 50 to 40
            // Check if source language matches (less important but still relevant)
            const sourceSimilarity = calculateSimilarity(entrySourceText, sourceText);
            
            // Calculate combined similarity score (weighted more towards target similarity)
            const combinedSimilarity = (targetSimilarity * 0.7) + (sourceSimilarity * 0.3);
            
            // Return entries with similarity above threshold (lowered from 70% to 60%)
            if (combinedSimilarity >= 60) {
              similarEntries.push({
                ...entry,
                similarity: combinedSimilarity,
                targetSimilarity: targetSimilarity,
                sourceSimilarity: sourceSimilarity
              });
            }
          }
        } catch (error) {
          console.error('Error processing entry:', error);
        }
      }
      
      // Sort by similarity (highest first)
      similarEntries.sort((a, b) => b.similarity - a.similarity);
      
      // Limit the number of results to improve modal performance
      const MAX_RESULTS = 10;
      const limitedResults = similarEntries.slice(0, MAX_RESULTS);
      
      console.log('Similar entries found:', limitedResults.length);
      return limitedResults;
    } catch (error) {
      console.error('Error finding similar translations:', error);
      return [];
    }
  };

  // Function to highlight similar words in the text - enhanced for better matching
  const getHighlightedText = (text1, text2) => {
    if (!text1 || !text2) return text1;
    
    // For very long texts, truncate to improve performance
    const maxLength = 500;
    const displayText = text1.length > maxLength ? 
      text1.substring(0, maxLength) + '...' : 
      text1;
    
    // Normalize and prepare text2 for comparison
    const normalizeString = (text) => {
      return text
        .toLowerCase()
        .replace(/\s+/g, ' ')
        .trim()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, ''); // Remove diacritics
    };
    
    const text2Normalized = normalizeString(text2);
    const words2 = text2Normalized.split(/\s+/);
    const words2Set = new Set(words2);
    
    // Create stems and partial matches for text2
    const words2Stems = new Set();
    const words2Partial = new Set();
    
    words2.forEach(word => {
      if (word.length < 3) return;
      
      // Add simple stems (remove common endings)
      if (word.length > 4) {
        ['s', 'es', 'ed', 'ing', 'ly'].forEach(ending => {
          if (word.endsWith(ending)) {
            const stem = word.slice(0, word.length - ending.length);
            if (stem.length >= 3) {
              words2Stems.add(stem);
            }
          }
        });
        
        // Add word root for partial matching
        words2Partial.add(word.substring(0, Math.min(5, word.length)));
      }
    });
    
    // Split text1 into words and process each word
    const words1 = displayText.split(/(\s+|[.,;:!?()])/); // Split by spaces and punctuation
    
    return words1.map(word => {
      // Skip spaces and punctuation
      if (!word.trim() || word.length < 3) return word;
      
      const wordNormalized = normalizeString(word);
      
      // Exact match
      if (words2Set.has(wordNormalized)) {
        return `<span style="font-style: italic; color: red;">${word}</span>`;
      }
      
      // Check for stem matches
      let stemMatched = false;
      
      // Check if this word's stem exists in the other text
      ['s', 'es', 'ed', 'ing', 'ly'].forEach(ending => {
        if (wordNormalized.endsWith(ending)) {
          const stem = wordNormalized.slice(0, wordNormalized.length - ending.length);
          if (stem.length >= 3 && words2Set.has(stem)) {
            stemMatched = true;
          }
        }
      });
      
      if (stemMatched) {
        return `<span style="font-style: italic; color: red;">${word}</span>`;
      }
      
      // Check if a stem of this word exists in the other text
      if (wordNormalized.length >= 4 && words2Stems.has(wordNormalized)) {
        return `<span style="font-style: italic; color: red;">${word}</span>`;
      }
      
      // Partial matching for longer words
      if (wordNormalized.length >= 5) {
        const partial1 = wordNormalized.substring(0, Math.min(5, wordNormalized.length));
        if (words2Partial.has(partial1)) {
          return `<span style="font-style: italic; color: red;">${word}</span>`;
        }
      }
      
      return word;
    }).join('');
  };

  // Function to use memory translation for a specific row
  const applyMemoryTranslation = (rowIndex, memoryTranslation) => {
    setTableData(prevData => {
      const newData = [...prevData];
      if (newData[rowIndex]) {
        newData[rowIndex] = {
          ...newData[rowIndex],
          translatedText: memoryTranslation
        };
      }
      return newData;
    });
    
    // Clear the similar translations for this row
    setSimilarTranslations(prev => {
      const updated = { ...prev };
      delete updated[rowIndex];
      return updated;
    });
  };

  // Function to keep AI translation
  const dismissSimilarTranslation = (rowIndex) => {
    // Just clear the similar translations for this row
    setSimilarTranslations(prev => {
      const updated = { ...prev };
      delete updated[rowIndex];
      return updated;
    });
  };

  useEffect(() => {
    // Load available translation models from API
    const fetchModels = async () => {
      try {
        const models = await loadAIModels();
        const activeModels = models.filter(model => model.isActive);
        setAvailableModels(activeModels);
        if (activeModels.length > 0 && !selectedModel) {
          setSelectedModel(activeModels[0]);
        }
      } catch (error) {
        console.error('Error fetching models:', error);
        setError('Failed to load AI models. Please try again later.');
      }
    };

    fetchModels();
  }, []);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (event) => {
      const binaryString = event.target.result;
      const workbook = XLSX.read(binaryString, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const data = XLSX.utils.sheet_to_json(worksheet);

      // Initialize the data with empty translations
      const initializedData = data.map(row => ({
        sourceText: row.Text || row.text || Object.values(row)[0],
        translatedText: '',
        sourceWordCount: 0,
        translatedWordCount: 0,
        status: 'pending'
      }));

      // Set total records and check if we need to show a warning
      const originalCount = initializedData.length;
      setTotalRecords(originalCount);
      setShowWarning(originalCount > maxRecords);

      // Only take the first maxRecords
      const limitedData = initializedData.slice(0, maxRecords);

      // Clear any previous data and set the new data
      setTableData(limitedData);
      setBatchResults({});
      setProcessingRows(new Set());
      setEditingIndex(null);
      setSimilarTranslations({});
    };

    reader.readAsBinaryString(file);
  };

  const translateWithOpenAI = async (text, apiKey) => {
    try {
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`,
        },
        body: JSON.stringify({
          model: 'gpt-3.5-turbo',
          messages: [
            {
              role: 'system',
              content: `You are a translation assistant. Translate the following text from ${sourceLanguage} to ${targetLanguage}. Provide only the translation, no additional explanations.`
            },
            {
              role: 'user',
              content: text
            }
          ],
          temperature: 0.3
        })
      });

      if (!response.ok) {
        throw new Error(`OpenAI API error: ${response.status}`);
      }

      const data = await response.json();
      return data.choices[0].message.content.trim();
    } catch (error) {
      throw new Error(`OpenAI translation failed: ${error.message}`);
    }
  };

  const translateWithGroq = async (text, apiKey) => {
    try {
      const response = await fetch('https://api.groq.com/openai/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`,
        },
        body: JSON.stringify({
          model: selectedModel.modelId || 'mistral-saba-24b',
          messages: [
            {
              role: 'system',
              content: `You are a translation assistant. Translate the following text from ${sourceLanguage} to ${targetLanguage}. Provide only the translation, no additional explanations.`
            },
            {
              role: 'user',
              content: text
            }
          ],
          temperature: 0.3
        })
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(`Groq API error: ${response.status} - ${errorData.error?.message || 'Unknown error'}`);
      }

      const data = await response.json();
      return data.choices[0].message.content.trim();
    } catch (error) {
      throw new Error(`Groq translation failed: ${error.message}`);
    }
  };

  const translateWithOllama = async (text, endpoint = 'http://localhost:11434') => {
    try {
      console.log('Ollama Request:', {
        endpoint,
        model: selectedModel.modelId,
        text: text.substring(0, 100)
      });

      const response = await fetch(`${endpoint}/api/generate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          model: selectedModel.modelId,
          prompt: `You are a translation assistant. Your task is to translate the following text from ${sourceLanguage} to ${targetLanguage}. Only output the translation, nothing else.\n\nText to translate:\n${text}\n\nTranslation:`,
          stream: false,
          options: {
            temperature: 0.1
          }
        })
      });

      if (!response.ok) {
        throw new Error(`Ollama API error: ${response.status}`);
      }

      const data = await response.json();
      console.log('Ollama Response:', data);

      let cleanedResponse = data.response
        .replace(/<think>[\s\S]*?<\/think>/g, '')
        .replace(/Translation:/i, '')
        .trim();

      if (!cleanedResponse) {
        cleanedResponse = data.response.trim();
      }

      return cleanedResponse;
    } catch (error) {
      console.error('Ollama error:', error);
      throw new Error(`Ollama translation failed: ${error.message}`);
    }
  };

  const translateWithHuggingFace = async (text, apiKey, modelId, endpoint) => {
    try {
      const apiUrl = endpoint || `https://api-inference.huggingface.co/models/${modelId}`;
      
      console.log('HF Request:', {
        url: apiUrl,
        modelId,
        hasApiKey: !!apiKey,
        text: text.substring(0, 100) // Log first 100 chars only
      });

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`
        },
        body: JSON.stringify({
          inputs: text,
          options: {
            wait_for_model: true
          }
        })
      });

      if (!response.ok) {
        throw new Error(`HuggingFace API error: ${response.status}`);
      }

      const data = await response.json();
      
      // Handle different response formats
      if (Array.isArray(data) && data.length > 0) {
        if (data[0].translation_text) {
          return data[0].translation_text;
        } else if (data[0].generated_text) {
          return data[0].generated_text;
        }
      }
      
      // Fallback to raw response
      return typeof data === 'string' ? data : JSON.stringify(data);
    } catch (error) {
      throw new Error(`HuggingFace translation failed: ${error.message}`);
    }
  };

  const translateText = async (text, index) => {
    if (!selectedModel) {
      console.error('No model selected');
      return;
    }

    setProcessingRows(prev => new Set(prev).add(index));

    try {
      let translatedText = '';

      switch (selectedModel.type) {
        case 'ollama':
          translatedText = await translateWithOllama(text);
          break;
        case 'openai':
          translatedText = await translateWithOpenAI(text, selectedModel.apiKey);
          break;
        case 'groq':
          translatedText = await translateWithGroq(text, selectedModel.apiKey);
          break;
        case 'huggingface':
          translatedText = await translateWithHuggingFace(
            text,
            selectedModel.apiKey,
            selectedModel.modelId,
            selectedModel.endpoint
          );
          break;
        default:
          throw new Error('Unsupported model type');
      }

      console.log('Translation completed:', translatedText?.substring(0, 30));
      
      // Find similar translations in the translation memory
      const similar = findSimilarTranslations(text, translatedText);
      console.log('Similar translations found:', similar.length);
      
      // Update similar translations state
      setSimilarTranslations(prev => {
        const updated = {
          ...prev,
          [index]: similar
        };
        console.log('Updated similarTranslations state:', updated);
        return updated;
      });

      setTableData(prevData => {
        const newData = [...prevData];
        newData[index] = {
          ...newData[index],
          translatedText,
          status: 'completed',
          sourceWordCount: text.split(/\s+/).length,
          translatedWordCount: translatedText.split(/\s+/).length
        };
        return newData;
      });

      setBatchResults(prev => ({
        ...prev,
        [index]: { success: true }
      }));
    } catch (error) {
      console.error('Translation error:', error);
      setBatchResults(prev => ({
        ...prev,
        [index]: { success: false, error: error.message }
      }));
      setTableData(prevData => {
        const newData = [...prevData];
        newData[index] = {
          ...newData[index],
          status: 'error'
        };
        return newData;
      });
    } finally {
      setProcessingRows(prev => {
        const newSet = new Set(prev);
        newSet.delete(index);
        return newSet;
      });
    }
  };

  const exportToExcel = () => {
    const exportData = tableData.map(row => ({
      'Source Text': row.sourceText,
      'Translated Text': row.translatedText,
      'Source Word Count': row.sourceWordCount,
      'Translated Word Count': row.translatedWordCount,
      'Status': row.status
    }));

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Translations');
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(data, 'batch_translations.xlsx');
  };

  const startNewSession = () => {
    setTableData([]);
    setBatchResults({});
    setProcessingRows(new Set());
    setEditingIndex(null);
    setTotalRecords(0);
    setShowWarning(false);
    setSimilarTranslations({});
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  // Function to manually check for similar translations for a specific row
  const checkSimilarTranslations = (index) => {
    const row = tableData[index];
    if (!row || !row.translatedText) {
      console.log('No translated text to check for row:', index);
      return;
    }
    
    console.log('Manually checking for similar translations for row:', index);
    
    // Show loading state
    setIsLoadingComparison(true);
    
    // Use setTimeout to prevent UI freezing
    setTimeout(() => {
      try {
        const similar = findSimilarTranslations(row.sourceText, row.translatedText);
        
        // First clear the loading state
        setIsLoadingComparison(false);
        
        if (similar.length > 0) {
          console.log('Found similar translations:', similar.length);
          setSimilarTranslations(prev => ({
            ...prev,
            [index]: similar
          }));
          setCurrentComparisonIndex(index);
          setShowSimilarityModal(true);
        } else {
          console.log('No similar translations found');
          // Show alert after loading state is cleared
          setTimeout(() => {
            alert('No similar translations found in the translation memory.');
          }, 100);
        }
      } catch (error) {
        // Clear loading state first
        setIsLoadingComparison(false);
        
        console.error('Error checking similar translations:', error);
        // Show error alert after a small delay
        setTimeout(() => {
          alert('Error checking similar translations. Please try again.');
        }, 100);
      }
    }, 0);
  };

  // Modify the monitor similarTranslations useEffect to remove auto-showing modal
  useEffect(() => {
    // Check if we have any similar translations
    const hasSimilarTranslations = Object.values(similarTranslations).some(
      translations => translations && translations.length > 0
    );
    
    console.log('Monitoring similarTranslations:', {
      hasSimilarTranslations,
      similarTranslationsCount: Object.keys(similarTranslations).length
    });
  }, [similarTranslations]);

  // Remove the debug panel in production
  const showDebugPanel = false; // Set to false in production

  return (
    <div className="min-h-screen bg-white">
      <PlaygroundSimpleHeader keyPrefix="playgroundPage" />
      <PlaygroundNavigation />
      
      <div className="container mx-auto px-4 py-8">
        {showWarning && (
          <div className="bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 rounded relative mb-4" role="alert">
            <span className="block sm:inline">Warning: Your Excel file contains {totalRecords} records. Only the first {maxRecords} records have been loaded.</span>
          </div>
        )}
        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
            <span className="block sm:inline">{error}</span>
            <button 
              onClick={() => {
                // Create some sample data for testing
                const sampleData = [
                  { english: "instrument", malay: "alat", domain: "Kahwin" },
                  { english: "office copy", malay: "salinan pejabat", domain: "Kahwin" },
                  { english: "national language", malay: "bahasa kebangsaan", domain: "Kahwin" },
                  { english: "Court", malay: "Mahkamah", domain: "Kahwin" },
                  { english: "qualification", malay: "Kelayakan", domain: "Education" },
                  { english: "degree", malay: "ijazah", domain: "Education" },
                  { english: "fellowship", malay: "fellowships", domain: "Education" },
                  { english: "authority to practise", malay: "kuasa untuk mengamalkan", domain: "Education" },
                  { english: "testimonial", malay: "testimoni", domain: "Education" }
                ];
                
                setTranslationMemory(sampleData);
                setError(null);
                console.log('Loaded sample translation memory data');
              }}
              className="ml-2 bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
            >
              Use Sample Data
            </button>
          </div>
        )}

        {/* Debug panel removed for production */}

        <Card className="mb-6">
          <CardContent className="p-6">
            <div className="flex flex-col gap-4">
              <div className="space-y-4 py-4">
                <h2 className="text-2xl font-bold">Batch Translation</h2>
                <p className="text-gray-500">
                  Upload an Excel file with text to translate. The file should have a column named "Text" or the first column will be used.
                </p>
                <div className="grid grid-cols-1 md:grid-cols-4 gap-6 items-end">
                  <div className="flex flex-col space-y-2">
                    <label htmlFor="model-select" className="text-sm font-medium">AI Translation Model</label>
                    <Select
                      value={selectedModel?.id || ''}
                      onValueChange={(value) => {
                        const model = availableModels.find(m => m.id === value);
                        setSelectedModel(model);
                      }}
                    >
                      <SelectTrigger id="model-select" className="w-full bg-white border-gray-300">
                        <SelectValue placeholder="Select Model" />
                      </SelectTrigger>
                      <SelectContent className="bg-white">
                        {availableModels.map((model) => (
                          <SelectItem 
                            key={model.id} 
                            value={model.id}
                            className="hover:bg-gray-200 focus:bg-gray-200 cursor-pointer py-2"
                          >
                            {model.name} 
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                  <div className="flex flex-col space-y-2">
                    <label htmlFor="file-upload" className="text-sm font-medium">Upload Excel File</label>
                    <Input
                      id="file-upload"
                      type="file"
                      accept=".xlsx,.xls"
                      onChange={handleFileUpload}
                      ref={fileInputRef}
                      className="w-full"
                    />
                  </div>
                  <div className="flex items-end">
                    <Button
                      onClick={exportToExcel}
                      disabled={tableData.length === 0}
                      variant="outline"
                      className="w-full"
                    >
                      Export to Excel
                    </Button>
                  </div>
                  <div className="flex items-end">
                    <Button
                      onClick={startNewSession}
                      disabled={tableData.length === 0}
                      variant="destructive"
                      className="w-full"
                    >
                      New Session
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>

        <div className="overflow-x-auto mt-6">
          <Table className="w-full border-collapse table-fixed">
            <TableHeader>
              <TableRow>
                <TableHead className="w-[45%]">Source Text</TableHead>
                <TableHead className="w-[10%] text-center">Actions</TableHead>
                <TableHead className="w-[45%]">Translated Text</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {tableData.map((row, index) => (
                <TableRow key={index} className="border-b hover:bg-white-50">
                  <TableCell className="align-top py-4 px-4">
                    <div className="max-h-[200px] overflow-y-auto whitespace-pre-wrap break-words p-2 bg-white-50 rounded">
                      {row.sourceText}
                    </div>
                  </TableCell>
                  <TableCell className="align-middle text-center py-4 px-2">
                    <div className="flex flex-col gap-2">
                      <Button
                        onClick={() => translateText(row.sourceText, index)}
                        disabled={processingRows.has(index) || !selectedModel}
                        size="sm"
                        className="w-full"
                      >
                        {processingRows.has(index) ? 'Processing...' : 'Translate'}
                      </Button>
                      
                      {row.translatedText && (
                        <Button
                          onClick={() => checkSimilarTranslations(index)}
                          disabled={isLoadingComparison}
                          variant="outline"
                          size="sm"
                          className="w-full"
                        >
                          {isLoadingComparison ? 'Checking...' : 'Check Similar'}
                        </Button>
                      )}
                    </div>
                  </TableCell>
                  <TableCell className="align-top py-4 px-4">
                    {editingIndex === index ? (
                      <textarea
                        value={row.translatedText}
                        onChange={(e) => {
                          const newData = [...tableData];
                          newData[index].translatedText = e.target.value;
                          setTableData(newData);
                        }}
                        onBlur={() => setEditingIndex(null)}
                        autoFocus
                        className="w-full min-h-[200px] p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                      />
                    ) : (
                      <div className="flex flex-col gap-3">
                        <div
                          className="cursor-pointer hover:bg-gray-100 p-3 rounded max-h-[200px] overflow-y-auto whitespace-pre-wrap break-words bg-white-50"
                          onClick={() => setEditingIndex(index)}
                        >
                          {row.translatedText || (row.status === 'error' ? 'Error translating' : '')}
                        </div>
                        
                        {/* Similar translations section - displayed directly under the translated text */}
                        {similarTranslations[index]?.length > 0 && (
                          <div className="border rounded-lg p-3 bg-blue-50">
                            <div className="flex justify-between items-center mb-2">
                              <h3 className="font-medium text-blue-800">
                                Similar Translation Found ({similarTranslations[index].length})
                              </h3>
                              <div className="text-sm text-blue-600">
                                Similarity: {similarTranslations[index][0].similarity.toFixed(1)}%
                              </div>
                            </div>
                            
                            {/* Display the first similar translation */}
                            {similarTranslations[index][0] && (
                              <div className="mb-3">
                                <div className="text-sm text-gray-500 mb-1">Memory Translation:</div>
                                <div 
                                  className="p-2 bg-white rounded border border-blue-200 max-h-[150px] overflow-y-auto whitespace-pre-wrap break-words"
                                  dangerouslySetInnerHTML={{ 
                                    __html: getHighlightedText(
                                      targetLanguage.toLowerCase().includes('melayu') 
                                        ? similarTranslations[index][0].malay 
                                        : similarTranslations[index][0].english,
                                      row.translatedText
                                    )
                                  }}
                                />
                              </div>
                            )}
                            
                            <div className="flex justify-end space-x-2 mt-2">
                              <Button
                                onClick={() => dismissSimilarTranslation(index)}
                                variant="outline"
                                size="sm"
                              >
                                Keep AI Translation
                              </Button>
                              <Button
                                onClick={() => applyMemoryTranslation(
                                  index,
                                  targetLanguage.toLowerCase().includes('melayu') 
                                    ? similarTranslations[index][0].malay 
                                    : similarTranslations[index][0].english
                                )}
                                variant="default"
                                size="sm"
                              >
                                Use Memory Translation
                              </Button>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
      
      {/* Loading overlay for when comparison is being calculated */}
      {isLoadingComparison && (
        <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-40">
          <div className="bg-white rounded-lg p-6 shadow-lg">
            <div className="flex items-center space-x-4">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
              <p>Finding similar translations...</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BatchTranslationPlayground;
