/** @format */

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../../components/ui/breadcrumb";
import { Button } from "../../components/ui/button";
import { Card } from "../../components/ui/card";
import { Input } from "../../components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import { Upload, RefreshCw, Download, Loader2 } from "lucide-react";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { saveAs } from 'file-saver';
import * as pdfjsLib from 'pdfjs-dist/webpack';
import { Document, Packer, Paragraph, TextRun } from 'docx';
import mammoth from 'mammoth';
import { loadAIModels } from "../../utils/aiModelUtils";

const FileTranslate = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "fileTranslate",
  });
  const [files, setFiles] = useState([]);
  const [sourceLanguage, setSourceLanguage] = useState("English");
  const [targetLanguage, setTargetLanguage] = useState("Bahasa Melayu");
  const [projectName, setProjectName] = useState("");
  const [selectedModel, setSelectedModel] = useState(null);
  const [availableModels, setAvailableModels] = useState([]);
  const [isTranslating, setIsTranslating] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchModels = async () => {
      try {
        const models = await loadAIModels();
        const activeModels = models.filter(model => model.isActive);
        setAvailableModels(activeModels);
        if (activeModels.length > 0) {
          setSelectedModel(activeModels[0]);
        }
      } catch (error) {
        console.error('Error fetching models:', error);
        setError('Failed to load AI models. Please try again later.');
      }
    };

    fetchModels();
  }, []);

  const extractTextFromPDF = async (file) => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const loadingTask = pdfjsLib.getDocument({ data: arrayBuffer });
      const pdf = await loadingTask.promise;
      let fullText = '';
      
      for (let i = 1; i <= pdf.numPages; i++) {
        const page = await pdf.getPage(i);
        const textContent = await page.getTextContent();
        const pageText = textContent.items.map(item => item.str).join(' ');
        fullText += pageText + '\n';
      }
      
      return fullText.trim();
    } catch (error) {
      throw new Error(`Failed to extract text from PDF: ${error.message}`);
    }
  };

  const extractTextFromDOCX = async (file) => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const result = await mammoth.extractRawText({ arrayBuffer });
      return result.value.trim();
    } catch (error) {
      throw new Error(`Failed to extract text from DOCX: ${error.message}`);
    }
  };

  const handleFileUpload = (event) => {
    const uploadedFiles = Array.from(event.target.files);
    
    const validFiles = uploadedFiles.filter(file => {
      const isPDF = file.type === 'application/pdf';
      const isDOCX = file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      if (!isPDF && !isDOCX) {
        console.warn(`${file.name} is not a PDF or DOCX file and was skipped`);
        return false;
      }
      return true;
    });

    setFiles((prev) => [...prev, ...validFiles.map(file => ({
      file,
      id: Date.now() + Math.random(),
      status: 'pending',
      translatedContent: null
    }))]);
  };

  const handleTranslation = async () => {
    if (!selectedModel) {
      alert("Please select a translation model in the Model Settings page first.");
      return;
    }

    if (!files.length) {
      alert("Please upload a file to translate.");
      return;
    }

    setIsTranslating(true);
    
    try {
      for (const fileObj of files) {
        if (fileObj.status === 'completed') continue;

        try {
          // Update status to processing
          setFiles(prev => prev.map(f => 
            f.id === fileObj.id ? { ...f, status: 'processing' } : f
          ));

          // Extract text based on file type
          let extractedText;
          if (fileObj.file.type === 'application/pdf') {
            extractedText = await extractTextFromPDF(fileObj.file);
          } else {
            extractedText = await extractTextFromDOCX(fileObj.file);
          }
          
          if (!extractedText.trim()) {
            throw new Error(`No text could be extracted from the ${fileObj.file.type === 'application/pdf' ? 'PDF' : 'DOCX'}`);
          }

          // Validate model configuration
          if (!selectedModel.apiKey && selectedModel.type !== 'ollama') {
            throw new Error(`Please add an API key for the ${selectedModel.name} model in Model Settings.`);
          }

          // Translate the extracted text
          let translatedContent;
          switch (selectedModel.type) {
            case 'openai':
              translatedContent = await translateWithOpenAI(extractedText, selectedModel.apiKey);
              break;
            case 'groq':
              translatedContent = await translateWithGroq(extractedText, selectedModel.apiKey);
              break;
            case 'ollama':
              translatedContent = await translateWithOllama(
                extractedText,
                selectedModel.endpoint
              );
              break;
            case 'huggingface':
              translatedContent = await translateWithHuggingFace(
                extractedText, 
                selectedModel.apiKey, 
                selectedModel.modelId, 
                selectedModel.endpoint
              );
              break;
            default:
              throw new Error('Unsupported model type');
          }

          // Count words
          const sourceWordCount = extractedText.trim().split(/\s+/).length;
          const translatedWordCount = translatedContent.trim().split(/\s+/).length;

          // Update file status with translated content
          setFiles(prev => prev.map(f => 
            f.id === fileObj.id ? { 
              ...f, 
              status: 'completed',
              translatedContent
            } : f
          ));

          // Save file translation to localStorage for statistics
          const fileTranslations = JSON.parse(localStorage.getItem('fileTranslations') || '[]');
          fileTranslations.push({
            fileName: fileObj.file.name,
            fileType: fileObj.file.type,
            timestamp: new Date().toISOString(),
            sourceLanguage,
            targetLanguage,
            modelType: selectedModel.type,
            status: 'completed',
            sourceText: extractedText,
            translatedContent,
            sourceWordCount,
            translatedWordCount
          });
          localStorage.setItem('fileTranslations', JSON.stringify(fileTranslations));

        } catch (error) {
          console.error('Translation error for file:', fileObj.file.name, error);
          setFiles(prev => prev.map(f => 
            f.id === fileObj.id ? { ...f, status: 'failed', error: error.message } : f
          ));
        }
      }
    } finally {
      setIsTranslating(false);
    }
  };

  const handleDownload = async (fileObj) => {
    try {
      // Create a new Word document
      const doc = new Document({
        sections: [{
          properties: {},
          children: fileObj.translatedContent.split('\n').map(line => 
            new Paragraph({
              children: [
                new TextRun({
                  text: line,
                  size: 24, // 12pt font
                }),
              ],
            })
          ),
        }],
      });

      // Generate the DOCX file
      const blob = await Packer.toBlob(doc);
      
      // Generate filename
      const filename = fileObj.file.name.replace('.pdf', '') + '_translated.docx';
      
      // Download file
      saveAs(blob, filename);
    } catch (error) {
      console.error('Download error:', error);
      alert('Failed to download the translated file');
    }
  };

  // Translation service functions
  const translateWithOpenAI = async (text, apiKey) => {
    try {
      // Split text into chunks of approximately 4000 characters (tokens are roughly 4 chars)
      // to stay well under OpenAI's token limits
      const MAX_CHUNK_SIZE = 4000;
      const textChunks = [];
      
      // Split by paragraphs first to maintain context
      const paragraphs = text.split(/\n+/);
      let currentChunk = '';
      
      for (const paragraph of paragraphs) {
        // If adding this paragraph would exceed the chunk size, save current chunk and start a new one
        if (currentChunk.length + paragraph.length > MAX_CHUNK_SIZE && currentChunk.length > 0) {
          textChunks.push(currentChunk);
          currentChunk = paragraph;
        } else {
          currentChunk += (currentChunk ? '\n' : '') + paragraph;
        }
      }
      
      // Add the last chunk if it's not empty
      if (currentChunk) {
        textChunks.push(currentChunk);
      }
      
      // Translate each chunk
      const translatedChunks = [];
      for (const chunk of textChunks) {
        const response = await fetch('https://api.openai.com/v1/chat/completions', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${apiKey}`,
          },
          body: JSON.stringify({
            model: 'gpt-3.5-turbo',
            messages: [
              {
                role: 'system',
                content: `You are a translation assistant. Translate the following text from ${sourceLanguage} to ${targetLanguage}. Provide only the translation, no additional explanations.`
              },
              {
                role: 'user',
                content: chunk
              }
            ],
            temperature: 0.3
          })
        });

        if (!response.ok) {
          throw new Error(`OpenAI API error: ${response.status}`);
        }

        const data = await response.json();
        translatedChunks.push(data.choices[0].message.content.trim());
      }
      
      // Combine all translated chunks
      return translatedChunks.join('\n');
    } catch (error) {
      throw new Error(`OpenAI translation failed: ${error.message}`);
    }
  };

  const translateWithGroq = async (text, apiKey) => {
    try {
      // Split text into chunks of approximately 8000 characters
      // Mixtral has a larger context window than GPT-3.5
      const MAX_CHUNK_SIZE = 8000;
      const textChunks = [];
      
      // Split by paragraphs first to maintain context
      const paragraphs = text.split(/\n+/);
      let currentChunk = '';
      
      for (const paragraph of paragraphs) {
        // If adding this paragraph would exceed the chunk size, save current chunk and start a new one
        if (currentChunk.length + paragraph.length > MAX_CHUNK_SIZE && currentChunk.length > 0) {
          textChunks.push(currentChunk);
          currentChunk = paragraph;
        } else {
          currentChunk += (currentChunk ? '\n' : '') + paragraph;
        }
      }
      
      // Add the last chunk if it's not empty
      if (currentChunk) {
        textChunks.push(currentChunk);
      }
      
      // Translate each chunk
      const translatedChunks = [];
      for (const chunk of textChunks) {
        const response = await fetch('https://api.groq.com/openai/v1/chat/completions', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${apiKey}`,
          },
          body: JSON.stringify({
            model: selectedModel.modelId || 'mistral-saba-24b',
            messages: [
              {
                role: 'system',
                content: `You are a translation assistant. Translate the following text from ${sourceLanguage} to ${targetLanguage}. Provide only the translation, no additional explanations.`
              },
              {
                role: 'user',
                content: chunk
              }
            ],
            temperature: 0.3
          })
        });

        if (!response.ok) {
          const errorData = await response.json().catch(() => ({}));
          throw new Error(`Groq API error: ${response.status} - ${errorData.error?.message || 'Unknown error'}`);
        }

        const data = await response.json();
        translatedChunks.push(data.choices[0].message.content.trim());
      }
      
      // Combine all translated chunks
      return translatedChunks.join('\n');
    } catch (error) {
      throw new Error(`Groq translation failed: ${error.message}`);
    }
  };

  const translateWithOllama = async (text, endpoint = 'http://localhost:11434') => {
    try {
      // Split text into chunks of approximately 4000 characters
      const MAX_CHUNK_SIZE = 4000;
      const textChunks = [];
      
      // Split by paragraphs first to maintain context
      const paragraphs = text.split(/\n+/);
      let currentChunk = '';
      
      for (const paragraph of paragraphs) {
        // If adding this paragraph would exceed the chunk size, save current chunk and start a new one
        if (currentChunk.length + paragraph.length > MAX_CHUNK_SIZE && currentChunk.length > 0) {
          textChunks.push(currentChunk);
          currentChunk = paragraph;
        } else {
          currentChunk += (currentChunk ? '\n' : '') + paragraph;
        }
      }
      
      // Add the last chunk if it's not empty
      if (currentChunk) {
        textChunks.push(currentChunk);
      }
      
      // Translate each chunk
      const translatedChunks = [];
      for (const chunk of textChunks) {
        const response = await fetch(`${endpoint}/api/generate`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            model: selectedModel.modelId,
            prompt: `Translate the following text from ${sourceLanguage} to ${targetLanguage}. Only provide the translation, no explanations:\n\n${chunk}`,
            stream: false,
            options: {
              temperature: 0.3
            }
          })
        });

        if (!response.ok) {
          throw new Error(`Ollama API error: ${response.status}`);
        }

        const data = await response.json();
        translatedChunks.push(data.response.trim());
      }
      
      // Combine all translated chunks
      return translatedChunks.join('\n');
    } catch (error) {
      throw new Error(`Ollama translation failed: ${error.message}`);
    }
  };

  const translateWithHuggingFace = async (text, apiKey, modelId, endpoint) => {
    try {
      // Use the provided endpoint or construct one from modelId
      const apiUrl = endpoint || `https://api-inference.huggingface.co/models/${modelId}`;
      
      // Split text into chunks of approximately 2000 characters
      // HuggingFace models often have smaller context windows
      const MAX_CHUNK_SIZE = 4000;
      const textChunks = [];
      
      // Split by paragraphs first to maintain context
      const paragraphs = text.split(/\n+/);
      let currentChunk = '';
      
      for (const paragraph of paragraphs) {
        // If adding this paragraph would exceed the chunk size, save current chunk and start a new one
        if (currentChunk.length + paragraph.length > MAX_CHUNK_SIZE && currentChunk.length > 0) {
          textChunks.push(currentChunk);
          currentChunk = paragraph;
        } else {
          currentChunk += (currentChunk ? '\n' : '') + paragraph;
        }
      }
      
      // Add the last chunk if it's not empty
      if (currentChunk) {
        textChunks.push(currentChunk);
      }
      
      // Translate each chunk
      const translatedChunks = [];
      for (const chunk of textChunks) {
        // Prepare translation prompt
        const prompt = `Translate from ${sourceLanguage} to ${targetLanguage}:\n${chunk}`;
        
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${apiKey}`
          },
          body: JSON.stringify({
            inputs: prompt,
            wait_for_model: true
          })
        });

        if (!response.ok) {
          if (response.status === 401) {
            throw new Error('Invalid API key. Please check your Hugging Face API key in the model settings.');
          }
          const errorData = await response.json().catch(() => ({}));
          throw new Error(`API error: ${response.status} - ${errorData.error || 'Unknown error'}`);
        }

        const data = await response.json();
        
        // Handle different response formats
        let translatedText;
        if (Array.isArray(data)) {
          // Translation model response
          if (data[0]?.translation_text) {
            translatedText = data[0].translation_text;
          }
          // Text generation model response
          else if (data[0]?.generated_text) {
            translatedText = data[0].generated_text;
          }
          // Raw text response
          else {
            translatedText = data[0];
          }
        } else {
          // Single object response
          if (data.translation_text) {
            translatedText = data.translation_text;
          }
          else if (data.generated_text) {
            translatedText = data.generated_text;
          }
          // Raw response
          else {
            translatedText = data;
          }
        }
        
        translatedChunks.push(translatedText);
      }
      
      // Combine all translated chunks
      return translatedChunks.join('\n');
    } catch (error) {
      console.error('Hugging Face translation error:', error);
      throw new Error(`Translation failed: ${error.message}`);
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      <br />
      <Breadcrumb className="pl-6 pt-6">
        <span className="text-xl font-semibold">{t("title")}</span>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink>{t("breadcrumb1")}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>{t("breadcrumb2")}</BreadcrumbPage>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>{t("breadcrumb3")}</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <div className="container h-full min-w-full mx-auto p-8">
        {/* Model Selection Card */}
        <Card className="p-6 shadow-md mb-6">
          <h2 className="text-lg font-semibold mb-4">Translation Model</h2>
          <div className="flex items-center gap-2">
            <select
              className="flex-1 border border-gray-300 bg-white rounded-md shadow-sm p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={selectedModel?.id || ""}
              onChange={(e) => {
                const model = availableModels.find(m => m.id.toString() === e.target.value);
                setSelectedModel(model);
              }}
            >
              <option value="">Select Translation Model</option>
              {availableModels.map((model) => (
                <option key={model.id} value={model.id}>
                  {model.name} 
                </option>
              ))}
            </select>
            <Button
              variant="outline"
              size="icon"
              onClick={() => {
                const fetchModels = async () => {
                  try {
                    const models = await loadAIModels();
                    const activeModels = models.filter(model => model.isActive);
                    setAvailableModels(activeModels);
                    if (activeModels.length > 0 && !selectedModel) {
                      setSelectedModel(activeModels[0]);
                    }
                  } catch (error) {
                    console.error('Error fetching models:', error);
                    setError('Failed to load AI models. Please try again later.');
                  }
                };

                fetchModels();
              }}
              className="flex items-center justify-center"
            >
              <RefreshCw className="h-4 w-4" />
            </Button>
          </div>
        </Card>

        {/* File Upload Card */}
        <Card className="p-6 shadow-md">
          <div className="flex flex-col items-center gap-4">
            <label className="text-xl font-semibold text-gray-700 flex items-center gap-2">
              <Upload className="h-6 w-6" /> {t("label1")}
              <span className="text-blue-500 underline cursor-pointer">
                {t("label2")}
              </span>
            </label>
            <p className="text-gray-500">
              Accepted formats: PDF and DOCX files
            </p>
            <input
              type="file"
              multiple
              accept=".pdf,application/pdf,.docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              onChange={handleFileUpload}
              className="hidden"
              id="file-upload"
            />
            <label 
              htmlFor="file-upload"
              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 cursor-pointer flex items-center gap-2"
            >
              <Upload className="h-4 w-4" />
              Upload PDF and DOCX Files
            </label>
          </div>

          {files.length > 0 && (
            <div className="mt-6">
              <h3 className="text-lg font-semibold mb-2">Selected Files:</h3>
              <div className="space-y-2">
                {files.map((fileObj) => (
                  <div key={fileObj.id} className="flex items-center justify-between bg-gray-50 p-2 rounded">
                    <div className="flex items-center gap-2">
                      <svg className="h-6 w-6 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
                      </svg>
                      <span className="text-sm">{fileObj.file.name}</span>
                      {fileObj.status === 'failed' && (
                        <span className="text-xs text-red-500">
                          Error: {fileObj.error}
                        </span>
                      )}
                    </div>
                    <button 
                      onClick={() => setFiles(files.filter(f => f.id !== fileObj.id))}
                      className="text-red-500 hover:text-red-700"
                    >
                      Remove
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                {t("label3")}
              </label>
              <select
                className="w-full border border-gray-300 bg-white rounded-md shadow-sm p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={sourceLanguage}
                onChange={(e) => setSourceLanguage(e.target.value)}
              >
                <option value="English">English</option>
                <option value="Bahasa Melayu">Bahasa Melayu</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                {t("label4")}
              </label>
              <select
                className="w-full border border-gray-300 bg-white rounded-md shadow-sm p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={targetLanguage}
                onChange={(e) => setTargetLanguage(e.target.value)}
              >
                <option value="Bahasa Melayu">Bahasa Melayu</option>
                <option value="English">English</option>
              </select>
            </div>
          </div>

          <div className="mt-6">
            <Button 
              className="w-full"
              disabled={!selectedModel || files.length === 0 || isTranslating}
              onClick={handleTranslation}
            >
              {isTranslating ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Translating...
                </>
              ) : (
                'Start Translation'
              )}
            </Button>
          </div>
        </Card>

        {/* Translation Status Table */}
        <Card className="mt-8 p-6 shadow-md">
          <h2 className="text-lg font-semibold mb-4">Translation Status</h2>
          <div className="overflow-x-auto">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableCell>File Name</TableCell>
                  <TableCell>Source</TableCell>
                  <TableCell>Target</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {files.map((fileObj) => (
                  <TableRow key={fileObj.id}>
                    <TableCell>{fileObj.file.name}</TableCell>
                    <TableCell>{sourceLanguage}</TableCell>
                    <TableCell>{targetLanguage}</TableCell>
                    <TableCell>
                      <span className={`
                        px-2 py-1 rounded-full text-xs font-medium
                        ${fileObj.status === 'completed' ? 'bg-green-100 text-green-800' : ''}
                        ${fileObj.status === 'processing' ? 'bg-yellow-100 text-yellow-800' : ''}
                        ${fileObj.status === 'failed' ? 'bg-red-100 text-red-800' : ''}
                        ${fileObj.status === 'pending' ? 'bg-gray-100 text-gray-800' : ''}
                      `}>
                        {fileObj.status.charAt(0).toUpperCase() + fileObj.status.slice(1)}
                      </span>
                    </TableCell>
                    <TableCell>
                      {fileObj.status === 'completed' && (
                        <Button
                          variant="outline"
                          size="sm"
                          className="flex items-center gap-2"
                          onClick={() => handleDownload(fileObj)}
                        >
                          <Download className="h-4 w-4" />
                          Download DOCX
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default FileTranslate;
