/** @format */

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../../components/ui/breadcrumb";
import { Button } from "../../components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import { Link } from 'react-router-dom';
import { toast } from "../../components/ui/use-toast";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../components/ui/alert-dialog";
import { 
  Plus, 
  Upload, 
  X, 
  FileJson, 
  ArrowLeft, 
  FileSpreadsheet,
  Loader2,
  RefreshCw
} from 'lucide-react';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs";

const TermBaseDomain = () => {
  const [domains, setDomains] = useState(() => {
    const saved = localStorage.getItem('termBaseDomains');
    return saved ? JSON.parse(saved) : [];
  });
  const [resourceFiles, setResourceFiles] = useState([]);
  const [newDomain, setNewDomain] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [fileType, setFileType] = useState('json');
  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  useEffect(() => {
    localStorage.setItem('termBaseDomains', JSON.stringify(domains));
  }, [domains]);

  useEffect(() => {
    // Fetch resource files when component mounts
    fetchResourceFiles();
  }, []);

  const fetchResourceFiles = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get('/utm-ittbm/api/resources/files');
      setResourceFiles(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching resource files:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to load resource files",
      });
      setIsLoading(false);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    handleFile(file);
  };

  const handleFile = (file) => {
    if (file) {
      if ((fileType === 'json' && file.type === 'application/json') || 
          (fileType === 'csv' && (file.type === 'text/csv' || file.name.endsWith('.csv')))) {
        setSelectedFile(file);
        toast({
          title: "File selected",
          description: `${file.name} has been selected`,
        });
      } else {
        toast({
          variant: "destructive",
          title: "Invalid file type",
          description: `Please upload a ${fileType.toUpperCase()} file`,
        });
      }
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    handleFile(file);
  };

  const parseCSV = (csvText) => {
    // Simple CSV parser - handles comma-separated values
    // Assumes first row is header and subsequent rows are data
    const rows = csvText.split('\n');
    const headers = rows[0].split(',').map(header => header.trim().replace(/^"(.*)"$/, '$1'));
    
    // Find the indices of english and malay columns
    const englishIndex = headers.findIndex(h => h.toLowerCase() === 'english' || h.toLowerCase() === 'source');
    const malayIndex = headers.findIndex(h => h.toLowerCase() === 'malay' || h.toLowerCase() === 'target');
    
    if (englishIndex === -1 || malayIndex === -1) {
      throw new Error("CSV must have 'English/Source' and 'Malay/Target' columns");
    }
    
    // Parse the data rows
    const result = [];
    for (let i = 1; i < rows.length; i++) {
      if (!rows[i].trim()) continue; // Skip empty rows
      
      // Split by comma and handle quoted values
      let values = [];
      let inQuote = false;
      let currentValue = '';
      let row = rows[i];
      
      for (let j = 0; j < row.length; j++) {
        const char = row[j];
        
        if (char === '"' && (j === 0 || row[j-1] !== '\\')) {
          inQuote = !inQuote;
        } else if (char === ',' && !inQuote) {
          values.push(currentValue.trim());
          currentValue = '';
        } else {
          currentValue += char;
        }
      }
      
      // Add the last value
      values.push(currentValue.trim());
      
      // Remove surrounding quotes from all values
      values = values.map(val => val.replace(/^"(.*)"$/, '$1'));
      
      if (values.length >= Math.max(englishIndex, malayIndex) + 1) {
        result.push({
          english: values[englishIndex],
          malay: values[malayIndex]
        });
      }
    }
    
    return result;
  };

  // Function to upload file to server
  const uploadFileToServer = async (file, domainName) => {
    try {
      // Use the provided domainName parameter
      if (!domainName || domainName.trim() === '') {
        throw new Error('Domain name is required');
      }
      
      console.log('Using domain name for upload:', domainName); // Debug log
      
      // Create a new FormData object
      const formData = new FormData();
      
      // IMPORTANT: Add the domain name FIRST, then the file
      // This order can be important for some server configurations
      formData.append('domainName', domainName);
      formData.append('file', file);
      
      // Log all form data entries for debugging
      for (let [key, value] of formData.entries()) {
        console.log(`Form data entry - ${key}:`, value instanceof File ? value.name : value);
      }
      
      // Make the request with both the form data and a custom header
      // Also include the domain name as a URL parameter for extra reliability
      const response = await axios.post(`/utm-ittbm/api/upload/termbase?domainName=${encodeURIComponent(domainName)}`, formData, {
        headers: {
          'X-Domain-Name': domainName,
          'Content-Type': 'multipart/form-data'
        }
      });
      
      if (!response.data.success) {
        throw new Error(response.data.error || 'Upload failed');
      }
      
      return response.data;
    } catch (error) {
      console.error('Error uploading file:', error);
      throw new Error(error.response?.data?.error || error.message || 'Failed to upload file to server');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!newDomain.trim()) {
      toast({
        variant: "destructive",
        title: "Domain name required",
        description: "Please enter a domain name",
      });
      return;
    }

    if (!selectedFile) {
      toast({
        variant: "destructive",
        title: "File required",
        description: `Please upload a ${fileType.toUpperCase()} file`,
      });
      return;
    }

    try {
      setIsUploading(true);
      
      // Get the domain name directly from the state variable
      const domainNameToUse = newDomain.trim();
      
      // Log the domain name before uploading
      console.log('Submitting with domain name:', domainNameToUse);
      
      // First upload the file to the server with the domain name
      const uploadResult = await uploadFileToServer(selectedFile, domainNameToUse);
      
      console.log('Upload result:', uploadResult);
      
      // Read the file content
      const reader = new FileReader();
      reader.onload = async (e) => {
        try {
          let content;
          
          if (fileType === 'json') {
            // Parse JSON content
            content = JSON.parse(e.target.result);
            
            // Validate the JSON structure
            if (!Array.isArray(content) || !content.every(item => 
              item && typeof item === 'object' && 
              typeof item.english === 'string' && 
              typeof item.malay === 'string')) {
              throw new Error("Invalid JSON format. File must contain an array of objects with 'english' and 'malay' fields.");
            }
          } else if (fileType === 'csv') {
            // Parse CSV content
            content = parseCSV(e.target.result);
            
            if (!Array.isArray(content) || content.length === 0) {
              throw new Error("Invalid CSV format or no valid data rows found.");
            }
          }

          // Convert the content to a base64 string safely
          const jsonString = JSON.stringify(content);
          const base64Content = btoa(unescape(encodeURIComponent(jsonString)));
          
          const timestamp = new Date().toISOString();
          const newDomainEntry = {
            id: Date.now(),
            name: domainNameToUse,
            fileName: uploadResult.filename || selectedFile.name, // Use the server-generated filename
            content: base64Content,
            uploadDate: timestamp,
            filePath: uploadResult.filePath, // Store the server file path
            serverFileName: uploadResult.filename // Store the server filename
          };

          setDomains(prev => [...prev, newDomainEntry]);
          setNewDomain('');
          setSelectedFile(null);
          setIsDialogOpen(false);
          setIsUploading(false);

          // Save to localStorage
          localStorage.setItem('termBaseDomains', JSON.stringify([...domains, newDomainEntry]));

          // Refresh the resource files list
          fetchResourceFiles();

          toast({
            title: "Domain added",
            description: `${domainNameToUse} has been added successfully and saved as ${uploadResult.filename} in the resources folder`,
          });
        } catch (error) {
          setIsUploading(false);
          toast({
            variant: "destructive",
            title: "Invalid file format",
            description: error.message,
          });
        }
      };

      if (fileType === 'json') {
        reader.readAsText(selectedFile);
      } else if (fileType === 'csv') {
        reader.readAsText(selectedFile);
      }
    } catch (error) {
      setIsUploading(false);
      toast({
        variant: "destructive",
        title: "Error",
        description: error.message,
      });
    }
  };

  const deleteResourceFile = async (filename) => {
    try {
      setIsDeleting(true);
      setIsDeleteDialogOpen(false);
      
      // Call the API to delete the file
      const response = await axios.delete(`/utm-ittbm/api/resources/file/${encodeURIComponent(filename)}`);
      
      if (response.data.success) {
        // Remove the file from the local state
        setResourceFiles(prev => prev.filter(file => file.name !== filename));
        
        // Also remove from localStorage if it exists there
        // Extract domain name from filename (remove extension)
        const domainName = filename.replace(/\.[^/.]+$/, "");
        setDomains(prev => {
          const updatedDomains = prev.filter(domain => 
            domain.name !== domainName && 
            domain.fileName !== filename && 
            domain.serverFileName !== filename
          );
          // Update localStorage
          localStorage.setItem('termBaseDomains', JSON.stringify(updatedDomains));
          return updatedDomains;
        });
        
        toast({
          title: "File deleted",
          description: `${filename} has been deleted successfully`,
        });
      } else {
        throw new Error(response.data.error || 'Failed to delete file');
      }
    } catch (error) {
      console.error('Error deleting file:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: error.response?.data?.error || error.message || "Failed to delete file",
      });
    } finally {
      setIsDeleting(false);
      setFileToDelete(null);
    }
  };

  const confirmDelete = (filename) => {
    setFileToDelete(filename);
    setIsDeleteDialogOpen(true);
  };

  const handleDelete = (id) => {
    setDomains(prev => prev.filter(domain => domain.id !== id));
    toast({
      title: "Domain deleted",
      description: "The domain has been deleted",
    });
  };

  return (
    <div className="container mx-auto p-4">
      <div className="mb-6">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink href="/">Home</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink href="/gtt">GTT</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>Term Base Domain</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>

      <Card>
        <CardHeader>
          <div className="flex justify-between items-center">
            <div>
              <CardTitle>Term Base Domain</CardTitle>
              <CardDescription>
                Manage your term base domains for translation
              </CardDescription>
            </div>
            <div className="flex gap-2">
                <Link to="/app/term-base-dictionary">
                  <Button variant="outline" className="bg-white border-gray-300 hover:bg-gray-50">
                    <ArrowLeft className="h-4 w-4 mr-2" />
                    Back to Dictionary
                  </Button>
                </Link>
                <Button 
                  variant="outline" 
                  className="bg-white border-gray-300 hover:bg-gray-50"
                  onClick={fetchResourceFiles}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                  ) : (
                    <RefreshCw className="h-4 w-4 mr-2" />
                  )}
                  Refresh
                </Button>
                <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                  <DialogTrigger asChild>
                    <Button className="bg-indigo-600 hover:bg-indigo-700 text-white">
                      <Plus className="h-4 w-4 mr-2" />
                      Add Term/Dictionary
                    </Button>
                  </DialogTrigger>
                  <DialogContent className="sm:max-w-[425px]">
                    <DialogHeader>
                      <DialogTitle>Add New Term Base Domain</DialogTitle>
                      <DialogDescription>
                        Upload a JSON or CSV file containing term base pairs for a specific domain.
                      </DialogDescription>
                    </DialogHeader>
                    <form onSubmit={handleSubmit} className="space-y-4">
                      <div className="space-y-2">
                        <Label htmlFor="domain-name">Domain Name</Label>
                        <Input
                          id="domain-name"
                          value={newDomain}
                          onChange={(e) => setNewDomain(e.target.value)}
                          placeholder="Enter domain name"
                        />
                      </div>
                      
                      <Tabs defaultValue="json" onValueChange={setFileType}>
                        <TabsList className="grid w-full grid-cols-2">
                          <TabsTrigger value="json">JSON File</TabsTrigger>
                          <TabsTrigger value="csv">CSV File</TabsTrigger>
                        </TabsList>
                        <TabsContent value="json">
                          <div className="space-y-2">
                            <Label>Upload JSON File</Label>
                            <div
                              className={`border-2 border-dashed rounded-md p-6 text-center cursor-pointer transition-colors ${
                                isDragging
                                  ? "border-indigo-500 bg-indigo-50"
                                  : "border-gray-300 hover:border-indigo-400 hover:bg-gray-50"
                              }`}
                              onDragOver={handleDragOver}
                              onDragLeave={handleDragLeave}
                              onDrop={handleDrop}
                              onClick={() => document.getElementById("file-upload-json").click()}
                            >
                              <input
                                id="file-upload-json"
                                type="file"
                                accept=".json"
                                onChange={handleFileChange}
                                className="hidden"
                              />
                              {selectedFile && fileType === 'json' ? (
                                <div className="flex items-center justify-center gap-2">
                                  <FileJson className="h-5 w-5 text-indigo-600" />
                                  <span className="text-sm font-medium">{selectedFile.name}</span>
                                  <button
                                    type="button"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setSelectedFile(null);
                                    }}
                                    className="p-1 rounded-full hover:bg-gray-200"
                                  >
                                    <X className="h-4 w-4 text-gray-500" />
                                  </button>
                                </div>
                              ) : (
                                <div className="flex flex-col items-center justify-center">
                                  <Upload className="h-10 w-10 text-gray-400 mb-2" />
                                  <p className="text-sm font-medium text-gray-700">
                                    Drag and drop or click to upload
                                  </p>
                                  <p className="text-xs text-gray-500 mt-1">
                                    JSON file with english and malay fields
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        </TabsContent>
                        <TabsContent value="csv">
                          <div className="space-y-2">
                            <Label>Upload CSV File</Label>
                            <div
                              className={`border-2 border-dashed rounded-md p-6 text-center cursor-pointer transition-colors ${
                                isDragging
                                  ? "border-indigo-500 bg-indigo-50"
                                  : "border-gray-300 hover:border-indigo-400 hover:bg-gray-50"
                              }`}
                              onDragOver={handleDragOver}
                              onDragLeave={handleDragLeave}
                              onDrop={handleDrop}
                              onClick={() => document.getElementById("file-upload-csv").click()}
                            >
                              <input
                                id="file-upload-csv"
                                type="file"
                                accept=".csv"
                                onChange={handleFileChange}
                                className="hidden"
                              />
                              {selectedFile && fileType === 'csv' ? (
                                <div className="flex items-center justify-center gap-2">
                                  <FileSpreadsheet className="h-5 w-5 text-green-600" />
                                  <span className="text-sm font-medium">{selectedFile.name}</span>
                                  <button
                                    type="button"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setSelectedFile(null);
                                    }}
                                    className="p-1 rounded-full hover:bg-gray-200"
                                  >
                                    <X className="h-4 w-4 text-gray-500" />
                                  </button>
                                </div>
                              ) : (
                                <div className="flex flex-col items-center justify-center">
                                  <Upload className="h-10 w-10 text-gray-400 mb-2" />
                                  <p className="text-sm font-medium text-gray-700">
                                    Drag and drop or click to upload
                                  </p>
                                  <p className="text-xs text-gray-500 mt-1">
                                    CSV file with English/Source and Malay/Target columns
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        </TabsContent>
                      </Tabs>
                      
                      <DialogFooter>
                        <Button 
                          type="submit" 
                          className="bg-indigo-600 hover:bg-indigo-700 text-white"
                          disabled={isUploading}
                        >
                          {isUploading ? (
                            <>
                              <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                              Uploading...
                            </>
                          ) : (
                            'Add Domain'
                          )}
                        </Button>
                      </DialogFooter>
                    </form>
                  </DialogContent>
                </Dialog>
            </div>
          </div>
        </CardHeader>
        <CardContent>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Domain Name</TableHead>
                <TableHead>File Name</TableHead>
                <TableHead>Upload Date</TableHead>
                <TableHead className="text-right">Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={4} className="text-center py-6">
                    <div className="flex justify-center items-center">
                      <Loader2 className="h-6 w-6 animate-spin text-indigo-600 mr-2" />
                      <span>Loading resources...</span>
                    </div>
                  </TableCell>
                </TableRow>
              ) : resourceFiles.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={4} className="text-center py-6 text-gray-500">
                    No resources found. Add a new domain to get started.
                  </TableCell>
                </TableRow>
              ) : (
                resourceFiles.map((file, index) => {
                  // Extract domain name from filename (remove extension)
                  const domainName = file.name.replace(/\.[^/.]+$/, "");
                  
                  return (
                    <TableRow key={index}>
                      <TableCell className="font-medium">{domainName}</TableCell>
                      <TableCell>
                        <a 
                          href={file.path} 
                          target="_blank" 
                          rel="noopener noreferrer"
                          className="text-indigo-600 hover:underline"
                        >
                          {file.name}
                        </a>
                      </TableCell>
                      <TableCell>
                        {/* We don't have upload date from the API, so we'll show "Available" */}
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                          Available
                        </span>
                      </TableCell>
                      <TableCell className="text-right">
                        <Button
                          variant="ghost"
                          className="text-red-600 hover:text-red-800 hover:bg-red-50"
                          onClick={() => confirmDelete(file.name)}
                          disabled={isDeleting}
                        >
                          {isDeleting && fileToDelete === file.name ? (
                            <>
                              <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                              Deleting...
                            </>
                          ) : (
                            'Delete'
                          )}
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </CardContent>
      </Card>

      {/* Delete Confirmation Dialog */}
      <AlertDialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <AlertDialogContent className="bg-gray-50">
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This will permanently delete the file "{fileToDelete}" from the resources folder.
              This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => setFileToDelete(null)}>Cancel</AlertDialogCancel>
            <AlertDialogAction 
              onClick={() => deleteResourceFile(fileToDelete)}
              className="bg-red-600 hover:bg-red-700 text-white"
              disabled={isDeleting}
            >
              {isDeleting ? (
                <>
                  <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                  Deleting...
                </>
              ) : (
                'Delete'
              )}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default TermBaseDomain;
