/** @format */

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/ui/button";
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from "../../components/ui/card";
import { Languages, Save, Download, FileDown, Pencil, RefreshCw, Trash2 } from "lucide-react";
import { saveAs } from 'file-saver';
import { Table, TableHeader, TableBody, TableRow, TableHead, TableCell } from "../../components/ui/table";
import { useGlossary } from "../../contexts/GlossaryContext";
import { processTextWithGlossary } from "../../utils/glossaryHighlighter";
import { loadAIModels } from "../../utils/aiModelUtils";
import PlaygroundSimpleHeader from "../../components/core/public/PlaygroundSimpleHeader";
import PlaygroundNavigation from "../../components/core/public/PlaygroundNavigation";

const TextTranslationPlayground = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "playgroundPage",
  });

  const { findExactTerm, findTermContaining, isLoaded } = useGlossary();

  const [sourceText, setSourceText] = useState("");
  const [translatedText, setTranslatedText] = useState("");
  const [sourceLanguage, setSourceLanguage] = useState("English");
  const [targetLanguage, setTargetLanguage] = useState("Bahasa Melayu");
  const [savedTranslations, setSavedTranslations] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null);
  const [availableModels, setAvailableModels] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [wordCount, setWordCount] = useState(0);
  const [languageError, setLanguageError] = useState("");

  // Function to count words in a text
  const countWords = (text) => {
    return text.trim() ? text.trim().split(/\s+/).length : 0;
  };

  useEffect(() => {
    const fetchModels = async () => {
      try {
        const models = (await loadAIModels()).filter(model => model.isActive);
        console.log('Available models:', models);
        setAvailableModels(models);
        if (models.length > 0 && !selectedModel) {
          setSelectedModel(models[0]);
        }
      } catch (error) {
        console.error('Error fetching models:', error);
      }
    };

    fetchModels();
  }, []);

  // Add this function to handle language validation
  const validateLanguageSelection = (source, target) => {
    if (source === target) {
      setLanguageError("Source and target languages cannot be the same");
      return false;
    }
    setLanguageError("");
    return true;
  };

  const handleTranslate = async () => {
    if (!sourceText.trim()) {
      alert("Please enter text to translate");
      return;
    }

    if (!selectedModel) {
      alert("Please select a translation model");
      return;
    }

    if (!validateLanguageSelection(sourceLanguage, targetLanguage)) {
      return;
    }

    if (countWords(sourceText) > 100) {
      alert("Please limit your text to 100 words to control token usage");
      return;
    }

    setIsLoading(true);
    try {
      let translatedResult;
      
      switch (selectedModel.type) {
        case 'openai':
          translatedResult = await translateWithOpenAI(sourceText, selectedModel.apiKey);
          break;
        case 'groq':
          translatedResult = await translateWithGroq(sourceText, selectedModel.apiKey);
          break;
        case 'ollama':
          translatedResult = await translateWithOllama(sourceText, selectedModel.endpoint);
          break;
        case 'huggingface':
          translatedResult = await translateWithHuggingFace(
            sourceText, 
            selectedModel.apiKey, 
            selectedModel.modelId, 
            selectedModel.endpoint
          );
          break;
        default:
          throw new Error('Unsupported model type');
      }

      setTranslatedText(translatedResult);

      // Calculate word counts
      const sourceWordCount = sourceText.trim().split(/\s+/).length;
      const translatedWordCount = translatedResult.trim().split(/\s+/).length;

      // Save translation to localStorage for statistics
      const textTranslations = JSON.parse(localStorage.getItem('textTranslations') || '[]');
      const newTranslation = {
        id: Date.now().toString(),
        sourceText,
        translatedText: translatedResult,
        timestamp: new Date().toISOString(),
        sourceLanguage,
        targetLanguage,
        modelType: selectedModel.type,
        sourceWordCount,
        translatedWordCount,
        status: 'completed'
      };
      textTranslations.push(newTranslation);
      localStorage.setItem('textTranslations', JSON.stringify(textTranslations));

      // Save to history if translation was successful
      setSavedTranslations(prev => [newTranslation, ...prev]);
    } catch (error) {
      console.error('Translation error:', error);
      alert("Translation failed: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = () => {
    if (!sourceText.trim() || !translatedText.trim()) {
      alert("Please translate some text before saving");
      return;
    }

    const sourceWordCount = sourceText.trim().split(/\s+/).length;
    const translatedWordCount = translatedText.trim().split(/\s+/).length;

    const translation = {
      id: Date.now().toString(),
      sourceText,
      translatedText,
      sourceLanguage,
      targetLanguage,
      modelType: selectedModel.type,
      timestamp: new Date().toISOString(),
      sourceWordCount,
      translatedWordCount,
      status: 'completed'
    };

    const textTranslations = JSON.parse(localStorage.getItem('textTranslations') || '[]');
    textTranslations.push(translation);
    localStorage.setItem('textTranslations', JSON.stringify(textTranslations));
    setSavedTranslations(prev => [translation, ...prev]);
  };

  const handleDownload = (translation) => {
    const jsonContent = [
      {
        english: translation.sourceLanguage === "English" ? translation.sourceText : translation.translatedText,
        malay: translation.sourceLanguage === "Bahasa Melayu" ? translation.sourceText : translation.translatedText
      }
    ];

    const blob = new Blob([JSON.stringify(jsonContent, null, 2)], {
      type: "application/json;charset=utf-8"
    });

    saveAs(blob, `translation_${translation.timestamp}.json`);
  };

  const handleDownloadAll = () => {
    if (savedTranslations.length === 0) {
      alert("No translations to download");
      return;
    }

    const jsonContent = savedTranslations.map(translation => ({
      english: translation.sourceLanguage === "English" ? translation.sourceText : translation.translatedText,
      malay: translation.sourceLanguage === "Bahasa Melayu" ? translation.sourceText : translation.translatedText
    }));

    const blob = new Blob([JSON.stringify(jsonContent, null, 2)], {
      type: "application/json;charset=utf-8"
    });

    saveAs(blob, `all_translations_${new Date().toISOString().split('T')[0]}.json`);
  };

  const handleClear = () => {
    setSourceText("");
    setTranslatedText("");
    setIsLoading(false);
    setWordCount(0);
  };

  // Translation service functions
  const translateWithOpenAI = async (text, apiKey) => {
    try {
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`,
        },
        body: JSON.stringify({
          model: 'gpt-3.5-turbo',
          messages: [
            {
              role: 'system',
              content: `You are a professional translator specializing in English and Bahasa Melayu translations. 
              Current task: Translate FROM ${sourceLanguage} TO ${targetLanguage}.
              Rules:
              1. If source is Bahasa Melayu and target is English: Translate to proper English
              2. If source is English and target is Bahasa Melayu: Translate to proper Bahasa Melayu
              3. Maintain the original meaning and context
              4. Only provide the direct translation, no explanations
              5. Ensure the output is ONLY in the target language`
            },
            {
              role: 'user',
              content: text
            }
          ],
          temperature: 0.3
        })
      });

      if (!response.ok) {
        throw new Error(`OpenAI API error: ${response.status}`);
      }

      const data = await response.json();
      return data.choices[0].message.content.trim();
    } catch (error) {
      throw new Error(`OpenAI translation failed: ${error.message}`);
    }
  };

  const translateWithGroq = async (text, apiKey) => {
    try {
      const response = await fetch('https://api.groq.com/openai/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`,
        },
        body: JSON.stringify({
          model: selectedModel.modelId || 'mistral-saba-24b',
          messages: [
            {
              role: 'system',
              content: `You are a professional translator specializing in English and Bahasa Melayu translations. 
              Current task: Translate FROM ${sourceLanguage} TO ${targetLanguage}.
              Rules:
              1. If source is Bahasa Melayu and target is English: Translate to proper English
              2. If source is English and target is Bahasa Melayu: Translate to proper Bahasa Melayu
              3. Maintain the original meaning and context
              4. Only provide the direct translation, no explanations
              5. Ensure the output is ONLY in the target language`
            },
            {
              role: 'user',
              content: text
            }
          ],
          temperature: 0.3
        })
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(`Groq API error: ${response.status} - ${errorData.error?.message || 'Unknown error'}`);
      }

      const data = await response.json();
      return data.choices[0].message.content.trim();
    } catch (error) {
      throw new Error(`Groq translation failed: ${error.message}`);
    }
  };

  const translateWithOllama = async (text, endpoint = 'http://localhost:11434') => {
    try {
      console.log('Ollama Request:', {
        endpoint,
        model: selectedModel.modelId,
        text: text.substring(0, 100)
      });

      const response = await fetch(`${endpoint}/api/generate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          model: selectedModel.modelId,
          prompt: `You are a professional translator specializing in English and Bahasa Melayu translations.

Current task: Translate FROM ${sourceLanguage} TO ${targetLanguage}.

Rules:
1. If source is Bahasa Melayu and target is English: Translate to proper English
2. If source is English and target is Bahasa Melayu: Translate to proper Bahasa Melayu
3. Maintain the original meaning and context
4. Only provide the direct translation, no explanations
5. Ensure the output is ONLY in the target language

Text to translate:
${text}

Translation:`,
          stream: false,
          options: {
            temperature: 0.1
          }
        })
      });

      if (!response.ok) {
        throw new Error(`Ollama API error: ${response.status}`);
      }

      const data = await response.json();
      return data.response.trim();
    } catch (error) {
      console.error('Ollama error:', error);
      throw new Error(`Ollama translation failed: ${error.message}`);
    }
  };

  const translateWithHuggingFace = async (text, apiKey, modelId, endpoint) => {
    try {
      const apiUrl = endpoint || `https://api-inference.huggingface.co/models/${modelId}`;
      
      // Log request details for debugging
      console.log('HF Request:', {
        url: apiUrl,
        modelId,
        hasApiKey: !!apiKey,
        text: text.substring(0, 100) // Log first 100 chars only
      });

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`
        },
        body: JSON.stringify({
          inputs: text,
          options: {
            wait_for_model: true
          }
        })
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        console.error('HF Error:', errorData);
        throw new Error(`Hugging Face API error: ${response.status} - ${errorData.error || 'Unknown error'}`);
      }

      const data = await response.json();
      console.log('HF Response:', data); // Debug log
      
      // Handle different response formats
      if (Array.isArray(data)) {
        // Translation model response
        if (data[0]?.translation_text) {
          return data[0].translation_text;
        }
        // Text generation response
        if (data[0]?.generated_text) {
          return data[0].generated_text;
        }
        // Raw text array response
        if (typeof data[0] === 'string') {
          return data[0];
        }
        // Try to extract text from complex array response
        if (data[0]?.text) {
          return data[0].text;
        }
        return JSON.stringify(data[0]);
      }
      
      // Single object response
      if (data.translation_text) {
        return data.translation_text;
      }
      if (data.generated_text) {
        return data.generated_text;
      }
      if (data.text) {
        return data.text;
      }
      
      // If we get here, try to convert the response to a string
      if (typeof data === 'string') {
        return data;
      }
      
      return JSON.stringify(data);
    } catch (error) {
      console.error('HF Translation error:', error);
      throw new Error(`Hugging Face translation failed: ${error.message}`);
    }
  };

  const getModelIcon = (type) => {
    switch (type) {
      case 'openai':
        return <Languages className="h-4 w-4 text-blue-500" />;
      case 'groq':
        return <Languages className="h-4 w-4 text-red-500" />;
      case 'ollama':
        return <Languages className="h-4 w-4 text-gray-500" />;
      case 'huggingface':
        return <Languages className="h-4 w-4 text-blue-500" />;
      default:
        return <Languages className="h-4 w-4 text-gray-500" />;
    }
  };

  // Render the translated text with glossary tooltips
  const renderTranslatedText = () => {
    if (!translatedText || !isLoaded) {
      return translatedText;
    }

    return processTextWithGlossary(translatedText, findExactTerm);
  };

  return (
    <div className="min-h-screen bg-white">
      <PlaygroundSimpleHeader keyPrefix="playgroundPage" />
      <PlaygroundNavigation />
      
      <div className="container mx-auto px-4 py-8">
        {/* Model Selection */}
        <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
          <div className="flex flex-col space-y-2">
            <h2 className="text-lg font-semibold text-gray-700">AI Translation Model</h2>
            <div className="flex items-center gap-2">
              <select
                className="flex-1 border border-gray-200 rounded-md p-2 text-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                value={selectedModel?.id || ""}
                onChange={(e) => {
                  const model = availableModels.find(m => m.id.toString() === e.target.value);
                  setSelectedModel(model);
                }}
              >
                <option value="">Select Translation Model</option>
                {availableModels.map((model) => (
                  <option key={model.id} value={model.id}>
                    {model.name}
                  </option>
                ))}
              </select>
              <Button
                variant="outline"
                size="icon"
                className="border-gray-200 hover:bg-gray-50"
                onClick={() => {
                  const models = loadAIModels().filter(model => model.isActive);
                  setAvailableModels(models);
                  if (models.length > 0 && !selectedModel) {
                    setSelectedModel(models[0]);
                  }
                }}
              >
                <RefreshCw className="h-4 w-4 text-gray-500" />
              </Button>
            </div>
          </div>
        </div>

        {/* Translation Container */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
          {/* Source Language Section */}
          <div className="bg-white rounded-lg shadow-sm p-6">
            <div className="flex flex-col space-y-4">
              <div className="flex flex-col sm:flex-row gap-4 justify-between">
                <div className="flex flex-col gap-2 w-full sm:w-3/5">
                  <select 
                    className={`w-full border ${languageError ? 'border-red-500' : 'border-gray-200'} rounded-md p-2 text-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
                    value={sourceLanguage}
                    onChange={(e) => {
                      const newSourceLang = e.target.value;
                      if (newSourceLang === targetLanguage) {
                        setTargetLanguage(newSourceLang === "English" ? "Bahasa Melayu" : "English");
                      }
                      setSourceLanguage(newSourceLang);
                      validateLanguageSelection(newSourceLang, targetLanguage);
                    }}
                  >
                    <option>English</option>
                    <option>Bahasa Melayu</option>
                  </select>
                  {languageError && <span className="text-sm text-red-500">{languageError}</span>}
                </div>
                <div className="flex gap-2">
                  <Button 
                    variant="outline"
                    onClick={handleClear}
                    className="border-gray-200 hover:bg-gray-50 text-gray-600"
                  >
                    <Trash2 className="h-4 w-4 mr-2" />
                    Clear
                  </Button>
                  <Button 
                    onClick={handleTranslate}
                    disabled={isLoading || !sourceText.trim()}
                    className="bg-blue-500 hover:bg-blue-600 text-white"
                  >
                    <Languages className="h-4 w-4 mr-2" />
                    {isLoading ? "Translating..." : "Translate"}
                  </Button>
                </div>
              </div>
              <textarea
                className="w-full h-[300px] border border-gray-200 rounded-md p-4 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent resize-none"
                placeholder="Enter text to translate (max 100 words)"
                value={sourceText}
                onChange={(e) => {
                  const newText = e.target.value;
                  const newWordCount = countWords(newText);
                  
                  if (newWordCount <= 100) {
                    setSourceText(newText);
                    setWordCount(newWordCount);
                  } else {
                    // If exceeding 100 words, don't update the text
                    alert("Maximum 100 words allowed for translation");
                  }
                }}
              ></textarea>
              <div className="flex justify-end">
                <span className={`text-sm ${wordCount > 90 ? 'text-red-500 font-medium' : 'text-gray-500'}`}>
                  {wordCount}/100 words
                </span>
              </div>
            </div>
          </div>

          {/* Target Language Section */}
          <div className="bg-white rounded-lg shadow-sm p-6">
            <div className="flex flex-col space-y-4">
              <div className="flex flex-col gap-2 w-full sm:w-3/5">
                <select
                  className={`w-full border ${languageError ? 'border-red-500' : 'border-gray-200'} rounded-md p-2 text-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
                  value={targetLanguage}
                  onChange={(e) => {
                    const newTargetLang = e.target.value;
                    if (newTargetLang === sourceLanguage) {
                      setSourceLanguage(newTargetLang === "English" ? "Bahasa Melayu" : "English");
                    }
                    setTargetLanguage(newTargetLang);
                    validateLanguageSelection(sourceLanguage, newTargetLang);
                  }}
                >
                  <option>Bahasa Melayu</option>
                  <option>English</option>
                </select>
                {languageError && <span className="text-sm text-red-500">{languageError}</span>}
              </div>
              <div
                className="w-full min-h-[300px] border border-gray-200 rounded-md p-4 bg-gray-50 text-gray-700 focus:outline-none whitespace-pre-wrap"
              >
                {renderTranslatedText()}
              </div>
            </div>
          </div>
        </div>

        {/* Translation History */}
        {savedTranslations.length > 0 && (
          <div className="bg-white rounded-lg shadow-sm p-6">
            <div className="flex justify-between items-center mb-4">
              <div>
                <h2 className="text-lg font-semibold text-gray-700">Translation History</h2>
                <p className="text-sm text-gray-500">Your saved translations</p>
              </div>
              <Button 
                variant="outline"
                onClick={handleDownloadAll}
                className="border-gray-200 hover:bg-gray-50 text-gray-600"
              >
                <FileDown className="h-4 w-4 mr-2" />
                Download All
              </Button>
            </div>
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="border-b border-gray-200">
                    <th className="text-left py-3 px-4 text-sm font-medium text-gray-500">Source Text</th>
                    <th className="text-left py-3 px-4 text-sm font-medium text-gray-500">Translated Text</th>
                    <th className="text-left py-3 px-4 text-sm font-medium text-gray-500">Languages</th>
                    <th className="text-left py-3 px-4 text-sm font-medium text-gray-500">Model</th>
                    <th className="text-right py-3 px-4 text-sm font-medium text-gray-500">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {savedTranslations.map((translation) => (
                    <tr key={translation.id} className="border-b border-gray-100 hover:bg-gray-50">
                      <td className="py-3 px-4 text-sm text-gray-600 max-w-[200px] truncate">
                        {translation.sourceText}
                      </td>
                      <td className="py-3 px-4 text-sm text-gray-600 max-w-[200px] truncate">
                        {translation.translatedText}
                      </td>
                      <td className="py-3 px-4 text-sm text-gray-600">
                        {translation.sourceLanguage} → {translation.targetLanguage}
                      </td>
                      <td className="py-3 px-4">
                        <div className="flex items-center gap-1 text-sm">
                          {translation.modelType === 'openai' && <Languages className="h-4 w-4 text-blue-500" />}
                          {translation.modelType === 'groq' && <Languages className="h-4 w-4 text-red-500" />}
                          {translation.modelType === 'ollama' && <Languages className="h-4 w-4 text-gray-500" />}
                          {translation.modelType === 'huggingface' && <Languages className="h-4 w-4 text-blue-500" />}
                          <span className="text-gray-600">{translation.modelName}</span>

                        </div>
                      </td>
                      <td className="py-3 px-4 text-right">
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={() => handleDownload(translation)}
                          className="hover:bg-gray-100 text-gray-600"
                        >
                          <Download className="h-4 w-4" />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TextTranslationPlayground;
