/** @format */

import React, { useState, useEffect, useRef } from 'react';
import { Card, CardContent } from '../../components/ui/card';
import { Button } from '../../components/ui/button';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../components/ui/table';
import { Input } from '../../components/ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select";
import mammoth from 'mammoth';
import { Document, Packer, Table as DocxTable, TableRow as DocxTableRow, TableCell as DocxTableCell, Paragraph, HeadingLevel } from 'docx';
import { loadAIModels } from "../../utils/aiModelUtils";

const HighlightedText = ({ text, originalText }) => {
  return (
    <span>
      {text.split(' ').map((word, i) => {
        const isNewWord = originalText && !originalText.includes(word);
        return (
          <span
            key={i}
            className={isNewWord ? 'text-red-500' : ''}
          >
            {word}{' '}
          </span>
        );
      })}
    </span>
  );
};

const FileExtraction = () => {
  const [tableData, setTableData] = useState([]);
  const [processingRows, setProcessingRows] = useState(new Set());
  const [selectedModel, setSelectedModel] = useState(null);
  const [availableModels, setAvailableModels] = useState([]);
  const [sourceLanguage, setSourceLanguage] = useState("English");
  const [targetLanguage, setTargetLanguage] = useState("Bahasa Melayu");
  const [editingIndex, setEditingIndex] = useState(null);
  const [originalTranslations, setOriginalTranslations] = useState({});
  const [error, setError] = useState(null);
  const fileInputRef = useRef(null);

  // Helper function to normalize text for comparison
  const normalizeText = (text) => {
    return text.toLowerCase().trim().split(/\s+/);
  };

  // Helper function to check if a word is new
  const isWordNew = (word, originalText) => {
    if (!originalText) return false;
    const normalizedWord = word.toLowerCase().trim();
    const normalizedOriginal = normalizeText(originalText);
    return !normalizedOriginal.includes(normalizedWord);
  };

  // Function to save cursor position
  const saveCaretPosition = (element) => {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const preCaretRange = range.cloneRange();
    preCaretRange.selectNodeContents(element);
    preCaretRange.setEnd(range.endContainer, range.endOffset);
    return preCaretRange.toString().length;
  };

  // Function to restore cursor position
  const restoreCaretPosition = (element, pos) => {
    const range = document.createRange();
    const sel = window.getSelection();
    let currentPos = 0;
    let found = false;

    function traverseNodes(node) {
      if (found) return;
      if (node.nodeType === Node.TEXT_NODE) {
        const nodeLength = node.length;
        if (currentPos + nodeLength >= pos) {
          range.setStart(node, pos - currentPos);
          range.setEnd(node, pos - currentPos);
          found = true;
        }
        currentPos += nodeLength;
      } else {
        for (const child of node.childNodes) {
          traverseNodes(child);
        }
      }
    }

    traverseNodes(element);
    sel.removeAllRanges();
    sel.addRange(range);
  };

  const renderHighlightedContent = (text, index) => {
    return text.split(' ').map((word, i) => {
      const isNewWord = originalTranslations[index] && !originalTranslations[index].includes(word);
      return `<span class="${isNewWord ? 'text-red-500' : ''}">${word}</span>${i < text.split(' ').length - 1 ? ' ' : ''}`;
    }).join('');
  };

  useEffect(() => {
    const fetchModels = async () => {
      try {
        const models = await loadAIModels();
        const activeModels = models.filter(model => model.isActive);
        setAvailableModels(activeModels);
        if (activeModels.length > 0 && !selectedModel) {
          setSelectedModel(activeModels[0]);
        }
      } catch (error) {
        console.error('Error fetching models:', error);
        setError('Failed to load AI models. Please try again later.');
      }
    };

    fetchModels();
  }, []);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (event) => {
      const arrayBuffer = event.target.result;
      
      // Use mammoth to extract text from docx
      mammoth.extractRawText({ arrayBuffer })
        .then(result => {
          const text = result.value;
          const sentences = text.split(/(?<=[.!?])\s+/);
          
          const initializedData = sentences
            .filter(sentence => sentence.trim().length > 0)
            .map(sentence => ({
              sourceText: sentence.trim(),
              translatedText: '',
              status: 'pending'
            }));

          setTableData(initializedData);
          setProcessingRows(new Set());
          setEditingIndex(null);
        })
        .catch(error => {
          console.error('Error extracting text from docx:', error);
          alert('Error extracting text from the document. Please make sure it is a valid .docx file.');
        });
    };

    reader.readAsArrayBuffer(file);
  };

  const translateWithOpenAI = async (text, apiKey) => {
    try {
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`,
        },
        body: JSON.stringify({
          model: 'gpt-3.5-turbo',
          messages: [
            {
              role: 'system',
              content: `You are a translation assistant. Translate the following text from ${sourceLanguage} to ${targetLanguage}. Provide only the translation, no additional explanations.`
            },
            {
              role: 'user',
              content: text
            }
          ],
          temperature: 0.3
        })
      });

      if (!response.ok) {
        throw new Error(`OpenAI API error: ${response.status}`);
      }

      const data = await response.json();
      return data.choices[0].message.content.trim();
    } catch (error) {
      throw new Error(`OpenAI translation failed: ${error.message}`);
    }
  };

  const translateWithGroq = async (text, apiKey) => {
    try {
      const response = await fetch('https://api.groq.com/openai/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`,
        },
        body: JSON.stringify({
          model: selectedModel.modelId || 'mistral-saba-24b',
          messages: [
            {
              role: 'system',
              content: `You are a translation assistant. Translate the following text from ${sourceLanguage} to ${targetLanguage}. Provide only the translation, no additional explanations.`
            },
            {
              role: 'user',
              content: text
            }
          ],
          temperature: 0.3
        })
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(`Groq API error: ${response.status} - ${errorData.error?.message || 'Unknown error'}`);
      }

      const data = await response.json();
      return data.choices[0].message.content.trim();
    } catch (error) {
      throw new Error(`Groq translation failed: ${error.message}`);
    }
  };

  const translateWithOllama = async (text, endpoint = 'http://localhost:11434') => {
    try {
      const response = await fetch(`${endpoint}/api/generate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          model: selectedModel.modelId,
          prompt: `You are a translation assistant. Your task is to translate the following text from ${sourceLanguage} to ${targetLanguage}. Only output the translation, nothing else.\n\nText to translate:\n${text}\n\nTranslation:`,
          stream: false,
          options: {
            temperature: 0.1
          }
        })
      });

      if (!response.ok) {
        throw new Error(`Ollama API error: ${response.status}`);
      }

      const data = await response.json();
      let cleanedResponse = data.response
        .replace(/<think>[\s\S]*?<\/think>/g, '')
        .replace(/Translation:/i, '')
        .trim();

      return cleanedResponse || data.response.trim();
    } catch (error) {
      console.error('Ollama error:', error);
      throw new Error(`Ollama translation failed: ${error.message}`);
    }
  };

  const translateWithHuggingFace = async (text, apiKey, modelId, endpoint) => {
    try {
      const apiUrl = endpoint || `https://api-inference.huggingface.co/models/${modelId}`;
      
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`
        },
        body: JSON.stringify({
          inputs: text,
          options: {
            wait_for_model: true
          }
        })
      });

      if (!response.ok) {
        throw new Error(`HuggingFace API error: ${response.status}`);
      }

      const data = await response.json();
      
      if (Array.isArray(data) && data.length > 0) {
        if (data[0].translation_text) return data[0].translation_text;
        if (data[0].generated_text) return data[0].generated_text;
        if (typeof data[0] === 'string') return data[0];
      }
      
      if (data.translation_text) return data.translation_text;
      if (data.generated_text) return data.generated_text;
      if (typeof data === 'string') return data;
      
      throw new Error('Unexpected response format from Hugging Face API');
    } catch (error) {
      throw new Error(`HuggingFace translation failed: ${error.message}`);
    }
  };

  const translateText = async (text, index) => {
    if (!selectedModel) {
      console.error('No model selected');
      return;
    }

    setProcessingRows(prev => new Set(prev).add(index));

    try {
      let translatedText = '';

      switch (selectedModel.type) {
        case 'ollama':
          translatedText = await translateWithOllama(text);
          break;
        case 'openai':
          translatedText = await translateWithOpenAI(text, selectedModel.apiKey);
          break;
        case 'groq':
          translatedText = await translateWithGroq(text, selectedModel.apiKey);
          break;
        case 'huggingface':
          translatedText = await translateWithHuggingFace(
            text,
            selectedModel.apiKey,
            selectedModel.modelId,
            selectedModel.endpoint
          );
          break;
        default:
          throw new Error('Unsupported model type');
      }

      setTableData(prevData => {
        const newData = [...prevData];
        newData[index] = {
          ...newData[index],
          translatedText,
          status: 'completed'
        };
        return newData;
      });

      // Store the original AI translation
      setOriginalTranslations(prev => ({
        ...prev,
        [index]: translatedText
      }));

    } catch (error) {
      console.error('Translation error:', error);
      setTableData(prevData => {
        const newData = [...prevData];
        newData[index] = {
          ...newData[index],
          status: 'error'
        };
        return newData;
      });
    } finally {
      setProcessingRows(prev => {
        const newSet = new Set(prev);
        newSet.delete(index);
        return newSet;
      });
    }
  };

  const deleteRow = (index) => {
    // Update tableData by removing the row at the specified index
    setTableData(prevData => {
      const newData = [...prevData];
      newData.splice(index, 1);
      return newData;
    });
    
    // Update originalTranslations by removing the entry for this index
    // and shifting all higher indices down by 1
    setOriginalTranslations(prev => {
      const newOriginalTranslations = {};
      Object.keys(prev).forEach(key => {
        const keyIndex = parseInt(key);
        if (keyIndex < index) {
          newOriginalTranslations[keyIndex] = prev[keyIndex];
        } else if (keyIndex > index) {
          newOriginalTranslations[keyIndex - 1] = prev[keyIndex];
        }
      });
      return newOriginalTranslations;
    });
    
    // If we're editing this row, reset editingIndex
    if (editingIndex === index) {
      setEditingIndex(null);
    } else if (editingIndex > index) {
      // If we're editing a row after this one, adjust the index
      setEditingIndex(editingIndex - 1);
    }
    
    // Remove from processingRows if it's there
    if (processingRows.has(index)) {
      setProcessingRows(prev => {
        const newSet = new Set(prev);
        newSet.delete(index);
        return newSet;
      });
    }
  };

  const translateAll = async () => {
    if (!selectedModel) {
      console.error('No model selected');
      return;
    }

    for (let i = 0; i < tableData.length; i++) {
      if (tableData[i].status !== 'completed') {
        await translateText(tableData[i].sourceText, i);
      }
    }
  };

  const startNewSession = () => {
    if (tableData.length > 0) {
      if (!window.confirm('Are you sure you want to start a new session? This will clear all current translations.')) {
        return;
      }
    }
    setTableData([]);
    setProcessingRows(new Set());
    setEditingIndex(null);
    setOriginalTranslations({});
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const downloadDocx = async () => {
    const doc = new Document({
      sections: [{
        children: [
          new Paragraph({
            text: "Translation Results",
            heading: HeadingLevel.HEADING_1
          }),
          new DocxTable({
            rows: [
              new DocxTableRow({
                children: [
                  new DocxTableCell({
                    children: [new Paragraph({ text: "Source Text" })],
                    width: { size: 50, type: "percentage" }
                  }),
                  new DocxTableCell({
                    children: [new Paragraph({ text: "Translated Text" })],
                    width: { size: 50, type: "percentage" }
                  })
                ]
              }),
              ...tableData.map(row => 
                new DocxTableRow({
                  children: [
                    new DocxTableCell({
                      children: [new Paragraph({ text: row.sourceText })],
                      width: { size: 50, type: "percentage" }
                    }),
                    new DocxTableCell({
                      children: [new Paragraph({ text: row.translatedText })],
                      width: { size: 50, type: "percentage" }
                    })
                  ]
                })
              )
            ]
          })
        ]
      }]
    });

    const blob = await Packer.toBlob(doc);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'translation_results.docx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const downloadJSON = () => {
    // Create JSON data in the required format
    const jsonData = tableData.map(row => ({
      english: sourceLanguage.toLowerCase() === "english" ? row.sourceText : row.translatedText,
      malay: targetLanguage.toLowerCase() === "bahasa melayu" ? row.translatedText : row.sourceText
    }));

    // Convert to JSON string with pretty formatting
    const jsonString = JSON.stringify(jsonData, null, 2);
    
    // Create blob and download
    const blob = new Blob([jsonString], { type: 'application/json' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'translation_results.json';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className="container mx-auto px-4 py-6 max-w-7xl">
      <Card className="bg-white shadow-lg rounded-lg overflow-hidden border-t-4 border-blue-500">
        <CardContent className="p-4 md:p-6">
          <div className="flex flex-col gap-4">
            <div className="space-y-2">
              <h2 className="text-2xl md:text-3xl font-bold text-gray-800">File Extraction and Translation</h2>
              <p className="text-sm md:text-base text-gray-600">
                Upload a Word document (.docx) to extract text by sentences and translate them using AI.
              </p>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-3 md:gap-4">
              <div className="flex flex-col space-y-1">
                <label htmlFor="model-select" className="text-sm font-semibold text-gray-700">
                  Translation Engine Model
                </label>
                <Select
                  value={selectedModel?.id || ''}
                  onValueChange={(value) => {
                    const model = availableModels.find(m => m.id === value);
                    setSelectedModel(model);
                  }}
                >
                  <SelectTrigger 
                    id="model-select" 
                    className="w-full bg-white border-2 border-gray-200 hover:border-blue-400 focus:border-blue-500 transition-colors"
                  >
                    <SelectValue placeholder="Select Model" />
                  </SelectTrigger>
                  <SelectContent className="bg-white border-2 border-gray-200 shadow-lg">
                    {availableModels.map((model) => (
                      <SelectItem 
                        key={model.id} 
                        value={model.id}
                        className="hover:bg-blue-50 focus:bg-blue-50 cursor-pointer py-2"
                      >
                        {model.name} 
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              <div className="flex flex-col space-y-1">
                <label htmlFor="file-upload" className="text-sm font-semibold text-gray-700">
                  Upload Word Document
                </label>
                <Input
                  id="file-upload"
                  type="file"
                  accept=".docx"
                  onChange={handleFileUpload}
                  ref={fileInputRef}
                  className="w-full border-2 border-gray-200 hover:border-blue-400 focus:border-blue-500 transition-colors text-sm h-10"
                />
              </div>

              <div className="flex flex-col space-y-1">
                <label className="text-sm font-semibold text-gray-700 invisible">
                  Action
                </label>
                <Button
                  onClick={translateAll}
                  disabled={tableData.length === 0 || !selectedModel}
                  className="w-full bg-blue-500 hover:bg-blue-600 text-white transition-colors disabled:bg-gray-300 text-sm h-10"
                >
                  Translate All
                </Button>
              </div>

              <div className="flex flex-col space-y-1">
                <label className="text-sm font-semibold text-gray-700 invisible">
                  Actions
                </label>
                <div className="grid grid-cols-3 gap-1 h-10">
                  <Button
                    onClick={startNewSession}
                    disabled={tableData.length === 0}
                    className="bg-red-500 hover:bg-red-600 text-white transition-colors disabled:bg-gray-300 text-xs px-0.5 h-full flex items-center justify-center"
                  >
                    <span className="truncate">Clear</span>
                  </Button>
                  <Button
                    onClick={downloadDocx}
                    disabled={tableData.length === 0}
                    className="bg-gray-500 hover:bg-gray-600 text-white transition-colors disabled:bg-gray-300 text-xs px-0.5 h-full flex items-center justify-center"
                  >
                    <span className="truncate">Docx</span>
                  </Button>
                  <Button
                    onClick={downloadJSON}
                    disabled={tableData.length === 0}
                    className="bg-blue-500 hover:bg-blue-600 text-white transition-colors disabled:bg-gray-300 text-xs px-0.5 h-full flex items-center justify-center"
                  >
                    <span className="truncate">JSON</span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>

      {tableData.length > 0 && (
        <div className="mt-4 md:mt-6 bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="overflow-x-auto">
            <Table className="w-full">
              <TableHeader>
                <TableRow className="bg-gray-50">
                  <TableHead className="w-[45%] py-3 text-gray-700 font-semibold text-sm">Source Text</TableHead>
                  <TableHead className="w-[10%] text-center text-gray-700 font-semibold text-sm">Actions</TableHead>
                  <TableHead className="w-[45%] text-gray-700 font-semibold text-sm">Translated Text</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {tableData.map((row, index) => (
                  <TableRow 
                    key={index} 
                    className="border-b border-gray-100 hover:bg-blue-50 transition-colors"
                  >
                    <TableCell className="align-top py-3 px-3">
                      <div className="max-h-[150px] overflow-y-auto whitespace-pre-wrap break-words p-2 bg-gray-50 rounded text-sm">
                        {row.sourceText}
                      </div>
                    </TableCell>
                    <TableCell className="align-middle text-center py-3 px-2">
                      <div className="flex flex-col gap-1">
                        <Button
                          onClick={() => translateText(row.sourceText, index)}
                          disabled={processingRows.has(index) || !selectedModel}
                          className={`w-full ${
                            processingRows.has(index)
                              ? 'bg-gray-400'
                              : 'bg-blue-500 hover:bg-blue-600'
                          } text-white transition-colors disabled:bg-gray-300 text-xs py-1 px-2`}
                        >
                          {processingRows.has(index) ? 'Processing...' : 'Translate'}
                        </Button>
                        <Button
                          onClick={() => deleteRow(index)}
                          className="w-full bg-red-500 hover:bg-red-600 text-white transition-colors text-xs py-1 px-2"
                        >
                          Delete
                        </Button>
                      </div>
                    </TableCell>
                    <TableCell className="align-top py-3 px-3">
                      {editingIndex === index ? (
                        <textarea
                          value={row.translatedText}
                          onChange={(e) => {
                            const newData = [...tableData];
                            newData[index].translatedText = e.target.value;
                            setTableData(newData);
                          }}
                          onBlur={() => setEditingIndex(null)}
                          autoFocus
                          className="w-full min-h-[150px] p-2 border-2 rounded focus:border-blue-500 focus:outline-none transition-colors text-sm"
                        />
                      ) : (
                        <div
                          className="cursor-pointer hover:bg-blue-50 p-2 rounded max-h-[150px] overflow-y-auto whitespace-pre-wrap break-words bg-gray-50 transition-colors text-sm"
                          onClick={() => setEditingIndex(index)}
                        >
                          {row.translatedText ? (
                            <span>
                              {row.translatedText.split(' ').map((word, i) => {
                                const isNewWord = originalTranslations[index] && !originalTranslations[index].includes(word);
                                return (
                                  <span
                                    key={i}
                                    className={isNewWord ? 'text-red-500' : ''}
                                  >
                                    {word}{' '}
                                  </span>
                                );
                              })}
                            </span>
                          ) : (
                            row.status === 'error' ? 'Error translating' : ''
                          )}
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      )}
    </div>
  );
};

export default FileExtraction;
