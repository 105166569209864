/** @format */

const API_BASE_URL = '/utm-ittbm/api';

export const loadAIModels = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/ai-models`);
    if (!response.ok) {
      throw new Error('Failed to load AI models');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error loading AI models:', error);
    return [];
  }
};

export const saveAIModel = async (modelConfig) => {
  try {
    const response = await fetch(`${API_BASE_URL}/ai-models`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(modelConfig),
    });
    
    if (!response.ok) {
      throw new Error('Failed to save AI model');
    }
    
    return true;
  } catch (error) {
    console.error('Error saving AI model:', error);
    return false;
  }
};

export const updateAIModel = async (modelId, isActive) => {
  try {
    const response = await fetch(`${API_BASE_URL}/ai-models/${modelId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ isActive }),
    });
    
    if (!response.ok) {
      throw new Error('Failed to update AI model');
    }
    
    return true;
  } catch (error) {
    console.error('Error updating AI model:', error);
    return false;
  }
};
