/** @format */

import React, { useState, useEffect, useRef } from 'react';
import { Card, CardContent } from '../../components/ui/card';
import { Button } from '../../components/ui/button';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../components/ui/table';
import { Input } from '../../components/ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { loadAIModels } from "../../utils/aiModelUtils";

const TextBatchTranslate = () => {
  const [tableData, setTableData] = useState([]);
  const [processingRows, setProcessingRows] = useState(new Set());
  const [processingBatch, setProcessingBatch] = useState(false);
  const [selectedModel, setSelectedModel] = useState(null);
  const [availableModels, setAvailableModels] = useState([]);
  const [sourceLanguage, setSourceLanguage] = useState("English");
  const [targetLanguage, setTargetLanguage] = useState("Bahasa Melayu");
  const [batchResults, setBatchResults] = useState({});
  const [editingIndex, setEditingIndex] = useState(null);
  const [originalText, setOriginalText] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchModels = async () => {
      try {
        const models = await loadAIModels();
        const activeModels = models.filter(model => model.isActive);
        console.log('Available models:', activeModels); // Debug log
        setAvailableModels(activeModels);
        if (activeModels.length > 0 && !selectedModel) {
          setSelectedModel(activeModels[0]);
        }
      } catch (error) {
        console.error('Error fetching models:', error);
        setError('Failed to load AI models. Please try again later.');
      }
    };

    fetchModels();
  }, []);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const binaryStr = event.target.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const data = XLSX.utils.sheet_to_json(worksheet);

      // Initialize the data with empty translations
      const initializedData = data.map(row => ({
        sourceText: row.Text || row.text || Object.values(row)[0], // Try to find the text column
        translatedText: '',
        sourceWordCount: 0,
        translatedWordCount: 0,
        status: 'pending'
      }));

      setTableData(initializedData);
    };

    reader.readAsBinaryString(file);
  };

  const translateWithOpenAI = async (text, apiKey) => {
    try {
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`,
        },
        body: JSON.stringify({
          model: 'gpt-3.5-turbo',
          messages: [
            {
              role: 'system',
              content: `You are a translation assistant. Translate the following text from ${sourceLanguage} to ${targetLanguage}. Provide only the translation, no additional explanations.`
            },
            {
              role: 'user',
              content: text
            }
          ],
          temperature: 0.3
        })
      });

      if (!response.ok) {
        throw new Error(`OpenAI API error: ${response.status}`);
      }

      const data = await response.json();
      return data.choices[0].message.content.trim();
    } catch (error) {
      throw new Error(`OpenAI translation failed: ${error.message}`);
    }
  };

  const translateWithGroq = async (text, apiKey) => {
    try {
      const response = await fetch('https://api.groq.com/openai/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`,
        },
        body: JSON.stringify({
          model: selectedModel.modelId || 'mistral-saba-24b',
          messages: [
            {
              role: 'system',
              content: `You are a translation assistant. Translate the following text from ${sourceLanguage} to ${targetLanguage}. Provide only the translation, no additional explanations.`
            },
            {
              role: 'user',
              content: text
            }
          ],
          temperature: 0.3
        })
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(`Groq API error: ${response.status} - ${errorData.error?.message || 'Unknown error'}`);
      }

      const data = await response.json();
      return data.choices[0].message.content.trim();
    } catch (error) {
      throw new Error(`Groq translation failed: ${error.message}`);
    }
  };

  const translateWithOllama = async (text, endpoint = 'http://localhost:11434') => {
    try {
      console.log('Ollama Request:', {
        endpoint,
        model: selectedModel.modelId,
        text: text.substring(0, 100)
      });

      const response = await fetch(`${endpoint}/api/generate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          model: selectedModel.modelId,
          prompt: `You are a translation assistant. Your task is to translate the following text from ${sourceLanguage} to ${targetLanguage}. Only output the translation, nothing else.\n\nText to translate:\n${text}\n\nTranslation:`,
          stream: false,
          options: {
            temperature: 0.1
          }
        })
      });

      if (!response.ok) {
        throw new Error(`Ollama API error: ${response.status}`);
      }

      const data = await response.json();
      console.log('Ollama Response:', data);

      let cleanedResponse = data.response
        .replace(/<think>[\s\S]*?<\/think>/g, '')
        .replace(/Translation:/i, '')
        .trim();

      if (!cleanedResponse) {
        cleanedResponse = data.response.trim();
      }

      return cleanedResponse;
    } catch (error) {
      console.error('Ollama error:', error);
      throw new Error(`Ollama translation failed: ${error.message}`);
    }
  };

  const translateWithHuggingFace = async (text, apiKey, modelId, endpoint) => {
    try {
      const apiUrl = endpoint || `https://api-inference.huggingface.co/models/${modelId}`;
      
      console.log('HF Request:', {
        url: apiUrl,
        modelId,
        hasApiKey: !!apiKey,
        text: text.substring(0, 100) // Log first 100 chars only
      });

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`
        },
        body: JSON.stringify({
          inputs: text,
          options: {
            wait_for_model: true,
            use_cache: true
          }
        })
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        console.error('HF Error:', errorData);
        throw new Error(`Hugging Face API error: ${response.status} - ${errorData.error || 'Unknown error'}`);
      }

      const data = await response.json();
      console.log('HF Response:', data);

      // Handle different response formats
      if (Array.isArray(data)) {
        // Translation model response
        if (data[0]?.translation_text) {
          return data[0].translation_text;
        }
        // Text generation response
        if (data[0]?.generated_text) {
          return data[0].generated_text;
        }
        // Raw text array response
        if (typeof data[0] === 'string') {
          return data[0];
        }
        // If we can't find a valid response format, log and throw error
        console.error('Unexpected HF array response format:', data);
        throw new Error('Unexpected response format from Hugging Face API');
      }
      
      // Single object response
      if (data.translation_text) {
        return data.translation_text;
      }
      if (data.generated_text) {
        return data.generated_text;
      }
      if (typeof data === 'string') {
        return data;
      }
      
      // If we can't find a valid response format, log and throw error
      console.error('Unexpected HF response format:', data);
      throw new Error('Unexpected response format from Hugging Face API');
    } catch (error) {
      console.error('HF Translation error:', error);
      throw new Error(`Hugging Face translation failed: ${error.message}`);
    }
  };

  const handleRowTranslation = async (index) => {
    if (!selectedModel) {
      alert('Please select a translation model first');
      return;
    }

    setProcessingRows(prev => new Set([...prev, index]));
    
    try {
      const row = tableData[index];
      let translatedText;

      // Log the translation attempt
      console.log('Starting translation for row:', index, {
        model: selectedModel,
        sourceText: row.sourceText
      });

      switch (selectedModel.type) {
        case 'openai':
          translatedText = await translateWithOpenAI(row.sourceText, selectedModel.apiKey);
          break;
        case 'groq':
          translatedText = await translateWithGroq(row.sourceText, selectedModel.apiKey);
          break;
        case 'ollama':
          translatedText = await translateWithOllama(row.sourceText, selectedModel.endpoint);
          break;
        case 'huggingface':
          translatedText = await translateWithHuggingFace(
            row.sourceText, 
            selectedModel.apiKey, 
            selectedModel.modelId, 
            selectedModel.endpoint
          );
          break;
        default:
          throw new Error('Unsupported model type');
      }

      // Validate translation result
      if (translatedText === undefined || translatedText === null) {
        throw new Error('Translation result is empty');
      }

      // Ensure translatedText is a string
      translatedText = String(translatedText);

      // Calculate word counts
      const sourceWordCount = row.sourceText.trim().split(/\s+/).length;
      const translatedWordCount = translatedText.trim().split(/\s+/).length;

      const updatedData = [...tableData];
      updatedData[index] = {
        ...row,
        translatedText,
        sourceWordCount,
        translatedWordCount,
        status: 'completed'
      };
      setTableData(updatedData);

      // Save to translation history
      const textTranslations = JSON.parse(localStorage.getItem('textTranslations') || '[]');
      const newTranslation = {
        id: Date.now().toString(),
        sourceText: row.sourceText,
        translatedText,
        timestamp: new Date().toISOString(),
        sourceLanguage,
        targetLanguage,
        modelType: selectedModel.type,
        sourceWordCount,
        translatedWordCount,
        status: 'completed'
      };
      textTranslations.push(newTranslation);
      localStorage.setItem('textTranslations', JSON.stringify(textTranslations));

    } catch (error) {
      console.error('Translation error:', error);
      const updatedData = [...tableData];
      updatedData[index] = {
        ...updatedData[index],
        translatedText: 'Translation failed: ' + error.message,
        status: 'failed'
      };
      setTableData(updatedData);
    } finally {
      setProcessingRows(prev => {
        const newSet = new Set(prev);
        newSet.delete(index);
        return newSet;
      });
    }
  };

  const handleBatchTranslate = async () => {
    if (!selectedModel) {
      alert('Please select a translation model first');
      return;
    }

    if (tableData.length === 0) {
      alert('Please upload a file first');
      return;
    }

    setProcessingBatch(true);

    try {
      // Create a copy of the current state that we'll update throughout the process
      let updatedData = [...tableData];

      // Process all rows in sequence
      for (let i = 0; i < tableData.length; i++) {
        setProcessingRows(prev => new Set([...prev, i]));
        
        try {
          const row = updatedData[i];
          let translatedText;

          // Perform translation based on selected model
          switch (selectedModel.type) {
            case 'openai':
              translatedText = await translateWithOpenAI(row.sourceText, selectedModel.apiKey);
              break;
            case 'groq':
              translatedText = await translateWithGroq(row.sourceText, selectedModel.apiKey);
              break;
            case 'ollama':
              translatedText = await translateWithOllama(row.sourceText, selectedModel.endpoint);
              break;
            case 'huggingface':
              translatedText = await translateWithHuggingFace(
                row.sourceText,
                selectedModel.apiKey,
                selectedModel.modelId,
                selectedModel.endpoint
              );
              break;
            default:
              throw new Error('Unsupported model type');
          }

          // Validate translation result
          if (translatedText === undefined || translatedText === null) {
            throw new Error('Translation result is empty');
          }

          // Ensure translatedText is a string
          translatedText = String(translatedText);

          // Calculate word counts
          const sourceWordCount = row.sourceText.trim().split(/\s+/).length;
          const translatedWordCount = translatedText.trim().split(/\s+/).length;

          // Create the result object
          const result = {
            translatedText,
            sourceWordCount,
            translatedWordCount,
            status: 'completed'
          };

          // Update the data array
          updatedData[i] = {
            ...row,
            ...result
          };

          // Update the table data immediately to show progress
          setTableData([...updatedData]);

          // Save to translation history immediately
          const textTranslations = JSON.parse(localStorage.getItem('textTranslations') || '[]');
          const newTranslation = {
            id: Date.now().toString(),
            sourceText: row.sourceText,
            translatedText,
            timestamp: new Date().toISOString(),
            sourceLanguage,
            targetLanguage,
            modelType: selectedModel.type,
            sourceWordCount,
            translatedWordCount,
            status: 'completed'
          };
          textTranslations.push(newTranslation);
          localStorage.setItem('textTranslations', JSON.stringify(textTranslations));

        } catch (error) {
          console.error('Translation error for row', i, error);
          
          // Update the failed row
          updatedData[i] = {
            ...updatedData[i],
            translatedText: 'Translation failed: ' + error.message,
            status: 'failed'
          };

          // Update the table data immediately to show the error
          setTableData([...updatedData]);
        } finally {
          setProcessingRows(prev => {
            const newSet = new Set(prev);
            newSet.delete(i);
            return newSet;
          });
        }

        // Add a small delay between translations to avoid rate limiting
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
    } finally {
      setProcessingBatch(false);
    }
  };

  const handleEdit = (index) => {
    setOriginalText(tableData[index].translatedText);
    setEditingIndex(index);
    // Wait for the textarea to be rendered, then adjust its height
    setTimeout(() => {
      const textarea = document.getElementById(`edit-textarea-${index}`);
      if (textarea) {
        adjustTextareaHeight(textarea);
      }
    }, 0);
  };

  const handleCancel = (index) => {
    const updatedData = [...tableData];
    updatedData[index].translatedText = originalText;
    setTableData(updatedData);
    setEditingIndex(null);
    setOriginalText('');
  };

  const adjustTextareaHeight = (element) => {
    element.style.height = 'auto';
    element.style.height = (element.scrollHeight) + 'px';
  };

  const handleTextareaChange = (e, index) => {
    const updatedData = [...tableData];
    updatedData[index].translatedText = e.target.value;
    setTableData(updatedData);
    adjustTextareaHeight(e.target);
  };

  const handleSaveEdit = (index, newText) => {
    const updatedData = [...tableData];
    updatedData[index] = {
      ...updatedData[index],
      translatedText: newText,
      status: 'completed'
    };
    setTableData(updatedData);
    setEditingIndex(null);
  };

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      tableData.map(row => ({
        'Source Text': row.sourceText,
        'Translated Text': row.translatedText,
        'Status': row.status,
        'Source Word Count': row.sourceWordCount,
        'Translated Word Count': row.translatedWordCount
      }))
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Translations");
    XLSX.writeFile(workbook, `batch_translations_${new Date().toISOString().split('T')[0]}.xlsx`);
  };

  const handleNewSession = () => {
    // Confirm before clearing if there's data
    if (tableData.length > 0) {
      if (!window.confirm('Are you sure you want to start a new session? This will clear all current translations.')) {
        return;
      }
    }

    // Clear all states
    setTableData([]);
    setProcessingRows(new Set());
    setProcessingBatch(false);
    setEditingIndex(null);
    
    // Clear the file input
    const fileInput = document.getElementById('excel-file');
    if (fileInput) {
      fileInput.value = '';
    }
  };

  return (
    <div className="container mx-auto px-4 py-6 max-w-7xl">
      <Card className="bg-white shadow-lg rounded-lg overflow-hidden border-t-4 border-blue-500">
        <CardContent className="p-4 md:p-6">
          <div className="flex flex-col gap-4">
            <div className="space-y-2">
              <h2 className="text-2xl md:text-3xl font-bold text-gray-800">Batch Translation</h2>
              <p className="text-sm md:text-base text-gray-600">
                Upload an Excel file (.xlsx) to translate text in batch using AI.
              </p>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-3 md:gap-4">
              <div className="flex flex-col space-y-1">
                <label htmlFor="model-select" className="text-sm font-semibold text-gray-700">
                  Translation Engine Model
                </label>
                <Select
                  value={selectedModel?.id.toString()}
                  onValueChange={(value) => {
                    const model = availableModels.find(m => m.id.toString() === value);
                    setSelectedModel(model);
                  }}
                >
                  <SelectTrigger className="w-full bg-white border border-gray-300 hover:border-gray-400 focus:border-gray-500">
                    <SelectValue placeholder="Select AI Model" className="text-gray-700" />
                  </SelectTrigger>
                  <SelectContent className="bg-white border border-gray-200 shadow-lg">
                    {availableModels.map((model) => (
                      <SelectItem 
                        key={model.id} 
                        value={model.id.toString()}
                        className="hover:bg-gray-100 focus:bg-gray-100 cursor-pointer py-2 text-gray-700"
                      >
                        {model.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              <div className="flex flex-col space-y-1">
                <label htmlFor="excel-file" className="text-sm font-semibold text-gray-700">
                  Upload Excel File
                </label>
                <Input
                  id="excel-file"
                  type="file"
                  accept=".xlsx,.xls"
                  onChange={handleFileUpload}
                  className="w-full border border-gray-300 hover:border-blue-500 focus:border-blue-500"
                />
              </div>

              <div className="flex flex-col space-y-1 sm:col-span-2">
                <label className="text-sm font-semibold text-gray-700">
                  Actions
                </label>
                <div className="flex gap-2">
                  <Button
                    onClick={handleNewSession}
                    variant="outline"
                    className="bg-gray-100 text-gray-700 hover:bg-gray-200 border border-gray-300"
                  >
                    New Session
                  </Button>
                  {tableData.length > 0 && (
                    <>
                      <Button 
                        onClick={handleBatchTranslate} 
                        className="bg-blue-500 text-white hover:bg-blue-600"
                        disabled={processingBatch}
                      >
                        {processingBatch ? 'Translating...' : 'Translate All'}
                      </Button>
                      <Button 
                        onClick={downloadExcel}
                        className="bg-green-500 text-white hover:bg-green-600"
                      >
                        Download Results
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </div>

            {tableData.length > 0 && (
              <div className="mt-6">
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead className="font-semibold">Source Text</TableHead>
                      <TableHead className="font-semibold w-24">Action</TableHead>
                      <TableHead className="font-semibold">Translated Text</TableHead>
                      <TableHead className="font-semibold w-24">Status</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {tableData.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell className="align-top">{row.sourceText}</TableCell>
                        <TableCell className="align-top">
                          <Button
                            onClick={() => handleRowTranslation(index)}
                            disabled={processingRows.has(index)}
                            size="sm"
                            className="w-full bg-blue-500 text-white hover:bg-blue-600"
                          >
                            {processingRows.has(index) ? 'Translating...' : 'Translate'}
                          </Button>
                        </TableCell>
                        <TableCell className="align-top">
                          {editingIndex === index ? (
                            <div className="flex flex-col gap-2 w-full">
                              <textarea
                                id={`edit-textarea-${index}`}
                                value={row.translatedText}
                                onChange={(e) => handleTextareaChange(e, index)}
                                className="w-full min-h-[100px] p-2 border rounded border-gray-300 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                                style={{
                                  resize: 'none',
                                  overflowY: 'hidden',
                                  lineHeight: '1.5'
                                }}
                              />
                              <div className="flex justify-end gap-2">
                                <Button
                                  onClick={() => handleCancel(index)}
                                  size="sm"
                                  variant="outline"
                                  className="border-gray-300 hover:bg-gray-100"
                                >
                                  Cancel
                                </Button>
                                <Button
                                  onClick={() => handleSaveEdit(index, row.translatedText)}
                                  size="sm"
                                  className="bg-green-500 text-white hover:bg-green-600"
                                >
                                  Save
                                </Button>
                              </div>
                            </div>
                          ) : (
                            <div className="flex justify-between items-start gap-2">
                              <span className="whitespace-pre-wrap">{row.translatedText}</span>
                              <Button
                                onClick={() => handleEdit(index)}
                                size="sm"
                                variant="ghost"
                                className="text-gray-500 hover:text-blue-500 shrink-0"
                              >
                                Edit
                              </Button>
                            </div>
                          )}
                        </TableCell>
                        <TableCell className="align-top">
                          <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                            ${row.status === 'completed' ? 'bg-green-100 text-green-800' : 
                              row.status === 'failed' ? 'bg-red-100 text-red-800' : 
                              'bg-gray-100 text-gray-800'}`}>
                            {row.status === 'completed' ? 'Done' : 
                             row.status === 'failed' ? 'Failed' : 
                             'Pending'}
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default TextBatchTranslate;
