/** @format */

import React, { useState, useEffect, useCallback } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../../components/ui/breadcrumb";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import { Search, Download, RefreshCw, FileJson } from "lucide-react";
import { toast } from "../../components/ui/use-toast";
import { Link } from "react-router-dom";
import axios from 'axios';

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000; // 1 second

const ResourcesTermBase = () => {
  const [terms, setTerms] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredTerms, setFilteredTerms] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [resourceFiles, setResourceFiles] = useState([]);
  const [retryCount, setRetryCount] = useState(0);
  const [selectedLetter, setSelectedLetter] = useState(null);
  const [lastRefreshed, setLastRefreshed] = useState(Date.now());
  const [loadingProgress, setLoadingProgress] = useState({ loaded: 0, total: 0 });

  const loadResourceFiles = useCallback(async (retry = 0) => {
    try {
      setIsLoading(true);
      setError(null);
      
      // Add cache-busting parameter to prevent caching
      const response = await axios.get(`/utm-ittbm/api/resources/files?t=${Date.now()}`);
      console.log('Resource files response:', response.data);
      
      if (Array.isArray(response.data)) {
        // Filter out .DS_Store and other non-JSON files
        const jsonFiles = response.data.filter(file => file.name.endsWith('.json'));
        if (jsonFiles.length > 0) {
          setResourceFiles(jsonFiles);
          setRetryCount(0); // Reset retry count on success
          setLastRefreshed(Date.now());
          
          // Load all files content
          await loadAllFilesContent(jsonFiles);
        } else {
          throw new Error('No JSON files found in resources directory');
        }
      } else {
        throw new Error('Invalid response format for resource files');
      }
    } catch (error) {
      console.error('Error loading resource files:', error);
      
      // Retry logic
      if (retry < MAX_RETRIES) {
        console.log(`Retrying... Attempt ${retry + 1} of ${MAX_RETRIES}`);
        setRetryCount(retry + 1);
        setTimeout(() => loadResourceFiles(retry + 1), RETRY_DELAY);
        return;
      }
      
      setError('Failed to load resource files. Please try again.');
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to load resource files",
      });
    } finally {
      setIsLoading(false);
    }
  }, []);

  const loadFileContent = useCallback(async (filename, retry = 0) => {
    try {
      const response = await axios.get(`/utm-ittbm/api/resources/file/${encodeURIComponent(filename)}`);
      console.log(`File content response for ${filename}:`, response.data);
      
      if (Array.isArray(response.data)) {
        // Filter out any invalid entries
        const validTerms = response.data.filter(term => 
          term && 
          typeof term === 'object' && 
          typeof term.english === 'string' && 
          typeof term.malay === 'string'
        ).map(term => ({
          ...term,
          sourceFile: filename // Add source file information
        }));
        
        return validTerms;
      } else {
        throw new Error('Invalid file content format');
      }
    } catch (error) {
      console.error(`Error loading file content for ${filename}:`, error);
      
      // Retry logic
      if (retry < MAX_RETRIES) {
        console.log(`Retrying... Attempt ${retry + 1} of ${MAX_RETRIES}`);
        return loadFileContent(filename, retry + 1);
      }
      
      toast({
        variant: "destructive",
        title: "Error",
        description: `Failed to load content from ${filename}`,
      });
      
      return [];
    }
  }, []);

  const loadAllFilesContent = useCallback(async (files) => {
    setLoadingProgress({ loaded: 0, total: files.length });
    
    const allTerms = [];
    
    for (let i = 0; i < files.length; i++) {
      const fileTerms = await loadFileContent(files[i].name);
      allTerms.push(...fileTerms);
      setLoadingProgress({ loaded: i + 1, total: files.length });
    }
    
    // Sort all terms alphabetically
    const sortedTerms = allTerms.sort((a, b) => 
      a.english.toLowerCase().localeCompare(b.english.toLowerCase())
    );
    
    setTerms(sortedTerms);
    setFilteredTerms(sortedTerms);
    
    toast({
      title: "Terms loaded",
      description: `Loaded ${sortedTerms.length} terms from ${files.length} files`,
    });
  }, [loadFileContent]);

  // Load resource files on component mount
  useEffect(() => {
    loadResourceFiles();
  }, [loadResourceFiles]);

  // Filter terms when search query changes or letter is selected
  useEffect(() => {
    let filtered = terms;
    
    // Apply search filter if query exists
    if (searchQuery.trim()) {
      filtered = terms.filter(term => 
        term.english.toLowerCase().includes(searchQuery.toLowerCase()) ||
        term.malay.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
    
    // Apply letter filter if a letter is selected and no search query
    else if (selectedLetter && !searchQuery.trim()) {
      filtered = terms.filter(term => 
        term.english.toLowerCase().startsWith(selectedLetter.toLowerCase())
      );
    }
    
    setFilteredTerms(filtered);
  }, [searchQuery, terms, selectedLetter]);

  // Add a periodic refresh for resource files (every 5 minutes)
  useEffect(() => {
    const intervalId = setInterval(() => {
      loadResourceFiles();
    }, 300000); // 5 minutes
    
    return () => clearInterval(intervalId);
  }, [loadResourceFiles]);

  const handleManualRefresh = () => {
    loadResourceFiles();
    toast({
      title: "Refreshing",
      description: "Loading all terms from resource files...",
    });
  };

  const highlightSearchText = (text, searchQuery) => {
    if (!searchQuery.trim()) return text;
    
    const regex = new RegExp(`(${searchQuery})`, 'gi');
    const parts = text.split(regex);
    
    return parts.map((part, index) => {
      if (part.toLowerCase() === searchQuery.toLowerCase()) {
        return <span key={index} className="text-red-500 font-medium">{part}</span>;
      }
      return part;
    });
  };

  const handleExportTerms = () => {
    if (filteredTerms.length === 0) {
      toast({
        variant: "destructive",
        title: "No terms to export",
        description: "There are no terms available to export.",
      });
      return;
    }

    // Create a CSV string
    const csvContent = [
      "English,Malay", // Header row
      ...filteredTerms.map(term => `"${term.english.replace(/"/g, '""')}","${term.malay.replace(/"/g, '""')}"`)
    ].join("\n");

    // Create a Blob and download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `all_terms.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleLetterClick = (letter) => {
    setSearchQuery(""); // Clear search when selecting a letter
    setSelectedLetter(letter === selectedLetter ? null : letter);
  };

  const getAlphabetLetters = () => {
    // Generate A-Z array
    return Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i));
  };

  const getAvailableLetters = () => {
    // Get unique first letters from all terms
    const uniqueLetters = new Set();
    terms.forEach(term => {
      if (term.english && term.english.length > 0) {
        uniqueLetters.add(term.english.charAt(0).toUpperCase());
      }
    });
    return Array.from(uniqueLetters).sort();
  };

  return (
    <>
      <Breadcrumb className="pl-6 pt-6">
        <span className="text-xl font-semibold">Dictionary Base</span>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink href="/dashboard">Term Base</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator>/</BreadcrumbSeparator>
          <BreadcrumbItem>
            <BreadcrumbPage>Dictionary</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <div className="p-6">
        <Card>
          <CardHeader>
            <div className="flex justify-between items-start">
              <div>
                <CardTitle>Dictionary Base</CardTitle>
                <CardDescription>
                  Browse and search words
                  {lastRefreshed && (
                    <span className="text-xs text-gray-500 block mt-1">
                      Last refreshed: {new Date(lastRefreshed).toLocaleTimeString()}
                    </span>
                  )}
                </CardDescription>
              </div>
              <div className="flex gap-4">
                <Button
                  variant="outline"
                  onClick={handleManualRefresh}
                  disabled={isLoading}
                  className="flex items-center gap-2 bg-white border-gray-300 hover:bg-gray-50"
                >
                  <RefreshCw className={`h-4 w-4 ${isLoading ? 'animate-spin' : ''}`} />
                  Refresh 
                </Button>
                <div className="relative w-[300px]">
                  <Search className="absolute left-2 top-2.5 h-4 w-4 text-gray-500" />
                  <Input
                    type="text"
                    placeholder="Search words..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="pl-8 bg-white border-gray-300"
                  />
                </div>
                <Button
                  variant="outline"
                  size="icon"
                  onClick={handleExportTerms}
                  disabled={filteredTerms.length === 0}
                  title="Export to CSV"
                  className="bg-white border-gray-300 hover:bg-gray-50"
                >
                  <Download className="h-4 w-4" />
                </Button>
              </div>
            </div>
          </CardHeader>
          <CardContent>
            {/* Resource Files Summary */}
            <div className="mb-6">
              <h2 className="text-xl font-semibold text-gray-900 mb-2">
                Dictionary Database
                {retryCount > 0 && (
                  <span className="ml-2 text-sm text-gray-500">
                    (Retrying... {retryCount}/{MAX_RETRIES})
                  </span>
                )}
              </h2>
              <div className="text-sm text-gray-600 mb-2">
                {resourceFiles.length > 0 ? (
                  <p>Loaded {resourceFiles.length} resource files with a total of {terms.length} terms</p>
                ) : (
                  <p>No resource files found</p>
                )}
              </div>
              {isLoading && loadingProgress.total > 0 && (
                <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
                  <div 
                    className="bg-blue-600 h-2.5 rounded-full" 
                    style={{ width: `${(loadingProgress.loaded / loadingProgress.total) * 100}%` }}
                  ></div>
                  <p className="text-xs text-gray-500 mt-1">
                    Loading files: {loadingProgress.loaded}/{loadingProgress.total}
                  </p>
                </div>
              )}
            </div>

            {/* Alphabetical Navigation */}
            {!isLoading && filteredTerms.length > 0 && !searchQuery && (
              <div className="mb-6">
                <div className="flex flex-wrap gap-2 justify-center">
                  {getAlphabetLetters().map(letter => {
                    const isAvailable = getAvailableLetters().includes(letter);
                    return (
                      <button
                        key={letter}
                        onClick={() => isAvailable && handleLetterClick(letter)}
                        className={`w-8 h-8 flex items-center justify-center rounded-full text-sm font-medium transition-colors
                          ${selectedLetter === letter 
                            ? 'bg-indigo-600 text-white' 
                            : isAvailable 
                              ? 'bg-gray-100 text-gray-800 hover:bg-gray-200' 
                              : 'bg-gray-50 text-gray-300 cursor-default'
                          }`}
                        disabled={!isAvailable}
                      >
                        {letter}
                      </button>
                    );
                  })}
                  {selectedLetter && (
                    <button
                      onClick={() => setSelectedLetter(null)}
                      className="ml-2 px-3 py-1 text-xs bg-gray-200 hover:bg-gray-300 rounded-full"
                    >
                      Clear
                    </button>
                  )}
                </div>
              </div>
            )}

            {/* Table */}
            {isLoading && terms.length === 0 ? (
              <div className="text-center py-4">
                <div className="flex justify-center items-center">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
                  <span className="ml-3">Loading terms from all files...</span>
                </div>
              </div>
            ) : (
              <>
                {selectedLetter && !searchQuery && (
                  <div className="mb-4 px-2">
                    <h3 className="text-lg font-medium">
                      Terms starting with "{selectedLetter}" ({filteredTerms.length})
                    </h3>
                  </div>
                )}
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead className="w-[50px]">No.</TableHead>
                      <TableHead>English Words</TableHead>
                      <TableHead>Malay Words</TableHead>
                      <TableHead>Source File</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {filteredTerms.length > 0 ? (
                      filteredTerms.map((term, index) => (
                        <TableRow key={index}>
                          <TableCell className="font-medium">{index + 1}</TableCell>
                          <TableCell>
                            {highlightSearchText(term.english, searchQuery)}
                          </TableCell>
                          <TableCell>
                            {highlightSearchText(term.malay, searchQuery)}
                          </TableCell>
                          <TableCell className="text-xs text-gray-500">
                            {term.sourceFile}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={4} className="text-center py-4">
                          {error ? (
                            <span className="text-red-500">{error}</span>
                          ) : (
                            searchQuery ? 
                              "No matching terms found" : 
                              "No terms available in the resource files"
                          )}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </>
            )}
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default ResourcesTermBase; 