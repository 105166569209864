/** @format */

import React, { useState, useEffect } from "react";
import { Button } from "../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../../components/ui/dialog";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../../components/ui/breadcrumb";
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  flexRender,
} from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { Languages, Save, Download, FileDown, Pencil, RefreshCw, Trash2 } from "lucide-react";
import { saveAs } from 'file-saver';
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from "../../components/ui/card";
import { Table, TableHeader, TableBody, TableRow, TableHead, TableCell } from "../../components/ui/table";
import { loadAIModels } from "../../utils/aiModelUtils";

const TextTranslate = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "textTranslate",
  });

  const [sourceText, setSourceText] = useState("");
  const [translatedText, setTranslatedText] = useState("");
  const [sourceLanguage, setSourceLanguage] = useState("English");
  const [targetLanguage, setTargetLanguage] = useState("Bahasa Melayu");
  const [savedTranslations, setSavedTranslations] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null);
  const [availableModels, setAvailableModels] = useState([]);
  const [editingTranslation, setEditingTranslation] = useState(null);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [editedSourceText, setEditedSourceText] = useState("");
  const [editedTranslatedText, setEditedTranslatedText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchModels = async () => {
      try {
        const models = await loadAIModels();
        const activeModels = models.filter(model => model.isActive);
        console.log('Available models:', activeModels); // Debug log
        setAvailableModels(activeModels);
        if (activeModels.length > 0 && !selectedModel) {
          setSelectedModel(activeModels[0]);
        }
      } catch (error) {
        console.error('Error fetching models:', error);
        setError('Failed to load AI models. Please try again later.');
      }
    };

    fetchModels();
  }, []);

  const handleTranslate = async () => {
    if (!sourceText.trim()) {
      alert("Please enter text to translate");
      return;
    }

    if (!selectedModel) {
      alert("Please select a translation model");
      return;
    }

    setIsLoading(true);
    try {
      let translatedResult;
      
      switch (selectedModel.type) {
        case 'openai':
          translatedResult = await translateWithOpenAI(sourceText, selectedModel.apiKey);
          break;
        case 'groq':
          translatedResult = await translateWithGroq(sourceText, selectedModel.apiKey);
          break;
        case 'ollama':
          translatedResult = await translateWithOllama(sourceText, selectedModel.endpoint);
          break;
        case 'huggingface':
          translatedResult = await translateWithHuggingFace(
            sourceText, 
            selectedModel.apiKey, 
            selectedModel.modelId, 
            selectedModel.endpoint
          );
          break;
        default:
          throw new Error('Unsupported model type');
      }

      setTranslatedText(translatedResult);

      // Calculate word counts
      const sourceWordCount = sourceText.trim().split(/\s+/).length;
      const translatedWordCount = translatedResult.trim().split(/\s+/).length;

      // Save translation to localStorage for statistics
      const textTranslations = JSON.parse(localStorage.getItem('textTranslations') || '[]');
      const newTranslation = {
        id: Date.now().toString(),
        sourceText,
        translatedText: translatedResult,
        timestamp: new Date().toISOString(),
        sourceLanguage,
        targetLanguage,
        modelType: selectedModel.type,
        sourceWordCount,
        translatedWordCount,
        status: 'completed'
      };
      textTranslations.push(newTranslation);
      localStorage.setItem('textTranslations', JSON.stringify(textTranslations));

      // Save to history if translation was successful
      setSavedTranslations(prev => [newTranslation, ...prev]);
    } catch (error) {
      console.error('Translation error:', error);
      alert("Translation failed: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = () => {
    if (!sourceText.trim() || !translatedText.trim()) {
      alert("Please translate some text before saving");
      return;
    }

    const sourceWordCount = sourceText.trim().split(/\s+/).length;
    const translatedWordCount = translatedText.trim().split(/\s+/).length;

    const translation = {
      id: Date.now().toString(),
      sourceText,
      translatedText,
      sourceLanguage,
      targetLanguage,
      modelType: selectedModel.type,
      timestamp: new Date().toISOString(),
      sourceWordCount,
      translatedWordCount,
      status: 'completed'
    };

    const textTranslations = JSON.parse(localStorage.getItem('textTranslations') || '[]');
    textTranslations.push(translation);
    localStorage.setItem('textTranslations', JSON.stringify(textTranslations));
    setSavedTranslations(prev => [translation, ...prev]);
  };

  const handleEdit = (translation) => {
    setEditingTranslation(translation);
    setEditedSourceText(translation.sourceText);
    setEditedTranslatedText(translation.translatedText);
    setIsEditDialogOpen(true);
  };

  const handleSaveEdit = () => {
    if (!editedSourceText.trim() || !editedTranslatedText.trim()) {
      alert("Both source and translated text must not be empty");
      return;
    }

    // Calculate word counts for edited text
    const sourceWordCount = editedSourceText.trim().split(/\s+/).length;
    const translatedWordCount = editedTranslatedText.trim().split(/\s+/).length;

    // Create updated translation object
    const updatedTranslation = {
      ...editingTranslation,
      sourceText: editedSourceText,
      translatedText: editedTranslatedText,
      sourceWordCount,
      translatedWordCount,
      timestamp: new Date().toISOString()
    };

    // Update localStorage
    const textTranslations = JSON.parse(localStorage.getItem('textTranslations') || '[]');
    const updatedTranslations = textTranslations.map(t => 
      t.id === editingTranslation.id ? updatedTranslation : t
    );
    localStorage.setItem('textTranslations', JSON.stringify(updatedTranslations));

    // Update UI state
    setSavedTranslations(prev =>
      prev.map(t => t.id === editingTranslation.id ? updatedTranslation : t)
    );
    setSourceText(editedSourceText);
    setTranslatedText(editedTranslatedText);
    setIsEditDialogOpen(false);
    setEditingTranslation(null);
    setEditedSourceText("");
    setEditedTranslatedText("");
  };

  const handleDownload = (translation) => {
    const jsonContent = [
      {
        english: translation.sourceLanguage === "English" ? translation.sourceText : translation.translatedText,
        malay: translation.sourceLanguage === "Bahasa Melayu" ? translation.sourceText : translation.translatedText
      }
    ];

    const blob = new Blob([JSON.stringify(jsonContent, null, 2)], {
      type: "application/json;charset=utf-8"
    });

    saveAs(blob, `translation_${translation.timestamp}.json`);
  };

  const handleDownloadAll = () => {
    if (savedTranslations.length === 0) {
      alert("No translations to download");
      return;
    }

    const jsonContent = savedTranslations.map(translation => ({
      english: translation.sourceLanguage === "English" ? translation.sourceText : translation.translatedText,
      malay: translation.sourceLanguage === "Bahasa Melayu" ? translation.sourceText : translation.translatedText
    }));

    const blob = new Blob([JSON.stringify(jsonContent, null, 2)], {
      type: "application/json;charset=utf-8"
    });

    saveAs(blob, `all_translations_${new Date().toISOString().split('T')[0]}.json`);
  };

  const handleClear = () => {
    setSourceText("");
    setTranslatedText("");
    setIsLoading(false);
  };

  // Translation service functions
  const translateWithOpenAI = async (text, apiKey) => {
    try {
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`,
        },
        body: JSON.stringify({
          model: 'gpt-3.5-turbo',
          messages: [
            {
              role: 'system',
              content: `You are a translation assistant. Translate the following text from ${sourceLanguage} to ${targetLanguage}. Provide only the translation, no additional explanations.`
            },
            {
              role: 'user',
              content: text
            }
          ],
          temperature: 0.3
        })
      });

      if (!response.ok) {
        throw new Error(`OpenAI API error: ${response.status}`);
      }

      const data = await response.json();
      return data.choices[0].message.content.trim();
    } catch (error) {
      throw new Error(`OpenAI translation failed: ${error.message}`);
    }
  };

  const translateWithGroq = async (text, apiKey) => {
    try {
      const response = await fetch('https://api.groq.com/openai/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`,
        },
        body: JSON.stringify({
          model: selectedModel.modelId || 'mistral-saba-24b',
         
          messages: [
            {
              role: 'system',
              content: `You are a translation assistant. Translate the following text from ${sourceLanguage} to ${targetLanguage}. Provide only the translation, no additional explanations.`
            },
            {
              role: 'user',
              content: text
            }
          ],
          temperature: 0.3
        })
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(`Groq API error: ${response.status} - ${errorData.error?.message || 'Unknown error'}`);
      }

      const data = await response.json();
      return data.choices[0].message.content.trim();
    } catch (error) {
      throw new Error(`Groq translation failed: ${error.message}`);
    }
  };

  const translateWithOllama = async (text, endpoint = 'http://localhost:11434') => {
    try {
      // Log request details for debugging
      console.log('Ollama Request:', {
        endpoint,
        model: selectedModel.modelId,
        text: text.substring(0, 100) // Log first 100 chars only
      });

      const response = await fetch(`${endpoint}/api/generate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          model: selectedModel.modelId,
          prompt: `You are a translation assistant. Your task is to translate the following text from ${sourceLanguage} to ${targetLanguage}. Only output the translation, nothing else.\n\nText to translate:\n${text}\n\nTranslation:`,
          stream: false,
          options: {
            temperature: 0.1
          }
        })
      });

      if (!response.ok) {
        throw new Error(`Ollama API error: ${response.status}`);
      }

      const data = await response.json();
      console.log('Ollama Response:', data); // Debug log

      // Clean up the response by removing any thinking process or extra text
      let cleanedResponse = data.response
        .replace(/<think>[\s\S]*?<\/think>/g, '') // Remove thinking process
        .replace(/Translation:/i, '') // Remove "Translation:" prefix
        .trim();

      // If the response is empty after cleaning, use the original response
      if (!cleanedResponse) {
        cleanedResponse = data.response.trim();
      }

      return cleanedResponse;
    } catch (error) {
      console.error('Ollama error:', error);
      throw new Error(`Ollama translation failed: ${error.message}`);
    }
  };

  const translateWithHuggingFace = async (text, apiKey, modelId, endpoint) => {
    try {
      const apiUrl = endpoint || `https://api-inference.huggingface.co/models/${modelId}`;
      
      // Log request details for debugging
      console.log('HF Request:', {
        url: apiUrl,
        modelId,
        hasApiKey: !!apiKey
      });

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`
        },
        body: JSON.stringify({
          inputs: text,
          options: {
            wait_for_model: true
          }
        })
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        console.error('HF Error:', errorData);
        throw new Error(`Hugging Face API error: ${response.status} - ${errorData.error || 'Unknown error'}`);
      }

      const data = await response.json();
      
      // Handle different response formats
      if (Array.isArray(data)) {
        // Translation model response
        if (data[0]?.translation_text) {
          return data[0].translation_text;
        }
        // Text generation response
        if (data[0]?.generated_text) {
          return data[0].generated_text;
        }
        // Raw text array response
        return data[0];
      }
      
      // Single object response
      if (data.translation_text) {
        return data.translation_text;
      }
      if (data.generated_text) {
        return data.generated_text;
      }
      
      // Raw text response
      return data;
    } catch (error) {
      console.error('HF Translation error:', error);
      throw new Error(`Hugging Face translation failed: ${error.message}`);
    }
  };

  const getModelIcon = (type) => {
    switch (type) {
      case 'openai':
        return <Languages className="h-4 w-4 text-green-500" />;
      case 'groq':
        return <Languages className="h-4 w-4 text-blue-500" />;
      case 'ollama':
        return <Languages className="h-4 w-4 text-purple-500" />;
      case 'huggingface':
        return <Languages className="h-4 w-4 text-yellow-500" />;
      default:
        return <Languages className="h-4 w-4 text-gray-500" />;
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      <div className="container mx-auto px-6 py-6 space-y-6">
        <div className="flex justify-between items-center">
          <div>
            <h1 className="text-2xl font-bold">{t("title")}</h1>
          </div>
        </div>

        {/* Model Selection */}
        <Card>
          <CardHeader>
            <CardTitle>Translation Model</CardTitle>
            <CardDescription>Select the AI model to use for translation</CardDescription>
          </CardHeader>
          <CardContent>
            <div className="flex items-center gap-2">
              <select
                className="flex-1 border p-2 rounded"
                value={selectedModel?.id || ""}
                onChange={(e) => {
                  const model = availableModels.find(m => m.id.toString() === e.target.value);
                  setSelectedModel(model);
                }}
              >
                <option value="">Select Translation Model</option>
                {availableModels.map((model) => (
                  <option key={model.id} value={model.id}>
                    {model.name} 
                  </option>
                ))}
              </select>
              <Button
                variant="outline"
                size="icon"
                onClick={() => {
                  const models = loadAIModels();
                  setAvailableModels(models);
                  if (models.length > 0 && !selectedModel) {
                    setSelectedModel(models[0]);
                  }
                }}
              >
                <RefreshCw className="h-4 w-4" />
              </Button>
            </div>
          </CardContent>
        </Card>

        {/* Translation Container */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {/* Source Language Section */}
          <Card>
            <CardHeader>
              <CardTitle>Source Text</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="flex flex-col gap-4">
                <div className="flex flex-col sm:flex-row gap-2 justify-between">
                  <select 
                    className="w-full sm:w-3/5 border p-2 rounded"
                    value={sourceLanguage}
                    onChange={(e) => setSourceLanguage(e.target.value)}
                  >
                    <option>English</option>
                    <option>Bahasa Melayu</option>
                  </select>
                  <div className="flex gap-2">
                    <Button 
                      variant="outline"
                      onClick={handleClear}
                      className="flex items-center gap-2"
                    >
                      <Trash2 className="h-4 w-4" />
                      {t("clear")}
                    </Button>
                    <Button 
                      onClick={handleTranslate}
                      disabled={isLoading || !sourceText.trim()}
                      className="flex items-center gap-2"
                    >
                      <Languages className="h-4 w-4" />
                      {isLoading ? t("translating") : t("translate")}
                    </Button>
                  </div>
                </div>
                <textarea
                  className="w-full border p-2 rounded h-40 resize-none"
                  placeholder={t("placeholder1")}
                  value={sourceText}
                  onChange={(e) => setSourceText(e.target.value)}
                ></textarea>
              </div>
            </CardContent>
          </Card>

          {/* Target Language Section */}
          <Card>
            <CardHeader>
              <CardTitle>Translated Text</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="flex flex-col gap-4">
                <select
                  className="w-full sm:w-3/5 border p-2 rounded"
                  value={targetLanguage}
                  onChange={(e) => setTargetLanguage(e.target.value)}
                >
                  <option>Bahasa Melayu</option>
                  <option>English</option>
                </select>
                <textarea
                  className="w-full border p-2 rounded h-40 resize-none"
                  placeholder={t("placeholder2")}
                  value={translatedText}
                  readOnly
                ></textarea>
              </div>
            </CardContent>
          </Card>
        </div>

        {/* Translation History */}
        {savedTranslations.length > 0 && (
          <Card>
            <CardHeader className="flex flex-row items-center justify-between">
              <div>
                <CardTitle>Translation History</CardTitle>
                <CardDescription>Your saved translations</CardDescription>
              </div>
              <Button 
                variant="outline" 
                onClick={handleDownloadAll}
                className="flex items-center gap-2"
              >
                <FileDown className="h-4 w-4" />
                Download All
              </Button>
            </CardHeader>
            <CardContent>
              <div className="overflow-x-auto">
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Source Text</TableHead>
                      <TableHead>Translated Text</TableHead>
                      <TableHead>Languages</TableHead>
                      <TableHead>Model</TableHead>
                      <TableHead className="text-right">Actions</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {savedTranslations.map((translation, index) => (
                      <TableRow key={index}>
                        <TableCell className="max-w-[200px] truncate">
                          {translation.sourceText}
                        </TableCell>
                        <TableCell className="max-w-[200px] truncate">
                          {translation.translatedText}
                        </TableCell>
                        <TableCell>
                          {translation.sourceLanguage} → {translation.targetLanguage}
                        </TableCell>
                        <TableCell>
                          <div className="flex items-center gap-2">
                            <div className={`p-1 rounded-lg ${
                              translation.modelType === 'openai' ? 'bg-green-100' :
                              translation.modelType === 'groq' ? 'bg-blue-100' :
                              translation.modelType === 'ollama' ? 'bg-purple-100' :
                              translation.modelType === 'huggingface' ? 'bg-yellow-100' :
                              'bg-gray-100'
                            }`}>
                              {getModelIcon(translation.modelType)}
                            </div>
                            <span>{translation.modelType}</span>
                          </div>
                        </TableCell>
                        <TableCell className="text-right space-x-2">
                          <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => handleEdit(translation)}
                          >
                            <Pencil className="h-4 w-4" />
                          </Button>
                          <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => handleDownload(translation)}
                          >
                            <FileDown className="h-4 w-4" />
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </CardContent>
          </Card>
        )}

        {/* Edit Dialog */}
        <Dialog open={isEditDialogOpen} onOpenChange={setIsEditDialogOpen}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Edit Translation</DialogTitle>
            </DialogHeader>
            <div className="grid gap-4 py-4">
              <div>
                <label className="block mb-2 font-medium">
                  Source Text ({editingTranslation?.sourceLanguage})
                </label>
                <textarea
                  className="w-full border p-2 rounded h-32"
                  value={editedSourceText}
                  onChange={(e) => setEditedSourceText(e.target.value)}
                />
              </div>
              <div>
                <label className="block mb-2 font-medium">
                  Translated Text ({editingTranslation?.targetLanguage})
                </label>
                <textarea
                  className="w-full border p-2 rounded h-32"
                  value={editedTranslatedText}
                  onChange={(e) => setEditedTranslatedText(e.target.value)}
                />
              </div>
            </div>
            <DialogFooter>
              <Button variant="outline" onClick={() => setIsEditDialogOpen(false)}>
                Cancel
              </Button>
              <Button onClick={handleSaveEdit}>
                Save Changes
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export default TextTranslate;
