/** @format */

import React from "react";
import { useNavigate } from "react-router-dom";

const About = () => {
  const navigate = useNavigate();

  const handleStartTranslating = () => {
    navigate("/login");
  };

  return (
    <div className="flex flex-col bg-gray-50 min-h-screen">
      {/* Hero Section */}
      <div className="w-full bg-gradient-to-r from-gray-600 to-gray-700 py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h1 className="text-5xl font-extrabold text-white mb-6">
            Breaking Language Barriers
          </h1>
          <p className="text-xl text-blue-100 max-w-3xl mx-auto">
            MyTranslate combines translation memory with advanced AI to deliver
            precise, consistent translations for your global content
          </p>
        </div>
      </div>

      {/* Key Features Section */}
      <div className="w-full py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center text-gray-900 mb-16">
            Powerful Translation Technology
          </h2>
          
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-16">
            {/* MyTranslate LLM Feature */}
            <div className="bg-white rounded-xl shadow-sm hover:shadow-lg transition-shadow duration-300 overflow-hidden">
              <div className="h-2 bg-gradient-to-r from-blue-500 to-indigo-600"></div>
              <div className="p-8">
                <div className="flex items-center mb-6">
                  <div className="p-3 bg-blue-100 rounded-lg mr-4">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
                    </svg>
                  </div>
                  <h3 className="text-2xl font-bold text-gray-900">MyTranslate LLM</h3>
                </div>
                <p className="text-lg text-gray-700 mb-6">
                  Our proprietary language model understands context and nuance, delivering translations that capture the true meaning of your content, not just word-for-word equivalents.
                </p>
                <div className="grid grid-cols-2 gap-4">
                  <div className="bg-blue-50 rounded-lg p-4">
                    <div className="font-semibold text-blue-700">Context-Aware</div>
                  </div>
                  <div className="bg-blue-50 rounded-lg p-4">
                    <div className="font-semibold text-blue-700">English-Bahasa Melayu</div>
                  </div>
                </div>
              </div>
            </div>

            {/* Translation Memory Feature */}
            <div className="bg-white rounded-xl shadow-sm hover:shadow-lg transition-shadow duration-300 overflow-hidden">
              <div className="h-2 bg-gradient-to-r from-indigo-500 to-purple-600"></div>
              <div className="p-8">
                <div className="flex items-center mb-6">
                  <div className="p-3 bg-indigo-100 rounded-lg mr-4">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-indigo-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
                    </svg>
                  </div>
                  <h3 className="text-2xl font-bold text-gray-900">Translation Memory</h3>
                </div>
                <p className="text-lg text-gray-700 mb-6">
                  Reuse your previous translations with our intelligent memory system that automatically suggests matches as you work, ensuring consistency across all your content.
                </p>
                <div className="grid grid-cols-2 gap-4">
                  <div className="bg-indigo-50 rounded-lg p-4">
                    <div className="font-semibold text-indigo-700">Auto-Suggest</div>
                  </div>
                  <div className="bg-indigo-50 rounded-lg p-4">
                    <div className="font-semibold text-indigo-700">Terminology</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Secondary Features Section */}
      <div className="w-full py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center text-gray-900 mb-12">
            Additional Features
          </h2>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-gradient-to-br from-blue-50 to-indigo-50 rounded-xl p-6 hover:shadow-md transition-shadow duration-300">
              <div className="text-blue-600 mb-2">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
                </svg>
              </div>
              <h3 className="font-bold text-xl text-gray-900 mb-2">Smart Terms</h3>
              <p className="text-gray-700">Create and manage terminology databases to ensure consistency across all translations.</p>
            </div>
            
            <div className="bg-gradient-to-br from-purple-50 to-pink-50 rounded-xl p-6 hover:shadow-md transition-shadow duration-300">
              <div className="text-purple-600 mb-2">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                </svg>
              </div>
              <h3 className="font-bold text-xl text-gray-900 mb-2">File Translation</h3>
              <p className="text-gray-700">Upload documents in various formats and receive professionally translated versions with original formatting.</p>
            </div>
            
            <div className="bg-gradient-to-br from-green-50 to-teal-50 rounded-xl p-6 hover:shadow-md transition-shadow duration-300">
              <div className="text-green-600 mb-2">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
                </svg>
              </div>
              <h3 className="font-bold text-xl text-gray-900 mb-2">JSON Generation</h3>
              <p className="text-gray-700">Automatically create ready-to-use localization files for your software applications.</p>
            </div>
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="w-full py-16 bg-gradient-to-r from-gray-600 to-gray-700">
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 text-center text-white">
          <h2 className="text-3xl font-bold mb-4">Our Mission</h2>
          <p className="text-xl mb-8 max-w-3xl mx-auto">
            We're dedicated to breaking down language barriers and helping businesses
            communicate clearly with audiences worldwide through innovative AI translation technology.
          </p>
          <button 
            className="bg-white text-indigo-700 font-semibold px-8 py-3 rounded-lg hover:bg-blue-50 transition-colors duration-300"
            onClick={handleStartTranslating}
          >
            Start Translating Today
          </button>
        </div>
      </div>
    </div>
  );
};

export default About;
